import { genUniqueId } from "./fn";

export default function getUniqueOrchidDeviceId() {
  let orchidDeviceId = window.localStorage.getItem("orchidDeviceId");
  if (orchidDeviceId === null) {
    orchidDeviceId = genUniqueId(16);
    window.localStorage.setItem("orchidDeviceId", orchidDeviceId);
  }
  return orchidDeviceId;
}
