import { useEffect, useMemo, useRef, useReducer } from "react";

import { useUser } from "contexts/UserContext";
import { fetchOrchidAPI } from "utils/api";
import {
  reportError,
  reportProblem,
  UNKNOWN_ERROR_MESSAGE,
} from "utils/frontend_error_reporting";
import { sleep } from "utils/fn";
import { getErrorDetail } from "components/utility/utils";

import { APIRequests, APIRequestResult, RequestResultDataType } from "./types";
import {
  APIDataProviderReducer,
  initApiDataProviderState,
  fetchDelayTime,
} from "./reducers/api-data-provider-state";

export * from "./types";

/*
Example use:

import { usePrivateAPIData } from "contexts/api-data-provider";

...

const {
  appointments: {
    result: appointments,
    errorText: appointmentsError
  },
  groupSessions: {
    result: groupSessions,
    errorText: groupSessionsError
  },
} = usePrivateAPIData({
    appointments: {resource: "/api/scheduler/v1/snapshot/appointments_v2"},
    groupSessions: {resource: "/api/scheduler/v1/snapshot/group_sessions"},
}, 0);

...

<p>Error: {appointmentsError && getErrorDetail(appointmentsError)}</p>
<p>Error: {groupSessionsError && getErrorDetail(groupSessionsError)}</p>
<pre>appointments: {JSON.stringify(appointments, null, 2)}</pre>
<pre>group sessions: {JSON.stringify(groupSessions, null, 2)}</pre>

*/

export function usePrivateAPIData(
  requests: APIRequests,
  reloadTimestamp: number | undefined,
): APIRequestResult {
  const { user } = useUser();
  const userSub = user?.sub;

  const [{ resultData, currentRequest }, stateDispatch] = useReducer(
    APIDataProviderReducer,
    initApiDataProviderState(requests, reloadTimestamp),
  );

  const prevUserSubRef = useRef<undefined | string>();

  useEffect(() => {
    if (userSub !== prevUserSubRef.current) {
      stateDispatch({
        type: "reset",
        currentTimestamp: new Date().getTime(),
        requests,
        reloadTimestamp,
      });
    } else {
      stateDispatch({
        type: "requests-change",
        currentTimestamp: new Date().getTime(),
        requests,
        reloadTimestamp,
      });
    }
    prevUserSubRef.current = userSub;
  }, [userSub, requests, reloadTimestamp]);

  useEffect(() => {
    if (!currentRequest) return;

    let abortController = new AbortController();

    async function fetchNext(currentRequest: RequestResultDataType) {
      const { key, requestData } = currentRequest;

      const timeToSleep = fetchDelayTime(currentRequest, new Date().getTime());
      if (timeToSleep > 0) await sleep(timeToSleep);

      try {
        if (abortController === null) {
          // we report this but still try to continue
          await reportProblem({
            reporter_module: "usePrivateAPIData",
            context: { func: "fetchNext" },
            frontend_error: "abortController cannot be null",
          });
        }
        const response = await fetchOrchidAPI(requestData.resource, {
          ...requestData.fetchArgs,
          signal: abortController ? abortController.signal : null,
        });
        if (!response.ok) {
          const errorText = await response.text();
          stateDispatch({
            type: "load-failed",
            currentTimestamp: new Date().getTime(),
            key,
            errorText,
            statusCode: response.status,
          });
        } else {
          const resultData = await response.json();
          stateDispatch({
            type: "data-loaded",
            currentTimestamp: new Date().getTime(),
            key,
            resultData,
            statusCode: response.status,
          });
        }
      } catch (e: unknown) {
        const err = e instanceof Error ? e : new Error(`${e}`);
        if (err.name === "AbortError") {
          // this fetch was cancelled
          return;
        }
        await reportError({
          reporter_module: "usePrivateAPIData",
          context: { func: "fetchNext" },
          err,
        });
        stateDispatch({
          type: "load-failed",
          currentTimestamp: new Date().getTime(),
          key,
          errorText: UNKNOWN_ERROR_MESSAGE,
          statusCode: 503,
        });
      }
    }

    (async () => fetchNext(currentRequest))();

    return () => {
      if (abortController) abortController.abort();
    };
  }, [currentRequest]);

  return resultData;
}

export function useFetchAPIError(
  results: APIRequestResult,
): string | undefined {
  return useMemo(() => {
    for (const result of Object.values(results)) {
      if (result.errorText) return getErrorDetail(result.errorText);
    }
    return undefined;
  }, [results]);
}
