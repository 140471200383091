import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { useUser } from "contexts/UserContext";

import { apiHostAndPath } from "utils/api";
import getUniqueOrchidDeviceId from "utils/orchidDeviceId";

interface OrchidWebSocketContextProps {
  // todo: add lastMessage type
  lastMessage: Record<string, any> | null;
}

export const OrchidWebSocketContext =
  createContext<OrchidWebSocketContextProps>({} as OrchidWebSocketContextProps);

interface Props {
  children: ReactNode;
}

export const OrchidWebSocketProvider = ({ children }: Props) => {
  const userSub = useUser().user?.sub ?? "";
  const [lastMessage, setLastMessage] =
    useState<OrchidWebSocketContextProps["lastMessage"]>(null);
  const orchidSocketRef = useRef<WebSocket | null>(null);
  const retryCountRef = useRef(0);
  const reconnectTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );

  const connectWebSocket = useCallback(() => {
    if (reconnectTimeoutRef.current !== null) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    reconnectTimeoutRef.current = null;
    retryCountRef.current += 1;

    orchidSocketRef.current = new WebSocket(
      apiHostAndPath("/instant_messaging/ws"),
    );
    orchidSocketRef.current.addEventListener("message", (event) => {
      retryCountRef.current = 0;
      const message = JSON.parse(event.data);
      console.log("orchidSocket >> ", message);
      setLastMessage(message);
    });

    orchidSocketRef.current.addEventListener("error", () => {
      if (orchidSocketRef.current) {
        orchidSocketRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        return;
      }
      const retryMs = Math.min(
        60000,
        500 * (retryCountRef.current * retryCountRef.current),
      );
      console.log(
        `websocket error (retry count ${retryCountRef.current}), reconnection in ${retryMs}ms`,
      );
      reconnectTimeoutRef.current = setTimeout(
        () => connectWebSocket(),
        retryMs,
      );
    });
    orchidSocketRef.current.addEventListener("close", () => {
      if (orchidSocketRef.current) {
        orchidSocketRef.current.close();
      }
      if (reconnectTimeoutRef.current) return;
      const retryMs = Math.min(
        60000,
        500 * (retryCountRef.current * retryCountRef.current),
      );
      console.log(
        `websocket error (retry count ${retryCountRef.current}), reconnection in ${retryMs}ms`,
      );
      reconnectTimeoutRef.current = setTimeout(
        () => connectWebSocket(),
        retryMs,
      );
    });
  }, []);

  // on user change (componentDidMount + componentDidUpdate)
  useEffect(() => {
    if (userSub) {
      connectWebSocket();
      console.log("user login by websocket context");
    } else {
      // TODO: clear user
    }
  }, [userSub, connectWebSocket]);

  useEffect(() => {
    const orchidDeviceId = getUniqueOrchidDeviceId();
    document.cookie = `orchid_device_id=${orchidDeviceId}; SameSite=lax; Secure`;
  });

  return (
    <OrchidWebSocketContext.Provider
      value={{
        lastMessage,
      }}
    >
      {children}
    </OrchidWebSocketContext.Provider>
  );
};

export const useOrchidWebSocket = () => {
  return useContext(OrchidWebSocketContext);
};
