import React, { useState } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import requestDemo from "services/requestDemo";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";
import { AmplitudePageName, track } from "utils/amplitude";

const AMPLITUDE_COMPONENT_NAME = "demo-registration-form";

interface Props {
  amplitudePageName: AmplitudePageName;
}

const OrchidProRegisterForm: React.FC<Props> = ({ amplitudePageName }) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const [state, setState] = useState({
    email: "",
    first_name: "",
    last_name: "",
    validated: false,
  });

  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError("");
    setSuccessMsg("");

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setState({
        ...state,
        validated: true,
      });
      return;
    }
    setShowLoader(true);

    try {
      await requestDemo(state.first_name, state.last_name, state.email);
      window.location.href = `https://calendly.com/hey-orchid/orchid-walkthrough?name=${state.first_name}%20${state.last_name}&email=${state.email}`;
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  const onInputChange = (event: any) => {
    setState({
      ...state,
      [event.target.id]: event.target.value,
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validated={state.validated}
      className={getClasses(
        styles["book-demo"],
        isPhonePortrait && styles["book-demo--phone"],
      )}
      noValidate
    >
      <div className={styles["book-demo__form-elements"]}>
        <Form.Group
          controlId="email"
          className={styles["book-demo__form-group"]}
        >
          <Form.Control
            required
            type="email"
            placeholder="Your best email address"
            value={state.email}
            onChange={onInputChange}
            className={styles["book-demo__form-field"]}
            onFocus={() =>
              track(
                amplitudePageName,
                [AMPLITUDE_COMPONENT_NAME, "email-field"],
                "focus",
              )
            }
          />
        </Form.Group>
        <Form.Group
          controlId="first_name"
          className={styles["book-demo__form-group"]}
        >
          <Form.Control
            required
            type="text"
            placeholder="Your First Name"
            className={styles["book-demo__form-field"]}
            value={state.first_name}
            onChange={onInputChange}
            onFocus={() =>
              track(
                amplitudePageName,
                [AMPLITUDE_COMPONENT_NAME, "first-name-field"],
                "focus",
              )
            }
          />
        </Form.Group>
        <Form.Group
          controlId="last_name"
          className={styles["book-demo__form-group"]}
        >
          <Form.Control
            required
            type="text"
            name="last_name"
            placeholder="Your Last Name"
            className={styles["book-demo__form-field"]}
            value={state.last_name}
            onChange={onInputChange}
            onFocus={() =>
              track(
                amplitudePageName,
                [AMPLITUDE_COMPONENT_NAME, "last-name-field"],
                "focus",
              )
            }
          />
        </Form.Group>

        {error && (
          <Alert
            variant="danger"
            className={getClasses(
              "mb-0",
              !isPhonePortrait && styles["book-demo-message"],
            )}
          >
            {error}
          </Alert>
        )}
        {successMsg && (
          <Alert
            variant="success"
            className={getClasses(
              "mb-0",
              !isPhonePortrait && styles["book-demo-message"],
            )}
          >
            {successMsg}
          </Alert>
        )}
      </div>
      <Button
        type="submit"
        disabled={
          !(state.email && state.first_name && state.last_name) || showLoader
        }
        className={styles["book-demo-button"]}
        onClick={() =>
          track(
            amplitudePageName,
            [AMPLITUDE_COMPONENT_NAME, "get-free-demo-button"],
            "click",
          )
        }
      >
        Get Your Free 20-Minute Demo{" "}
        {showLoader && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </Form>
  );
};

export default OrchidProRegisterForm;
