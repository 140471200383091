import React, { useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as MdiHeart } from "assets/svg/switch-to-orchid/mdi-heart.svg";
import { ReactComponent as EHR } from "assets/svg/switch-to-orchid/EHR.svg";
import { ReactComponent as CalendarCheck } from "assets/svg/switch-to-orchid/calendar-check.svg";
import { ReactComponent as Clock } from "assets/svg/switch-to-orchid/clock.svg";
import { ReactComponent as ShieldCross } from "assets/svg/switch-to-orchid/shield-cross.svg";
import { ReactComponent as Clinician } from "assets/svg/switch-to-orchid/clinician.svg";
import { ReactComponent as LowestCost } from "assets/svg/switch-to-orchid/lowest-cost.svg";
import LazyImage from "components/lazy-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import SwitchToOrchidPhoneView from "./STOPhoneView";
import Footer from "components/footer/Footer";
import FullWidthImage from "components/full-width-image";

import { useTimeOnPageContext } from "contexts/time-on-page";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

import { Helmet } from "react-helmet";

import { AmplitudePageName, track } from "utils/amplitude";

import getClasses from "utils/getClasses";
import styles from "./SwitchToOrchid.module.scss";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "switch-to-orchid-page";

const ACCOMPLISHED = "/img/switch-to-orchid/accomplished.png";
const STEP_1 = "/img/switch-to-orchid/step-1.png";
const STEP_2 = "/img/switch-to-orchid/step-2.png";
const STEP_3 = "/img/switch-to-orchid/step-3.png";
const SWITCH = "/img/switch-to-orchid/switch.png";
const HELLO = "/img/switch-to-orchid/hello.png";
const PRIORITY = "/img/switch-to-orchid/priority.png";
const LESS_TIME = "/img/switch-to-orchid/less-time.png";

export const reasons = [
  {
    icon: <LowestCost />,
    title: "Lowest Payment Processing Fees",
  },
  {
    icon: <Clinician />,
    title: "Owned by Clinicians",
  },
  {
    icon: <Clock />,
    title: "Time-Saving Automation",
  },
  {
    icon: <CalendarCheck />,
    title: "Best-In-Class Scheduling",
  },
  {
    icon: <ShieldCross />,
    title: "Full HIPAA Compliance",
  },
  {
    icon: <EHR />,
    title: "Integrated EHR",
  },
];

export const testimonials = [
  {
    id: "0",
    content:
      "I thought switching from my EHR to Orchid would be hard! In minutes, I was able to see all of my patients onboarded.",
    date: "September 25, 2023",
    author: "Verified Orchid Clinician",
  },
  {
    id: "1",
    content:
      "When my friend asked me whether switching to Orchid was a pain, I was shocked to tell her that it was the smoothest switch I could have imagined!",
    date: "October 02, 2023",
    author: "Verified Orchid Clinician",
  },
  {
    id: "2",
    content:
      "The Orchid Team really made transferring my files from my EHR super easy. Once they had my files, I didn't have to do anything else other than start scheduling my patients!",
    date: "August 14, 2023",
    author: "Verified Orchid Clinician",
  },
];

export const questions = [
  {
    id: "0",
    question: "How easy is it to switch to Orchid?",
    answer:
      "Orchid provides 1-on-1 support to make the switch to Orchid super simple. We provide detailed guidance throughout the process and make sure you’re satisfied with the result.",
  },
  {
    id: "1",
    question: "What are the steps to switch to Orchid?",
    answer: `<p>It all starts with signing up for your 30-day free trial. From there, you can export your client data from your current EHR, we&rsquo;ll assist in uploading that client list to your new Orchid account, and voila &mdash; you&rsquo;ll be ready to use Orchid within 24 hours.</p>
      <p><a href="https://orchid.exchange/pricing" target="_blank" rel="noopener">Start your free trial &rarr;</a></p>`,
  },
  {
    id: "2",
    question: "What benefits do I get by switching to Orchid?",
    answer: `<p>When you switch to Orchid, you get access to the most affordable and innovative EHR in the industry. You’ll be able to manage your practice with top-rated time-saving features, like <a href='https://orchid.exchange/ai-progress-note-generator' target='_blank' rel='noopener'>our AI-powered clinical notes assistant</a>. Plus, our dedicated team is always standing by to support.</p>`,
  },
  {
    id: "3",
    question: "Is there support available during the switching process?",
    answer:
      "Yes, our onboarding experts are available to assist you with your data transfer, account set-up, and any questions you have along the way.",
  },
  {
    id: "4",
    question: "Is Orchid HIPAA-compliant?",
    answer: `<p>Yes, Orchid ensures HIPAA-compliance for your practice's security and confidentiality needs​​.</p>
      <p><a href="https://orchid.exchange/ehr-security" target="_blank" rel="noopener">Learn more about our EHR security measures &rarr;</a></p>`,
  },
];

const SwitchToOrchid = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  const { isPhonePortrait } = usePhonePortraitContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Make the Simpler Switch to Orchid EHR</title>
        <meta
          name="description"
          content="Learn how simple it is to switch EHRs to Orchid for your all-in-one private practice management needs."
        />
        <meta
          property="og:title"
          content="Make the Simpler Switch to Orchid EHR"
        />
        <meta
          property="og:description"
          content="Learn how simple it is to switch EHRs to Orchid for your all-in-one private practice management needs."
        />

        <meta
          name="twitter:title"
          content="Make the Simpler Switch to Orchid EHR"
        />
        <meta
          name="twitter:description"
          content="Learn how simple it is to switch EHRs to Orchid for your all-in-one private practice management needs."
        />
      </Helmet>
      {isPhonePortrait ? (
        <SwitchToOrchidPhoneView />
      ) : (
        <div className={styles["switch-to-orchid"]}>
          <div className={styles["switch"]}>
            <Container
              className={getClasses(
                styles["container"],
                styles["switch__left"],
              )}
            >
              <div className={styles["switch__title"]}>
                Switch to Orchid today.
              </div>
              <div className={styles["switch__text"]}>
                Get the powerful tools your practice needs to grow, <br /> plus
                a simpler way to switch EHRs in a snap.
              </div>
              <div className={"d-flex"}>
                <Button
                  className={styles["action"]}
                  as={Link}
                  to={"/professional-register"}
                >
                  Switch to Orchid for Free
                </Button>
              </div>
            </Container>
            <div className={styles["switch__right"]}>
              <LazyImage src={SWITCH} width={704} height={"auto"} />
            </div>
          </div>

          <Container className={styles["transition__container"]}>
            <h1 className={styles["title"]}>
              Your Simple Orchid EHR Transition Plan
            </h1>
            <div className={styles["start-trial"]}>
              <Link
                to="/pricing/core/month/trial"
                onClick={() =>
                  track(AMPLITUDE_PAGE_NAME, "start-trial-button", "click")
                }
              >
                Start your 30-day free trial
              </Link>
              , and we’ll make your EHR transition as simple as pie.*
            </div>

            <Row className={styles["step-row"]}>
              <Col className={styles["step-left"]}>
                <FullWidthImage src={STEP_1} fluid />
              </Col>
              <Col className={styles["step-right"]}>
                <div className={styles["step-tag"]}>STEP 1</div>
                <div className={styles["step-title"]}>
                  Export Your data from your ex-EHR.
                </div>
                <div className={styles["step-content"]}>
                  Follow the instructions in our handy EHR Transition Guide.
                </div>
              </Col>
            </Row>

            <Row className={styles["step-row"]}>
              <Col className={styles["step-2-left"]}>
                <div className={styles["step-tag"]}>STEP 2</div>
                <div className={styles["step-title"]}>
                  Let us import your data to Orchid.
                </div>
                <div className={styles["step-content"]}>
                  We’ll take care of those data import headaches for you.
                </div>
              </Col>
              <Col className={styles["step-2-right"]}>
                <FullWidthImage src={STEP_2} fluid />
              </Col>
            </Row>

            <Row className={styles["step-row"]}>
              <Col className={styles["step-left"]}>
                <FullWidthImage src={STEP_3} fluid />
              </Col>
              <Col className={styles["step-right"]}>
                <div className={styles["step-tag"]}>STEP 3</div>
                <div className={styles["step-title"]}>
                  Start using Orchid – all within a day.
                </div>
                <div className={styles["step-content"]}>
                  Get back to work right away with everything you need to
                  succeed.{" "}
                </div>
              </Col>
            </Row>
            <div className={styles["accomplished"]}>
              <div className={styles["accomplished-left"]}>
                <div className={styles["accomplished-left__title"]}>
                  Simple EHR Transition:
                </div>
                <div className={styles["accomplished-left__accomplished-text"]}>
                  Accomplished &#10003;
                </div>
                <div className={styles["accomplished-left__small-text"]}>
                  *Actual pie not included.
                </div>
              </div>
              <LazyImage src={ACCOMPLISHED} width={760} height={"auto"} />
            </div>
          </Container>

          <div className={styles["why-switch"]}>
            <Container className={styles["why-switch__container"]}>
              <h2 className={styles["why-switch__title"]}>
                Why Switch to Orchid?
              </h2>
              <Row>
                {reasons.map((reason) => {
                  return (
                    <Col className={styles["why-switch__col"]} md={4}>
                      <Card className={styles["why-switch__card"]}>
                        <div>{reason.icon}</div>
                        <div className={styles["why-switch__card-text"]}>
                          {reason.title}
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>

          <Container className={styles["card-section__container"]}>
            <Row className={styles["card-row"]}>
              <Col className={styles["card-left"]}>
                <FullWidthImage src={HELLO} fluid />
              </Col>
              <Col className={styles["card-right"]}>
                <div className={styles["card-title"]}>
                  <span
                    className={getClasses(
                      "pl-0",
                      styles["card-title__highlight"],
                    )}
                  >
                    One
                  </span>{" "}
                  affordable account is all you need.
                </div>
                <p className={"mb-3"}>
                  Use Orchid’s comprehensive EHR for everything you need to
                  streamline and grow your practice — all from a single account.
                </p>
                <Button
                  className={"p-0"}
                  variant={"link"}
                  as={Link}
                  to={"/pricing"}
                >
                  Explore all of Orchid’s benefits.
                </Button>
              </Col>
            </Row>
            <Row className={styles["card-row"]}>
              <Col className={styles["card-left"]}>
                <FullWidthImage src={LESS_TIME} fluid />
              </Col>
              <Col className={styles["card-right"]}>
                <div className={styles["card-title"]}>
                  Accomplish more in{" "}
                  <span
                    className={getClasses(
                      "pr-0",
                      styles["card-title__highlight"],
                    )}
                  >
                    less time
                  </span>
                  .
                </div>
                <p className={"mb-3"}>
                  Get innovative time-saving features like our fully
                  customizable templates, simpler online scheduling, Orchid’s AI
                  clinical notes assistant, and much more.
                </p>
                <Button
                  className={"p-0"}
                  variant={"link"}
                  as={Link}
                  to={"/blogs"}
                >
                  Learn more at the Orchid Blog.
                </Button>
              </Col>
            </Row>
            <Row className={styles["card-row"]}>
              <Col className={styles["card-left"]}>
                <FullWidthImage src={PRIORITY} fluid />
              </Col>
              <Col className={styles["card-right"]}>
                <div className={styles["card-title"]}>
                  Your
                  <span className={styles["card-title__highlight"]}>
                    #1 priority
                  </span>{" "}
                  is our #1 priority.
                </div>
                <p className={"mb-3"}>
                  Our team is here to give you personalized support for your
                  practice’s unique needs at every stage of the EHR transition —
                  and beyond.
                </p>
                <Button
                  className={"p-0"}
                  variant={"link"}
                  as={"a"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  href={"https://calendly.com/hey-orchid/orchid-walkthrough"}
                >
                  Set up your first free demo.
                </Button>
              </Col>
            </Row>
          </Container>
          <div className={styles["i-love-ehr"]}>
            <Container className={styles["i-love-ehr__container"]}>
              <div className={styles["i-love-ehr__title"]}>
                “I <MdiHeart /> my EHR,”
              </div>
              <div
                className={getClasses(
                  styles["bottom-56"],
                  styles["i-love-ehr__title"],
                )}
              >
                said no therapist ever — until they tried Orchid.
              </div>
              <Row className={styles["item-list"]}>
                {testimonials.map((item) => (
                  <Col md={4} key={item.id} className={styles["item"]}>
                    <Card className={styles["card"]}>
                      <div className={styles["card__body"]}>
                        <div className={styles["quote-sign"]}>“</div>
                        <div>{item.content}</div>
                      </div>
                      <div className={styles["card__footer"]}>
                        <span>{item.date}</span>
                        <span>•</span>
                        <span>{item.author}</span>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
          <Container className={styles["container"]}>
            <div className={styles["slogan"]}>
              <div className={styles["slogan__title"]}>
                Private Practice – Made Perfect
              </div>
              <div className={styles["slogan__content"]}>
                Save time, money, and admin headaches when you switch to Orchid
                today.
                <br />
                Experience the difference of managing your practice with Orchid.
              </div>
              <div className={"d-flex justify-content-center"}>
                <Button
                  className={styles["action"]}
                  as={Link}
                  to={"/professional-register"}
                >
                  Switch to Orchid
                </Button>
              </div>
            </div>

            <div className={styles["faq"]}>
              <h1 className={getClasses(styles["bottom-56"], styles["title"])}>
                FAQ: Switching to Orchid
              </h1>
              {questions.map(({ id, question, answer }, idx) => {
                return (
                  <CustomAccordion
                    key={id}
                    id={id}
                    index={idx}
                    question={question}
                    answer={answer}
                    amplitudePageName={AMPLITUDE_PAGE_NAME}
                  />
                );
              })}
            </div>
          </Container>

          <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
        </div>
      )}
    </>
  );
};

export default SwitchToOrchid;
