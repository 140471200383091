import React, { useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Icon1 } from "assets/svg/ehr-security/icon-1.svg";
import { ReactComponent as Icon2 } from "assets/svg/ehr-security/icon-2.svg";
import { ReactComponent as Icon3 } from "assets/svg/ehr-security/icon-3.svg";
import { ReactComponent as Icon4 } from "assets/svg/ehr-security/icon-4.svg";
import { ReactComponent as Icon5 } from "assets/svg/ehr-security/icon-5.svg";
import { ReactComponent as Icon6 } from "assets/svg/ehr-security/icon-6.svg";
import { ReactComponent as Icon7 } from "assets/svg/ehr-security/icon-7.svg";
import { ReactComponent as Icon8 } from "assets/svg/ehr-security/icon-8.svg";
import LazyImage from "components/lazy-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import EHRSecurityPhoneView from "./ESPhoneView";
import Footer from "components/footer/Footer";

import { useTimeOnPageContext } from "contexts/time-on-page";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

import { Helmet } from "react-helmet";

import { AmplitudePageName /*, track */ } from "utils/amplitude";

import getClasses from "utils/getClasses";
import styles from "./EHRSecurity.module.scss";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "ehr-security-page";

const LANDING = "/img/ehr-security/landing.png";

export const items = [
  {
    icon: <Icon1 />,
    title: "Advanced EHR Security Measures",
    content:
      "Orchid’s HIPAA-compliant EHR software prioritizes the confidentiality, privacy, and system availability of your data. Our unwavering commitment to security emphasizes safeguarding your electronic health records through state-of-the-art EHR security measures.",
  },
  {
    icon: <Icon2 />,
    title: "SOC 2 Type 2 Compliance",
    content:
      "Our system's security meets the standards of SOC 2 Type 2 compliance. That ensures the highest level of security for both patient and clinician data on our platform.",
  },
  {
    icon: <Icon3 />,
    title: "Military-Grade Encryption",
    content:
      "We use the most durable end-to-end encryption methods to secure sensitive data. This means that your files are encrypted during transmission and encrypted again when stored in our system, which minimizes the risk of data loss or unauthorized access.",
  },
  {
    icon: <Icon4 />,
    title: "Continuous Security Monitoring",
    content:
      "Our security team conducts regular scans of all services for vulnerabilities, promptly addressing any identified risks. We also consult with leading independent security firms to stay vigilant about external vulnerability threat assessments and penetration testing. These EHR security measures ensure our defenses remain up-to-date and effective against evolving threats.",
  },
  {
    icon: <Icon5 />,
    title: "High System Availability",
    content:
      "We guarantee a high system uptime (99.9% availability), and we have fail-safes for those just-in-case scenarios. Our data backup system is rigorous, with data stored in top-tier data centers that are protected 24/7, 365 days/year, ensuring continuous service and data protection even in case of catastrophic events.",
  },
  {
    icon: <Icon6 />,
    title: "Stringent Data Handling",
    content:
      "We adhere to strict data-handling requirements for electronic Protected Health Information (PHI), Personally Identifiable Information (PII), and Payment Card Information (PCI). This includes secure disposal systems and role-based access permissions, which ensures that only authorized personnel have to access sensitive information.",
  },
  {
    icon: <Icon7 />,
    title: "Your Data, Your Control",
    content:
      "Orchid respects your data ownership and privacy. We do not share your data for any item. If at any point you cancel your Orchid subscription, all patient data is securely removed from our systems.",
  },
  {
    icon: <Icon8 />,
    title: "Industry-Leading Cyber Liability Insurance",
    content:
      "To further protect our customers, we maintain industry-leading cyber liability insurance. That liability insurance covers a range of potential claims and provides an additional layer of security and peace of mind for all Orchid customers.",
  },
];

export const questions = [
  {
    id: "0",
    question: "Is Orchid HIPAA-compliant?",
    answer: "Yes, Orchid is HIPAA-compliant EHR software.",
  },
  {
    id: "1",
    question: "Do you have a Business Associate Agreement (BAA)?",
    answer:
      "Yes, Orchid includes a BAA for you to sign. When you start your free 30-day trial of Orchid, you’ll automatically be prompted to review the BAA and agree to it.",
  },
  {
    id: "2",
    question: "Who owns the data I input into Orchid?",
    answer:
      "As an Orchid user, you retain full ownership of all data you input. Your privacy and data sovereignty are our top priorities.",
  },
  {
    id: "3",
    question: "How does Orchid keep my data & my clients’ data secure?",
    answer: `<p>We adhere to the highest industry standards, including meeting SOC 2 Type 2 standards, which reflects our ongoing commitment to data security and privacy.</p>
      <p>Additionally, our platform undergoes regular security audits and updates to continually enhance Orchid’s defenses against emerging cybersecurity threats.</p>
      <p>These rigorous measures demonstrate our dedication to maintaining a secure and trustworthy environment for mental health clinicians and your clients.</p>
      `,
  },
];

const EHRSecurity = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  const { isPhonePortrait } = usePhonePortraitContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Orchid | EHR Security Measures You Can Trust</title>
        <meta
          name="description"
          content="Find out how Orchid’s HIPAA-compliant EHR software stays committed to the latest in EHR security measures and data privacy regulations."
        />
        <meta
          property="og:title"
          content="Orchid | EHR Security Measures You Can Trust"
        />
        <meta
          property="og:description"
          content="Find out how Orchid’s HIPAA-compliant EHR software stays committed to the latest in EHR security measures and data privacy regulations."
        />

        <meta
          name="twitter:title"
          content="Orchid | EHR Security Measures You Can Trust"
        />
        <meta
          name="twitter:description"
          content="Find out how Orchid’s HIPAA-compliant EHR software stays committed to the latest in EHR security measures and data privacy regulations."
        />
      </Helmet>
      {isPhonePortrait ? (
        <EHRSecurityPhoneView />
      ) : (
        <div>
          <div className={styles["landing"]}>
            <Container
              className={getClasses(
                styles["container"],
                styles["landing__left"],
              )}
            >
              <div className={styles["landing__title"]}>
                Unparalleled EHR
                <br /> Security You Can Trust
              </div>
              <div className={styles["landing__text"]}>
                Put your confidence in Orchid’s expert EHR security measures.
              </div>
              <div className={"d-flex"}>
                <Button
                  className={styles["action"]}
                  as={Link}
                  to={"/professional-register"}
                >
                  Get Your Secure Free Trial
                </Button>
              </div>
            </Container>
            <div className={styles["landing__right"]}>
              <LazyImage src={LANDING} width={821} height={"auto"} />
            </div>
          </div>
          <Container
            className={getClasses(styles["top-120"], styles["container"])}
          >
            <div className={styles["list__title"]}>
              Guaranteed Worry-Free EHR Security
            </div>
            <div className={styles["list__content"]}>
              Orchid is committed to the most rigorous EHR security measures, so
              you can <br />
              focus on your commitment to client care. 
            </div>
            <Row>
              {items.map((item) => {
                return (
                  <Col key={item.title} className={styles["list__col"]} md={6}>
                    <Card className={styles["list__card"]}>
                      <Row>
                        <div className={styles["list__card-left"]}>
                          {item.icon}
                        </div>
                        <div className={styles["list__card-right"]}>
                          <div className={styles["list__card-title"]}>
                            {item.title}
                          </div>
                          <div className={styles["list__card-content"]}>
                            {item.content}
                          </div>
                        </div>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            <div className={styles["commitment"]}>
              <div className={styles["commitment__title"]}>
                Our commitment to your security is no joke.
              </div>
              <div className={styles["commitment__content"]}>
                The whole team here at Orchid understands the critical
                importance of EHR security. We continuously evolve our security
                measures to meet and exceed industry standards, ensuring that
                everything from data protection to network protection is
                covered.
              </div>
              <div className={"d-flex"}>
                <Button
                  className={styles["action"]}
                  as={Link}
                  to={"/professional-register"}
                >
                  Try Orchid for Free
                </Button>
              </div>
            </div>
            <div className={styles["faq"]}>
              <h1 className={getClasses(styles["bottom-56"], styles["title"])}>
                FAQ:
                <br />
                Orchid EHR Security & Compliance
              </h1>
              {questions.map(({ id, question, answer }, idx) => {
                return (
                  <CustomAccordion
                    key={id}
                    id={id}
                    index={idx}
                    question={question}
                    answer={answer}
                    amplitudePageName={AMPLITUDE_PAGE_NAME}
                  />
                );
              })}
            </div>
            <div className={styles["more-question"]}>
              <div className={styles["more-question__title"]}>
                Got more questions about Orchid?
              </div>
              <Button as={Link} to={"/orchid-ehr-faq"}>
                Read the rest of our FAQs
              </Button>
            </div>
            <div className={styles["slogan"]}>
              <div className={styles["slogan__title"]}>
                Private Practice – Made Perfect
              </div>
              <div className={styles["slogan__content"]}>
                Save time, money, and admin headaches when you switch to Orchid
                today.
                <br />
                Experience the difference of managing your practice with Orchid.
              </div>
              <div className={"d-flex justify-content-center"}>
                <Button
                  size={"lg"}
                  className={styles["action"]}
                  as={Link}
                  to={"/professional-register"}
                >
                  Switch to Orchid
                </Button>
              </div>
            </div>
          </Container>

          <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
        </div>
      )}
    </>
  );
};

export default EHRSecurity;
