import React, { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

type Props = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

/**
 * renders a logo icon spinner suitable to be used in a component
 */
const LogoLoading = ({ className, ...props }: Props) => (
  // <video
  //   autoPlay
  //   loop
  //   muted
  //   playsInline
  //   width={100}
  //   height={100}
  //   {...props}
  // >
  //   <source src="/img/loader.webm" type="video/webm" />
  //   <source src="/img/loader.mp4" type="video/mp4" />
  // </video>
  <img
    alt={"loading"}
    src="/img/loader.gif"
    className={getClasses(styles["logo-loading"], className)}
    {...props}
  />
);

export default LogoLoading;
