import React, { ReactNode } from "react";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useHistory } from "react-router-dom";
import IconButton from "components/icon-button";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left.svg";
import styles from "./ContentHeader.module.scss";
import getClasses from "utils/getClasses";

type Props = {
  children?: ReactNode;
  className?: string;
  title?: string;
  hideBackButton?: boolean;
};

const ContentHeader = ({
  children,
  className,
  title,
  hideBackButton,
  ...props
}: Props) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const history = useHistory();

  return !isPhonePortrait ? null : (
    <header
      className={getClasses(
        styles["content-header"],
        hideBackButton && styles["content-header--hide-back-button"],
        className,
      )}
      {...props}
    >
      {children || (
        <>
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft />
          </IconButton>
          <div className="fw-500 fs-18">{title}</div>
        </>
      )}
    </header>
  );
};

export default ContentHeader;
