import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

export default async function login(usernameOrEmail: string, password: string) {
  const response = await fetchOrchidAPI("/api/login/v1/initiate_auth", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username_or_email: usernameOrEmail.trim(),
      password: password,
    }),
  });
  if (!response.ok) {
    const responseText = await response.text();
    throw new Error(getErrorDetail(responseText));
  }

  return;
}
