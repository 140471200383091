export const FAQ_GET_STARTED = [
  {
    id: "0",
    question: "What is Orchid?",
    answer: `<p>Orchid is an innovative electronic health record built by clinicians for clinicians. We&rsquo;re proud to be a
    privately owned company, <a href="https://www.ycombinator.com/companies/orchid" target="_blank"
        rel="noopener">backed by Y Combinator</a>.&nbsp;</p>
<p>Not only is Orchid&rsquo;s EHR the most affordable private practice management software on the market. We also lead
    the way with cutting-edge features like AI-driven insights, measurement-based mental health care, and robust
    automations that make client no-shows ancient history.&nbsp;</p>
<p>Founded by mental health professionals, Orchid makes it a priority to anticipate and evolve with your
    practice&rsquo;s needs &mdash; making us the perfect partner for private practice clinicians seeking efficiency,
    reliability, and cost-effectiveness.</p>
<p><a href="https://orchid.exchange/about-us" target="_blank" rel="noopener">Read more about Orchid&rsquo;s mission
        &rarr;</a></p>`,
  },
  {
    id: "1",
    question: "Who is Orchid best for?",
    answer: `<p>Orchid&rsquo;s EHR is primarily optimized for mental health clinicians who run their own solo private practice. Our
    growing community is composed of all types of licensed mental health clinicians, including: therapists, counselors,
    social workers, psychologists, psychiatrists, and more.&nbsp;</p>
<p>If you run a small group practice, you can learn more about how Orchid can also benefit your group practice by
    booking a walkthrough with our team.&nbsp;</p>
<p><a href="https://calendly.com/hey-orchid/orchid-walkthrough" target="_blank" rel="noopener">Get your
        free product walkthrough &rarr;&nbsp;</a></p>`,
  },
  {
    id: "2",
    question: "What features are included in Orchid’s EHR?",
    answer: `<p>Some of Orchid&rsquo;s key features include:</p>
    <ul>
        <li>Exclusive access to <a href="https://orchid.exchange/professional-search" target="_blank"
                rel="noopener">Orchid&rsquo;s professional directory</a></li>
        <li>Integrated telehealth</li>
        <li>Secure messaging</li>
        <li>Paperless intake forms &amp; progress notes</li>
        <li>Client portal</li>
        <li>Online appointment scheduling</li>
        <li>Integrated payment processing</li>
        <li>Free automated appointment reminders</li>
        <li>Optional <a href="https://orchid.exchange/blogs/ai-soap-notes" target="_blank" rel="noopener">AI-powered SOAP
                notes</a></li>
    </ul>
    <p><a href="https://orchid.exchange/pricing" target="_blank" rel="noopener">Explore all of Orchid&rsquo;s features
            &rarr;&nbsp;</a></p>`,
  },
  {
    id: "3",
    question: "Which devices can I use to access Orchid?",
    answer: `<p>Orchid is a browser-based platform. That means you can access all of Orchid&rsquo;s features in your account via your
    browser on both mobile and desktop devices. For the smoothest functionality, especially when it comes to optimal
    telehealth, we recommend keeping your preferred browser up-to-date.&nbsp;</p>`,
  },
  {
    id: "4",
    question: "Can I get a product demo before subscribing? ",
    answer: `<p>Yes, we&rsquo;d love to give you an overview of Orchid&rsquo;s most popular features and benefits. We offer a totally
    free 20-minute walkthrough, but if you need a shorter or longer demo, we&rsquo;re happy to make that happen,
    too.&nbsp;</p>
<p><a href="https://calendly.com/hey-orchid/orchid-walkthrough" target="_blank" rel="noopener">Schedule
        your free demo &rarr;&nbsp;</a></p>`,
  },
  {
    id: "5",
    question: "Is it easy to migrate to Orchid?",
    answer: `<p>It really is! Our switching team can even help you import your data from another EHR as needed, so you&rsquo;ll be
     able to start using Orchid within the same day you sign up.&nbsp;</p>
 <p>See how easy it is to switch &rarr;&nbsp;</p>`,
  },
  {
    id: "6",
    question: "How do I sign up for Orchid?",
    answer: `<p>The easiest way to sign up for Orchid is to get free 30-day access to our Core subscription plan. At any point during
    that 30-day free trial, you&rsquo;ll be able to choose the right paid plan for you.&nbsp;</p>
<p><a href="https://orchid.exchange/pricing" target="_blank" rel="noopener">Get your free trial &rarr;&nbsp;</a></p>`,
  },
  {
    id: "7",
    question: "How can I get support from Orchid if I need it?",
    answer: `<p>Our team is always ready to provide 24/7 world-class support to Orchid customers. You can expect an average response
    time of 1 hour or less.&nbsp;</p>
<p>Orchid support channels include:&nbsp;</p>
<ul>
    <li>Email</li>
    <li>Live chat</li>
    <li>1-on-1 video calls</li>
    <li>Phone</li>
</ul>
<p>Whenever you speak to us, you&rsquo;ll get help from a U.S.-based Orchid employee who&rsquo;s HIPAA-trained and a
    deep expert of all things Orchid.&nbsp;</p>
<p>Orchid customers also get complete access to our comprehensive Help Center, which is full of written, video, and
    visual resources to help make your Orchid experience a success.&nbsp;</p>`,
  },
  {
    id: "8",
    question: "Does Orchid offer specialized support for large practices?",
    answer: `<p>Orchid is equipped to support large practices with our dedicated account managers and tailored service solutions.
    Email us anytime at<em> info@orchid.health</em> &mdash; we&rsquo;d love to hear from you to learn more about your
    specialized needs.&nbsp;</p>`,
  },
];

export const FAQ_FEATURES = [
  {
    id: "0",
    question:
      "How do I know if Orchid’s EHR has the features I need to manage my private practice?",
    answer: `<p>If you&rsquo;re new to using an EHR, you can <a
    href="https://orchid.exchange/blogs/best-ehr-for-mental-health-private-practice" target="_blank"
    rel="noopener">read this post on the Orchid Blog</a> to learn more about why you&rsquo;ll benefit from the
streamlined practice management features that Orchid offers.</p>
<p><a href="https://orchid.exchange/pricing" target="_blank" rel="noopener">Explore the full list of Orchid&rsquo;s
    features on our pricing page &rarr;&nbsp;</a></p>`,
  },
  {
    id: "1",
    question: "Does Orchid’s EHR include telehealth capabilities?",
    answer: `<p>Yes! When you subscribe to Orchid, there&rsquo;s no need to subscribe to additional telehealth software. Clear,
    reliable, HIPAA-compliant telehealth tools are included on any Orchid plan.</p>`,
  },
  {
    id: "2",
    question: "Can clients schedule sessions with me through Orchid?",
    answer: `<p>Absolutely! Orchid includes comprehensive online appointment scheduling tools on any Orchid plan. Easily manage your
    calendar, and streamline the scheduling process with client self-scheduling that&rsquo;s integrated into your very
    own profile on Orchid&rsquo;s professional directory.&nbsp;</p>
<p><a href="https://orchid.exchange/blogs/5-Reasons-Why-Your-New-Therapy-Practice-Needs-Online-Appointment-Scheduling-Software"
        target="_blank" rel="noopener">Learn more about why online scheduling benefits your practice &rarr;&nbsp;</a>
</p>`,
  },
  {
    id: "3",
    question: "How can I get paid through Orchid?",
    answer: `<p>Orchid has partnered with Stripe, which is a popular PCI-compliant credit card payment processor. (Fun fact: Stripe
        was also funded by Y Combinator, just like us!)</p>
    <p>When you integrate your Orchid account with Stripe, it&rsquo;s a breeze to charge clients via debit or credit card,
        and track all payments within Orchid.&nbsp;</p>
    <p>For a limited time, you&rsquo;ll even get access to the lowest payment processing fees on the market when you process
        payment via Orchid&rsquo;s Stripe integration. That could save you literally thousands of dollars a year.&nbsp;</p>
    <h4>What if I prefer to accept payments another way?&nbsp;</h4>
    <p>You are welcome to process client payments off-platform via your own preferred methods, then record those payments in
        Orchid.&nbsp;</p>
    <p>But if you use Orchid&rsquo;s Stripe integration, it&rsquo;ll save you so much more time, money, and headaches
        compared to alternative payment routes. Orchid&rsquo;s Stripe integration streamlines a payment process that even
        helps you reduce the chance of client no-shows and easily empowers you to enforce late fees as needed.&nbsp;</p>
    <p><a href="https://orchid.exchange/blogs/patient-payment-management" target="_blank" rel="noopener">Learn the benefits
            of Orchid&rsquo;s payment management tools &rarr;&nbsp;</a></p>`,
  },
  {
    id: "4",
    question: "Can I create custom note templates and forms in Orchid?",
    answer: `<p>You betcha. Orchid&rsquo;s EHR features the most customizable template builder out there, with a user-friendly
    drag-and-drop editor. That level of customization equips you with the tools to tailor all of your progress note
    templates, intake forms, and more to suit your specific needs.&nbsp;</p>`,
  },
  {
    id: "5",
    question:
      "How can Orchid’s EHR help make it easier for me to take clinical notes?",
    answer: `<p>Those customizable note templates are just the (super useful) beginning. Orchid also offers a totally optional tool
    at the vanguard of artificial intelligence technology: the first EHR-integrated AI-powered note-taking assistant.
    Orchid AI is a HIPAA-compliant way for you to save hours each week on administrative work.&nbsp;</p>
<p>Learn more about the benefits of Orchid AI &rarr;&nbsp;</p>`,
  },
];

export const FAQ_SUBSCRIPTIONS = [
  {
    id: "0",
    question: "How much does Orchid cost?",
    answer: `<p>We offer a few different plans to accommodate your needs. Our most basic plan starts at $19.99/month, with the option
    to add Orchid&rsquo;s AI toolkit for an additional $9.99/month. Whichever plan you choose, you are free to change or
    cancel your subscription at any time.&nbsp;</p>
<p><a href="https://orchid.exchange/pricing" target="_blank" rel="noopener">Explore your options on our pricing page
        &rarr;&nbsp;</a></p>`,
  },
  {
    id: "5",
    question: "Does Orchid have any hidden fees?",
    answer: `<p>Orchid plans include no contracts, set-up fees, or other types of hidden upfront fees whatsoever. Just keep in mind
    that some state laws do require sales tax.&nbsp;</p>`,
  },
  {
    id: "1",
    question:
      "Which payment methods do you accept for monthly subscription costs?",
    answer: `<p>We accept debit or credit cards for Orchid subscription payments.&nbsp;&nbsp;</p>`,
  },
  {
    id: "2",
    question: "Can I upgrade my subscription plan during the trial period?",
    answer: `<p>Yep! When you sign up for a 30-day free trial of our Core plan, you can upgrade to a paid subscription on the Core or
    Complete plan at any time during that trial period.&nbsp;</p>
<p><a href="https://orchid.exchange/pricing" target="_blank" rel="noopener">Start your free trial today &rarr;&nbsp;</a>
</p>`,
  },
  {
    id: "3",
    question:
      "When I upgrade to a paid plan, will all of the work I did during my trial be saved in my account?",
    answer: `<p>Absolutely. That transition is a seamless one, so there&rsquo;s no interruption to your workflow. You can rest
    assured that anything you do in your Orchid account during your free trial will be automatically preserved when you
    upgrade to a paid subscription.&nbsp;</p>`,
  },
  {
    id: "4",
    question:
      "What happens if I don’t sign up for a paid subscription after my free trial?",
    answer: `<p>If you do not choose a subscription plan by the end of your trial, your account and any data you entered in it will
    be deleted from our system in strict accordance with the latest data privacy, security, and compliance
    requirements.&nbsp;</p>`,
  },
];

export const FAQ_SECURITY = [
  {
    id: "0",
    question: "Is Orchid HIPAA-compliant?",
    answer: `<p>Yes, Orchid is HIPAA-compliant EHR software.&nbsp;</p>`,
  },
  {
    id: "1",
    question: "Do you have a Business Associate Agreement (BAA)?",
    answer: `<p>Yes, Orchid includes a BAA for you to sign. When you start your free 30-day trial of Orchid, you&rsquo;ll
    automatically be prompted to review the BAA and agree to it.&nbsp;</p>`,
  },
  {
    id: "2",
    question: "Who owns the data I input into Orchid?",
    answer: `<p>As an Orchid user, you retain full ownership of all data you input. Your privacy and data sovereignty are our top
    priorities.&nbsp;</p>`,
  },
  {
    id: "3",
    question: "How does Orchid keep my data & my clients’ data secure?",
    answer: `<p>We adhere to the highest industry standards, including meeting SOC 2 Type 2 standards, which reflects our ongoing
    commitment to data security and privacy.&nbsp;</p>
<p>Additionally, our platform undergoes regular security audits and updates to continually enhance Orchid&rsquo;s
    defenses against emerging cybersecurity threats.&nbsp;</p>
<p>These rigorous measures demonstrate our dedication to maintaining a secure and trustworthy environment for mental
    health clinicians and your clients.&nbsp;</p>
<p>Learn more about how Orchid safeguards your data &rarr;&nbsp;</p>`,
  },
];
