import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import MemberRegisterForm from "./member-register-form";
import ResponsiveModal from "components/responsive-modal";
import { PhonePortraitContext } from "contexts/phone-portrait-context";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";
import IconButton from "components/icon-button";
import { ReactComponent as CloseButton } from "assets/svg/outline-cross.svg";

class MemberRegisterModal extends Component {
  state = {
    showEmailSentModal: false,
    email: "",
  };

  render() {
    return (
      <PhonePortraitContext.Consumer>
        {({ isPhonePortrait }) => (
          <ResponsiveModal
            centered
            show={this.props.show}
            onHide={this.props.onHide}
          >
            {!isPhonePortrait ? (
              <Modal.Header
                className={`px-4 border-0 align-items-center shadow-sm`}
                closeButton
              >
                <Modal.Title>Get started finding your support</Modal.Title>
              </Modal.Header>
            ) : (
              <Modal.Header className={styles["register-modal__header--phone"]}>
                <IconButton onClick={this.props.onHide}>
                  <CloseButton />
                </IconButton>
                <div>Sign in or register</div>
              </Modal.Header>
            )}

            <Modal.Body className="px-4">
              {isPhonePortrait && (
                <p className="bd-17 text-center px-4">
                  Sign in or create an account to join the Orchid community!
                </p>
              )}
              <MemberRegisterForm
                isAdultCheckId="is_over_eighteen"
                isInModal={true}
              />

              {!isPhonePortrait ? (
                <div>
                  Already have an account?
                  <Button
                    variant="link"
                    onClick={() => {
                      this.props.onHide();
                      this.props.openLoginModal();
                    }}
                  >
                    Log in
                  </Button>
                </div>
              ) : (
                <Button
                  variant="outline-primary"
                  className={getClasses(`w-100`, styles["auth-button--phone"])}
                  onClick={() => {
                    this.props.onHide();
                    this.props.openLoginModal();
                  }}
                >
                  Sign in
                </Button>
              )}
            </Modal.Body>
          </ResponsiveModal>
        )}
      </PhonePortraitContext.Consumer>
    );
  }
}

export default MemberRegisterModal;
