/**
 * Check if the user is using a phone, excluding tablet
 * @see https://developers.google.com/search/blog/2011/03/mo-better-to-also-detect-mobile-user
 */
export default function isUserAgentPhone() {
  if (
    /mobile/i.test(navigator.userAgent) &&
    !/ipad|tablet/i.test(navigator.userAgent)
  ) {
    console.log("using phone");
    return true;
  }

  return false;
}
