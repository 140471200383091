import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";
import getQueryParams from "utils/getQueryParams";

const URL = "/api/notes/v1/notes/";
/**
 * Query notes
 */
export default async function queryNoteMetaDataMinimals(
  params:
    | {
        note_schema_id?: number;
        partner_cognito_sub?: string;
        appointment_id?: number;
        group_session_id?: number;
        note_data_id?: number[];
        practice_group?: boolean;
        skip?: number;
        limit?: number;
      }
    | undefined = {},
): Promise<NoteMetaDataMinimal[]> {
  const url = URL + getQueryParams(params);

  const res = await fetchOrchidAPI(url);
  if (!res.ok) {
    throw new Error(
      `failed to query notes: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
