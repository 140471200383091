import { useState } from "react";
import Image, { ImageProps } from "react-bootstrap/Image";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

/**
 * renders image w/ lazy loading feature
 */
const LazyImage = ({
  className,
  src,
  width,
  height,
  style,
  fluid,
  thumbnail,
  rounded,
  roundedCircle,
  showGreyFallback,
  ...props
}: ImageProps & { showGreyFallback?: boolean }) => {
  const [error, setError] = useState(false);

  const imageWidth = width ?? "auto";
  const imageHeight = height ?? "auto";

  return error ? (
    <div
      className={getClasses(
        className,
        roundedCircle && styles["lazy-image--rounded"],
        showGreyFallback
          ? styles["lazy-image--fallback-grey"]
          : styles["lazy-image--fallback"],
      )}
      style={{
        width,
        height,
        ...style,
      }}
      {...props}
    />
  ) : (
    <Image
      alt={"loading"}
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      className={getClasses(
        "lazyload",
        className,
        styles["lazy-image"],
        !showGreyFallback && styles["lazy-image--loading-no-grey"],
        error && styles["lazy-image--error"],
      )}
      data-srcset={`${
        (getImageQuery(src, imageWidth, imageHeight),
        getImageQuery(src, imageWidth, imageHeight, 2))
      } 2x,
      ${getImageQuery(src, imageWidth, imageHeight, 4)} 4x`}
      data-src={getImageQuery(src, imageWidth, imageHeight)}
      onError={() => setError(true)}
      // need it for lighthouse audit
      width={imageWidth}
      height={imageHeight}
      style={style}
      rounded={rounded}
      roundedCircle={roundedCircle}
      fluid={fluid}
      thumbnail={thumbnail}
      {...props}
    />
  );
};

export default LazyImage;

function getImageQuery(
  src: string | undefined,
  width: string | number | undefined,
  height: string | number | undefined,
  pixelDensity = 1,
) {
  if (!src) {
    return "";
  }

  if (typeof width === "number" && typeof height === "number") {
    let connector = "?";
    // only checking timestamp for now, can be extended for others
    if (/\?timestamp=/.test(src)) {
      connector = "&";
    }

    return (
      src +
      (connector +
        "image_sizing=fill&width=" +
        getValidSize(width || height, pixelDensity) +
        "&height=" +
        getValidSize(height || width, pixelDensity))
    );
  }

  return src;
}

function getValidSize(arg: any, pixelDensity) {
  if (typeof arg === "number") {
    // get nearest 10x number for caching
    return Math.floor(Math.ceil(arg / 10) * 10 * pixelDensity);
  }
  return arg;
}
