import { useEffect, useState } from "react";
import { Button, Navbar, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// components
import GetStartedDropdown from "../../../auth/GetStartedDropdown";
import AfterLoginNavbar from "components/after-login-navbar/AfterLoginNavbar";
import NavBrand from "components/nav-brand/NavBrand";
import { NavLink } from "react-router-dom";
// contexts
import { useUser } from "contexts/UserContext";
import { useAuthModals } from "contexts/auth-modals-context";
// styles
import styles from "./GeneralNavigation.module.scss";
import getClasses from "utils/getClasses";
// constants
import { GENERAL_ROUTES } from "constants/generalRoutes";
import { track } from "utils/amplitude";

type GeneralNavigationProps = {
  disableNavbarScrolling: boolean;
};
const GeneralNavigation = ({
  disableNavbarScrolling,
}: GeneralNavigationProps) => {
  const { user } = useUser();
  const { openLoginModal, setCustomLoginModalProps } = useAuthModals();
  const { pathname } = useLocation();

  const [scrolling, setScrolling] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        if (!scrolling) {
          setScrolling(true);
        }
      } else if (scrolling) {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolling]);

  const login = () => {
    if (user === null) {
      setCustomLoginModalProps({
        isInNavbar: true,
      });
      return openLoginModal();
    }
  };

  const toggleDropdown = () => {
    setShowDropdown((s) => !s);
  };
  // TODO: refactor
  if (
    pathname.startsWith("/video-chat") ||
    pathname.startsWith("/group-video-chat") ||
    pathname.startsWith("/pro-dashboard") ||
    pathname.startsWith("/member-dashboard") ||
    pathname.startsWith("/member-intake-form") ||
    pathname.startsWith("/welcome") ||
    pathname.startsWith("/member-register") ||
    pathname.startsWith("/professional-register") ||
    pathname.startsWith("/v/")
  ) {
    return null;
  } else if (
    pathname.startsWith("/reset-password") ||
    pathname.startsWith("/login") ||
    pathname.endsWith("/checkout-confirm") ||
    pathname.startsWith("/page-not-found")
  ) {
    return (
      <Navbar className={getClasses("shadow-sm", styles["nav-bar"])}>
        <NavBrand />
      </Navbar>
    );
  } else {
    const shouldUseFixedNavbar =
      scrolling &&
      !(
        disableNavbarScrolling && pathname.startsWith("/professional-search/")
      ) &&
      !pathname.startsWith("/privacy-policy") &&
      !pathname.startsWith("/group-session/series") &&
      !pathname.startsWith("/member-dashboard") &&
      !pathname.startsWith("/member-register") &&
      !pathname.endsWith("/checkout");

    const shouldUseFadeInDownAnimation =
      shouldUseFixedNavbar &&
      !pathname.startsWith("/blogs/") &&
      !pathname.startsWith("/press");

    return (
      <header
        className={getClasses(
          "z-500",
          shouldUseFixedNavbar && styles["navbar-fixed"],
          shouldUseFadeInDownAnimation &&
            "animate__animated animate__fadeInDown",
        )}
      >
        {user ? (
          <AfterLoginNavbar />
        ) : (
          <>
            <Navbar
              expand="lg"
              className={getClasses("shadow-sm", styles["nav-bar"])}
            >
              <NavBrand />
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="text-center" id="basic-navbar-nav">
                <Nav className={styles["nav-bar__left"]}>
                  <NavLink
                    exact
                    className="nav-link"
                    to={GENERAL_ROUTES.professionalSearch}
                    onClick={() =>
                      track(
                        "common-navigation",
                        "find-a-professional-link",
                        "click",
                      )
                    }
                  >
                    Find a professional
                  </NavLink>
                  <NavLink
                    exact
                    className="nav-link"
                    to={GENERAL_ROUTES.groupSession}
                    onClick={() => {
                      track(
                        "common-navigation",
                        "find-a-support-group-link",
                        "click",
                      );
                    }}
                  >
                    Find a support group
                  </NavLink>
                  <NavLink
                    exact
                    className="nav-link"
                    to={GENERAL_ROUTES.pricing}
                    onClick={() => {
                      track("common-navigation", "pricing-link", "click");
                    }}
                  >
                    Pricing
                  </NavLink>
                  <NavLink
                    exact
                    className="nav-link"
                    to={GENERAL_ROUTES.blogs}
                    onClick={() => {
                      track("common-navigation", "blog-link", "click");
                    }}
                  >
                    Blog
                  </NavLink>
                  {/* <NavLink
                    exact
                    className="nav-link"
                    to={GENERAL_ROUTES.press}
                    onClick={() => {
                      track("common-navigation", "press-link", "click");
                    }}
                  >
                    Press
                  </NavLink> */}
                </Nav>
                {pathname.startsWith("/professional-homepage") ? (
                  <Nav className="ml-auto align-items-center">
                    <Nav.Link href="/">Find a professional on Orchid</Nav.Link>
                    <Button
                      variant="link"
                      className="rg-16"
                      onClick={() => {
                        track("pro-landing-page", "login-button", "click");
                        login();
                      }}
                    >
                      Log in
                    </Button>
                    <Button
                      variant="primary"
                      href="/professional-register"
                      onClick={() =>
                        track("pro-landing-page", "sign-up-button", "click", {
                          eventProperties: { section: "nav-bar" },
                        })
                      }
                    >
                      Sign up
                    </Button>
                  </Nav>
                ) : (
                  <Nav className="ml-auto align-items-center">
                    <Nav.Link
                      href="/professional-homepage"
                      onClick={() =>
                        track(
                          "member-landing-page",
                          "join-orchid-as-a-pro-link",
                          "click",
                        )
                      }
                    >
                      Join Orchid as a professional
                    </Nav.Link>
                    <Button
                      variant="link"
                      className="rg-16"
                      onClick={() => {
                        track("member-landing-page", "login-button", "click");
                        login();
                      }}
                    >
                      Log in
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        track(
                          "member-landing-page",
                          "get-started-button",
                          "click",
                        );
                        toggleDropdown();
                      }}
                    >
                      Get started
                    </Button>
                  </Nav>
                )}
              </Navbar.Collapse>
            </Navbar>
            {!user && showDropdown && (
              <GetStartedDropdown setShowDropdown={setShowDropdown} />
            )}
          </>
        )}
      </header>
    );
  }
};

export default GeneralNavigation;
