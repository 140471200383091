import type { ReactNode, MouseEvent } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import getClasses from "utils/getClasses";
import AutoFocusAlert from "../../auto-focus-alert";
import ModalHeaderLayout from "../modal-header-layout/ModalHeader";

import styles from "./ModalLayout.module.scss";
import ResponsiveModal from "../../responsive-modal";

export type ModalLayoutProps = {
  bodyClass?: string;
  Header?: ReactNode;
  headerClass?: string;
  Footer?: ReactNode;
  footerClass?: string;
  error?: Error | string;
  show: boolean | undefined;
  onHide?: (e?: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  footerJustification?: string;
  /**
   * show a divider line between modal header and body
   * @default undefined
   */
  showHeaderDivider?: boolean;
} & Omit<ModalProps, "onHide">;

const ModalLayout = ({
  bodyClass,
  Header,
  headerClass,
  Footer,
  footerClass,
  children,
  onHide,
  error,
  disabled,
  showHeaderDivider,
  footerJustification,
  ...rest
}: ModalLayoutProps) => (
  <ResponsiveModal
    size="lg"
    centered
    scrollable
    className={getClasses(
      styles["modal-layout"],
      styles[`modal-layout--${rest.size || "lg"}`],
    )}
    onHide={onHide}
    {...rest}
  >
    {Header && (
      <ModalHeaderLayout
        className={headerClass}
        Header={Header}
        onHide={onHide}
        disabled={disabled}
        showHeaderDivider={showHeaderDivider}
      />
    )}
    <Modal.Body className={getClasses(styles["body"], bodyClass)}>
      {error && <AutoFocusAlert error={error} />}
      {children}
      {/* <div className={styles["modal-layout__scroll-container"]}>{children}</div> */}
    </Modal.Body>
    {Footer && (
      <Modal.Footer
        className={getClasses(styles["footer"], footerClass)}
        style={{
          justifyContent:
            footerJustification !== undefined
              ? footerJustification
              : "flex-end",
        }}
      >
        {Footer}
      </Modal.Footer>
    )}
  </ResponsiveModal>
);

export default ModalLayout;
