import React, { useState, useEffect } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import MemberIntakeForm from "components/auth/member-intake-form";
import RequiredPanel from "components/auth/member-intake-form/required-panel";
import ModalLayout from "components/modal/modal-layout/ModalLayout";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useUser } from "contexts/UserContext";
import { useHistory } from "react-router-dom";
import postMember from "services/postMember";
import queryPatient from "services/queryPatient";
import styles from "./styles.module.scss";

const MemberIntakeFillIn = (props) => {
  const { show, isMemberIntakeFormIncomplete, isFinalizePurchased } = props;
  const { isPhonePortrait } = usePhonePortraitContext();
  const { user, updateUserInfo } = useUser();
  const history = useHistory();
  const [state, setState] = useState({
    validated: false,
    changed: false,
    member: {} as any,
    error: "" as any,
    tab: "required",
    busy: false,
  });

  // get member on init
  useEffect(() => {
    if (!user) {
      return;
    }
    queryPatient()
      .then((res) => setState({ ...state, member: res }))
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // suppress on hide on some routes
  const onHide = () => {
    if (
      !user?.signup_form_finished &&
      window.location.pathname.startsWith("/member-dashboard")
    ) {
      return;
    }
    return props.onHide();
  };

  const tabChangedCallback = (dataFromChild) => {
    setState({ ...state, tab: dataFromChild });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      error: "",
      changed: false,
    });
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setState({
        ...state,
        validated: true,
        error: "Please fill out all of the required fields correctly",
      });
      return;
    }

    if (state.tab === "required") {
      setState({ ...state, tab: "optional", error: "" });
    } else {
      await submitIntakeForm();
    }
  };

  const submitIntakeForm = async () => {
    setState({ ...state, busy: true });

    const member = { ...state.member };
    if (member.dob.includes("/")) {
      const [month, day, year] = member.dob.split("/");
      member.dob = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
    }

    try {
      await postMember(member);
    } catch (error) {
      setState({ ...state, error });
    } finally {
      setState({ ...state, busy: false, changed: false });
    }

    await updateUserInfo();

    const redirect_after_register = window.localStorage.getItem(
      "redirect_after_register",
    );
    window.localStorage.removeItem("redirect_after_register");

    onHide();

    if (window.location.pathname.startsWith("/member-intake-form")) {
      if (
        redirect_after_register &&
        !redirect_after_register.startsWith("/member-register")
      ) {
        return history.replace(redirect_after_register);
      }
      return history.push("/member-dashboard/homepage");
    }
  };

  const fieldChangedCallback = (name, value) => {
    setState({
      ...state,
      member: {
        ...state.member,
        [name]: value,
      },
      changed: true,
    });
  };

  return !show ? null : isMemberIntakeFormIncomplete ? (
    <ModalLayout
      size={"md"}
      show={show}
      onHide={() => {
        onHide();
        setState({ ...state, error: "", validated: false });
      }}
      contentClassName={
        !isPhonePortrait
          ? styles["complete-intake-modal__content"]
          : styles["complete-intake-modal__content--phone"]
      }
      Header={!isPhonePortrait ? "Complete registration" : "Finish setting up"}
      headerClass={
        !isPhonePortrait
          ? styles["complete-intake-modal__header"]
          : styles["complete-intake-modal__header--phone"]
      }
      bodyClass={styles["complete-intake-modal__body"]}
      Footer={
        <Button
          variant="primary"
          type="submit"
          form="intakeform"
          className="w-100"
        >
          Finish
        </Button>
      }
    >
      {state.error && (
        <Alert variant="danger" className="mb-0">
          {state.error.toString()}
        </Alert>
      )}
      {!isPhonePortrait && isFinalizePurchased && (
        <p>
          To finalize this purchase, please fill in the fields below to complete
          your registration.
        </p>
      )}
      <Form
        noValidate
        id="intakeform"
        name="intakeform"
        className={!isPhonePortrait ? "mt-3" : "mt-3 form-mobile"}
        validated={state.validated}
        onSubmit={handleSubmit}
      >
        <RequiredPanel
          fieldChangedCallback={fieldChangedCallback}
          member={state.member}
          registration={true}
        />
      </Form>
      {isPhonePortrait && (
        <p className="text-center rg-11">
          We require this field for communication with your insurance provider.
          Please make sure the sex your provide here is the same as what your
          insurance provider has on file.
        </p>
      )}
    </ModalLayout>
  ) : (
    <Form
      noValidate
      className={!isPhonePortrait ? "" : "form-mobile"}
      id="intakeform"
      name="intakeform"
      validated={state.validated}
      onSubmit={handleSubmit}
    >
      <MemberIntakeForm
        registration={true}
        fieldChangedCallback={fieldChangedCallback}
        member={state.member}
        tabChangedCallback={tabChangedCallback}
        tab={state.tab}
        error={state.error}
        changed={state.changed}
      />
    </Form>
  );
};

export default MemberIntakeFillIn;
