export function mergeDataByKey<Type>(
  data: Array<Type>,
  updateData: Array<Type>,
  dataKey: string,
): Array<Type> {
  const newData: Array<any> = [];
  for (const d of data) {
    const update = updateData.find((u) => u[dataKey] === d[dataKey]);
    if (update) {
      newData.push(update);
    } else {
      newData.push(d);
    }
  }
  for (const u of updateData) {
    if (!newData.find((d) => d[dataKey] === u[dataKey])) newData.push(u);
  }
  return newData;
}

export function mergeDataByKeyAndModifiedDateTime<Type>(
  data: Array<Type>,
  updateData: Array<Type>,
  dataKey: string,
  modifiedDateTimeKey: string,
): Array<Type> {
  const newData: Array<any> = [];
  for (const d of data) {
    const update = updateData.find((u) => u[dataKey] === d[dataKey]);
    if (
      update &&
      d[modifiedDateTimeKey].getTime() < update[modifiedDateTimeKey].getTime()
    ) {
      newData.push(update);
    } else {
      newData.push(d);
    }
  }
  for (const u of updateData) {
    if (!newData.find((d) => d[dataKey] === u[dataKey])) newData.push(u);
  }
  return newData;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function genUniqueId(byte_length: number): string {
  return Array.prototype.map
    .call(window.crypto.getRandomValues(new Uint8Array(byte_length)), (n) =>
      n.toString(16).padStart(2, "0"),
    )
    .join("");
}

export function filter_keys(obj, allowed_keys) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => allowed_keys.includes(key)),
  );
}

export function string2number(obj = {}, keys = []) {
  let _obj: any = {};
  keys.forEach((key) => {
    if (obj[key]) {
      _obj[key] = +obj[key];
    }
  });
  return {
    ...obj,
    ..._obj,
  };
}

export function maxBy<T>(elems: T[], keyFunc: (T) => number): T | undefined {
  return elems.length > 0
    ? elems.reduce((accu, e) => (keyFunc(accu) >= keyFunc(e) ? accu : e))
    : undefined;
}

export function isDevelopment() {
  return document.cookie.includes("devauth=");
}

export function shuffleArray<T>(arr: T[]) {
  for (let i = arr.length - 1; i > 0; --i) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
}

export function set_equal<T>(lhs: Set<T>, rhs: Set<T>) {
  if (lhs.size !== rhs.size) return false;
  for (const e of lhs) if (!rhs.has(e)) return false;
  return true;
}

export function setSubSetOf<T>(lhs: Set<T>, rhs: Set<T>): boolean {
  for (const e of lhs) {
    if (!rhs.has(e)) return false;
  }
  return true;
}

export function setDifference<T>(lhs: Set<T>, rhs: Set<T>): Set<T> {
  const diff = new Set(lhs);
  for (const elem of rhs) diff.delete(elem);
  return diff;
}

export function splitToChunks<T>(entries: T[], chunkSize: number): Array<T[]> {
  const chunks: Array<T[]> = [];
  for (let i = 0; i < entries.length; i += chunkSize) {
    chunks.push(entries.slice(i, i + chunkSize));
  }
  return chunks;
}

export function isSortedArrayEqual<T>(
  lhs: T[],
  rhs: T[],
  equalFunc: (lhs: T, rhs: T) => boolean,
) {
  if (lhs.length !== rhs.length) return false;
  for (let i = 0; i < lhs.length; ++i) {
    if (!equalFunc(lhs[i], rhs[i])) return false;
  }
  return true;
}
