import type { TNotesType } from "components/Dashboard/DoctorDashboard/notes-and-forms/types";

/**
 * get correct db to query schema
 * note schema db has migrated to notes_secure_data
 * form schema db in notes
 */
export default function getNoteOrFormSchemaDB<T extends TNotesType>(type: T) {
  if (type === "note") {
    return "notes_secure_data";
  }

  return "notes";
}
