import useId from "hooks/useId";
import React, { ComponentProps } from "react";
import { ChangeEvent } from "react";
import { FormCheck } from "react-bootstrap";
import getClasses from "utils/getClasses";
import styles from "./Checkbox.module.scss";

type Props = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputClassName?: string;
  labelClassName?: string;
  /**
   * optional. controls the shape of checkbox container
   * @default "circle"
   */
  variant?: "rectangle" | "circle";
  // if true, use 16px checkbox instead
  sizeSmall?: boolean;
} & Omit<ComponentProps<typeof FormCheck>, "custom" | "type" | "id">;

const Checkbox = ({
  className,
  label = " ",
  inputClassName,
  labelClassName,
  "aria-label": ariaLabel,
  variant = "circle",
  id,
  sizeSmall,
  ...props
}: Props) => {
  const assignedId = useId(id || "checkbox");

  return (
    <div
      className={getClasses(
        "custom-control custom-checkbox",
        styles["checkbox"],
        sizeSmall && styles["checkbox--sm"],
        className,
      )}
    >
      <input
        id={assignedId}
        aria-label={ariaLabel}
        type="checkbox"
        className={getClasses("custom-control-input", inputClassName)}
        value={undefined}
        {...props}
      />
      <label
        htmlFor={assignedId}
        className={getClasses(
          "custom-control-label",
          styles[`checkbox__label--${variant}`],
          labelClassName,
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
