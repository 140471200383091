import { isEqual } from "lodash";

import {
  APIRequestResult,
  APIRequests,
  APIRequestData,
  RequestResultDataType,
} from "../types";

function calculateTimeToSleep(retryCount: number): number {
  return retryCount === 0
    ? 0
    : Math.pow(2, Math.min(retryCount - 1, 6)) *
        1000 *
        (1.0 + Math.random() * 0.5);
}

export function fetchDelayTime(
  requestData: {
    retryCount: number;
    lastFetchTimestamp?: number;
  },
  currentTimestamp: number,
): number {
  const totalTimeToSleep = calculateTimeToSleep(requestData.retryCount);
  if (!requestData.lastFetchTimestamp) return totalTimeToSleep;
  return Math.max(
    0,
    totalTimeToSleep - (currentTimestamp - requestData.lastFetchTimestamp),
  );
}

export function initApiDataProviderState(
  requests: APIRequests,
  reloadTimestamp?: number,
) {
  let resultData = {};
  for (const [key, requestData] of Object.entries(requests)) {
    resultData[key] = initResultData(key, requestData);
  }

  return {
    resultData: resultData,
    reloadTimestamp,
  };
}

function initResultData(key: string, requestData: APIRequestData) {
  return {
    key,
    requestData,
    retryCount: 0,
  };
}

function make_request_order_key(
  currentTimestamp: number,
  data: {
    retryCount: number;
    lastFetchTimestamp?: number;
  },
  fetchOrder?: number,
): string {
  const fetchDelay = fetchDelayTime(data, currentTimestamp);
  return `${fetchDelay.toString().padStart(11, "0")}_${(
    fetchOrder || Number.MAX_SAFE_INTEGER
  )
    .toString()
    .padStart(16, "0")}`;
}

function selectPendingRequests(
  resultData: APIRequestResult,
  reloadTimestamp: number | undefined,
): RequestResultDataType[] {
  return Object.values(resultData).filter((r) => {
    return (
      r.result === undefined ||
      (reloadTimestamp !== undefined &&
        r.lastSuccessTimestamp !== undefined &&
        r.lastSuccessTimestamp < reloadTimestamp)
    );
  });
}

function getNextToFetch(
  requestsPending: RequestResultDataType[],
  currentTimestamp: number,
): undefined | RequestResultDataType {
  if (requestsPending.length === 0) return undefined;

  // Sort is required to be stable from ES2019. Even if it's not stable in all
  // browsers, I don't care enough to implement a stable sort for the time being.
  const firstEntry = requestsPending.sort((lhs, rhs) => {
    const lhsKey = make_request_order_key(
      currentTimestamp,
      lhs,
      lhs.requestData.fetchOrder,
    );
    const rhsKey = make_request_order_key(
      currentTimestamp,
      rhs,
      rhs.requestData.fetchOrder,
    );
    if (lhsKey < rhsKey) return -1;
    if (lhsKey > rhsKey) return 1;
    return 0;
  })[0];
  return firstEntry;
}

function updateCurrentRequest(
  state: APIDataProviderReducerState,
  currentTimestamp: number,
): APIDataProviderReducerState {
  const requestsPending = selectPendingRequests(
    state.resultData,
    state.reloadTimestamp,
  );
  const currentRequestKey = state?.currentRequest?.key;

  if (
    currentRequestKey &&
    requestsPending.find((r) => r.key === currentRequestKey)
  ) {
    return state;
  }

  return {
    ...state,
    currentRequest: getNextToFetch(requestsPending, currentTimestamp),
  };
}

type PrivateAPIDataResetAction = {
  type: "reset";
  currentTimestamp: number;
  requests: APIRequests;
  reloadTimestamp?: number;
};

type PrivateAPIDataRequestsChangedAction = {
  type: "requests-change";
  currentTimestamp: number;
  requests: APIRequests;
  reloadTimestamp?: number;
};

type PrivateAPIDataDataLoadedAction = {
  type: "data-loaded";
  currentTimestamp: number;
  key: string;
  resultData: any;
  statusCode: number;
};

type PrivateAPIDataLoadFailedAction = {
  type: "load-failed";
  currentTimestamp: number;
  key: string;
  errorText: string;
  statusCode: number;
};

type APIDataProviderReducerState = {
  resultData: APIRequestResult;
  reloadTimestamp?: number;
  currentRequest?: RequestResultDataType;
};

export function APIDataProviderReducer(
  state: APIDataProviderReducerState,
  action:
    | PrivateAPIDataResetAction
    | PrivateAPIDataRequestsChangedAction
    | PrivateAPIDataDataLoadedAction
    | PrivateAPIDataLoadFailedAction,
): APIDataProviderReducerState {
  switch (action.type) {
    case "reset": {
      return updateCurrentRequest(
        initApiDataProviderState(action.requests, action.reloadTimestamp),
        action.currentTimestamp,
      );
    }
    case "requests-change": {
      let changed = false;
      let resultDataUpdated: APIRequestResult = { ...state.resultData };
      for (const [key, requestData] of Object.entries(action.requests)) {
        const currentRequestInstance = state.resultData[key];
        if (
          currentRequestInstance &&
          isEqual(requestData, currentRequestInstance.requestData)
        ) {
          resultDataUpdated[key] = state.resultData[key];
        } else {
          changed = true;
          if (currentRequestInstance) {
            resultDataUpdated[key] = {
              ...resultDataUpdated[key],
              requestData: requestData,
            };
          } else {
            resultDataUpdated[key] = initResultData(key, requestData);
          }
        }
      }

      if (!changed && state.reloadTimestamp === action.reloadTimestamp)
        return state;

      return updateCurrentRequest(
        {
          ...state,
          resultData: resultDataUpdated,
          reloadTimestamp: action.reloadTimestamp,
        },
        action.currentTimestamp,
      );
    }
    case "data-loaded": {
      const newResultData: APIRequestResult = { ...state.resultData };
      const oldData = state.resultData[action.key];
      if (!oldData) return state;
      newResultData[action.key] = {
        ...oldData,
        result: action.resultData,
        lastFetchTimestamp: action.currentTimestamp,
        lastSuccessTimestamp: action.currentTimestamp,
        errorText: undefined,
        statusCode: action.statusCode,
        retryCount: 0,
      };
      return updateCurrentRequest(
        {
          ...state,
          resultData: newResultData,
          currentRequest: undefined,
        },
        action.currentTimestamp,
      );
    }
    case "load-failed": {
      const newResultData: APIRequestResult = { ...state.resultData };
      const oldData = state.resultData[action.key];
      newResultData[action.key] = {
        ...oldData,
        result: oldData ? oldData.result : undefined,
        lastFetchTimestamp: action.currentTimestamp,
        errorText: action.errorText,
        statusCode: action.statusCode,
        retryCount: oldData ? oldData.retryCount + 1 : 1,
      };
      return updateCurrentRequest(
        {
          ...state,
          resultData: newResultData,
          currentRequest: undefined,
        },
        action.currentTimestamp,
      );
    }
  }
}
