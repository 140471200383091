import { GENERAL_ROUTES } from "constants/generalRoutes";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useEffect } from "react";
import { useLocation } from "react-router";
import GeneralNavigation from "./components/general-navigation/GeneralNavigation";
import PhoneNavigation from "./components/phone-navigation/PhoneNavigation";

type AppNavigationProps = {
  disableNavbarScrolling: boolean;
};

const BLOG_ROUTE_TITLE = "Orchid Blog";
const OTHER_ROUTE_TITLE =
  "Orchid | Online Professional Therapy & Support Groups";

const AppNavigation = ({ disableNavbarScrolling }: AppNavigationProps) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const location = useLocation();

  // change document title
  useEffect(() => {
    if (location.pathname.startsWith(GENERAL_ROUTES.blogs)) {
      document.title = BLOG_ROUTE_TITLE;
    } else if (document.title !== OTHER_ROUTE_TITLE) {
      document.title = OTHER_ROUTE_TITLE;
    }
  }, [location]);

  return !isPhonePortrait ? (
    <GeneralNavigation disableNavbarScrolling={disableNavbarScrolling} />
  ) : (
    <PhoneNavigation disableNavbarScrolling={disableNavbarScrolling} />
  );
};

export default AppNavigation;
