export const getDisplayNameOptions = (medicalDegree) => {
  if (!medicalDegree) medicalDegree = "MD/DO";
  return [
    { value: "First Name Last Name", label: "First Name Last Name" },
    { value: "First Name", label: "First Name" },
    {
      value: "Preferred First Name Last Name",
      label: "Preferred First Name Last Name",
    },
    { value: "Preferred First Name", label: "Preferred First Name" },
    { value: "Dr. First Name Last Name", label: "Dr. First Name Last Name" },
    { value: "Dr. First Name", label: "Dr. First Name" },
    { value: "Dr. Last Name", label: "Dr. Last Name" },
    {
      value: "Dr. Preferred First Name Last Name",
      label: "Dr. Preferred First Name Last Name",
    },
    { value: "Dr. Preferred First Name", label: "Dr. Preferred First Name" },
    {
      value: `First Name Last Name, ${medicalDegree}`,
      label: `First Name Last Name, ${medicalDegree}`,
    },
    {
      value: `First Name, ${medicalDegree}`,
      label: `First Name, ${medicalDegree}`,
    },
    {
      value: `Preferred First Name Last Name, ${medicalDegree}`,
      label: `Preferred First Name Last Name, ${medicalDegree}`,
    },
    {
      value: `Preferred First Name, ${medicalDegree}`,
      label: `Preferred First Name, ${medicalDegree}`,
    },
  ];
};
