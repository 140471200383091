import { ChannelToLoad, ChatMessageData } from "../pubnub.types";
import { ChatHistoryReducerState } from "../state/chat-history-reducer";

function nextChannelToLoad(
  channelsToLoad: Array<ChannelToLoad>,
  pubNubChatHistory: Map<string, ChatMessageData[]>,
  channelLoadHistoryPriority: string | null,
  lastRequestedTimestampByChannel: ChatHistoryReducerState["lastRequestedTimestampByChannel"],
): ChannelToLoad | null {
  if (
    channelLoadHistoryPriority &&
    pubNubChatHistory.get(channelLoadHistoryPriority) === undefined &&
    !lastRequestedTimestampByChannel[channelLoadHistoryPriority]
  ) {
    const channelToLoad = channelsToLoad.find(
      (c) => c.channel === channelLoadHistoryPriority,
    );
    if (channelToLoad) return channelToLoad;
  }
  for (const channelToLoad of channelsToLoad) {
    const pubnubHistory = pubNubChatHistory.get(channelToLoad.channel);
    if (
      pubnubHistory === undefined &&
      !lastRequestedTimestampByChannel[channelToLoad.channel]
    )
      return channelToLoad;
  }
  return null;
}

export default nextChannelToLoad;
