import type { TNotesType } from "components/Dashboard/DoctorDashboard/notes-and-forms/types";
import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

export default async function createNoteOrFormData<T extends TNotesType>(
  type: T,
  arg: {
    /**
     * Appointment Id
     */
    appointment_id?: number | string;
    /**
     * Group Session Id
     */
    group_session_id?: number;
    /**
     * Patient Cognito Sub
     * maxLength: 64
     */
    patient_cognito_sub?: string;
  } & (T extends "note"
    ? {}
    : {
        form_schema_id: number;
        form_schema_revision?: number;
      }),
): Promise<T extends "note" ? CreatedNoteMetaData : FormMetaData> {
  const response = await fetchOrchidAPI(`/api/notes/v1/${type}s/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(arg),
  });

  if (!response.ok) {
    throw new Error(
      `failed to post ${type}: ${getErrorDetail(await response.text())}`,
    );
  }

  return response.json();
}
