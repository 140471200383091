import canElementBeRequired from "./elementTypeUtils";

const getCollectionJsonSchemaProperties = (elements: TemplateElement[]) =>
  elements.reduce(
    (acc, element) => ({
      ...acc,
      [element.id]: getV1JsonSchemaForElement(element),
    }),
    {},
  );

const getRequiredItemsIds = (elements: TemplateElement[]) =>
  elements
    .filter((element) => canElementBeRequired(element) && element.isRequired)
    .map((element) => element.id);

const getV1JsonSchemaForElement = (element: TemplateElement) => {
  switch (element.type) {
    case "section":
      return {
        // v2Type: element.type,
        type: "object",
        title: element.title,
        description: element.description,
        properties: getCollectionJsonSchemaProperties(element.children),
        required: getRequiredItemsIds(element.children),
      };

    case "text-only":
      return {
        // v2Type: element.type,
        type: "string",
        description: element.description,
      };

    case "short-answer":
      return {
        // v2Type: element.type,
        type: "string",
        title: element.title,
      };

    case "paragraph":
      return {
        // v2Type: element.type,
        type: "string",
        title: element.title,
      };

    case "radios":
      return {
        // v2Type: element.type,
        type: "string",
        title: element.title,
        enum: element.options.map((option) => option.title),
      };

    case "checkboxes":
      return {
        // v2Type: element.type,
        type: "array",
        title: element.title,
        items: {
          type: "string",
          enum: element.options.map((option) => option.title),
        },
        uniqueItems: true,
      };

    case "radios-grid":
      return {
        // v2Type: element.type,
        type: "object",
        title: element.title,
        properties: {
          rows: {
            title: "Row",
            type: "string",
            enum: element.rows.map((row) => row.title),
          },
          cols: {
            title: "Column",
            type: "string",
            enum: element.columns.map((col) => col.title),
          },
        },
      };

    default:
    case "unknown": {
      return {
        type: "string",
      };
    }
  }
};

const getCollectionUiSchema = (items: TemplateElement[]) => ({
  "ui:order": items.map((element) => element.id),
  ...items.reduce(
    (acc, element) => ({
      ...acc,
      [element.id]: getV1UiSchemaForElement(element),
    }),
    {},
  ),
});

const getV1UiSchemaForElement = (element: TemplateElement) => {
  switch (element.type) {
    case "unknown": {
      return {};
    }
    case "section": {
      return getCollectionUiSchema(element.children);
    }
    case "short-answer": {
      return {};
    }
    case "text-only": {
      return { "ui:widget": "textarea" };
    }
    case "paragraph": {
      return { "ui:widget": "textarea" };
    }
    case "radios": {
      return { "ui:widget": "radio" };
    }
    case "checkboxes": {
      return { "ui:widget": "checkboxes" };
    }
    case "radios-grid": {
      return { "ui:widget": "rating" };
    }
  }
};

export const convertTemplateToV1 = ({
  title,
  elements,
}: {
  title: string;
  elements: TemplateElement[];
}): {
  jsonSchema: JSONSchema;
  uiSchema: UISchema;
} => ({
  jsonSchema: {
    title,
    type: "object",
    description: "",
    properties: getCollectionJsonSchemaProperties(elements),
    required: getRequiredItemsIds(elements),
  },
  uiSchema: getCollectionUiSchema(elements),
});
