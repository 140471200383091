import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import LazyImage from "components/lazy-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import OrchidAIPhoneView from "./OAIPhoneView";
import Footer from "components/footer/Footer";
import FullWidthImage from "components/full-width-image";
import BlogCard from "components/Blogging/components/blog-card/BlogCard";

import { AmplitudePageName } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

import { Helmet } from "react-helmet";

import queryBlogEntry from "services/queryBlogEntry";

import getClasses from "utils/getClasses";
import styles from "./OrchidAI.module.scss";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "orchid-ai-page";

const LANDING = "/img/orchid-ai/landing.png";
const NEVER_START = "/img/orchid-ai/never-start.png";
const TRUST = "/img/orchid-ai/trust.png";
const STREAMLINE = "/img/orchid-ai/streamline.png";

export const questions = [
  {
    id: "0",
    question: "What’s Orchid all about?",
    answer: `<p>Orchid is a Y Combinator-backed, privately owned company built by leading mental health professionals and privacy experts. As the first EHR to integrate seamlessly with AI, we’re embracing the future of mental health care with a product that offers unparalleled efficiency, security, and support. Our number one priority is to empower clinicians with the tools they need to focus more on giving quality care to clients, while spending less time on administrative tasks.</p>
      <p><a href="https://orchid.exchange/about-us" target="_blank" rel="noopener">
      Learn more about our mission.</a></p>
      `,
  },
  {
    id: "1",
    question: "How can I get access to Orchid AI?",
    answer: `
      <p>Orchid’s AI progress note generator is included in the cost of our most popular Complete plan. Alternatively, it’s also available for an additional small fee on our Core plan.</p>
      <p><a href="https://orchid.exchange/pricing" target="_blank" rel="noopener">Learn more about Orchid subscriptions.</a></p>
      `,
  },
  {
    id: "2",
    question: "Can I use Orchid’s EHR without using Orchid AI? ",
    answer: `<p>Absolutely — <b>Orchid’s AI for clinical note-taking is 100% optional</b>. You can choose to use Orchid AI to generate some or all of your clinical notes, or choose to take notes manually in our EHR’s fully customizable templates. The decision is all yours.</p>`,
  },
  {
    id: "3",
    question: "Can I purchase Orchid AI without subscribing to Orchid’s EHR? ",
    answer:
      "Not ready to switch EHRs but interested in the streamlined power of Orchid’s AI progress note generator? Get early access to a version of Orchid AI that you can use on its own or with your existing EHR. We’re excited to offer even more flexibility and convenience to clinicians everywhere soon.",
  },
  {
    id: "4",
    question: "What types of clinical notes can Orchid AI generate?",
    answer:
      "Whichever type of clinical note you need generated, Orchid AI can make it happen. You can customize any note format from our template library to suit your specific needs, then let Orchid AI fill in those notes for you after each session. Orchid’s AI progress note generator can be your SOAP note generator, DAP note generator, or insert-your-note-of-choice generator — it’s up to you.",
  },
  {
    id: "5",
    question:
      "How do I get Orchid AI to generate those notes for each session?",
    answer: `<p>Orchid AI clinical notes are based on the session data you feed it. You'll get the most out of Orchid AI when you use the telehealth-integrated session recording feature to automatically generate a transcript for Orchid AI to analyze.&nbsp;</p>
      <p>However, it's possible to activate the power of Orchid AI in any of the following three ways:&nbsp;</p>
      <ul>
      <li>Use Orchid AI's integrated session recording software for your telehealth appointments. This recommended option streamlines the note-keeping process by automatically providing a session transcript, summary, and analytics for Orchid AI to generate clinical notes.&nbsp;</li><br />
      <li>Upload your own session recording in a variety of audio formats, so Orchid AI can generate a summary and clinical notes based on the contents of that recording.&nbsp;</li><br />
      <li>If you didn't get a transcript from your session, don't worry &mdash; you can still manually draft your own session summary, then let Orchid AI save you time by transforming that summary into your chosen clinical notes format.</li>
      </ul>`,
  },
  {
    id: "6",
    question: "How do I know if the notes generated by Orchid AI are reliable?",
    answer:
      "We’ll be real with you: Like anything generated by artificial intelligence, there’s a small chance Orchid’s AI progress note generator may stumble at times. That’s why your expert review is so important. After all, AI isn’t here to replace your trained expertise — it’s here to assist you in doing your best work more efficiently.",
  },
  {
    id: "7",
    question: "Is Orchid AI secure & HIPAA-compliant? ",
    answer: `
      <p>Yes, Orchid AI and everything else about Orchid is 100% secure and HIPAA-compliant. We uphold the highest standards of data security throughout our product. For example, any session recordings, transcripts, and client records are stored securely on Orchid’s end-to-end encrypted platform, until you request to delete them.</p>
      <p><a href="https://orchid.exchange/blogs/ai-privacy-issues" target="_blank" rel="noopener">Learn more about Orchid AI and privacy best practices.</p>
      `,
  },
];

export const testimonials = [
  {
    id: "0",
    content:
      "I love the new AI tool for SOAP notes! It has expedited the time it takes completing my notes, and I feel more motivated to do my notes now in a more timely manner.",
    date: "October 08, 2023",
    author: "Verified Orchid Clinician",
  },
  {
    id: "1",
    content:
      "This AI tool has helped me make my notes more efficient, clear, and concise! I don't want to stop using it!",
    date: "November 10, 2023",
    author: "Verified Orchid Clinician",
  },
  {
    id: "2",
    content:
      "I used to dread writing notes, and now I just know I can click a button to draft a note. It's like having my own personal assistant!",
    date: "November 17, 2023",
    author: "Verified Orchid Clinician",
  },
];

const OrchidAI = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  const { isPhonePortrait } = usePhonePortraitContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [blogs, setBlogs] = useState<Blog[]>([]);

  useEffect(() => {
    queryBlogEntry()
      .then((res) => setBlogs(res || []))
      .catch(console.error);
  }, []);

  const posts = useMemo(
    () =>
      blogs
        .filter(
          (blog) =>
            blog.id === 71 ||
            blog.id === 67 ||
            blog.id === 70 ||
            blog.id === 1 ||
            blog.id === 2 ||
            blog.id === 3,
        )
        .sort((a, b) => (a.create_datetime < b.create_datetime ? 1 : -1))
        .slice(0, 3),
    [blogs],
  );

  return (
    <>
      <Helmet>
        <title>
          Orchid AI: The 1st EHR-Integrated AI Progress Note Generator
        </title>
        <meta
          name="description"
          content="Save time with Orchid’s AI clinical notes generator, fully integrated into the best all-in-one EHR software for your private practice."
        />
        <meta
          property="og:title"
          content="Orchid AI: The 1st EHR-Integrated AI Progress Note Generator"
        />
        <meta
          property="og:description"
          content="Save time with Orchid’s AI clinical notes generator, fully integrated into the best all-in-one EHR software for your private practice."
        />

        <meta
          name="twitter:title"
          content="Orchid AI: The 1st EHR-Integrated AI Progress Note Generator"
        />
        <meta
          name="twitter:description"
          content="Save time with Orchid’s AI clinical notes generator, fully integrated into the best all-in-one EHR software for your private practice."
        />
      </Helmet>
      {isPhonePortrait ? (
        <OrchidAIPhoneView />
      ) : (
        <div>
          <div className={styles["landing"]}>
            <Container className={styles["landing__container"]}>
              <Row className={styles["landing__row"]}>
                <div className={styles["landing__left"]}>
                  <FullWidthImage src={LANDING} fluid />
                </div>
                <div className={styles["landing__right"]}>
                  <div className={styles["landing__title"]}>
                    Take back your time with Orchid AI
                  </div>
                  <div className={styles["landing__text"]}>
                    The first powerful AI progress note generator that’s fully
                    integrated into your EHR
                  </div>
                  <div className={"d-flex"}>
                    <Button
                      size={"lg"}
                      className={styles["action"]}
                      as={Link}
                      to={"/pricing"}
                    >
                      Try Orchid AI
                    </Button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>

          <div className={styles["embrace-section"]}>
            <Container className={styles["embrace-section__container"]}>
              <div className={styles["embrace-section__title"]}>
                Embrace the AI-powered evolution of mental healthcare
              </div>
              <div className={styles["embrace-section__sub-title"]}>
                See how other professionals are thriving thanks to Orchid AI
              </div>

              <Row className={styles["item-list"]}>
                {testimonials.map((item) => (
                  <Col md={4} key={item.id} className={styles["item"]}>
                    <Card className={styles["card"]}>
                      <div className={styles["card__body"]}>
                        <div className={styles["quote-sign"]}>“</div>
                        <div>{item.content}</div>
                      </div>
                      <div className={styles["card__footer"]}>
                        <span>{item.date}</span>
                        <span>•</span>
                        <span>{item.author}</span>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
          <Container className={styles["why-automate__container"]}>
            <h1 className={styles["title"]}>
              Why automate with Orchid AI clinical notes?
            </h1>

            <Row className={styles["why-automate__step-row"]}>
              <Col className={styles["why-automate__step-left"]}>
                <FullWidthImage src={NEVER_START} fluid />
              </Col>
              <Col className={styles["why-automate__step-right"]}>
                <div className={styles["why-automate__step-title"]}>
                  Never start from a blank page again.
                </div>
                <div className={styles["why-automate__step-content"]}>
                  Complete comprehensive, insurance-compliant notes more quickly
                  with the combined powers of Orchid’s AI progress note
                  generator and the fully customizable templates of your choice.
                </div>
                <div className={styles["why-automate__step-content"]}>
                  Think of Orchid AI as a super savvy SOAP note generator, a
                  dang-that’s-fast DAP note generator, or even a
                  wow-I-actually-get-my-evenings-back generator.
                </div>
              </Col>
            </Row>

            <Row className={styles["why-automate__step-row"]}>
              <Col className={styles["why-automate__step-2-left"]}>
                <div className={styles["why-automate__step-title"]}>
                  Invest your time in an AI product you can really trust.
                </div>
                <div className={styles["why-automate__step-content"]}>
                  When it comes to vetting new tech for your practice, you can
                  trust in the Orchid AI team. Orchid was founded and developed
                  by a team of board-certified licensed clinicians and AI &
                  privacy experts at the vanguard of their professions.
                </div>
              </Col>
              <Col className={styles["why-automate__step-2-right"]}>
                <LazyImage src={TRUST} height={422} width={652} />
              </Col>
            </Row>

            <Row
              className={getClasses("mb-0", styles["why-automate__step-row"])}
            >
              <Col className={styles["why-automate__step-left"]}>
                <FullWidthImage src={STREAMLINE} fluid />
              </Col>
              <Col className={styles["why-automate__step-right"]}>
                <div className={styles["why-automate__step-title"]}>
                  Streamline your clinical workflow — all in one place.
                </div>
                <div className={styles["why-automate__step-content"]}>
                  Save time by generating your AI clinical notes within the same
                  all-in-one practice management software that includes online
                  scheduling, automated reminders, integrated billing,
                  telehealth, and more.
                </div>
                <div className={styles["why-automate__step-content"]}>
                  Say goodbye to juggling multiple software accounts when you
                  say hello to Orchid.
                </div>
              </Col>
            </Row>
            <div className={"d-flex justify-content-center mt-5"}>
              <Button
                size={"lg"}
                as={Link}
                to={"/pricing"}
                target={"_blank"}
                rel={"noopener noreferrer"}
                className={styles["action"]}
              >
                Start benefiting from Orchid AI
              </Button>
            </div>
          </Container>

          <div className={styles["how-ai-works"]}>
            <div className={styles["how-ai-works__title"]}>
              How does Orchid AI work?
            </div>
            <Card className={styles["step-1-card"]}>
              <div className={styles["step-tag"]}>STEP 1</div>
              <div className={styles["step-title"]}>
                Start with your appointment.
              </div>
              <div className={styles["step-content"]}>
                Before you begin a session, set Orchid’s integrated
                HIPAA-compliant AI to generate a session transcript. From there,
                you can focus 100% on your client, rest assured that your
                administrative experience later will be a breeze.
              </div>
            </Card>

            <Card className={styles["step-2-card"]}>
              <div className={styles["step-tag"]}>STEP 2</div>
              <div className={styles["step-title"]}>
                Effortlessly generate AI clinical notes.
              </div>
              <div className={styles["step-content"]}>
                Kick back and watch as Orchid’s clinically-trained AI model
                drafts any type of note you need, based on key data from the
                session transcript. Get suggestions for CPT codes, diagnoses,
                goals, treatment plans, and more — all within moments.
              </div>
            </Card>

            <Card className={styles["step-3-card"]}>
              <div className={styles["step-tag"]}>STEP 3</div>
              <div className={styles["step-title"]}>
                Gain measurable session insights.
              </div>
              <div className={styles["step-content"]}>
                Along with the session transcript, Orchid’s AI progress note
                generator also draws insights into key session data points to
                help you become an even more effective clinician. For example,
                Orchid AI will visualize data on speech patterns, such as how
                often you and your client each talk vs. how often you’re each
                silent.
              </div>
            </Card>
            <Card className={styles["step-4-card"]}>
              <div className={styles["step-tag"]}>STEP 4</div>
              <div className={styles["step-title"]}>
                Review, sign, and submit.
              </div>
              <div className={styles["step-content"]}>
                Simply review Orchid AI’s draft with your expert eye before
                signing off on the note. Your trained expertise + Orchid AI’s
                handling of admin drudgery? Now that’s what we call an EHR Dream
                Team.
              </div>
            </Card>
            <Card className={styles["step-5-card"]}>
              <div className={styles["step-tag"]}>STEP 5</div>
              <div className={styles["step-title"]}>
                Do literally anything else.
              </div>
              <div className={styles["step-content"]}>
                Reunite with your hobbies, have a date night, go to bed early —
                what will you do with all the time you’ll get back, thanks to
                the power of Orchid’s AI progress note generator? You’ll even be
                able to make room in your schedule for more clients, helping you
                earn more and grow your practice more efficiently.
              </div>
            </Card>
            <div className={styles["how-ai-works__action"]}>
              <Button
                size={"lg"}
                as={Link}
                to={"/pricing"}
                target={"_blank"}
                rel={"noopener noreferrer"}
                className={styles["action"]}
              >
                Start automating your admin with Orchid AI
              </Button>
            </div>
          </div>
          <Container className={styles["faq__container"]}>
            <div className={styles["faq"]}>
              <h1 className={getClasses(styles["bottom-56"], styles["title"])}>
                Questions? We’ve got answers
              </h1>
              {questions.map(({ id, question, answer }, idx) => {
                return (
                  <CustomAccordion
                    key={id}
                    id={id}
                    index={idx}
                    question={question}
                    answer={answer}
                    amplitudePageName={AMPLITUDE_PAGE_NAME}
                  />
                );
              })}
            </div>
            <div className={styles["more-question"]}>
              <div className={styles["more-question__title"]}>
                Got more questions about Orchid?
              </div>
              <div>
                <Button as={Link} to={"/orchid-ehr-faq"}>
                  Read the rest of our FAQs
                </Button>
              </div>
            </div>
          </Container>
          <div className={styles["posts"]}>
            <Container className={styles["posts__container"]}>
              <div className={styles["posts__title"]}>
                Learn more on the Orchid Blog
              </div>
              <div className={styles["posts__content"]}>
                Find out more about using AI in EHR, and get the latest tips for
                how to optimize your private practice.
              </div>
              <Row className={styles["list"]}>
                {posts.map((blog) => (
                  <Col
                    xs={12}
                    md={4}
                    lg={4}
                    className={styles["blog-post"]}
                    key={blog.id}
                  >
                    <BlogCard
                      blog={blog}
                      amplitudePageName={AMPLITUDE_PAGE_NAME}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
          <div className={styles["get-power"]}>
            <Container className={styles["get-power__container"]}>
              <div
                className={getClasses(
                  styles["bottom-56"],
                  styles["get-power__title"],
                )}
              >
                Get the power of Orchid AI today.
              </div>
              <div className={styles[""]}>
                Transform your practice with increased efficiency, deeper
                insights, and improved client outcomes.
              </div>
              <div>
                <Button
                  size={"lg"}
                  className={styles["action"]}
                  as={"a"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  href={"https://calendly.com/hey-orchid/orchid-walkthrough"}
                >
                  Get your free Orchid AI demo
                </Button>
              </div>
            </Container>
          </div>
          <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
        </div>
      )}
    </>
  );
};

export default OrchidAI;
