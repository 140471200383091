import type Pubnub from "pubnub";

const chatMessageDecrypt = (
  pubnub: Pubnub,
  secureText: string,
  cipherKey: string,
) => {
  let openTextObj = pubnub.decrypt(secureText, cipherKey);
  if (!openTextObj) {
    // @ts-ignore
    pubnub._config.useRandomIVs = false;
    openTextObj = pubnub.decrypt(secureText, cipherKey);
    // @ts-ignore
    pubnub._config.useRandomIVs = true;
  }
  return openTextObj;
};

export default chatMessageDecrypt;
