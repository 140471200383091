import React, { useCallback, useEffect, useRef } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AmplitudePageName, track } from "utils/amplitude";
import useIdleTimeout from "hooks/useIdleTimeout";

interface TimeOnPageContextInterface {
  setPage: (
    page: AmplitudePageName,
    options?: { eventProperties?: Record<any, any> },
  ) => void;
}

const TimeOnPageContext = React.createContext<
  TimeOnPageContextInterface | undefined
>(undefined);

export const useTimeOnPageContext = () => {
  const timeOnPageContext = useContext(TimeOnPageContext);
  if (!timeOnPageContext) {
    throw new Error(
      "No TimeOnPageContext.Provider found when calling useTimeOnPageContext.",
    );
  }
  return timeOnPageContext;
};

interface Props {
  children: React.ReactNode;
}

type PageId = {
  page: AmplitudePageName;
  url: string;
};

export const TimeOnPageContextProvider: React.FC<Props> = ({ children }) => {
  /**
   * This context ensures correct time-on-page logging of instrumented pages
   * (instrumented meaning that the page calls setPage in a `useEffect(() => setPage(page), [setPage])`.
   * It starts the timer when setPage is called and it ends the timer when EITHER
   * the next setPage is called or the location changes to a page that does not call
   * setPage. The order useEffect and setPage are called should not matter,
   * as detailed below:
   *
   * - 1st time: [setPage], [location useEffect] => [starts the timer, sets pageRef], [does nothing]
   * - 1st time: [location useEffect], [setPage] => [does nothing], [starts the timer, sets pageRef]
   * - Subsequent time: [setPage], [location useEffect] => [logs the last page's time, sets the timer, sets new pageRef], [does nothing]
   * - Subsequent time: [location useEffect], [setPage] => [logs the last page's time, nullifies pageRef and startTimeRef], [sets the pageRef and timer]
   */
  const DEBUG = false;
  const pageRef = useRef<PageId | null>(null);
  const eventPropertiesRef = useRef<Record<any, any> | null>(null);
  const startTimeRef = useRef<Date | null>(null);
  const location = useLocation();

  /* When a user is considered idle, go ahead and send their page view event */
  useIdleTimeout({
    onIdle: () => {
      if (pageRef.current && startTimeRef.current) {
        const timeOnPage =
          (new Date().getTime() - startTimeRef.current.getTime()) / 1000;

        track(pageRef.current.page, [], "view", {
          eventProperties: {
            "time-on-page": timeOnPage,
            ...eventPropertiesRef.current,
          },
        });

        startTimeRef.current = null;
      }
    },
    /* When the user becomes active again, restart the timer from zero... we consider this a new page view */
    onActive: () => {
      startTimeRef.current = new Date();
    },
    idleTime: 120, // 2 minutes
  });

  useEffect(() => {
    // We are on a new page from the last one, but this page does not have
    // the TimeOnPage tracker set up with a call to `setPage`. We still want
    // to make sure the time for the last page gets tracked
    if (
      pageRef.current &&
      location.pathname + location.search !== pageRef.current?.url &&
      startTimeRef.current
    ) {
      const timeOnPage =
        (new Date().getTime() - startTimeRef.current.getTime()) / 1000;

      track(pageRef.current.page, [], "view", {
        eventProperties: {
          "time-on-page": timeOnPage,
          ...eventPropertiesRef.current,
        },
      });

      if (DEBUG) {
        console.log(
          `Time on last page (${pageRef.current?.page}) was ${
            (new Date().getTime() - startTimeRef.current.getTime()) / 1000
          } seconds`,
        );
      }
      startTimeRef.current = null;
      pageRef.current = null;
      eventPropertiesRef.current = null;
    }
  }, [location, DEBUG]);

  const setPage = useCallback(
    (
      page: AmplitudePageName,
      options?: { eventProperties?: Record<any, any> },
    ) => {
      const lastPage = pageRef.current;
      const lastEventProperties = eventPropertiesRef.current;
      pageRef.current = { page, url: location.pathname + location.search };
      eventPropertiesRef.current = options?.eventProperties || null;

      track(pageRef.current.page, [], "visit", {
        eventProperties: {
          ...options?.eventProperties,
        },
      });

      if (lastPage && startTimeRef.current) {
        const timeOnPage =
          (new Date().getTime() - startTimeRef.current.getTime()) / 1000;

        track(lastPage.page, [], "view", {
          eventProperties: {
            "time-on-page": timeOnPage,
            ...lastEventProperties,
          },
        });

        if (DEBUG) {
          console.log(
            `Time on last page (${lastPage.page}) was ${timeOnPage} seconds`,
          );
        }
      }

      startTimeRef.current = new Date();
    },
    [location.pathname, location.search, DEBUG],
  );

  return (
    <TimeOnPageContext.Provider value={{ setPage }}>
      {children}
    </TimeOnPageContext.Provider>
  );
};
