import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    hasGuardianToken: false,
    pendingLoginModal: false,
  },
  reducers: {
    setHasGuardianToken: (state, action: PayloadAction<boolean>) => {
      state.hasGuardianToken = action.payload;
    },
    showLoginModal: (state) => {
      state.pendingLoginModal = true;
    },
    loginModalShown: (state) => {
      state.pendingLoginModal = false;
    },
  },
});

export const { setHasGuardianToken, showLoginModal, loginModalShown } =
  authSlice.actions;

export const selectHasGuardianToken = (state: RootState) =>
  state.auth.hasGuardianToken;
export const selectPendingLoginModal = (state: RootState) =>
  state.auth.pendingLoginModal;
