import getOrchidProMedicalDegree from "utils/getOrchidProMedicalDegree";
import { getOrchidProNameAndAllGraduateDegreesString } from "utils/formatter/orchidPro";
import capitalize from "utils/capitalize";

export default function getOrchidProDisplayName(orchidPro) {
  const medicalDegree = getOrchidProMedicalDegree(orchidPro)
    ? getOrchidProMedicalDegree(orchidPro)
    : orchidPro.medical_degree
    ? orchidPro.medical_degree
    : "MD/DO";

  const { first_name, last_name, preferred_first_name, name_schema } =
    orchidPro;

  const preferredFirstName = preferred_first_name
    ? preferred_first_name
    : first_name;

  switch (name_schema) {
    case "First Name Last Name":
      return `${capitalize(first_name)} ${capitalize(last_name)}`;

    case "First Name":
      return capitalize(first_name);

    case "Preferred First Name Last Name":
      return `${capitalize(preferredFirstName)} ${capitalize(last_name)}`;

    case "Preferred First Name":
      return capitalize(preferredFirstName);

    case "Dr. First Name Last Name":
      return `Dr. ${capitalize(first_name)} ${capitalize(last_name)}`;

    case "Dr. First Name":
      return `Dr. ${capitalize(first_name)}`;

    case "Dr. Last Name":
      return `Dr. ${capitalize(last_name)}`;

    case "Dr. Preferred First Name Last Name":
      return `Dr. ${capitalize(preferredFirstName)} ${capitalize(last_name)}`;

    case "Dr. Preferred First Name":
      return `Dr. ${capitalize(preferredFirstName)}`;

    case `First Name Last Name, ${medicalDegree}`:
      return `${capitalize(first_name)} ${capitalize(
        last_name,
      )}, ${medicalDegree}`;

    case `First Name, ${medicalDegree}`:
      return `${capitalize(first_name)}, ${medicalDegree}`;

    case `Preferred First Name Last Name, ${medicalDegree}`:
      return `${capitalize(preferredFirstName)} ${capitalize(
        last_name,
      )}, ${medicalDegree}`;

    case `Preferred First Name, ${medicalDegree}`:
      return `${capitalize(preferredFirstName)}, ${medicalDegree}`;

    default:
      return getOrchidProNameAndAllGraduateDegreesString(orchidPro);
  }
}
