import { useEffect, useState } from "react";

const getStepFromHash = (steps: string[]) => {
  const stepFromHash = window.location.hash.slice(1);
  const defaultStep = steps[0] ?? "";
  if (!steps.includes(stepFromHash)) {
    window.location.hash = defaultStep;
    return defaultStep;
  }
  return stepFromHash;
};

const useHashStepNavigation = (steps: string[]) => {
  const [currentStep, setCurrentStep] = useState(() => getStepFromHash(steps));

  useEffect(() => {
    const onHashChange = () => setCurrentStep(getStepFromHash(steps));

    onHashChange();
    window.addEventListener("hashchange", onHashChange);

    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, [steps]);

  return { currentStep };
};

export default useHashStepNavigation;
