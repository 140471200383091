import { getErrorDetail } from "components/utility/utils";
// import { useUser } from "contexts/UserContext";
import useForm from "hooks/useForm";
import { useState } from "react";
import { fetchOrchidAPI } from "utils/api";

export type OrderType = "checkout" | "trial";

export const getPageInfo = (
  subClass: PlanType,
  subPeriod: SubPeriod,
  orderType: OrderType,
) => {
  const baseInfo = {
    core: {
      price: 19.99,
      annualCount: 11,
      canTrial: true,
      subClass: "Core",
    },
    complete: {
      price: 28.99,
      annualCount: 10,
      canTrial: false,
      subClass: "Complete",
    },
    "ai-only": {
      price: 9.99,
      annualCount: 12,
      canTrial: true,
      subClass: "AI Assistant",
    },
    "core-with-ai": {
      price: 29.97,
      annualCount: 11,
      canTrial: false,
      subClass: "Core with AI Toolkit",
    },
  };

  const pageGeneralInfo = {
    checkout: {
      title: `Purchase Orchid ${baseInfo[subClass].subClass} (${subPeriod})`,
      subtitle: null,
    },
    trial: {
      title: "Get started with Orchid",
      subtitle: "Enjoy your 30-day free trial!",
    },
  };

  return {
    ...baseInfo[subClass],
    isOntrial: baseInfo[subClass].canTrial && orderType === "trial",
    isAnnual: subPeriod === "annual",
    title: pageGeneralInfo[orderType].title,
    subtitle: pageGeneralInfo[orderType].subtitle,
    subPeriod: subPeriod,
    subtotal: function () {
      if (this.isOntrial) return "0.00";
      if (!this.isAnnual) return this.price;
      return this.price * this.annualCount;
    },
    nextBilling: function () {
      if (!this.isAnnual) return this.price;
      return this.price * this.annualCount;
    },
    nextBillingDue: function () {
      const locale = Intl.DateTimeFormat().resolvedOptions().locale;
      const ordinal = new Intl.PluralRules(locale, {
        type: "ordinal",
      });

      const suffixes = new Map([
        ["one", "st"],
        ["two", "nd"],
        ["few", "rd"],
        ["other", "th"],
      ]);

      const date = new Date();
      date.setDate(date.getDate() + 30);
      let [month, day] = date
        .toLocaleDateString(locale, {
          month: "long",
          day: "numeric",
        })
        .split(" ");
      day = day + suffixes.get(ordinal.select(+day));

      return `${month} ${day}`;
    },
  };
};

export const useRegisterUser = () => {
  // const { updateUserInfo } = useUser();
  const registerForm = useForm(
    {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
    },
    {
      first_name: {
        required: {
          validator: (value: string) => value.length > 0,
          message: "Please enter your first name",
        },
      },
      last_name: {
        required: {
          validator: (value: string) => value.length > 0,
          message: "Please enter your last name",
        },
      },
      email: {
        required: {
          validator: (value: string) => value.length > 0,
          message: "Please enter your email",
        },
        valid_format: {
          validator: (value: string) =>
            // eslint-disable-next-line
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value),
          message: "Please enter a valid email",
        },
      },
      password: {
        required: {
          validator: (value: string) => value.length > 0,
          message: "Please enter your password",
        },
        min_length: {
          validator: (value: string) => value.length > 9,
          message: "Password must be at least 10 characters",
        },
        contain_number: {
          // eslint-disable-next-line
          validator: (value: string) => /\d/.test(value),
          message: "Password must contain at least one number",
        },
        contain_lowercase: {
          validator: (value: string) => /[a-z]/.test(value),
          message: "Password must contain at least one lowercase letter",
        },
        contain_uppercase: {
          validator: (value: string) => /[A-Z]/.test(value),
          message: "Password must contain at least one uppercase letter",
        },
        contain_special_char: {
          validator: (value: string) =>
            // eslint-disable-next-line
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value),
          message: "Password must contain at least one special character",
        },
      },
      confirm_password: {
        required: {
          validator: (value: string) => value.length > 0,
          message: "Please confirm your password",
        },
        equalTo: {
          validator: (value: string, formData) => value === formData.password,
          message: "Passwords do not match",
        },
      },
    },
  );

  //password hide and show
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //register user
  const registerUser = async () => {
    const response = await fetchOrchidAPI("/api/users/v1/orchid_pro/account", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_in: {
          email: registerForm.value.email,
          password: registerForm.value.password,
          // invite_code: registerForm.value.invite_code,
        },
        orchid_pro_in: {
          first_name: registerForm.value.first_name,
          last_name: registerForm.value.last_name,
          is_over_eighteen: true,
        },
      }),
    });
    if (!response.ok)
      return { ok: false, message: getErrorDetail(await response.text()) };
    return { ok: true, message: response.statusText };
  };

  return {
    registerForm,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    registerUser,
  };
};

export const isPlanCancelled = (sub) => {
  return sub?.auto_renew === false;
};

export const isPlanTerminated = (sub) => {
  return sub?.terminated_code && sub?.terminated_time;
};

export const isPlanInactivated = (sub) => {
  return !sub?.activated_time;
};
