import LazyImage from "components/lazy-image";
import { ImageProps } from "react-bootstrap";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

const FullWidthImage = ({ className, src, ...props }: ImageProps) => (
  <LazyImage
    showGreyFallback
    src={src}
    alt={"loading"}
    {...props}
    className={getClasses(styles["full-width-image"], className)}
  />
);

export default FullWidthImage;
