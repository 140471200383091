import React, { useState, useEffect } from "react";
import { ReactComponent as CalculatorTooltip } from "assets/svg/calculator-tooltip.svg";
import styles from "./RangeSlider.module.scss";

const RangeSlider = ({ min, max, value, step, onChange }) => {
  const [minValue, setMinValue] = useState(value ? value.min : min);
  const [maxValue, setMaxValue] = useState(value ? value.max : max);

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMinChange = (e) => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value, maxValue - step);
    if (!value) setMinValue(newMinVal);
    onChange({ min: newMinVal, max: maxValue });
  };

  // const handleMaxChange = (e) => {
  //   e.preventDefault();
  //   const newMaxVal = Math.max(+e.target.value, minValue + step);
  //   if (!value) setMaxValue(newMaxVal);
  //   onChange({ min: minValue, max: newMaxVal });
  // };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["input-wrapper"]}>
        <input
          className={styles["input"]}
          type="range"
          value={minValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
          onMouseDown={() => setShowTooltip(true)}
          onMouseUp={() => setShowTooltip(false)}
          onTouchStart={() => setShowTooltip(true)}
          onTouchEnd={() => setShowTooltip(false)}
        />
        {/* <input
          className={styles["input"]}
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
        /> */}
      </div>

      <div className={styles["control-wrapper"]}>
        <div className={styles["control"]} style={{ left: `${minPos}%` }}>
          {showTooltip && (
            <div className={styles["tooltip-wrapper"]}>
              <div className={styles["tooltip"]}>
                <CalculatorTooltip />
                <div className={styles["tooltip-text"]}>
                  <div>{minValue}</div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles["rail"]}>
          <div
            className={styles["inner-rail"]}
            style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
          />
        </div>
        {/* <div className={styles["control"]} style={{ left: `${maxPos}%` }} /> */}
      </div>
    </div>
  );
};

export default RangeSlider;
