import React from "react";
import { lazy as reactLazy } from "react";

/**
 * What's the point of this?
 *
 * This is supposed to solve the following issue
 * 1. User loads a page (downloading the relevant js chunks)
 * 2. A deploy occurs
 * 3. Without reloading their browser, the user tries to navigate to another page.
 *    Their version of the code tries to call other chunks of the code that no longer exists
 *
 * To solve this, we hard refresh their page when they encounter a missing chunk
 */
const lazy = (
  factory: () => Promise<{
    default: React.ComponentType<any>;
  }>,
): React.LazyExoticComponent<React.ComponentType<any>> => {
  const newFactory = async (): Promise<{
    default: React.ComponentType<any>;
  }> => {
    try {
      return await factory();
    } catch {
      return Promise.resolve({
        default: () => (
          <div>
            A problem has occurred, please try clearing your cache or contact
            support.
          </div>
        ),
      });
    }
  };

  return reactLazy(newFactory);
};

export default lazy;
