import {
  ChatAccessDataMapByChannel,
  PrivateChatAccessData,
} from "../pubnub.types";

const findChatAccessDataByCognitoSub = (
  chatAccessDataByChannel: ChatAccessDataMapByChannel,
  partnerSub: string,
): PrivateChatAccessData | undefined => {
  const accessDataList: Array<PrivateChatAccessData> = Array.from(
    chatAccessDataByChannel.values(),
  );
  return accessDataList.find((a) => a.partnerCognitoSub === partnerSub);
};

export default findChatAccessDataByCognitoSub;
