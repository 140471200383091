import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import BsModal, { ModalProps } from "react-bootstrap/esm/Modal";
import getClasses from "utils/getClasses";

import styles from "./styles.module.scss";

type Props = {
  fullPage?: boolean;
} & ModalProps;

const ResponsiveModal = ({
  fullPage,
  dialogClassName,
  contentClassName,
  ...props
}: Props) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  return (
    <BsModal
      dialogClassName={dialogClassName}
      contentClassName={contentClassName}
      {...props}
      {...(isPhonePortrait && {
        className: getClasses(
          styles["responsive-modal--phone-portrait"],
          fullPage && styles["responsive-modal--phone-portrait--full-page"],
        ),
        dialogClassName: getClasses(
          styles["responsive-modal--phone-portrait__dialog"],
          dialogClassName,
        ),
        contentClassName: getClasses(
          styles["responsive-modal--phone-portrait__content"],
          contentClassName,
        ),
      })}
    />
  );
};

export default ResponsiveModal;
