import { selectHasGuardianToken, showLoginModal } from "store/authSlice";
import { store } from "store/store";

export function apiHostAndPath(path: string): string {
  if (window.location.hostname.endsWith(".secureportal.health")) {
    if (path.startsWith("/instant_messaging/ws")) {
      return `wss://${window.location.hostname}${path}`;
    } else {
      return `https://${window.location.hostname}${path}`;
    }
  }

  const LOCALHOST_API_HOSTNAME = "orchidsquirrel.com";
  // const LOCALHOST_API_HOSTNAME = "orchidstage.com";

  const remoteHostname =
    process.env?.NODE_ENV === "development"
      ? LOCALHOST_API_HOSTNAME
      : window.location.hostname;
  const hostname = remoteHostname.startsWith("www.")
    ? remoteHostname.substring(4)
    : remoteHostname;
  if (
    path.startsWith("/api/notes_secure_data/") ||
    path.startsWith("/api/static_drive/")
  ) {
    return `https://n42.${hostname}${path}`;
  } else if (path.startsWith("/api/login")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/drive_data")) {
    return `https://drive-data.${hostname}${path}`;
  } else if (path.startsWith("/api/drive")) {
    return `https://drive.${hostname}${path}`;
  } else if (path.startsWith("/api/users")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/pubnub_grant")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/reporting")) {
    return `https://report.${hostname}${path}`;
  } else if (path.startsWith("/instant_messaging/ws")) {
    return `wss://ws.${hostname}${path}`;
  } else {
    return `https://api.${hostname}${path}`;
  }
}

async function renewTokens() {
  const renewTokenResponse = await fetchOrchidAPI(
    "/api/login/v1/renew_access_token",
    { method: "POST" },
    false,
  );

  if (!renewTokenResponse.ok) {
    throw new Error("Failed to renew access token");
  }

  const isGuardian = selectHasGuardianToken(store.getState());
  if (isGuardian) {
    const renewGuardianTokenResponse = await fetchOrchidAPI(
      "/api/users/v1/minors/renew_current_access",
      { method: "POST" },
      false,
    );
    if (!renewGuardianTokenResponse.ok) {
      throw new Error("Failed to renew guardian token");
    }
  }
}

let renewTokensHandlers: { resolve: () => void; reject: () => void }[] = [];
// If token renewal is already in progress, wait for it to finish; otherwise - trigger it
function waitForRenewedTokens() {
  return new Promise<void>((resolve, reject) => {
    renewTokensHandlers.push({ resolve, reject });
    if (renewTokensHandlers.length > 1) {
      return;
    }
    renewTokens()
      .then(() => {
        renewTokensHandlers.forEach(({ resolve }) => resolve());
        renewTokensHandlers = [];
      })
      .catch(() => {
        // The failure could've happened for any reason, but since we got a 401 or 403 before and couldn't renew the token, the user won't be able to access the API without logging in again
        store.dispatch(showLoginModal());
        renewTokensHandlers.forEach(({ reject }) => reject());
        renewTokensHandlers = [];
      });
  });
}

export async function fetchOrchidAPI(
  resource: string,
  init: RequestInit = {},
  retryIfUnauthorized = true,
): Promise<Response> {
  try {
    const headers = new Headers(init.headers ? init.headers : {});
    if (!headers.has("Content-Type"))
      headers.set("Content-Type", "application/json");

    const initDefaultToJsonBody = {
      ...init,
      headers: headers,
      credentials: init.credentials ? init.credentials : "include",
      mode: init.credentials ? init.mode : "cors",
    };
    const response = await fetch(
      apiHostAndPath(resource),
      initDefaultToJsonBody,
    );

    const isUnauthorized = response.status === 401 || response.status === 403;

    if (!isUnauthorized || !retryIfUnauthorized) {
      return response;
    }

    // Refresh access token(s) and retry the original request
    try {
      await waitForRenewedTokens();
      // Token successfully renewed, retry the original request
      return fetchOrchidAPI(resource, init, false);
    } catch (err) {
      // Token renewal failed, let the caller handle the 401 response (the user will be shown a login modal anyway)
      return response;
    }
  } catch (err: any) {
    if (err.name === "AbortError") throw err;
    const init = { status: 503, statusText: "ServiceUnavailable" };
    const networkErr = new Response(err, init);
    return networkErr;
  }
}
