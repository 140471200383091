import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  FOOTER_SECTIONS_TOP,
  FOOTER_SECTIONS_BOTTOM,
} from "components/client-facing-landing-page/constants";
import { Link } from "react-router-dom";
import { AmplitudePageName, track } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import getClasses from "utils/getClasses";
import styles from "./Footer.module.scss";
import FooterSection from "./FooterSection";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

const Footer = ({
  AMPLITUDE_PAGE_NAME,
}: {
  AMPLITUDE_PAGE_NAME: AmplitudePageName;
}) => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => {
    setPage(AMPLITUDE_PAGE_NAME);
  }, [setPage, AMPLITUDE_PAGE_NAME]);

  const [footerExpanded, setFooterExpanded] = React.useState(false);
  const { isPhonePortrait } = usePhonePortraitContext();

  return (
    <footer
      className={getClasses(
        styles["row"],
        styles["row--text-white"],
        styles["row--footer"],
      )}
    >
      <Row
        className={getClasses(styles["row"], styles["row--footer-container"])}
      >
        <Row
          className={getClasses(
            styles["row"],
            styles["row--footer-columns-row-top"],
          )}
        >
          <Col
            className={getClasses(
              styles["col"],
              styles["col--footer-col-right"],
            )}
          >
            {FOOTER_SECTIONS_TOP.map((section) => (
              <FooterSection
                key={section.title}
                section={section}
                amplitudePageName={AMPLITUDE_PAGE_NAME}
                expanded={footerExpanded}
                handleViewAllClick={() => setFooterExpanded(true)}
              />
            ))}
          </Col>
        </Row>
        <Row
          className={getClasses(
            styles["row"],
            styles["row--footer-columns-row-mid"],
          )}
        ></Row>
        <Row className={styles["row--footer-columns-row"]}>
          <Row
            className={getClasses(
              "d-flex justify-content-between",
              !isPhonePortrait && styles["adjust-width"],
            )}
          >
            <div
              className={getClasses(
                styles["row--footer-columns-row-bottom"],
                isPhonePortrait &&
                  styles["row--footer-columns-row-bottom--phone"],
              )}
            >
              {FOOTER_SECTIONS_BOTTOM.map((el, i) => (
                <Col
                  key={i}
                  className={getClasses(
                    styles["col"],
                    styles["col--footer-col-bottom"],
                    isPhonePortrait && styles["col--footer-col-bottom--phone"],
                  )}
                >
                  {el.href ? (
                    <Link
                      className={styles["footer-bottom-col-link"]}
                      to={el.href}
                      onClick={() =>
                        el.id &&
                        track(AMPLITUDE_PAGE_NAME, `${el.id}-link`, "click")
                      }
                    >
                      {el.title}
                    </Link>
                  ) : (
                    el.title
                  )}
                </Col>
              ))}
            </div>
            <div
              className={getClasses(
                styles["row--footer-columns-row-bottom__right"],
                isPhonePortrait &&
                  styles["row--footer-columns-row-bottom__right--phone"],
              )}
            >
              If you have an emergency and need immediate help, call{" "}
              <a href="tel://911">911</a> or go to the nearest emergency room.
            </div>
          </Row>
          <Row
            className={getClasses(
              "d-flex justify-content-between",
              !isPhonePortrait && styles["adjust-width"],
            )}
          >
            <div className={styles["row--footer-columns-row-bottom"]}></div>
            <Row
              className={getClasses(
                styles["footer-phone-line-row"],
                styles["row--footer-columns-row-bottom__right"],
                isPhonePortrait &&
                  styles["row--footer-columns-row-bottom__right--phone"],
              )}
            >
              <Col className={styles["footer-phone-line-col"]}>
                <b>Suicide Prevention Lifeline</b>
                <div>
                  <a href="tel://+1-800-273-8255">+1-800-273-8255</a>
                </div>
              </Col>
              <Col
                className={getClasses(
                  styles["footer__middle-col"],
                  isPhonePortrait && styles["footer__middle-col--phone"],
                )}
              >
                <b>Crisis Text Line</b>
                <div>
                  Text HOME to <a href="sms://741741">741741</a>
                </div>
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>
    </footer>
  );
};

export default Footer;
