import React, { useContext } from "react";
import { Accordion, Card } from "react-bootstrap";

import { ReactComponent as OutlineCross24 } from "assets/svg/outline-cross.svg";
import { ReactComponent as OutlinePlus24 } from "assets/svg/plus-24.svg";

import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";

import getClasses from "utils/getClasses";
import styles from "./CustomAccordion.module.scss";
import { track } from "utils/amplitude";

function ContextAwareToggle({ children, eventKey, callback, ...props }) {
  const { isPhonePortrait } = usePhonePortraitContext();
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return !isPhonePortrait ? (
    <Accordion.Toggle
      as={Card.Header}
      eventKey={eventKey}
      onClick={decoratedOnClick}
      className={getClasses(styles["custom-accordion__card-header"])}
      style={{ flex: 1 }}
    >
      <div>{children}</div>{" "}
      <div className={styles["custom-accordion__icon-wrapper"]}>
        {isCurrentEventKey ? <OutlineCross24 /> : <OutlinePlus24 />}
      </div>
    </Accordion.Toggle>
  ) : (
    <Accordion.Toggle
      as={Card.Header}
      eventKey={eventKey}
      onClick={decoratedOnClick}
      className={styles["custom-accordion__card-header--phone"]}
    >
      {children}{" "}
      <div
        className={
          isPhonePortrait && styles["custom-accordion__card-header-icon--phone"]
        }
      >
        {isCurrentEventKey ? <OutlineCross24 /> : <OutlinePlus24 />}
      </div>
    </Accordion.Toggle>
  );
}

const CustomAccordion = ({
  id,
  question,
  answer,
  amplitudePageName,
  index,
}) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  return (
    <Accordion
      className={styles["custom-accordion__accordion"]}
      onClick={() =>
        amplitudePageName &&
        track(amplitudePageName, "accordion-question-&-answer", "click", {
          eventProperties: { question },
        })
      }
    >
      <Card className={styles["custom-accordion__card"]}>
        <ContextAwareToggle eventKey={id} callback={() => null}>
          <h3 className={styles["custom-accordion__question"]}>{question}</h3>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey={id}>
          <div
            className={getClasses(
              "card-body",
              !isPhonePortrait
                ? styles["custom-accordion__card-body"]
                : styles["custom-accordion__card-body--phone"],
            )}
          >
            <div
              className={getClasses("mb-0", styles["custom-accordion__text"])}
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CustomAccordion;
