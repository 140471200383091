import { reportProblem } from "utils/frontend_error_reporting";
import {
  ChannelData,
  ChatMessageNotificationData,
  ChatMessageNotificationDataEncrypted,
  ChatMessageReaction,
} from "../pubnub.types";
import updateMessages from "../utils/update-messages";

const addReceiptToMessageInList = <
  T extends ChatMessageNotificationData | ChatMessageNotificationDataEncrypted,
>(
  messages: T[],
  messageTimetoken: string,
  cognitoSub: string,
  timestamp: Date,
) =>
  messages.map((message) =>
    message.messageTimetoken !== messageTimetoken
      ? message
      : {
          ...message,
          receiptsByCognitoSub: {
            ...message.receiptsByCognitoSub,
            [cognitoSub]: timestamp,
          },
        },
  );

const setReactionForMessageInList = <
  T extends ChatMessageNotificationData | ChatMessageNotificationDataEncrypted,
>(
  messages: T[],
  messageTimetoken: string,
  reaction: ChatMessageReaction | null,
) =>
  messages.map((message) =>
    message.messageTimetoken !== messageTimetoken
      ? message
      : {
          ...message,
          reaction,
        },
  );

type ChannelsReducerNewMessagesAction = {
  type: "new_messages";
  channel: string;
  messagesEncrypted: Array<ChatMessageNotificationDataEncrypted>;
};

type ChannelsReducerMessagesDecryptAction = {
  type: "messages_decrypt";
  channels: Map<string, Array<ChatMessageNotificationData>>;
};

type ChannelsReducerResetAction = {
  type: "reset";
};

type ChannelsReducerMessageReadConfirmAction = {
  type: "message_read_confirm";
  channel: string;
  messageTimetoken: string;
  cognitoSub: string;
  timestamp: Date;
};

type ChannelsReducerSetMessageReactionAction = {
  type: "set_message_reaction";
  channel: string;
  sender: string;
  messageTimetoken: string;
  reaction: ChatMessageReaction | null;
};

const channelsReducer = (
  state: {
    [channel: string]: ChannelData;
  },
  action:
    | ChannelsReducerResetAction
    | ChannelsReducerNewMessagesAction
    | ChannelsReducerMessagesDecryptAction
    | ChannelsReducerMessageReadConfirmAction
    | ChannelsReducerSetMessageReactionAction,
) => {
  switch (action.type) {
    case "reset": {
      console.log("channelsReducer::reset", action);
      return {};
    }
    case "message_read_confirm": {
      console.log("message_read_confirm", action);
      const channelData = state[action.channel];
      if (channelData === undefined) {
        return state;
      }

      return {
        ...state,
        [action.channel]: {
          ...channelData,
          messages: addReceiptToMessageInList(
            channelData.messages,
            action.messageTimetoken,
            action.cognitoSub,
            action.timestamp,
          ),
          messagesEncrypted: addReceiptToMessageInList(
            channelData.messagesEncrypted,
            action.messageTimetoken,
            action.cognitoSub,
            action.timestamp,
          ),
        },
      };
    }
    case "set_message_reaction": {
      console.log("channelsReducer::setMessageReaction", action);
      const channelData = state[action.channel];
      if (channelData === undefined) {
        return state;
      }

      return {
        ...state,
        [action.channel]: {
          ...channelData,
          messages: setReactionForMessageInList(
            channelData.messages,
            action.messageTimetoken,
            action.reaction,
          ),
          messagesEncrypted: setReactionForMessageInList(
            channelData.messagesEncrypted,
            action.messageTimetoken,
            action.reaction,
          ),
        },
      };
    }
    case "new_messages": {
      console.log("channelsReducer::new_messages", action);
      const channelData = state[action.channel] ?? {
        channel: action.channel,
        messages: [],
        messagesEncrypted: [],
      };
      return {
        ...state,
        [action.channel]: {
          ...channelData,
          messagesEncrypted: updateMessages(
            channelData.messagesEncrypted,
            action.messagesEncrypted,
          ),
        },
      };
    }
    case "messages_decrypt": {
      console.log("channelsReducer::messages_decrypt", action);
      const nextState = Object.assign({}, state);
      for (const [channel, channelDataUpdate] of action.channels.entries()) {
        const channelData = state[channel];
        if (channelData === undefined) {
          reportProblem({
            reporter_module: "channelsReducer/messages_decrypt",
            context: { channel },
            frontend_error:
              "Channel data was not found for decrypted messages, this should never happen",
          });
          continue; // do not hard fail to avoid completely breaking chat
        }
        const messageTimetokensDecrypted = new Set(
          channelDataUpdate.map((d) => d.messageTimetoken),
        );
        const encryptedMessagesLeft = channelData.messagesEncrypted.filter(
          (d) => !messageTimetokensDecrypted.has(d.messageTimetoken),
        );
        nextState[channel] = {
          ...channelData,
          messagesEncrypted: encryptedMessagesLeft,
          messages: updateMessages(channelData.messages, channelDataUpdate),
        };
      }
      return nextState;
    }
  }
};

export default channelsReducer;
