import { ChatMessageData } from "../pubnub.types";
import pubNubTimetokenToTimestamp from "../utils/pubnub-timetoken/pubnub-timetoken-to-timestamp";
import updateMessages from "../utils/update-messages";

export interface ChatHistoryReducerState {
  chatHistory: Map<string, ChatMessageData[]>;
  oldestMessageDatetimeByChannel: {
    [channel: string]: number;
  };
  lastRequestedTimestampByChannel: {
    [channel: string]: number;
  };
}

interface ResetAction {
  type: "reset";
}

interface AddMessagesAction {
  type: "add";
  channel: string;
  messages: ChatMessageData[];
}

interface AddReactionAction {
  type: "add_reaction";
  channel: string;
  messageTimetoken: string;
  actionTimetoken: string;
  reactionEmoji: string;
}

interface SetLastRequestedTimestampAction {
  type: "set_last_requested_timestamp_by_channel";
  channel: string;
  timestamp: number;
}

export type ChatHistoryReducerAction =
  | ResetAction
  | AddMessagesAction
  | AddReactionAction
  | SetLastRequestedTimestampAction;

export const chatHistoryReducerInitialState: ChatHistoryReducerState = {
  chatHistory: new Map<string, ChatMessageData[]>(),
  oldestMessageDatetimeByChannel: {},
  lastRequestedTimestampByChannel: {},
};

function chatHistoryReducer(
  state: ChatHistoryReducerState,
  action: ChatHistoryReducerAction,
): ChatHistoryReducerState {
  switch (action.type) {
    case "reset": {
      return {
        chatHistory: new Map<string, ChatMessageData[]>(),
        oldestMessageDatetimeByChannel: {},
        lastRequestedTimestampByChannel: {},
      };
    }
    case "add": {
      const newChatHistory = new Map<string, ChatMessageData[]>(
        state.chatHistory,
      );
      const existingMessages = newChatHistory.get(action.channel);
      if (existingMessages) {
        const messages = updateMessages(existingMessages, action.messages);
        newChatHistory.set(action.channel, messages);
      } else {
        newChatHistory.set(action.channel, action.messages);
      }
      const [oldestDatetimeInIncomingMessages] = action.messages
        .map((message) =>
          message.messageTimetoken
            ? pubNubTimetokenToTimestamp(message.messageTimetoken)
            : Number.POSITIVE_INFINITY,
        )
        .sort();
      return {
        ...state,
        chatHistory: newChatHistory,
        oldestMessageDatetimeByChannel: {
          ...state.oldestMessageDatetimeByChannel,
          [action.channel]: Math.min(
            oldestDatetimeInIncomingMessages,
            state.oldestMessageDatetimeByChannel[action.channel] ??
              Number.POSITIVE_INFINITY,
          ),
        },
      };
    }
    case "add_reaction": {
      const newChatHistory = new Map<string, ChatMessageData[]>(
        state.chatHistory,
      );
      const channelData = newChatHistory.get(action.channel);
      if (!channelData) {
        return state;
      }
      newChatHistory.set(
        action.channel,
        channelData.map((message) => {
          if (message.messageTimetoken !== action.messageTimetoken) {
            return message;
          }
          return {
            ...message,
            reaction: {
              actionTimetoken: action.actionTimetoken,
              emoji: action.reactionEmoji,
            },
          };
        }),
      );
      return {
        ...state,
        chatHistory: newChatHistory,
      };
    }
    case "set_last_requested_timestamp_by_channel": {
      return {
        ...state,
        lastRequestedTimestampByChannel: {
          ...state.lastRequestedTimestampByChannel,
          [action.channel]: action.timestamp,
        },
      };
    }
  }
}

export default chatHistoryReducer;
