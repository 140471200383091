import { TableOfContentsEntry } from "components/Blogging/utils/prepareTableOfContents";
import { useCallback, useEffect, useState } from "react";

const getSectionHeadingPositions = (data: TableOfContentsEntry[]) => {
  const sectionHeadings = data.map((entry) =>
    document.getElementById(entry.sectionHeadingId),
  );
  const filteredSectionHeadings = sectionHeadings.filter(
    (el) => el !== null,
  ) as HTMLElement[];
  return filteredSectionHeadings.map((el) => el.offsetTop);
};

const useTableOfContentsHighlighting = (
  data: TableOfContentsEntry[],
  isPhonePortrait: boolean,
  articleBodyRef: React.RefObject<HTMLDivElement>,
  headerHeight,
) => {
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [shouldIgnoreNextScroll, setShouldIgnoreNextScroll] = useState(false);

  const [sectionHeadingPositions, setSectionHeadingPositions] = useState<
    number[]
  >([]);

  const [isHidden, setIsHidden] = useState(isPhonePortrait);

  useEffect(() => {
    setSectionHeadingPositions(getSectionHeadingPositions(data));
  }, [data]);

  const updateHighlightedIndex = useCallback(() => {
    const scrollPosition = window.scrollY;

    const isScrolledToTheBottom =
      scrollPosition + window.innerHeight >= document.body.scrollHeight;

    if (isScrolledToTheBottom) {
      setHighlightedIndex(data.length - 1);
      return;
    }

    if (shouldIgnoreNextScroll) {
      setShouldIgnoreNextScroll(false);
      return;
    }

    const headingPostionLocations = sectionHeadingPositions.map((position) => ({
      belowTopEdge: position >= scrollPosition + headerHeight,
      aboveBottomEdge: position <= scrollPosition + window.innerHeight,
    }));

    const areAllHeadingsBelowBottomEdge = headingPostionLocations.every(
      (el) => !el.aboveBottomEdge,
    );
    if (areAllHeadingsBelowBottomEdge) {
      setHighlightedIndex(-1);
      return;
    }

    const firstVisibleHeadingIndex = headingPostionLocations.findIndex(
      (el) => el.belowTopEdge && el.aboveBottomEdge,
    );
    if (firstVisibleHeadingIndex !== -1) {
      setHighlightedIndex(firstVisibleHeadingIndex);
      return;
    }

    const firstHeadingBelowTopEdgeIndex = headingPostionLocations.findIndex(
      (el) => el.belowTopEdge,
    );
    if (firstHeadingBelowTopEdgeIndex === -1) {
      setHighlightedIndex(data.length - 1);
    } else if (firstHeadingBelowTopEdgeIndex === 0) {
      setHighlightedIndex(0);
    } else {
      setHighlightedIndex(firstHeadingBelowTopEdgeIndex - 1);
    }
  }, [
    data.length,
    headerHeight,
    shouldIgnoreNextScroll,
    sectionHeadingPositions,
  ]);

  const updateIsHidden = useCallback(() => {
    if (!isPhonePortrait || !articleBodyRef.current) {
      setIsHidden(false);
      return;
    }

    const scrollPosition = window.scrollY;
    setIsHidden(
      scrollPosition < articleBodyRef.current.offsetTop - headerHeight,
    );
  }, [isPhonePortrait, articleBodyRef, headerHeight]);

  const onResize = useCallback(() => {
    setSectionHeadingPositions(getSectionHeadingPositions(data));
    updateHighlightedIndex();
    updateIsHidden();
  }, [updateHighlightedIndex, updateIsHidden, data]);

  const onScroll = useCallback(() => {
    updateHighlightedIndex();
    updateIsHidden();
  }, [updateHighlightedIndex, updateIsHidden]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, [onResize, onScroll]);

  const setIndexManually = useCallback(
    (newIndex: number) => {
      setHighlightedIndex(newIndex);
      setShouldIgnoreNextScroll(true);
    },
    [setHighlightedIndex, setShouldIgnoreNextScroll],
  );

  return {
    isHidden,
    highlightedIndex,
    setIndexManually,
  };
};

export default useTableOfContentsHighlighting;
