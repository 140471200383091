import { useState } from "react";
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs.js";

/**
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, onActive, idleTime = 1 }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const DEBUG = false;

  const handleIdle = () => {
    DEBUG && console.log("now idle");
    onIdle();
    setIdle(true);
  };

  const handleActive = () => {
    DEBUG && console.log("now active");
    onActive();
    setIdle(false);
  };

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    onAction: handleActive,
    debounce: 500,
  });

  return {
    isIdle,
  };
};

export default useIdleTimeout;
