import React, { useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

export default function FormControlWithMask(props) {
  const [field, setField] = useState();
  const [cursor, setCursor] = useState();
  const [value, setValue] = useState();

  const fieldRef = useRef();

  const mask_regexp =
    "^" +
    props.mask
      .replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& - whole string
      .replaceAll(props.maskChar, props.maskRegex) +
    "$";
  const allowedCursors = [props.mask.search(props.maskChar)].concat(
    props.mask
      .split("")
      .map((c, i) => (c === props.maskChar ? i + 1 : null))
      .filter((x) => x),
  );

  useEffect(() => setField(props.value), [props.value]);

  useEffect(() => {
    const moveCursorToFirstMaskChar = () => {
      const stops = [
        ...new Set(
          props.mask.match(
            RegExp(
              props.maskChar + "[^" + props.maskChar + "]+" + props.maskChar,
              "g",
            ),
          ),
        ),
      ].map((s) => s.substring(1).replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
      const regex = RegExp(props.maskChar + "|" + stops.join("|"));
      const index = field.search(regex);
      if (index > 0) {
        fieldRef.current.selectionStart = index;
        fieldRef.current.selectionEnd = index;
      }
      return index;
    };
    if (cursor && field && value) {
      const allowed_cursor =
        value.length >= field.length
          ? allowedCursors.find((x) => x >= cursor) ??
            props.mask.lastIndexOf(props.maskChar) + 1
          : allowedCursors.reverse().find((x) => x <= cursor) ??
            props.mask.indexOf(props.maskChar);
      if (
        moveCursorToFirstMaskChar() >= allowed_cursor ||
        moveCursorToFirstMaskChar() < 0
      ) {
        fieldRef.current.selectionStart = allowed_cursor;
        fieldRef.current.selectionEnd = allowed_cursor;
      }
    } else if (field) {
      moveCursorToFirstMaskChar();
    }
  }, [field, cursor, props.mask, props.maskChar, allowedCursors, value]);

  const onChangeValue = (event, value) => {
    props.onChange({
      currentTarget: { ...event.currentTarget, value, name: props.name },
      target: { ...event.target, value, name: props.name },
    });
  };

  const onFieldChange = (event) => {
    const value = event.currentTarget.value;
    setValue(event.currentTarget.value);
    const value_typed = value
      .substring(props.mask.search(props.maskChar))
      .match(RegExp(props.maskRegex, "g"));
    let value_masked = props.mask;
    if (value_typed) {
      for (let c of value_typed) {
        value_masked = value_masked.replace(props.maskChar, c);
      }
    }
    const point_temp =
      field !== value_masked
        ? Math.max(
            fieldRef.current.selectionStart,
            fieldRef.current.selectionEnd,
          )
        : cursor;
    setField(value_masked);
    onChangeValue(event, value_masked);
    setCursor(point_temp);
  };

  const onFieldFocus = (event) => {
    if (!(field || props.value)) {
      const value_masked = props.mask;
      setField(value_masked);
      onChangeValue(event, value_masked);
    }
    props.onFocus && props.onFocus();
  };

  const onFieldBlur = (event) => {
    if (field && field === props.mask) {
      setField(null);
      onChangeValue(event, null);
    }
    setCursor(null);
  };

  return (
    <Form.Control
      required={props.required}
      ref={fieldRef}
      name={props.name}
      placeholder={props.placeholder || props.mask}
      pattern={mask_regexp}
      value={field || ""}
      onChange={onFieldChange}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
      className={getClasses(props.highlighted && styles["highlighted"])}
    />
  );
}
