import React, { useEffect } from "react";
import { STATE_OPTIONS } from "constants/stateOptions";
import { PROVINCE_OPTIONS } from "constants/provinceOptions";
import { Form, Col } from "react-bootstrap";
import CustomSelect from "components/custom-select";

const RequiredPanel = ({ member, registration, fieldChangedCallback }) => {
  const onInputChange = (event) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    fieldChangedCallback(name, value);
  };

  useEffect(() => {
    if (!member.country) {
      fieldChangedCallback("country", "US");
    }
  }, [member.country, fieldChangedCallback]);

  return (
    <>
      <Form.Group>
        <Form.Label className="rg-12 gray01 mb-1">
          Legal First Name <span className="required">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter first name"
          name="first_name"
          aria-describedby="userNameHelp"
          value={member.first_name || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="rg-12 gray01 mb-1">
          Legal Last Name <span className="required">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter last name"
          name="last_name"
          aria-describedby="userNameHelp"
          value={member.last_name || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      {registration && (
        <Form.Group>
          <Form.Label className="rg-12 gray01 mb-1">
            Date of Birth <span className="required">*</span>
          </Form.Label>
          <Form.Control
            required
            type="date"
            max={
              new Date(new Date().setFullYear(new Date().getFullYear() - 13))
                .toISOString()
                .split("T")[0]
            }
            pattern="^(0[1-9]|1[012])/(0[1-9]|[12][0-9]|3[01])/(19|20)\d\d$"
            placeholder="mm/dd/yyyy"
            name="dob"
            value={member.dob || ""}
            onChange={onInputChange}
          />
        </Form.Group>
      )}
      <Form.Group>
        <Form.Label className="rg-12 gray01 mb-1">
          Street Address <span className="required">*</span>
        </Form.Label>
        <Form.Control
          required
          name="home_address_line1"
          value={member.home_address_line1 || ""}
          onChange={onInputChange}
          placeholder={"Enter Address"}
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label className="rg-12 gray01 mb-1">
            Country <span className="required">*</span>
          </Form.Label>
          <CustomSelect
            options={[
              { value: "US", label: "United States" },
              { value: "CA", label: "Canada" },
            ]}
            onChange={(el) => fieldChangedCallback("country", el.value)}
            required
            value={[
              { value: "US", label: "United States" },
              { value: "CA", label: "Canada" },
            ].find((option) => option.value === member.country)}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label className="rg-12 gray01 mb-1">
            {member.country === "CA" ? "Province" : "State"}{" "}
            <span className="required">*</span>
          </Form.Label>
          <CustomSelect
            options={member.country === "CA" ? PROVINCE_OPTIONS : STATE_OPTIONS}
            onChange={(el) => fieldChangedCallback("home_state", el.value)}
            required
            value={(member.country === "CA"
              ? PROVINCE_OPTIONS
              : STATE_OPTIONS
            ).find((option) => option.value === member.home_state)}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label className="rg-12 gray01 mb-1">
            City <span className="required">*</span>
          </Form.Label>
          <Form.Control
            required
            name="home_city"
            onChange={onInputChange}
            value={member.home_city || ""}
            placeholder={"Enter City"}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label className="rg-12 gray01 mb-1">
            {member.country === "CA" ? "Postal Code" : "Zip"}{" "}
            <span className="required">*</span>
          </Form.Label>
          <Form.Control
            required
            name="home_zipcode"
            value={member.home_zipcode || ""}
            onChange={onInputChange}
            placeholder={
              member.country === "CA" ? "Enter Postal Code" : "Enter Zipcode"
            }
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default RequiredPanel;
