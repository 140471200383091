import { useOrchidPubNubState } from "contexts/pubnub/OrchidPubNubStateContext";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";
import totalNumberOfNotifications from "contexts/pubnub/utils/total-number-of-notifications";
type Props = {
  className?: string;
};
const MessageNotifications = ({ className }: Props) => {
  const { privateChats, pubNubUser } = useOrchidPubNubState();
  const notifications =
    pubNubUser !== null
      ? totalNumberOfNotifications(privateChats, pubNubUser.cognitoSub)
      : 0;

  const favicon: any = document.getElementById("favicon");
  if (favicon) {
    if (notifications > 0) {
      favicon.href = "/favicon_sticky.ico";
    } else {
      favicon.href = "/favicon.ico";
    }
  }

  return notifications === 0 ? null : (
    <span className={getClasses(styles["message-notifications"], className)}>
      {notifications}
    </span>
  );
};

export default MessageNotifications;
