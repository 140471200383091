import moment from "moment-timezone";
import createNewPatient from "services/patient-chart/createNewPatient";
import querySchemas from "services/querySchemas";
import attachNoteToAppointment from "services/scheduler/attachNoteToAppointment";
import postAppointmentSeries from "services/scheduler/postAppointmentSeries";

const prepareCreateDemoSessionPayload = ({
  patientId,
  timezone,
  daysFromNow,
  hour,
  sessionTypeLabel,
  sessionTypeLengthMin,
}: {
  patientId: string;
  timezone: string;
  daysFromNow: number;
  hour: number;
  sessionTypeLabel: string;
  sessionTypeLengthMin: number;
}): InviteSeriesInput => {
  const appointmentStart = moment()
    .tz(timezone)
    .add(daysFromNow, "day")
    .startOf("day")
    .add(hour, "hours")
    .toISOString();

  const appointmentEnd = moment(appointmentStart)
    .add(sessionTypeLengthMin, "minute")
    .toISOString();

  return {
    appointment_type_label: sessionTypeLabel,
    force_book_over_blocked: true,
    patient_user_id: patientId,
    appointments: [
      {
        appointment_start: appointmentStart,
        appointment_end: appointmentEnd,
      },
    ],
    invoicing: {
      automation: "manual",
    },
    skip_email: true,
  };
};

const createDemoPatient = async (timezone: string) => {
  const patientResponse = await createNewPatient({
    first_name: "Jane",
    preferred_first_name: "Jane",
    last_name: "Orchid",
    dob: "1988-10-23",
  });
  const patientId = patientResponse?.patient?.patient_user_id;

  if (!patientId) {
    throw new Error("Failed to create patient");
  }

  const [
    freeIntroSessionResponse,
    regularSessionYesterdayResponse,
    regularSessionTomorrowResponse,
  ] = await Promise.all([
    postAppointmentSeries(
      prepareCreateDemoSessionPayload({
        patientId,
        timezone,
        daysFromNow: -2,
        hour: 9,
        sessionTypeLabel: "Free introduction",
        sessionTypeLengthMin: 25,
      }),
    ),
    postAppointmentSeries(
      prepareCreateDemoSessionPayload({
        patientId,
        timezone,
        daysFromNow: -1,
        hour: 11,
        sessionTypeLabel: "Regular session",
        sessionTypeLengthMin: 25,
      }),
    ),
    postAppointmentSeries(
      prepareCreateDemoSessionPayload({
        patientId,
        timezone,
        daysFromNow: 1,
        hour: 11,
        sessionTypeLabel: "Regular session",
        sessionTypeLengthMin: 25,
      }),
    ),
  ]);
  const freeIntroSession = freeIntroSessionResponse.invites_created[0];
  const regularSessionYesterday =
    regularSessionYesterdayResponse.invites_created[0];
  const regularSessionTomorrow =
    regularSessionTomorrowResponse.invites_created[0];

  if (
    !freeIntroSession ||
    !regularSessionYesterday ||
    !regularSessionTomorrow
  ) {
    throw new Error("Failed to create all sessions");
  }

  const noteSchemas = await querySchemas("note");
  const soapNoteSchema = noteSchemas.find(
    (schema) => schema.title === "SOAP Note",
  );

  if (!soapNoteSchema) {
    throw new Error("SOAP Note schema not found");
  }

  await Promise.all([
    attachNoteToAppointment({
      appointmentId: freeIntroSession.appointment_id,
      patientId,
      noteSchema: soapNoteSchema,
    }),
    attachNoteToAppointment({
      appointmentId: regularSessionYesterday.appointment_id,
      patientId,
      noteSchema: soapNoteSchema,
    }),
  ]);
};

export default createDemoPatient;
