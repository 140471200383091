import { ChatMessageData } from "../pubnub.types";

const getLatestDate = (dates: Date[]): Date | null => {
  const validTimestamps = dates
    .map((d) => d.getTime())
    .filter((d) => !!d) as number[];
  if (validTimestamps.length > 0) {
    return new Date(Math.max(...validTimestamps));
  } else {
    return null;
  }
};

const getLastReceiptTimestamp = (
  messages: ChatMessageData[],
  cognitoSub: string,
): Date | null => {
  const receipts: Date[] = [];
  for (const message of messages) {
    for (const [receiptCognitoSub, receiptTimestamp] of Object.entries(
      message.receiptsByCognitoSub,
    )) {
      if (receiptCognitoSub === cognitoSub) {
        receipts.push(receiptTimestamp);
      }
    }
  }
  return getLatestDate(receipts);
};

export default getLastReceiptTimestamp;
