import { useEffect, useState } from "react";
import IconButton from "components/icon-button";
import { ReactComponent as OutlineInfoCircle } from "assets/svg/outline-info-circle.svg";
import { ReactComponent as CloseButton } from "assets/svg/outline-cross.svg";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

type Props = {
  show: boolean;
  onHide?: () => void;
  /**
   * duration for the saved tag to show
   * @default 2000
   */
  duration?: number | null;
  errorMsg?: string;
  className?: string;
  top?: number;
};
const ErrorMessage = ({
  show,
  onHide,
  duration,
  errorMsg,
  className,
}: Props) => {
  const [localShow, setLocalShow] = useState(show);
  useEffect(() => setLocalShow(show), [show]);

  useEffect(() => {
    if (!localShow) {
      return;
    }
    if (!duration) {
      return;
    }
    const timer = setTimeout(() => {
      setLocalShow(false);
      onHide && onHide();
    }, duration ?? 2000);

    return () => timer && clearTimeout(timer);
  }, [localShow, onHide, duration]);

  return localShow ? (
    <div className={getClasses(className, styles["error-message"])}>
      <div className={styles["error-message__content"]}>
        <div className={styles["error-message__content-left"]}>
          <OutlineInfoCircle className="danger" />
        </div>
        <span className={styles["error-message__content-right"]}>
          {errorMsg}
        </span>
      </div>
      <IconButton
        onClick={() => {
          setLocalShow(false);
          onHide && onHide();
        }}
      >
        <CloseButton className={"ml-3"} />
      </IconButton>
    </div>
  ) : null;
};

export default ErrorMessage;
