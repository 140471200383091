import { TableOfContentsEntry } from "components/Blogging/utils/prepareTableOfContents";
import styles from "./TableOfContents.module.scss";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import getClasses from "utils/getClasses";
import useTableOfContentsHighlighting from "./useTableOfContentsHighlighting";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { AmplitudePageName, track } from "utils/amplitude";

const HEADER_HEIGHT = 60;
const BASE_SCROLL_ADJUSTMENT = 86;
const AMPLITUDE_COMPONENT_NAME = "blog-table-of-contents";

interface TableOfContentsProps {
  data: TableOfContentsEntry[];
  articleBodyRef: React.RefObject<HTMLDivElement>;
  amplitudePageName: AmplitudePageName;
  ignoreHeader?: boolean;
}

const TableOfContents = ({
  data,
  articleBodyRef,
  amplitudePageName,
  ignoreHeader = false,
}: TableOfContentsProps) => {
  const { isPhonePortrait } = usePhonePortraitContext();

  const { highlightedIndex, setIndexManually, isHidden } =
    useTableOfContentsHighlighting(
      data,
      isPhonePortrait,
      articleBodyRef,
      ignoreHeader ? 0 : HEADER_HEIGHT,
    );

  const [isExpanded, setIsExpanded] = useState(false);

  const currentSectionTitle = useMemo(
    () => data[highlightedIndex]?.title ?? "",
    [highlightedIndex, data],
  );

  const onHeaderClick = useCallback(() => {
    if (isPhonePortrait) {
      setIsExpanded((prev) => !prev);
    }
  }, [isPhonePortrait]);

  const onListContainerClick = useCallback(() => {
    if (isPhonePortrait) {
      setIsExpanded((prev) => !prev);
    }
  }, [isPhonePortrait]);

  const onListItemClick = useCallback(
    (event: SyntheticEvent, entry: TableOfContentsEntry, index: number) => {
      const sectionHeading = document.getElementById(entry.sectionHeadingId);
      if (sectionHeading) {
        // The animated sticky header covers the section heading, so we need to scroll manually
        event.preventDefault();
        const isHeaderSticky = window.scrollY > HEADER_HEIGHT;
        const scrollAdjustment =
          HEADER_HEIGHT * (isHeaderSticky ? -1 : -2) - BASE_SCROLL_ADJUSTMENT;
        window.scrollTo(0, sectionHeading.offsetTop + scrollAdjustment);
      }
      setIndexManually(index);
    },
    [setIndexManually],
  );

  const componentClassName = useMemo(() => {
    const classNames = [styles["table-of-contents"]];
    if (isPhonePortrait) {
      classNames.push(styles["table-of-contents--phone"]);
      classNames.push(
        isExpanded
          ? styles["table-of-contents--phone--expanded"]
          : styles["table-of-contents--phone--collapsed"],
      );
      if (isHidden) {
        classNames.push(styles["table-of-contents--phone--hidden"]);
      }
      if (ignoreHeader) {
        classNames.push(styles["table-of-contents--phone--ignore-header"]);
      }
    } else {
      classNames.push(styles["table-of-contents--desktop"]);
    }
    return classNames.join(" ");
  }, [isExpanded, isPhonePortrait, isHidden, ignoreHeader]);

  const shouldShowMobileHeader = isPhonePortrait;
  const shouldShowList = !isPhonePortrait || isExpanded;

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={componentClassName}>
      {shouldShowMobileHeader && (
        <div className={styles["mobile-header"]} onClick={onHeaderClick}>
          <span className={styles["mobile-header__label"]}>Overview</span>
          <span className={styles["mobile-header__current-section"]}>
            {currentSectionTitle}
          </span>
        </div>
      )}
      {shouldShowList && (
        <div
          className={styles["list-container"]}
          onClick={onListContainerClick}
        >
          <nav className={styles["list-container__content"]}>
            <div className={styles["title"]}>Overview</div>
            <ul>
              {data.map((entry, i) => (
                <li
                  key={entry.sectionHeadingId}
                  className={getClasses(
                    i === highlightedIndex && styles["highlighted"],
                  )}
                >
                  <a
                    href={`#${entry.sectionHeadingId}`}
                    onClick={(event) => {
                      track(
                        amplitudePageName,
                        [AMPLITUDE_COMPONENT_NAME, "section"],
                        "click",
                        {
                          eventProperties: {
                            "section-title": entry.title,
                          },
                        },
                      );
                      onListItemClick(event, entry, i);
                    }}
                  >
                    {entry.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default TableOfContents;
