import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Get Segment write key
 */
export default async function querySegmentWriteKey() {
  const res = await fetchOrchidAPI(`/api/users/v1/segment/write_key`);
  if (!res.ok) {
    throw new Error(
      `failed to get Segment write key: ${getErrorDetail(await res.text())}`,
    );
  }
  const apiKey = await res.json();
  return apiKey.write_key;
}
