export const ORCHID_PRO_TYPE_OPTIONS = [
  { value: "physician", label: "Physician" },
  { value: "psychologist", label: "Psychologist" },
  { value: "social worker", label: "Social Worker" },
  { value: "therapist", label: "Therapist" },
  // { value: "other", label: "Other" },
];

export const PHYSICIAN_PRIMARY_SPECIALTY = [
  { value: "psychiatry", label: "Psychiatry" },
  { value: "neurology", label: "Neurology" },
  {
    value: "physical medicine and rehabilitation",
    label: "Physical Medicine and Rehabilitation",
  },
];

export const PAIN_MED_SUBSPECIALTY_OPTIONS = [
  { value: "cancer rehabilitation", label: "Cancer Rehabilitation" },
  { value: "pain management", label: "Pain Management" },
  { value: "traumatic brain injury", label: "Traumatic Brain Injury" },
  { value: "spinal cord injury", label: "Spinal Cord Injury" },
  { value: "sports medicine", label: "Sports Medicine" },
  { value: "pediatrics", label: "Pediatrics" },
  {
    value: "hospice & palliative medicine",
    label: "Hospice & Palliative Medicine",
  },
];

export const NEUROLOGY_SUBSPECIALTY_OPTIONS = [
  { value: "behavioral neurology", label: "Behavioral Neurology" },
  { value: "clinical neurophysiology", label: "Clinical Neurophysiology" },
  { value: "geriatric neurology", label: "Geriatric Neurology" },
  { value: "headache medicine", label: "Headache Medicine" },
  { value: "neuromuscular medicine", label: "Neuromuscular Medicine" },
  {
    value: "neurodevelopmental disabilities",
    label: "Neurodevelopmental Disabilities",
  },
  { value: "neuro-oncology", label: "Neuro-oncology" },
  { value: "neuroradiology", label: "Neuroradiology" },
  { value: "vascular neurology", label: "Vascular Neurology" },
  {
    value: "hospice and palliative medicine",
    label: "Hospice and Palliative Medicine",
  },
  { value: "pain medicine", label: "Pain Medicine" },
  { value: "sleep medicine", label: "Sleep Medicine" },
];

export const PSYCHIATRY_SUBSPECIALTY_OPTIONS = [
  { value: "addiction psychiatry", label: "Addiction Psychiatry" },
  // { value: "Adult Psychiatry", label: "Adult Psychiatry" },
  // { value: "Brain Injury", label: "Brain Injury" },
  {
    value: "child & adolescent psychiatry",
    label: "Child and Adolescent Psychiatry",
  },
  // { value: "Clinical Neurophysiology", label: "Clinical Neurophysiology" },
  {
    value: "consultation-liaison psychiatry",
    label: "Consultation-Liaison Psychiatry",
  },
  { value: "forensic psychiatry", label: "Forensic Psychiatry" },
  { value: "geriatric psychiatry", label: "Geriatric Psychiatry" },
  { value: "neuropsychiatry", label: "Neuropsychiatry" },
  {
    value: "hospice and palliative medicine",
    label: "Hospice and Palliative Medicine",
  },
  { value: "pain medicine", label: "Pain Medicine" },
  { value: "sleep medicine", label: "Sleep Medicine" },
  { value: "other", label: "Other" },
];

export const PSYCHOLOGIST_PRIMARY_SPECIALTY = [
  { value: "clinical neuropsychology", label: "Clinical Neuropsychology" },
  { value: "clinical health psychology", label: "Clinical Health Psychology" },
  { value: "psychoanalysis", label: "Psychoanalysis" },
  { value: "school psychology", label: "School Psychology" },
  { value: "clinical psychology", label: "Clinical Psychology" },
  {
    value: "clinical child and adolescent psychology",
    label: "Clinical Child and Adolescent Psychology",
  },
  { value: "counseling psychology", label: "Counseling Psychology" },
  {
    value: "industrial-organizational psychology",
    label: "Industrial-Organizational Psychology",
  },
  {
    value: "behavioral and cognitive psychology",
    label: "Behavioral and Cognitive Psychology",
  },
  { value: "forensic psychology", label: "Forensic Psychology" },
  {
    value: "couple and family psychology",
    label: "Couple and Family Psychology",
  },
  { value: "geropsychology", label: "Geropsychology" },
  {
    value: "police and public safety psychology",
    label: "Police and Public Safety Psychology",
  },
  { value: "sleep psychology", label: "Sleep Psychology" },
  { value: "rehabilitation psychology", label: "Rehabilitation Psychology" },
  {
    value: "group psychology and group psychotherapy",
    label: "Group Psychology and Group Psychotherapy",
  },
  {
    value: "serious mental illness psychology",
    label: "Serious Mental Illness Psychology",
  },
  {
    value: "clinical psychopharmacology",
    label: "Clinical Psychopharmacology",
  },
];

export const PSYCHOLOGIST_SUBSPECIALTY = [
  { value: "addiction psychology", label: "Addiction Psychology" },
  { value: "sport psychology", label: "Sport Psychology" },
  {
    value: "biofeedback and applied psychophysiology",
    label: "Biofeedback and Applied Psychophysiology",
  },
];

export const NON_PHYSICIAN_DEGREE_OPTIONS = [
  { value: "BSW", label: "BSW" },
  { value: "DDiv", label: "DDiv" },
  { value: "DMin", label: "DMin" },
  { value: "DrPH", label: "DrPH" },
  { value: "DSW", label: "DSW" },
  { value: "EdD", label: "EdD" },
  { value: "EdM", label: "EdM" },
  { value: "MA", label: "MA" },
  { value: "MAHS", label: "MAHS" },
  { value: "MAOP", label: "MAOP" },
  { value: "MC", label: "MC" },
  { value: "MDiv", label: "MDiv" },
  { value: "MEd", label: "MEd" },
  { value: "MHDL", label: "MHDL" },
  { value: "MPH", label: "MPH" },
  { value: "MPS", label: "MPS" },
  { value: "MS", label: "MS" },
  { value: "MSc", label: "MSc" },
  { value: "MSEd", label: "MSEd" },
  { value: "MSN", label: "MSN" },
  { value: "MSS", label: "MSS" },
  { value: "MSSW", label: "MSSW" },
  { value: "MSW", label: "MSW" },
  { value: "PhD", label: "PhD" },
  { value: "PsyaD", label: "PsyaD" },
  { value: "PsyD", label: "PsyD" },
  { value: "ScD", label: "ScD" },
  { value: "MFT", label: "MFT" },

  // TODO: check if medical degree option is needed
  { value: "DO", label: "DO" },
  { value: "MD", label: "MD" },
];

export const PHYSICIAN_DEGREE_OPTIONS = [
  { value: "DO", label: "DO" },
  { value: "MD", label: "MD" },
];

export const NON_PHYSICIAN_CERTIFICATION_OPTIONS = [
  { value: "ABECSW", label: "ABECSW" },
  { value: "ABFamP", label: "ABFamP" },
  { value: "ABPN", label: "ABPN" },
  { value: "ABPP", label: "ABPP" },
  { value: "ACSW", label: "ACSW" },
  { value: "ADTR", label: "ADTR" },
  { value: "AMFT", label: "AMFT" },
  { value: "APRN", label: "APRN" },
  { value: "ATR", label: "ATR" },
  { value: "ATR-BC", label: "ATR-BC" },
  { value: "CAADC", label: "CAADC" }, // Addiction Certified Advanced Alcohol and Drug Counselor
  { value: "CAC", label: "CAC" },
  { value: "CADAC", label: "CADAC" },
  { value: "CADC", label: "CADC" },
  { value: "CAGS", label: "CAGS" },
  { value: "CAMF", label: "CAMF" },
  { value: "CAP", label: "CAP" },
  { value: "CART", label: "CART" },
  { value: "CAS", label: "CAS" },
  { value: "CASAC", label: "CASAC" },
  { value: "CBT", label: "CBT" },
  { value: "CCADC", label: "CCADC" },
  { value: "CCDP", label: "CCDP" },
  { value: "CCH", label: "CCH" },
  { value: "CCHT", label: "CCHT" },
  { value: "CCMH", label: "CCMH" },
  { value: "CCMHC", label: "CCMHC" },
  { value: "CCPT", label: "CCPT" },
  { value: "CCSW", label: "CCSW" },
  { value: "CDCES", label: "CDCES" },
  { value: "CEAP", label: "CEAP" },
  { value: "CEDS", label: "CEDS" },
  { value: "CFLE", label: "CFLE" },
  { value: "CGP", label: "CGP" },
  { value: "CHT", label: "CHT" },
  { value: "CICSW", label: "CICSW" },
  { value: "CISW", label: "CISW" },
  { value: "CMFT", label: "CMFT" },
  { value: "CP", label: "CP" },
  { value: "CPC", label: "CPC" },
  { value: "CPLC", label: "CPLC" },
  { value: "CRADC", label: "CRADC" },
  { value: "CRC", label: "CRC" },
  { value: "CRNP", label: "CRNP" },
  { value: "CSAC", label: "CSAC" },
  { value: "CSW", label: "CSW" },
  { value: "CSW-C", label: "CSW-C" },
  { value: "CpastC", label: "CpastC" },
  { value: "DAPA", label: "DAPA" },
  { value: "DCSW", label: "DCSW" },
  { value: "DOT SAP", label: "DOT SAP" },
  { value: "DTR", label: "DTR" },
  { value: "LAC", label: "LAC" },
  { value: "LADAC", label: "LADAC" },
  { value: "LADC", label: "LADC" },
  { value: "LAMFT", label: "LAMFT" },
  { value: "LAPC", label: "LAPC" },
  { value: "LASAC", label: "LASAC" },
  { value: "LCADC", label: "LCADC" },
  { value: "LCAS", label: "LCAS" },
  { value: "LCAT", label: "LCAT" },
  { value: "LCDC", label: "LCDC" },
  { value: "LCDP", label: "LCDP" },
  { value: "LCMFT", label: "LCMFT" },
  { value: "LCMHC", label: "LCMHC" },
  { value: "LCP", label: "LCP" },
  { value: "LCPC", label: "LCPC" },
  { value: "LCSW", label: "LCSW" },
  { value: "LCSW-C", label: "LCSW-C" },
  { value: "LCSW-R", label: "LCSW-R" },
  { value: "LGSW", label: "LGSW" },
  { value: "LICSW", label: "LICSW" },
  { value: "LIMFT", label: "LIMFT" },
  { value: "LISW", label: "LISW" },
  { value: "LISW-CP", label: "LISW-CP" },
  { value: "LLP", label: "LLP" },
  { value: "LMFT", label: "LMFT" },
  { value: "LMHC", label: "LMHC" },
  { value: "LMHP", label: "LMHP" },
  { value: "LMSW", label: "LMSW" },
  { value: "LMSW-ACP", label: "LMSW-ACP" },
  { value: "LP", label: "LP" },
  { value: "LPA", label: "LPA" },
  { value: "LPC", label: "LPC" },
  { value: "LPCC", label: "LPCC" },
  { value: "LPCMH", label: "LPCMH" },
  { value: "LPE", label: "LPE" },
  { value: "LPP", label: "LPP" },
  { value: "LSATP", label: "LSATP" },
  { value: "LSCSW", label: "LSCSW" },
  { value: "LSP", label: "LSP" },
  { value: "LSW", label: "LSW" },
  { value: "LpastC", label: "LpastC" },
  { value: "MAC", label: "MAC" },
  { value: "MFCC", label: "MFCC" },
  { value: "MFT", label: "MFT" },
  { value: "MT-BC", label: "MT-BC" },
  { value: "NBCCH", label: "NBCCH" },
  { value: "NBCDCH", label: "NBCDCH" },
  { value: "NCC", label: "NCC" },
  { value: "NCPsyA", label: "NCPsyA" },
  { value: "NCSC", label: "NCSC" },
  { value: "NCSP", label: "NCSP" },
  { value: "PA", label: "PA" },
  { value: "PLMHP", label: "PLMHP" },
  { value: "PLPC", label: "PLPC" },
  { value: "PMHNP", label: "PMHNP" },
  { value: "RAS", label: "RAS" },
  { value: "RDN", label: "RDN" },
  { value: "RDT", label: "RDT" },
  { value: "REAT", label: "REAT" },
  { value: "RN", label: "RN" },
  { value: "RPC", label: "RPC" },
  { value: "RPT", label: "RPT" },
  { value: "RPT-S", label: "RPT-S" },
  { value: "SAP", label: "SAP" },
  { value: "SW", label: "SW" },
  { value: "TLLP", label: "TLLP" },
  { value: "Other", label: "Other" },
];

export const LANGUAGE_SPOKEN_OPTIONS = [
  { label: "English", value: "English" },
  { label: "Mandarin Chinese", value: "Mandarin Chinese" },
  { label: "Hindi", value: "Hindi" },
  { label: "Spanish", value: "Spanish" },
  { label: "French", value: "French" },
  { label: "Standard Arabic", value: "Standard Arabic" },
  { label: "Bengali", value: "Bengali" },
  { label: "Russian", value: "Russian" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Urdu", value: "Urdu" },
  { label: "German", value: "German" },
  { label: "Japanese", value: "Japanese" },
  { label: "Swahili", value: "Swahili" },
  { label: "Marathi", value: "Marathi" },
  { label: "Telugu", value: "Telugu" },
  { label: "Turkish", value: "Turkish" },
  { label: "Tamil", value: "Tamil" },
  { label: "Western Punjabi", value: "Western Punjabi" },
  { label: "Korean", value: "Korean" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Hausa", value: "Hausa" },
  { label: "Javanese", value: "Javanese" },
  { label: "Egyptian Arabic", value: "Egyptian Arabic" },
  { label: "Italian", value: "Italian" },
  { label: "Thai", value: "Thai" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Kannada", value: "Kannada" },
  { label: "Persian", value: "Persian" },
  { label: "Bhojpuri", value: "Bhojpuri" },
  { label: "Southern Min", value: "Southern Min" },
  { label: "Filipino", value: "Filipino" },
  { label: "Cantonese", value: "Cantonese" },
];

export const ORG_ENTITY_TYPE_SOLO = [
  { value: "sole proprietor", label: "Sole Proprietor" },
  { value: "llc", label: "LLC" },
  { value: "pllc", label: "PLLC" },
  { value: "c-corporation", label: "C-Corporation" },
  { value: "s-corporation", label: "S-Corporation" },
];

export const ORG_ENTITY_TYPE_GROUP = [
  { value: "group practice", label: "Group Practice" },
  { value: "non-profit", label: "Non-Profit" },
  { value: "hospital", label: "Hospital" },
  { value: "other", label: "Other" },
];

export const PSYCHOLOGIST_LICENSE_TYPE = [
  { value: "Licensed Psychologist (LP)", label: "Licensed Psychologist (LP)" },
  {
    value: "Psychologist Courtesy License (PCL)",
    label: "Psychologist Courtesy License (PCL)",
  },
  {
    value: "Psychologist Temporary License (PTL)",
    label: "Psychologist Temporary License (PTL)",
  },
  {
    value: "Licensed Clinical Psychologist (LCP or LPC or LCPC)",
    label: "Licensed Clinical Psychologist (LCP or LPC or LCPC)",
  },
];

export const SOCIAL_WORKER_LICENSE_TYPE = [
  {
    value: "Licensed Independent Social Worker (LISW)",
    label: "Licensed Independent Social Worker (LISW)",
  },
  {
    value: "Licensed Independent Social Worker - Supervisor (LISW-S)",
    label: "Licensed Independent Social Worker - Supervisor (LISW-S)",
  },
  {
    value: "Licensed Master Social Worker (LMSW)",
    label: "Licensed Master Social Worker (LMSW)",
  },
  {
    value: "Licensed Independent Clinical Social Worker (LICSW)",
    label: "Licensed Independent Clinical Social Worker (LICSW)",
  },
  {
    value: "Licensed Clinical Social Worker (LCSW)",
    label: "Licensed Clinical Social Worker (LCSW)",
  },
  {
    value: "Licensed Social Worker (LSW)",
    label: "Licensed Social Worker (LSW)",
  },
];

export const THERAPIST_LICENSE_TYPE = [
  {
    value: "Licensed Professional Counselor (LPC)",
    label: "Licensed Professional Counselor (LPC)",
  },
  {
    value: "Licensed Mental Health Counselor (LMHC)",
    label: "Licensed Mental Health Counselor (LMHC)",
  },
  {
    value: "Licensed Clinical Professional Counselor (LCPC)",
    label: "Licensed Clinical Professional Counselor (LCPC)",
  },
  {
    value: "Licensed Professional Clinical Counselor of Mental Health (LPCC)",
    label: "Licensed Professional Clinical Counselor of Mental Health (LPCC)",
  },
  {
    value: "Licensed Professional Clinical Counselor of Mental Health (LPCC-S)",
    label: "Licensed Professional Clinical Counselor of Mental Health (LPCC-S)",
  },
  {
    value: "Licensed Clinical Mental Health Counselor (LCMHC)",
    label: "Licensed Clinical Mental Health Counselor (LCMHC)",
  },
  {
    value: "Licensed Mental Health Practitioner (LMHP)",
    label: "Licensed Mental Health Practitioner (LMHP)",
  },
  {
    value: "Licensed Marriage and Family Therapist (LMFT)",
    label: "Licensed Marriage and Family Therapist (LMFT)",
  },
  {
    value: "Licensed Clinical Marriage and Family Therapist (LCMFT)",
    label: "Licensed Clinical Marriage and Family Therapist (LCMFT)",
  },
  {
    value: "Licensed Independent Marriage and Family Therapist (LIMFT)",
    label: "Licensed Independent Marriage and Family Therapist (LIMFT)",
  },
  {
    value: "Licensed Marriage and Family Counselor (LMFC)",
    label: "Licensed Marriage and Family Counselor (LMFC)",
  },
  {
    value: "Certified Marriage and Family Therapist (CMFT)",
    label: "Certified Marriage and Family Therapist (CMFT)",
  },
  {
    value: "Independent Marriage and Family Therapist (IMFT)",
    label: "Independent Marriage and Family Therapist (IMFT)",
  },
  {
    value: "Independent Marriage and Family Therapist Supervisor (IMFT-S)",
    label: "Independent Marriage and Family Therapist Supervisor (IMFT-S)",
  },
  {
    value: "Registered Clinical Counselor (RCC)",
    label: "Registered Clinical Counselor (RCC)",
  },
  {
    value: "Licensed Clinical Social Worker (LCSW)",
    label: "Licensed Clinical Social Worker (LCSW)",
  },
];

export const PSYCHOLOGIST_LICENSE_TYPE_UNDER_SUPERVISION = [
  { value: "Licensed Psychologist (LP)", label: "Licensed Psychologist (LP)" },
  {
    value: "Licensed Psychological Associate (LPA)",
    label: "Licensed Psychological Associate (LPA)",
  },
  {
    value: "Psychological Associate Temporary License (PATL)",
    label: "Psychological Associate Temporary License (PATL)",
  },
  { value: "Licensed Psychologist (LP)", label: "Licensed Psychologist (LP)" },
  {
    value: "Psychologist Courtesy License (PCL)",
    label: "Psychologist Courtesy License (PCL)",
  },
  {
    value: "Psychologist Temporary License (PTL)",
    label: "Psychologist Temporary License (PTL)",
  },
  {
    value: "Licensed Clinical Psychologist (LCP)",
    label: "Licensed Clinical Psychologist (LCP)",
  },
];

export const SOCIAL_WORKER_LICENSE_TYPE_UNDER_SUPERVISION = [
  {
    value: "Licensed Social Worker (LSW)",
    label: "Licensed Social Worker (LSW)",
  },
  {
    value: "Licensed Master Social Worker (LMSW)",
    label: "Licensed Master Social Worker (LMSW)",
  },
  {
    value: "Social Work Assistant (SWA)",
    label: "Social Work Assistant (SWA)",
  },
  { value: "Social Work Trainee (SWT)", label: "Social Work Trainee (SWT)" },
  {
    value: "Licensed Bachelor Social Worker (LBSW)",
    label: "Licensed Bachelor Social Worker (LBSW)",
  },
  {
    value: "Licensed Bachelor Social Worker Temporary License (LBSW-TL)",
    label: "Licensed Bachelor Social Worker Temporary License (LBSW-TL)",
  },
  {
    value: "Licensed Graduate Social Worker (LGSW)",
    label: "Licensed Graduate Social Worker (LGSW)",
  },
  {
    value: "Associate Clinical Social Worker (ACSW)",
    label: "Associate Clinical Social Worker (ACSW)",
  },
  {
    value: "Provisional Social Worker (SWP)",
    label: "Provisional Social Worker (SWP)",
  },
  {
    value: "Clinical Social Worker Candidate (SWC)",
    label: "Clinical Social Worker Candidate (SWC)",
  },
  { value: "Other", label: "Other" },
];

export const THERAPIST_LICENSE_TYPE_UNDER_SUPERVISION = [
  {
    value: "Licensed Associate Counselor (LAC)",
    label: "Licensed Associate Counselor (LAC)",
  },
  {
    value: "Licensed Professional Counselor Associate (LPCA)",
    label: "Licensed Professional Counselor Associate (LPCA)",
  },
  {
    value: "Licensed Graduate Professional Counselor",
    label: "Licensed Graduate Professional Counselor",
  },
  { value: "Counselor-in-training", label: "Counselor-in-training" },
  { value: "Clinical Resident", label: "Clinical Resident" },
  {
    value: "Licensed Marital and Family Therapy Associate (LMFTA)",
    label: "Licensed Marital and Family Therapy Associate (LMFTA)",
  },
  {
    value: "Licensed Associate Marriage and Family Therapist (LAMFT)",
    label: "Licensed Associate Marriage and Family Therapist (LAMFT)",
  },
  {
    value: "Marriage and Family Therapist Intern",
    label: "Marriage and Family Therapist Intern",
  },
  {
    value: "Registered Marriage and Family Therapist Intern",
    label: "Registered Marriage and Family Therapist Intern",
  },
  {
    value: "Associate Marriage and Family Therapist",
    label: "Associate Marriage and Family Therapist",
  },
  {
    value: "Licensed Graduate Marriage and Family Therapist",
    label: "Licensed Graduate Marriage and Family Therapist",
  },
  {
    value: "Marriage and Family Therapist Temporary Permit",
    label: "Marriage and Family Therapist Temporary Permit",
  },
  {
    value: "Licensed Marriage and Family Therapist Intern (LMFT Intern)",
    label: "Licensed Marriage and Family Therapist Intern (LMFT Intern)",
  },
  { value: "Temporarily Licensed MFT", label: "Temporarily Licensed MFT" },
  {
    value: "Provisional Marriage and Family Therapist (PMFT)",
    label: "Provisional Marriage and Family Therapist (PMFT)",
  },
  { value: "MFT Trainee", label: "MFT Trainee" },
  {
    value: "Licensed Professional Counselor (LPC)",
    label: "Licensed Professional Counselor (LPC)",
  },
  {
    value: "Licensed Mental Health Counselor (LMHC)",
    label: "Licensed Mental Health Counselor (LMHC)",
  },
  {
    value: "Licensed Clinical Professional Counselor (LCPC)",
    label: "Licensed Clinical Professional Counselor (LCPC)",
  },
  {
    value: "Licensed Professional Clinical Counselor of Mental Health (LPCC)",
    label: "Licensed Professional Clinical Counselor of Mental Health (LPCC)",
  },
  {
    value: "Licensed Professional Clinical Counselor of Mental Health (LPCC-S)",
    label: "Licensed Professional Clinical Counselor of Mental Health (LPCC-S)",
  },
  {
    value: "Licensed Clinical Mental Health Counselor (LCMHC)",
    label: "Licensed Clinical Mental Health Counselor (LCMHC)",
  },
  {
    value: "Licensed Mental Health Practitioner (LMHP)",
    label: "Licensed Mental Health Practitioner (LMHP)",
  },
  {
    value: "Licensed Marriage and Family Therapist (LMFT)",
    label: "Licensed Marriage and Family Therapist (LMFT)",
  },
  {
    value: "Licensed Clinical Marriage and Family Therapist (LCMFT)",
    label: "Licensed Clinical Marriage and Family Therapist (LCMFT)",
  },
  {
    value: "Licensed Independent Marriage and Family Therapist (LIMFT)",
    label: "Licensed Independent Marriage and Family Therapist (LIMFT)",
  },
  {
    value: "Licensed Marriage and Family Counselor (LMFC)",
    label: "Licensed Marriage and Family Counselor (LMFC)",
  },
  {
    value: "Certified Marriage and Family Therapist (CMFT)",
    label: "Certified Marriage and Family Therapist (CMFT)",
  },
  {
    value: "Independent Marriage and Family Therapist (IMFT)",
    label: "Independent Marriage and Family Therapist (IMFT)",
  },
  {
    value: "Independent Marriage and Family Therapist Supervisor (IMFT-S)",
    label: "Independent Marriage and Family Therapist Supervisor (IMFT-S)",
  },
  {
    value: "Licensed Clinical Social Worker (LCSW)",
    label: "Licensed Clinical Social Worker (LCSW)",
  },
];

export const FOCUS_ISSUES = [
  { value: "Abandonment", label: "Abandonment" },
  {
    value: "Abortion, Pregnancy Loss and Miscarriage",
    label: "Abortion, Pregnancy Loss and Miscarriage",
  },
  { value: "Abuse", label: "Abuse" },
  { value: "ADHD", label: "ADHD" },
  { value: "Adjustment Disorder", label: "Adjustment Disorder" },
  { value: "Adolescent Issues", label: "Adolescent Issues" },
  { value: "Adoption Issues", label: "Adoption Issues" },
  { value: "Aging Concerns", label: "Aging Concerns" },
  {
    value: "Alcohol Abuse and Alcohol Dependence",
    label: "Alcohol Abuse and Alcohol Dependence",
  },
  { value: "Alzheimer's", label: "Alzheimer's" },
  { value: "Anger Issues", label: "Anger Issues" },
  { value: "Anxiety", label: "Anxiety" },
  { value: "Attachment", label: "Attachment" },
  { value: "Autism Spectrum Disorder", label: "Autism Spectrum Disorder" },
  { value: "Bipolar", label: "Bipolar" },
  { value: "Behavioral Issues", label: "Behavioral Issues" },
  { value: "Body Image Issues", label: "Body Image Issues" },
  {
    value: "Body-focused repetitive behavior (BFRB)",
    label: "Body-focused repetitive behavior (BFRB)",
  },
  { value: "Bullying", label: "Bullying" },
  { value: "Burnout", label: "Burnout" },
  { value: "Cancer", label: "Cancer" },
  { value: "Career Counseling", label: "Career Counseling" },
  { value: "Child Issues", label: "Child Issues" },
  { value: "Chronic Impulsivity", label: "Chronic Impulsivity" },
  { value: "Chronic Pain or Illness", label: "Chronic Pain or Illness" },
  { value: "Codependency", label: "Codependency" },
  { value: "College Mental Health", label: "College Mental Health" },
  { value: "Compassion Fatigue", label: "Compassion Fatigue" },
  { value: "Coparenting", label: "Coparenting" },
  { value: "Coping Skills", label: "Coping Skills" },
  {
    value: "Cultural and Systemic Oppression",
    label: "Cultural and Systemic Oppression",
  },
  { value: "Depression", label: "Depression" },
  { value: "Dissociative Disorders", label: "Dissociative Disorders" },
  { value: "Divorce", label: "Divorce" },
  {
    value: "Domestic Violence or Intimate Partner Violence",
    label: "Domestic Violence or Intimate Partner Violence",
  },
  {
    value: "Drug Dependence / Abuse / Addiction",
    label: "Drug Dependence / Abuse / Addiction",
  },
  { value: "Eating Disorders", label: "Eating Disorders" },
  { value: "Emotional Abuse", label: "Emotional Abuse" },
  { value: "Emotional Disturbance", label: "Emotional Disturbance" },
  { value: "Emotional Eating", label: "Emotional Eating" },
  { value: "Family Caregiving Stress", label: "Family Caregiving Stress" },
  { value: "Family Conflict", label: "Family Conflict" },
  { value: "Foster Care Issues", label: "Foster Care Issues" },
  { value: "Gambling Addiction", label: "Gambling Addiction" },
  { value: "Gender Identity", label: "Gender Identity" },
  { value: "Grief or Loss", label: "Grief or Loss" },
  { value: "Highly Sensitive Person", label: "Highly Sensitive Person" },
  {
    value: "Historical/ Intergenerational Trauma",
    label: "Historical/ Intergenerational Trauma",
  },
  { value: "Immigration/Acculturation", label: "Immigration/Acculturation" },
  { value: "Incarceration and Reentry", label: "Incarceration and Reentry" },
  { value: "Infertility", label: "Infertility" },
  { value: "Infidelity or Affairs", label: "Infidelity or Affairs" },
  { value: "Insomnia", label: "Insomnia" },
  { value: "Intellectual Disability", label: "Intellectual Disability" },
  {
    value: "International Students/Immigrants",
    label: "International Students/Immigrants",
  },
  { value: "Internet Addiction", label: "Internet Addiction" },
  { value: "Job Stress", label: "Job Stress" },
  { value: "Kink / Sexual Outsiders", label: "Kink / Sexual Outsiders" },
  { value: "Learning Disorders", label: "Learning Disorders" },
  { value: "LGBTQ Issues", label: "LGBTQ Issues" },
  { value: "Life Coaching", label: "Life Coaching" },
  { value: "Life Transitions", label: "Life Transitions" },
  { value: "Medication Management", label: "Medication Management" },
  { value: "Men's Issues", label: "Men's Issues" },
  {
    value: "Obsessive Compulsive Disorder (OCD)",
    label: "Obsessive Compulsive Disorder (OCD)",
  },
  {
    value: "Oppositional Defiant Disorder (ODD)",
    label: "Oppositional Defiant Disorder (ODD)",
  },
  { value: "Panic", label: "Panic" },
  { value: "Parenting", label: "Parenting" },
  { value: "Peer Relationships", label: "Peer Relationships" },
  { value: "Personal Growth", label: "Personal Growth" },
  { value: "Personality Disorders", label: "Personality Disorders" },
  { value: "Physical Disability", label: "Physical Disability" },
  { value: "Political Climate", label: "Political Climate" },
  {
    value: "Polyamorous and Open Relationships",
    label: "Polyamorous and Open Relationships",
  },
  {
    value: "Post Traumatic Stress Disorder (PTSD)",
    label: "Post Traumatic Stress Disorder (PTSD)",
  },
  { value: "Pregnancy and Postpartum", label: "Pregnancy and Postpartum" },
  {
    value: "Psychosis and Schizophrenia",
    label: "Psychosis and Schizophrenia",
  },
  { value: "Racial Identity", label: "Racial Identity" },
  {
    value: "Relationship / Marriage Issues",
    label: "Relationship / Marriage Issues",
  },
  { value: "School Issues", label: "School Issues" },
  { value: "Self-Esteem", label: "Self-Esteem" },
  { value: "Self-Harm", label: "Self-Harm" },
  { value: "Sexual Abuse or Assault", label: "Sexual Abuse or Assault" },
  { value: "Sexual Addiction", label: "Sexual Addiction" },
  { value: "Sexual Identity", label: "Sexual Identity" },
  { value: "Sexual Problems", label: "Sexual Problems" },
  { value: "Sleep or Insomnia", label: "Sleep or Insomnia" },
  { value: "Social Anxiety", label: "Social Anxiety" },
  { value: "Social Phobia", label: "Social Phobia" },
  {
    value: "Spiritual Abuse and Religious Trauma",
    label: "Spiritual Abuse and Religious Trauma",
  },
  { value: "Spirituality", label: "Spirituality" },
  { value: "Stress", label: "Stress" },
  { value: "Suicidal Thoughts", label: "Suicidal Thoughts" },
  {
    value: "Therapists Who Treat Other Therapists",
    label: "Therapists Who Treat Other Therapists",
  },
  { value: "Tourette Syndrome", label: "Tourette Syndrome" },
  { value: "Toxic Masculinity", label: "Toxic Masculinity" },
  { value: "Transgender", label: "Transgender" },
  {
    value: "Traumatic Brain Injury (TBI)",
    label: "Traumatic Brain Injury (TBI)",
  },
  { value: "Veterans/Military Service", label: "Veterans/Military Service" },
  { value: "Weight Loss", label: "Weight Loss" },
  { value: "White Privilege/Fragility", label: "White Privilege/Fragility" },
  { value: "Women's Issues", label: "Women's Issues" },
];

export const TREATMENT_APPROACHES = [
  {
    value: "Accelerated Experiential Dynamic Psychotherapy (AEDP)",
    label: "Accelerated Experiential Dynamic Psychotherapy (AEDP)",
  },
  {
    value: "Acceptance and Commitment Therapy (ACT)",
    label: "Acceptance and Commitment Therapy (ACT)",
  },
  { value: "Addiction Counseling", label: "Addiction Counseling" },
  { value: "Anger Management Therapy", label: "Anger Management Therapy" },
  {
    value: "Animal Assisted Therapy (AAT)",
    label: "Animal Assisted Therapy (AAT)",
  },
  { value: "Art Therapy", label: "Art Therapy" },
  { value: "Attachment Theory", label: "Attachment Theory" },
  { value: "Biofeedback", label: "Biofeedback" },
  { value: "Brainspotting", label: "Brainspotting" },
  { value: "Clinical Neuropsychology", label: "Clinical Neuropsychology" },
  {
    value: "Clinical Supervision and Licensed Supervisors",
    label: "Clinical Supervision and Licensed Supervisors",
  },
  { value: "Coaching", label: "Coaching" },
  {
    value: "Cognitive Behavioral Therapy (CBT)",
    label: "Cognitive Behavioral Therapy (CBT)",
  },
  {
    value: "Collaborative Couple Therapy",
    label: "Collaborative Couple Therapy",
  },
  { value: "Compassion Focused Therapy", label: "Compassion Focused Therapy" },
  { value: "Contemplative Therapy", label: "Contemplative Therapy" },
  { value: "Couples Counseling", label: "Couples Counseling" },
  {
    value: "Culturally Sensitive Therapy",
    label: "Culturally Sensitive Therapy",
  },
  { value: "Dance / Movement Therapy", label: "Dance / Movement Therapy" },
  { value: "Depth Therapy", label: "Depth Therapy" },
  {
    value: "Dialectical Behavior Therapy (DBT)",
    label: "Dialectical Behavior Therapy (DBT)",
  },
  { value: "Discernment Counseling", label: "Discernment Counseling" },
  { value: "Eclectic Therapy", label: "Eclectic Therapy" },
  { value: "EcoTherapy", label: "EcoTherapy" },
  { value: "EMDR", label: "EMDR" },
  {
    value: "Emotionally Focused Therapy",
    label: "Emotionally Focused Therapy",
  },
  { value: "Energy Psychology", label: "Energy Psychology" },
  {
    value: "Equine Assisted Psychotherapy (EAP)",
    label: "Equine Assisted Psychotherapy (EAP)",
  },
  { value: "Existential Therapy", label: "Existential Therapy" },
  { value: "Experiential Therapy", label: "Experiential Therapy" },
  { value: "Exposure Therapy", label: "Exposure Therapy" },
  { value: "Expressive Art Therapy", label: "Expressive Art Therapy" },
  { value: "Family Systems", label: "Family Systems" },
  { value: "Family Therapy", label: "Family Therapy" },
  { value: "Feminist Therapy", label: "Feminist Therapy" },
  { value: "Gestalt Therapy", label: "Gestalt Therapy" },
  { value: "Gottman Method", label: "Gottman Method" },
  { value: "Hakomi", label: "Hakomi" },
  { value: "Harm Reduction Therapy", label: "Harm Reduction Therapy" },
  { value: "Holistic Therapy", label: "Holistic Therapy" },
  { value: "Humanistic Therapy", label: "Humanistic Therapy" },
  { value: "Hypnotherapy", label: "Hypnotherapy" },
  { value: "Imago Relationship Therapy", label: "Imago Relationship Therapy" },
  { value: "Integral Therapy", label: "Integral Therapy" },
  { value: "Integrative Therapy", label: "Integrative Therapy" },
  { value: "Internal Family Systems", label: "Internal Family Systems" },
  {
    value: "Interpersonal Neurobiology (IPNB)",
    label: "Interpersonal Neurobiology (IPNB)",
  },
  {
    value: "Interpersonal Therapy (IPT)",
    label: "Interpersonal Therapy (IPT)",
  },
  { value: "Intervention", label: "Intervention" },
  {
    value: "Metacognitive Therapy (MCT)",
    label: "Metacognitive Therapy (MCT)",
  },
  { value: "Mindfulness-based Therapy", label: "Mindfulness-based Therapy" },
  { value: "Motivational Interviewing", label: "Motivational Interviewing" },
  { value: "Multicultural", label: "Multicultural" },
  { value: "Music Therapy", label: "Music Therapy" },
  { value: "Narrative Therapy", label: "Narrative Therapy" },
  {
    value: "NeuroAffective Relational Model (NARM)",
    label: "NeuroAffective Relational Model (NARM)",
  },
  { value: "Neurofeedback", label: "Neurofeedback" },
  { value: "Nonviolent Communication", label: "Nonviolent Communication" },
  { value: "Past Life Regression", label: "Past Life Regression" },
  { value: "Person-Centered (Rogerian)", label: "Person-Centered (Rogerian)" },
  { value: "Play Therapy", label: "Play Therapy" },
  { value: "Premarital Counseling", label: "Premarital Counseling" },
  { value: "Psychoanalytic Therapy", label: "Psychoanalytic Therapy" },
  {
    value: "Psychobiological Approach to Couple Therapy (PACT)",
    label: "Psychobiological Approach to Couple Therapy (PACT)",
  },
  { value: "Psychodrama", label: "Psychodrama" },
  { value: "Psychodynamic Therapy", label: "Psychodynamic Therapy" },
  {
    value: "Psychological Testing and Evaluation",
    label: "Psychological Testing and Evaluation",
  },
  { value: "Relational Therapy", label: "Relational Therapy" },
  { value: "Sand Tray Therapy", label: "Sand Tray Therapy" },
  { value: "Sandplay Therapy", label: "Sandplay Therapy" },
  { value: "Sensorimotor Psychotherapy", label: "Sensorimotor Psychotherapy" },
  {
    value: "Solution Focused Brief Therapy",
    label: "Solution Focused Brief Therapy",
  },
  {
    value: "Somatic Therapy (Body Centered)",
    label: "Somatic Therapy (Body Centered)",
  },
  { value: "Strength-Based Therapy", label: "Strength-Based Therapy" },
  { value: "Transpersonal Therapy", label: "Transpersonal Therapy" },
  { value: "Trauma Therapy", label: "Trauma Therapy" },
  { value: "Wilderness Therapy", label: "Wilderness Therapy" },
];

export const INSURANCE_TYPES = [
  { value: "Out-of-Pocket, Cash", label: "Out-of-Pocket, Cash" },
  { value: "#", label: "#" },
  { value: "1199SEIU", label: "1199SEIU" },
  { value: "1st Agency", label: "1st Agency" },
  { value: "20/20 Eyecare Plan", label: "20/20 Eyecare Plan" },
  { value: "A", label: "A" },
  { value: "AARP", label: "AARP" },
  { value: "ACE", label: "ACE" },
  { value: "AIG", label: "AIG" },
  { value: "APWU", label: "APWU" },
  { value: "ATRIO Health Plans", label: "ATRIO Health Plans" },
  { value: "AVMA Life", label: "AVMA Life" },
  { value: "Absolute Total Care", label: "Absolute Total Care" },
  { value: "Access Medicare (NY)", label: "Access Medicare (NY)" },
  {
    value: "Accountable Health Plan of Ohio",
    label: "Accountable Health Plan of Ohio",
  },
  { value: "Advanced Health", label: "Advanced Health" },
  { value: "AdvantageMD", label: "AdvantageMD" },
  { value: "Advantica", label: "Advantica" },
  { value: "Advent Health", label: "Advent Health" },
  { value: "Adventist Health", label: "Adventist Health" },
  { value: "Advocate Health Care", label: "Advocate Health Care" },
  { value: "Aetna", label: "Aetna" },
  { value: "Aetna Better Health", label: "Aetna Better Health" },
  { value: "Affinity Health Plan", label: "Affinity Health Plan" },
  { value: "AgeRight Advantage", label: "AgeRight Advantage" },
  { value: "AgeWell New York", label: "AgeWell New York" },
  { value: "Agile Health Insurance", label: "Agile Health Insurance" },
  {
    value: "Alameda Alliance for Health",
    label: "Alameda Alliance for Health",
  },
  { value: "Aliera Health Care", label: "Aliera Health Care" },
  { value: "Alignment Health Plan", label: "Alignment Health Plan" },
  { value: "All Savers Insurance", label: "All Savers Insurance" },
  { value: "AllCare Health", label: "AllCare Health" },
  { value: "AllState", label: "AllState" },
  { value: "AllWays Health Partners", label: "AllWays Health Partners" },
  { value: "Allegiance Life and Health", label: "Allegiance Life and Health" },
  { value: "Alliant Health Plans", label: "Alliant Health Plans" },
  { value: "Allianz Worldwide Care", label: "Allianz Worldwide Care" },
  { value: "Allwell", label: "Allwell" },
  { value: "AlohaCare", label: "AlohaCare" },
  { value: "AlphaCare", label: "AlphaCare" },
  {
    value: "AltaMed Senior BuenaCare (PACE)",
    label: "AltaMed Senior BuenaCare (PACE)",
  },
  {
    value: "Altius (Coventry Health Care)",
    label: "Altius (Coventry Health Care)",
  },
  { value: "AlwaysCare", label: "AlwaysCare" },
  { value: "Ambetter", label: "Ambetter" },
  { value: "AmeriGroup", label: "AmeriGroup" },
  { value: "AmeriHealth", label: "AmeriHealth" },
  { value: "AmeriHealth Caritas", label: "AmeriHealth Caritas" },
  { value: "America's 1st Choice", label: "America's 1st Choice" },
  { value: "American Behavioral", label: "American Behavioral" },
  { value: "American Eldercare", label: "American Eldercare" },
  {
    value: "American Healthcare Alliance",
    label: "American Healthcare Alliance",
  },
  {
    value: "American Maritime Officers Plans",
    label: "American Maritime Officers Plans",
  },
  {
    value: "American Republic Insurance Company",
    label: "American Republic Insurance Company",
  },
  { value: "Amida Care", label: "Amida Care" },
  {
    value: "Amplifon Hearing Health Care",
    label: "Amplifon Hearing Health Care",
  },
  { value: "Anthem Blue Cross", label: "Anthem Blue Cross" },
  {
    value: "Anthem Blue Cross Blue Shield",
    label: "Anthem Blue Cross Blue Shield",
  },
  { value: "Apostrophe", label: "Apostrophe" },
  { value: "ArchCare", label: "ArchCare" },
  { value: "Arise Health Plan", label: "Arise Health Plan" },
  { value: "Arizona Complete Health", label: "Arizona Complete Health" },
  {
    value: "Arizona Foundation for Medical Care",
    label: "Arizona Foundation for Medical Care",
  },
  {
    value: "Arkansas Blue Cross Blue Shield",
    label: "Arkansas Blue Cross Blue Shield",
  },
  { value: "Arkansas Total Care", label: "Arkansas Total Care" },
  { value: "Ascension Complete", label: "Ascension Complete" },
  { value: "Ascension Health", label: "Ascension Health" },
  { value: "Aspire Health Plan", label: "Aspire Health Plan" },
  { value: "Assurant Employee Benefits", label: "Assurant Employee Benefits" },
  { value: "Assurant Health", label: "Assurant Health" },
  { value: "Asuris Northwest Health", label: "Asuris Northwest Health" },
  { value: "Aultcare", label: "Aultcare" },
  { value: "AvMed", label: "AvMed" },
  { value: "Avera Health Plans", label: "Avera Health Plans" },
  { value: "Avesis", label: "Avesis" },
  { value: "B", label: "B" },
  { value: "BMC HealthNet Plan", label: "BMC HealthNet Plan" },
  { value: "Banker's Life", label: "Banker's Life" },
  { value: "Banner Health", label: "Banner Health" },
  { value: "Baptist Health Plan", label: "Baptist Health Plan" },
  {
    value: "BayCarePlus Medicare Advantage",
    label: "BayCarePlus Medicare Advantage",
  },
  { value: "Beacon Health Options", label: "Beacon Health Options" },
  {
    value: "Beaumont Employee Health Plan",
    label: "Beaumont Employee Health Plan",
  },
  { value: "Beech Street", label: "Beech Street" },
  { value: "Best Choice Plus", label: "Best Choice Plus" },
  { value: "Best Doctors Insurance", label: "Best Doctors Insurance" },
  { value: "Best Life And Health", label: "Best Life And Health" },
  {
    value: "Better Health (Florida Medicaid)",
    label: "Better Health (Florida Medicaid)",
  },
  { value: "Bind HealthCare", label: "Bind HealthCare" },
  { value: "Blue Choice Health Plan", label: "Blue Choice Health Plan" },
  {
    value: "Blue Cross Blue Shield (BCBS)",
    label: "Blue Cross Blue Shield (BCBS)",
  },
  {
    value: "Blue Cross Blue Shield Federal Employee Program",
    label: "Blue Cross Blue Shield Federal Employee Program",
  },
  {
    value: "Blue Cross Blue Shield of Alabama",
    label: "Blue Cross Blue Shield of Alabama",
  },
  {
    value: "Blue Cross Blue Shield of Arizona",
    label: "Blue Cross Blue Shield of Arizona",
  },
  {
    value: "Blue Cross Blue Shield of Georgia",
    label: "Blue Cross Blue Shield of Georgia",
  },
  {
    value: "Blue Cross Blue Shield of Illinois",
    label: "Blue Cross Blue Shield of Illinois",
  },
  {
    value: "Blue Cross Blue Shield of Kansas",
    label: "Blue Cross Blue Shield of Kansas",
  },
  {
    value: "Blue Cross Blue Shield of Kansas City",
    label: "Blue Cross Blue Shield of Kansas City",
  },
  {
    value: "Blue Cross Blue Shield of Louisiana",
    label: "Blue Cross Blue Shield of Louisiana",
  },
  {
    value: "Blue Cross Blue Shield of Massachusetts",
    label: "Blue Cross Blue Shield of Massachusetts",
  },
  {
    value: "Blue Cross Blue Shield of Michigan",
    label: "Blue Cross Blue Shield of Michigan",
  },
  {
    value: "Blue Cross Blue Shield of Minnesota",
    label: "Blue Cross Blue Shield of Minnesota",
  },
  {
    value: "Blue Cross Blue Shield of Mississippi",
    label: "Blue Cross Blue Shield of Mississippi",
  },
  {
    value: "Blue Cross Blue Shield of Montana",
    label: "Blue Cross Blue Shield of Montana",
  },
  {
    value: "Blue Cross Blue Shield of Nebraska",
    label: "Blue Cross Blue Shield of Nebraska",
  },
  {
    value: "Blue Cross Blue Shield of New Mexico",
    label: "Blue Cross Blue Shield of New Mexico",
  },
  {
    value: "Blue Cross Blue Shield of North Carolina",
    label: "Blue Cross Blue Shield of North Carolina",
  },
  {
    value: "Blue Cross Blue Shield of North Dakota",
    label: "Blue Cross Blue Shield of North Dakota",
  },
  {
    value: "Blue Cross Blue Shield of Oklahoma",
    label: "Blue Cross Blue Shield of Oklahoma",
  },
  {
    value: "Blue Cross Blue Shield of Rhode Island",
    label: "Blue Cross Blue Shield of Rhode Island",
  },
  {
    value: "Blue Cross Blue Shield of South Carolina",
    label: "Blue Cross Blue Shield of South Carolina",
  },
  {
    value: "Blue Cross Blue Shield of Tennessee",
    label: "Blue Cross Blue Shield of Tennessee",
  },
  {
    value: "Blue Cross Blue Shield of Texas",
    label: "Blue Cross Blue Shield of Texas",
  },
  {
    value: "Blue Cross Blue Shield of Vermont",
    label: "Blue Cross Blue Shield of Vermont",
  },
  {
    value: "Blue Cross Blue Shield of Western New York",
    label: "Blue Cross Blue Shield of Western New York",
  },
  {
    value: "Blue Cross Blue Shield of Wyoming",
    label: "Blue Cross Blue Shield of Wyoming",
  },
  { value: "Blue Cross of Idaho", label: "Blue Cross of Idaho" },
  {
    value: "Blue Cross of Northeastern Pennsylvania",
    label: "Blue Cross of Northeastern Pennsylvania",
  },
  { value: "Blue Shield of California", label: "Blue Shield of California" },
  {
    value: "Blue Shield of Northeastern New York",
    label: "Blue Shield of Northeastern New York",
  },
  { value: "Brand New Day", label: "Brand New Day" },
  {
    value: "Braven Health (Horizon Blue Cross Blue Shield of New Jersey)",
    label: "Braven Health (Horizon Blue Cross Blue Shield of New Jersey)",
  },
  { value: "BridgeSpan", label: "BridgeSpan" },
  { value: "Bridgeway Health Solutions", label: "Bridgeway Health Solutions" },
  { value: "Bright Health", label: "Bright Health" },
  { value: "Buckeye Health Plan", label: "Buckeye Health Plan" },
  { value: "C", label: "C" },
  { value: "CBA Blue", label: "CBA Blue" },
  { value: "CDPHP", label: "CDPHP" },
  { value: "CHAMPVA", label: "CHAMPVA" },
  { value: "CHP Group", label: "CHP Group" },
  { value: "CHRISTUS Health Plan", label: "CHRISTUS Health Plan" },
  { value: "CalOptima", label: "CalOptima" },
  { value: "CalPERS", label: "CalPERS" },
  { value: "CalViva Health", label: "CalViva Health" },
  {
    value: "California Foundation for Medical Care",
    label: "California Foundation for Medical Care",
  },
  {
    value: "California Health and Wellness",
    label: "California Health and Wellness",
  },
  { value: "Calvos", label: "Calvos" },
  {
    value: "Cambridge Health Alliance (CHA)",
    label: "Cambridge Health Alliance (CHA)",
  },
  { value: "Capital Blue Cross", label: "Capital Blue Cross" },
  { value: "Capital Health Plan", label: "Capital Health Plan" },
  { value: "Care Access Health Plan", label: "Care Access Health Plan" },
  { value: "Care Improvement Plus", label: "Care Improvement Plus" },
  { value: "Care N' Care", label: "Care N' Care" },
  { value: "Care1st", label: "Care1st" },
  { value: "CareConnect", label: "CareConnect" },
  {
    value: "CareFirst Blue Cross Blue Shield (Health)",
    label: "CareFirst Blue Cross Blue Shield (Health)",
  },
  { value: "CareMore", label: "CareMore" },
  { value: "CareOregon", label: "CareOregon" },
  {
    value: "CarePartners of Connecticut",
    label: "CarePartners of Connecticut",
  },
  {
    value: "CarePlus Health Plans (Florida Medicare)",
    label: "CarePlus Health Plans (Florida Medicare)",
  },
  { value: "CareSource", label: "CareSource" },
  { value: "Cascade Health Alliance", label: "Cascade Health Alliance" },
  { value: "Caterpillar", label: "Caterpillar" },
  { value: "CeltiCare Health Plan", label: "CeltiCare Health Plan" },
  { value: "CenCal Health", label: "CenCal Health" },
  { value: "Cenpatico", label: "Cenpatico" },
  { value: "Centennial Care", label: "Centennial Care" },
  {
    value: "Center for Elders' Independence (PACE)",
    label: "Center for Elders' Independence (PACE)",
  },
  { value: "CenterLight Healthcare", label: "CenterLight Healthcare" },
  {
    value: "Centers Plan for Healthy Living",
    label: "Centers Plan for Healthy Living",
  },
  {
    value: "Centers for Medicare & Medicaid Services",
    label: "Centers for Medicare & Medicaid Services",
  },
  { value: "Centivo", label: "Centivo" },
  { value: "Centra Health", label: "Centra Health" },
  {
    value: "Central California Alliance for Health",
    label: "Central California Alliance for Health",
  },
  {
    value: "Central Health Plan of California",
    label: "Central Health Plan of California",
  },
  { value: "Century Healthcare - CHC", label: "Century Healthcare - CHC" },
  {
    value: "Children's Community Health Plan",
    label: "Children's Community Health Plan",
  },
  {
    value: "Children's Medical Center Health Plan",
    label: "Children's Medical Center Health Plan",
  },
  {
    value: "Children's Medical Services (CMS)",
    label: "Children's Medical Services (CMS)",
  },
  {
    value: "Chinese Community Health Plan",
    label: "Chinese Community Health Plan",
  },
  { value: "Choice Care Network", label: "Choice Care Network" },
  {
    value: "Christian Healthcare Ministries",
    label: "Christian Healthcare Ministries",
  },
  { value: "Cigna", label: "Cigna" },
  { value: "Cigna-HealthSpring", label: "Cigna-HealthSpring" },
  { value: "Clarion Health", label: "Clarion Health" },
  {
    value: "Clark County Self-Funded Health",
    label: "Clark County Self-Funded Health",
  },
  { value: "Clear Spring Health", label: "Clear Spring Health" },
  { value: "Clements Worldwide", label: "Clements Worldwide" },
  {
    value: "Cleveland Clinic Employee Health Plan",
    label: "Cleveland Clinic Employee Health Plan",
  },
  { value: "Clover Health", label: "Clover Health" },
  { value: "Cofinity", label: "Cofinity" },
  { value: "Colorado Access", label: "Colorado Access" },
  { value: "Columbia Pacific CCO", label: "Columbia Pacific CCO" },
  { value: "Columbia United Providers", label: "Columbia United Providers" },
  { value: "ComPsych", label: "ComPsych" },
  {
    value: "Common Ground Healthcare Cooperative",
    label: "Common Ground Healthcare Cooperative",
  },
  { value: "Commonwealth Care Alliance", label: "Commonwealth Care Alliance" },
  {
    value: "Community Behavioral Health",
    label: "Community Behavioral Health",
  },
  {
    value: "Community Care Alliance of Illinois",
    label: "Community Care Alliance of Illinois",
  },
  { value: "Community Care Associates", label: "Community Care Associates" },
  {
    value: "Community Care Behavioral Health Organization",
    label: "Community Care Behavioral Health Organization",
  },
  { value: "Community Care Plan", label: "Community Care Plan" },
  {
    value: "Community Care of North Carolina",
    label: "Community Care of North Carolina",
  },
  { value: "Community Eye Care", label: "Community Eye Care" },
  {
    value: "Community First Health Plans",
    label: "Community First Health Plans",
  },
  { value: "Community Health Choice", label: "Community Health Choice" },
  { value: "Community Health Group", label: "Community Health Group" },
  { value: "Community Health Options", label: "Community Health Options" },
  { value: "Community Health Partners", label: "Community Health Partners" },
  {
    value: "Community Health Plan of Washington",
    label: "Community Health Plan of Washington",
  },
  { value: "CommunityCare of Oklahoma", label: "CommunityCare of Oklahoma" },
  { value: "CompBenefits", label: "CompBenefits" },
  { value: "Companion Life", label: "Companion Life" },
  {
    value: "Comprehensive Health Insurance Plan (CHIP) of Illinois",
    label: "Comprehensive Health Insurance Plan (CHIP) of Illinois",
  },
  {
    value: "Comprehensive Medical and Dental Program (CMDP)",
    label: "Comprehensive Medical and Dental Program (CMDP)",
  },
  { value: "Connect Care", label: "Connect Care" },
  { value: "ConnectiCare", label: "ConnectiCare" },
  { value: "Consolidated Health Plans", label: "Consolidated Health Plans" },
  { value: "Constitution Life", label: "Constitution Life" },
  { value: "Consumer Health Network", label: "Consumer Health Network" },
  { value: "Contra Costa Health Plan", label: "Contra Costa Health Plan" },
  {
    value: "Cook Children's Health Plan",
    label: "Cook Children's Health Plan",
  },
  { value: "Coordinated Care Health", label: "Coordinated Care Health" },
  { value: "Corvel", label: "Corvel" },
  { value: "CountyCare (Cook County)", label: "CountyCare (Cook County)" },
  { value: "Coventry Health Care", label: "Coventry Health Care" },
  { value: "Cox HealthPlans", label: "Cox HealthPlans" },
  { value: "Create", label: "Create" },
  { value: "Crystal Run Health Plans", label: "Crystal Run Health Plans" },
  { value: "Culinary Health Fund", label: "Culinary Health Fund" },
  { value: "CuraLinc Healthcare", label: "CuraLinc Healthcare" },
  { value: "D", label: "D" },
  { value: "DAKOTACARE", label: "DAKOTACARE" },
  { value: "DC Medicaid", label: "DC Medicaid" },
  { value: "DMC Care", label: "DMC Care" },
  {
    value: "DOCS (Doctors of the Oregon South Coast)",
    label: "DOCS (Doctors of the Oregon South Coast)",
  },
  { value: "DavidShield", label: "DavidShield" },
  { value: "Davis Vision", label: "Davis Vision" },
  { value: "Deaconess Health Plans", label: "Deaconess Health Plans" },
  { value: "Dean Health Plan", label: "Dean Health Plan" },
  {
    value: "Dell Children's Health Plan",
    label: "Dell Children's Health Plan",
  },
  { value: "Denver Health Medical Plan", label: "Denver Health Medical Plan" },
  {
    value: "Department of Medical Assistance Services",
    label: "Department of Medical Assistance Services",
  },
  { value: "Deseret Mutual", label: "Deseret Mutual" },
  { value: "Devon Health Services", label: "Devon Health Services" },
  { value: "Devoted Health", label: "Devoted Health" },
  { value: "Dimension Health", label: "Dimension Health" },
  { value: "Doctors HealthCare Plans", label: "Doctors HealthCare Plans" },
  { value: "Driscoll Health Plan", label: "Driscoll Health Plan" },
  { value: "E", label: "E" },
  { value: "EHP Significa", label: "EHP Significa" },
  { value: "EMI Health", label: "EMI Health" },
  { value: "ESSENCE Healthcare", label: "ESSENCE Healthcare" },
  {
    value: "Eastern Oregon Coordinated Care Organization",
    label: "Eastern Oregon Coordinated Care Organization",
  },
  {
    value: "Easy Choice Health Plan (California)",
    label: "Easy Choice Health Plan (California)",
  },
  {
    value: "Easy Choice Health Plan of New York",
    label: "Easy Choice Health Plan of New York",
  },
  { value: "El Paso First Health Plans", label: "El Paso First Health Plans" },
  { value: "Elderplan", label: "Elderplan" },
  { value: "EmblemHealth", label: "EmblemHealth" },
  {
    value: "EmblemHealth (formerly known as GHI)",
    label: "EmblemHealth (formerly known as GHI)",
  },
  {
    value: "EmblemHealth (formerly known as HIP)",
    label: "EmblemHealth (formerly known as HIP)",
  },
  { value: "Emory Health Care Plan", label: "Emory Health Care Plan" },
  {
    value: "Empire Blue Cross Blue Shield (Health)",
    label: "Empire Blue Cross Blue Shield (Health)",
  },
  {
    value: "Empire BlueCross BlueShield HealthPlus",
    label: "Empire BlueCross BlueShield HealthPlus",
  },
  { value: "Empire Plan", label: "Empire Plan" },
  {
    value: "Empower Healthcare Solutions",
    label: "Empower Healthcare Solutions",
  },
  { value: "Encore Health Network", label: "Encore Health Network" },
  { value: "Envolve Benefit Options", label: "Envolve Benefit Options" },
  { value: "Eon Health", label: "Eon Health" },
  { value: "Epic Hearing Health Care", label: "Epic Hearing Health Care" },
  { value: "Erickson Advantage", label: "Erickson Advantage" },
  { value: "EverCare", label: "EverCare" },
  {
    value: "Evergreen Health Cooperative",
    label: "Evergreen Health Cooperative",
  },
  {
    value: "Evolutions Healthcare Systems",
    label: "Evolutions Healthcare Systems",
  },
  {
    value: "Excellus Blue Cross Blue Shield",
    label: "Excellus Blue Cross Blue Shield",
  },
  { value: "Experience HealthND", label: "Experience HealthND" },
  {
    value: "Extended Managed Long Term Care",
    label: "Extended Managed Long Term Care",
  },
  { value: "EyeMed", label: "EyeMed" },
  { value: "Eyetopia Vision Care", label: "Eyetopia Vision Care" },
  { value: "F", label: "F" },
  {
    value: "Fallon Community Health Plan (FCHP)",
    label: "Fallon Community Health Plan (FCHP)",
  },
  { value: "Family Health Network", label: "Family Health Network" },
  { value: "FamilyCare Health Plans", label: "FamilyCare Health Plans" },
  { value: "Fidelis Care (NY)", label: "Fidelis Care (NY)" },
  { value: "First Choice Health", label: "First Choice Health" },
  {
    value: "First Choice Health Plan of Mississippi",
    label: "First Choice Health Plan of Mississippi",
  },
  {
    value: "First Health (Coventry Health Care)",
    label: "First Health (Coventry Health Care)",
  },
  { value: "FirstCare Health Plans", label: "FirstCare Health Plans" },
  { value: "FirstCarolinaCare", label: "FirstCarolinaCare" },
  {
    value: "Florida Blue: Blue Cross Blue Shield of Florida",
    label: "Florida Blue: Blue Cross Blue Shield of Florida",
  },
  { value: "Florida Community Care", label: "Florida Community Care" },
  { value: "Florida Health Care Plans", label: "Florida Health Care Plans" },
  { value: "Florida Health Partners", label: "Florida Health Partners" },
  {
    value: "Florida Hospital Healthcare System (FHHS)",
    label: "Florida Hospital Healthcare System (FHHS)",
  },
  { value: "Florida KidCare", label: "Florida KidCare" },
  {
    value: "Fort Bend County Indigent Health Care",
    label: "Fort Bend County Indigent Health Care",
  },
  { value: "Fortified Provider Network", label: "Fortified Provider Network" },
  { value: "Freedom Health", label: "Freedom Health" },
  { value: "Fresenius Health Plans", label: "Fresenius Health Plans" },
  { value: "Friday Health Plans", label: "Friday Health Plans" },
  { value: "G", label: "G" },
  { value: "GEHA", label: "GEHA" },
  { value: "GEMCare Health Plan", label: "GEMCare Health Plan" },
  {
    value: "GWH-Cigna (formerly Great West Healthcare)",
    label: "GWH-Cigna (formerly Great West Healthcare)",
  },
  { value: "Galaxy Health", label: "Galaxy Health" },
  { value: "Gateway Health", label: "Gateway Health" },
  { value: "Geisinger Health Plan", label: "Geisinger Health Plan" },
  {
    value: "General Vision Services (GVS)",
    label: "General Vision Services (GVS)",
  },
  { value: "GeoBlue", label: "GeoBlue" },
  { value: "Gilsbar 360 Alliance", label: "Gilsbar 360 Alliance" },
  { value: "Global Health", label: "Global Health" },
  { value: "Gold Coast Health Plan", label: "Gold Coast Health Plan" },
  { value: "Golden Rule", label: "Golden Rule" },
  {
    value: "Golden State Medicare Health Plan",
    label: "Golden State Medicare Health Plan",
  },
  {
    value: "Green Mountain Care (Vermont)",
    label: "Green Mountain Care (Vermont)",
  },
  { value: "Group Health Cooperative", label: "Group Health Cooperative" },
  {
    value: "Group Health Cooperative of Eau Claire",
    label: "Group Health Cooperative of Eau Claire",
  },
  {
    value: "Group Health Cooperative of South Central Wisconsin",
    label: "Group Health Cooperative of South Central Wisconsin",
  },
  { value: "Guardian", label: "Guardian" },
  { value: "Gundersen Health Plan", label: "Gundersen Health Plan" },
  { value: "H", label: "H" },
  { value: "HAP (Health Alliance Plan)", label: "HAP (Health Alliance Plan)" },
  { value: "HAP Midwest Health Plan", label: "HAP Midwest Health Plan" },
  { value: "HFN", label: "HFN" },
  { value: "HFS Medical Benefits", label: "HFS Medical Benefits" },
  { value: "HUSKY Health", label: "HUSKY Health" },
  { value: "Hamaspik Choice", label: "Hamaspik Choice" },
  { value: "Harken Health", label: "Harken Health" },
  { value: "Harmony Health Plan", label: "Harmony Health Plan" },
  {
    value: "Harvard Pilgrim Health Care",
    label: "Harvard Pilgrim Health Care",
  },
  {
    value: "Hawaii Medical Assurance Association (HMAA)",
    label: "Hawaii Medical Assurance Association (HMAA)",
  },
  {
    value: "Hawaii Medical Service Association (HMSA)",
    label: "Hawaii Medical Service Association (HMSA)",
  },
  { value: "Health Alliance", label: "Health Alliance" },
  { value: "Health Choice Arizona", label: "Health Choice Arizona" },
  { value: "Health First (FL)", label: "Health First (FL)" },
  { value: "Health First Colorado", label: "Health First Colorado" },
  {
    value: "Health First Health Plans (Florida)",
    label: "Health First Health Plans (Florida)",
  },
  { value: "Health Net", label: "Health Net" },
  { value: "Health New England", label: "Health New England" },
  {
    value: "Health Partners Plans (Pennsylvania)",
    label: "Health Partners Plans (Pennsylvania)",
  },
  { value: "Health Plan of Nevada", label: "Health Plan of Nevada" },
  { value: "Health Plan of San Joaquin", label: "Health Plan of San Joaquin" },
  { value: "Health Plan of San Mateo", label: "Health Plan of San Mateo" },
  { value: "Health Plus", label: "Health Plus" },
  { value: "Health Share of Oregon", label: "Health Share of Oregon" },
  { value: "Health Sun", label: "Health Sun" },
  { value: "HealthChoice Oklahoma", label: "HealthChoice Oklahoma" },
  { value: "HealthChoice of Michigan", label: "HealthChoice of Michigan" },
  { value: "HealthFirst (NY)", label: "HealthFirst (NY)" },
  { value: "HealthNow", label: "HealthNow" },
  { value: "HealthPartners", label: "HealthPartners" },
  { value: "HealthScope Benefits", label: "HealthScope Benefits" },
  { value: "HealthSmart", label: "HealthSmart" },
  { value: "HealthTeam Advantage", label: "HealthTeam Advantage" },
  {
    value: "Healthcare Highways Health Plan",
    label: "Healthcare Highways Health Plan",
  },
  { value: "Healthlink", label: "Healthlink" },
  { value: "Healthy Texas Women", label: "Healthy Texas Women" },
  { value: "HealthyCT", label: "HealthyCT" },
  { value: "Hear In America", label: "Hear In America" },
  { value: "Hennepin Health", label: "Hennepin Health" },
  { value: "Heritage Vision Plans", label: "Heritage Vision Plans" },
  {
    value: "Highmark Blue Cross Blue Shield",
    label: "Highmark Blue Cross Blue Shield",
  },
  {
    value: "Highmark Blue Cross Blue Shield of Delaware",
    label: "Highmark Blue Cross Blue Shield of Delaware",
  },
  { value: "Highmark Blue Shield", label: "Highmark Blue Shield" },
  {
    value: "Highmark BlueCross BlueShield of West Virginia",
    label: "Highmark BlueCross BlueShield of West Virginia",
  },
  {
    value: "Hillsborough Health Care Plan",
    label: "Hillsborough Health Care Plan",
  },
  { value: "Home State Health Plan", label: "Home State Health Plan" },
  { value: "Hometown Health", label: "Hometown Health" },
  {
    value: "Horizon Blue Cross Blue Shield of New Jersey",
    label: "Horizon Blue Cross Blue Shield of New Jersey",
  },
  {
    value: "Horizon Blue Cross Blue Shield of New Jersey For Barnabas Health",
    label: "Horizon Blue Cross Blue Shield of New Jersey For Barnabas Health",
  },
  {
    value: "Horizon Blue Cross Blue Shield of New Jersey For Novartis",
    label: "Horizon Blue Cross Blue Shield of New Jersey For Novartis",
  },
  { value: "Horizon NJ Health", label: "Horizon NJ Health" },
  { value: "Hudson Health Plan", label: "Hudson Health Plan" },
  { value: "Humana", label: "Humana" },
  {
    value: "Humana Behavioral Health (LifeSynch)",
    label: "Humana Behavioral Health (LifeSynch)",
  },
  { value: "I", label: "I" },
  { value: "IHC Health Solutions", label: "IHC Health Solutions" },
  {
    value: "IMS (Independent Medical Systems)",
    label: "IMS (Independent Medical Systems)",
  },
  {
    value: "IU Health Plans (Indiana University Health)",
    label: "IU Health Plans (Indiana University Health)",
  },
  { value: "Illinicare Health", label: "Illinicare Health" },
  {
    value: "Illinois' Primary Care Case Management (PCCM)",
    label: "Illinois' Primary Care Case Management (PCCM)",
  },
  { value: "Imagine Health", label: "Imagine Health" },
  {
    value: "Imperial Health Plan of California",
    label: "Imperial Health Plan of California",
  },
  {
    value: "Imperial Insurance Company of Texas",
    label: "Imperial Insurance Company of Texas",
  },
  { value: "Independence Blue Cross", label: "Independence Blue Cross" },
  { value: "Independence Care System", label: "Independence Care System" },
  { value: "Independent Health", label: "Independent Health" },
  { value: "Indiana Medicaid", label: "Indiana Medicaid" },
  { value: "Ingham Health Plan", label: "Ingham Health Plan" },
  { value: "Inland Empire Health Plan", label: "Inland Empire Health Plan" },
  { value: "Innovation Health", label: "Innovation Health" },
  { value: "Integra", label: "Integra" },
  { value: "Inter Valley Health Plan", label: "Inter Valley Health Plan" },
  {
    value: "InterCommunity Health Network CCO",
    label: "InterCommunity Health Network CCO",
  },
  { value: "Intergroup Services", label: "Intergroup Services" },
  { value: "Iowa MediPASS", label: "Iowa MediPASS" },
  { value: "Iowa Total Care", label: "Iowa Total Care" },
  { value: "Itasca Medical Care", label: "Itasca Medical Care" },
  { value: "J", label: "J" },
  { value: "Jackson Care Connect", label: "Jackson Care Connect" },
  { value: "Jackson Health Plan", label: "Jackson Health Plan" },
  { value: "Jai Medical Systems", label: "Jai Medical Systems" },
  {
    value: "Johns Hopkins Employer Health Programs",
    label: "Johns Hopkins Employer Health Programs",
  },
  { value: "K", label: "K" },
  { value: "KPS Health Plans", label: "KPS Health Plans" },
  { value: "Kaiser Permanente", label: "Kaiser Permanente" },
  { value: "Kansas HealthWave", label: "Kansas HealthWave" },
  { value: "Kansas Superior Select", label: "Kansas Superior Select" },
  { value: "KelseyCare Advantage", label: "KelseyCare Advantage" },
  { value: "Keystone First", label: "Keystone First" },
  { value: "L", label: "L" },
  { value: "L.A. Care Health Plan", label: "L.A. Care Health Plan" },
  { value: "L.A. Care Health Plan", label: "L.A. Care Health Plan" },
  { value: "Land of Lincoln Health", label: "Land of Lincoln Health" },
  { value: "Landmark Healthplan", label: "Landmark Healthplan" },
  { value: "Lasso Healthcare", label: "Lasso Healthcare" },
  { value: "Legacy Health", label: "Legacy Health" },
  {
    value: "Lehigh Valley Health Network Health Plan",
    label: "Lehigh Valley Health Network Health Plan",
  },
  {
    value: "Leon Medical Centers Health Plans",
    label: "Leon Medical Centers Health Plans",
  },
  { value: "Liberty Health Advantage", label: "Liberty Health Advantage" },
  { value: "Liberty HealthShare", label: "Liberty HealthShare" },
  { value: "Liberty Mutual", label: "Liberty Mutual" },
  { value: "LifeWise", label: "LifeWise" },
  { value: "Lifestyle Health", label: "Lifestyle Health" },
  { value: "Lighthouse Guild", label: "Lighthouse Guild" },
  { value: "Lighthouse Health Plan", label: "Lighthouse Health Plan" },
  { value: "Lincoln Financial Group", label: "Lincoln Financial Group" },
  { value: "Live360 Health Plan", label: "Live360 Health Plan" },
  { value: "Longevity Health Plan", label: "Longevity Health Plan" },
  {
    value: "Louisiana Healthcare Connections",
    label: "Louisiana Healthcare Connections",
  },
  { value: "Lutheran Preferred", label: "Lutheran Preferred" },
  { value: "M", label: "M" },
  { value: "MCM Maxcare", label: "MCM Maxcare" },
  { value: "MDwise", label: "MDwise" },
  { value: "MHNet Behavioral Health", label: "MHNet Behavioral Health" },
  { value: "MINES & Associates", label: "MINES & Associates" },
  {
    value: "MMM of Florida (Medicare and Much More)",
    label: "MMM of Florida (Medicare and Much More)",
  },
  { value: "MO HealthNet", label: "MO HealthNet" },
  {
    value: "MOAA (Miltary Officers Association of America)",
    label: "MOAA (Miltary Officers Association of America)",
  },
  { value: "MVP Health Care", label: "MVP Health Care" },
  { value: "Magellan Health", label: "Magellan Health" },
  { value: "MagnaCare", label: "MagnaCare" },
  { value: "Magnolia Health Plan", label: "Magnolia Health Plan" },
  { value: "Mail Handlers Benefit Plan", label: "Mail Handlers Benefit Plan" },
  { value: "MaineCare", label: "MaineCare" },
  {
    value: "Managed Health Network (MHN)",
    label: "Managed Health Network (MHN)",
  },
  {
    value: "Managed Health Services (Indiana)",
    label: "Managed Health Services (Indiana)",
  },
  {
    value: "Managed Health Services (Wisconsin)",
    label: "Managed Health Services (Wisconsin)",
  },
  {
    value: "Managed HealthCare Northwest",
    label: "Managed HealthCare Northwest",
  },
  { value: "March Vision Care", label: "March Vision Care" },
  { value: "Maricopa Health Plan", label: "Maricopa Health Plan" },
  { value: "Martin's Point HealthCare", label: "Martin's Point HealthCare" },
  {
    value: "Maryland Medical Assistance (Medicaid)",
    label: "Maryland Medical Assistance (Medicaid)",
  },
  { value: "Maryland Physicians Care", label: "Maryland Physicians Care" },
  { value: "MassHealth", label: "MassHealth" },
  {
    value: "Massachusetts Laborers' Health & Welfare Fund",
    label: "Massachusetts Laborers' Health & Welfare Fund",
  },
  { value: "Mayo Medical Plan", label: "Mayo Medical Plan" },
  { value: "McLaren Health Plan", label: "McLaren Health Plan" },
  { value: "MedStar Family Choice", label: "MedStar Family Choice" },
  { value: "MedStar Select", label: "MedStar Select" },
  { value: "Medi-Cal", label: "Medi-Cal" },
  { value: "MediGold", label: "MediGold" },
  { value: "MediPass", label: "MediPass" },
  { value: "Medica", label: "Medica" },
  {
    value: "Medica HealthCare Plans (Florida)",
    label: "Medica HealthCare Plans (Florida)",
  },
  { value: "Medicaid", label: "Medicaid" },
  {
    value: "Medical Eye Services (MES Vision)",
    label: "Medical Eye Services (MES Vision)",
  },
  { value: "Medical Mutual", label: "Medical Mutual" },
  { value: "Medicare", label: "Medicare" },
  { value: "Memorial Healthcare System", label: "Memorial Healthcare System" },
  { value: "Memorial Hermann", label: "Memorial Hermann" },
  { value: "Mercy Care", label: "Mercy Care" },
  { value: "Meridian Health Plan", label: "Meridian Health Plan" },
  { value: "Meritain Health", label: "Meritain Health" },
  { value: "MetLife", label: "MetLife" },
  { value: "MetroHealth", label: "MetroHealth" },
  { value: "MetroPlus Health Plan", label: "MetroPlus Health Plan" },
  { value: "Metropolitan Health Plan", label: "Metropolitan Health Plan" },
  {
    value: "Miami Children's Health Plan",
    label: "Miami Children's Health Plan",
  },
  { value: "Michigan Complete Health", label: "Michigan Complete Health" },
  { value: "Michigan No-Fault", label: "Michigan No-Fault" },
  { value: "Minuteman Health", label: "Minuteman Health" },
  {
    value: "Mississippi Division of Medicaid",
    label: "Mississippi Division of Medicaid",
  },
  { value: "Missouri Care", label: "Missouri Care" },
  { value: "Moda Health", label: "Moda Health" },
  { value: "Molina Healthcare", label: "Molina Healthcare" },
  { value: "Montana Health Cooperative", label: "Montana Health Cooperative" },
  { value: "Montefiore HMO", label: "Montefiore HMO" },
  { value: "MoreCare", label: "MoreCare" },
  { value: "Mountain Health Co-Op", label: "Mountain Health Co-Op" },
  { value: "Multiplan PHCS", label: "Multiplan PHCS" },
  { value: "Mutual of Omaha", label: "Mutual of Omaha" },
  { value: "N", label: "N" },
  { value: "NALC Health Benefit Plan", label: "NALC Health Benefit Plan" },
  {
    value: "NECA/IBEW Family Medical Care Plan",
    label: "NECA/IBEW Family Medical Care Plan",
  },
  { value: "NY State No-Fault", label: "NY State No-Fault" },
  { value: "NY: YourCare Health Plan", label: "NY: YourCare Health Plan" },
  {
    value: "National Congress of Employers (NCE)",
    label: "National Congress of Employers (NCE)",
  },
  {
    value: "National Vision Administrators",
    label: "National Vision Administrators",
  },
  { value: "Nationwide", label: "Nationwide" },
  { value: "Navajo Nation", label: "Navajo Nation" },
  {
    value: "Nebraska Total Care (Heritage Health)",
    label: "Nebraska Total Care (Heritage Health)",
  },
  {
    value: "Neighborhood Health Plan (Massachusetts)",
    label: "Neighborhood Health Plan (Massachusetts)",
  },
  {
    value: "Neighborhood Health Plan of Rhode Island",
    label: "Neighborhood Health Plan of Rhode Island",
  },
  { value: "Network Health Plan", label: "Network Health Plan" },
  { value: "Nevada Preferred", label: "Nevada Preferred" },
  {
    value: "New Directions Behavioral Health",
    label: "New Directions Behavioral Health",
  },
  {
    value: "New Hampshire Healthy Families",
    label: "New Hampshire Healthy Families",
  },
  {
    value: "New Mexico Health Connections",
    label: "New Mexico Health Connections",
  },
  {
    value: "New York Hotel Trades Council",
    label: "New York Hotel Trades Council",
  },
  { value: "NextLevelHealth", label: "NextLevelHealth" },
  { value: "Nippon Life Benefits", label: "Nippon Life Benefits" },
  {
    value: "North Carolina Health Choice (NCHC) for Children",
    label: "North Carolina Health Choice (NCHC) for Children",
  },
  {
    value: "North Shore LIJ CareConnect",
    label: "North Shore LIJ CareConnect",
  },
  { value: "Northland PACE", label: "Northland PACE" },
  { value: "NovaNet", label: "NovaNet" },
  { value: "O", label: "O" },
  {
    value: "OHMS (Oregon Health Management Services)",
    label: "OHMS (Oregon Health Management Services)",
  },
  { value: "OSU Health Plan", label: "OSU Health Plan" },
  { value: "Ohara, LLC", label: "Ohara, LLC" },
  { value: "Ohio Health Choice", label: "Ohio Health Choice" },
  { value: "OhioHealthy", label: "OhioHealthy" },
  { value: "On Lok Lifeways", label: "On Lok Lifeways" },
  { value: "On Lok Lifeways (PACE)", label: "On Lok Lifeways (PACE)" },
  { value: "OneNet PPO", label: "OneNet PPO" },
  { value: "Opticare of Utah", label: "Opticare of Utah" },
  { value: "Optima Health", label: "Optima Health" },
  { value: "Optimum HealthCare", label: "Optimum HealthCare" },
  { value: "Optum Health", label: "Optum Health" },
  { value: "Oscar Health Insurance Co.", label: "Oscar Health Insurance Co." },
  { value: "Oxford (UnitedHealthcare)", label: "Oxford (UnitedHealthcare)" },
  { value: "P", label: "P" },
  { value: "PA Health and Wellness", label: "PA Health and Wellness" },
  { value: "PA Medical Assistance", label: "PA Medical Assistance" },
  {
    value: "PBA (Patrolmen's Benefit Association)",
    label: "PBA (Patrolmen's Benefit Association)",
  },
  { value: "POMCO", label: "POMCO" },
  { value: "Pacific Health Alliance", label: "Pacific Health Alliance" },
  { value: "PacificSource Health Plans", label: "PacificSource Health Plans" },
  { value: "Palmetto GBA", label: "Palmetto GBA" },
  {
    value: "Pan-American Life Insurance Group",
    label: "Pan-American Life Insurance Group",
  },
  {
    value: "Paradigm Senior Care Advantage",
    label: "Paradigm Senior Care Advantage",
  },
  { value: "Paramount Healthcare", label: "Paramount Healthcare" },
  {
    value: "Parkland Community Health Plan",
    label: "Parkland Community Health Plan",
  },
  { value: "Parkview Total Health", label: "Parkview Total Health" },
  { value: "Partners Health Plan", label: "Partners Health Plan" },
  {
    value: "Partnership HealthPlan of California",
    label: "Partnership HealthPlan of California",
  },
  {
    value: "Passport Health Plan (Kentucky)",
    label: "Passport Health Plan (Kentucky)",
  },
  {
    value: "Passport To Health (Montana Medicaid)",
    label: "Passport To Health (Montana Medicaid)",
  },
  {
    value: "Patient 1st (Alabama Medicaid)",
    label: "Patient 1st (Alabama Medicaid)",
  },
  { value: "Peach State Health Plan", label: "Peach State Health Plan" },
  { value: "PeachCare for Kids", label: "PeachCare for Kids" },
  { value: "PennCare", label: "PennCare" },
  { value: "Peoples Health", label: "Peoples Health" },
  { value: "Phoenix Health Plan", label: "Phoenix Health Plan" },
  {
    value: "Physician Assured Access System",
    label: "Physician Assured Access System",
  },
  { value: "Physician Benefits Trust", label: "Physician Benefits Trust" },
  { value: "Physicians Eyecare Plan", label: "Physicians Eyecare Plan" },
  { value: "Physicians Health Plan", label: "Physicians Health Plan" },
  {
    value: "Physicians Health Plan of Northern Indiana, Inc.",
    label: "Physicians Health Plan of Northern Indiana, Inc.",
  },
  { value: "PhysiciansCare", label: "PhysiciansCare" },
  {
    value: "Piedmont Community Health Plan",
    label: "Piedmont Community Health Plan",
  },
  {
    value: "Piedmont WellStar Health Plans",
    label: "Piedmont WellStar Health Plans",
  },
  { value: "Positive Health Care", label: "Positive Health Care" },
  { value: "Preferential Care Network", label: "Preferential Care Network" },
  { value: "Preferred Care Partners", label: "Preferred Care Partners" },
  { value: "PreferredOne", label: "PreferredOne" },
  { value: "Premera Blue Cross", label: "Premera Blue Cross" },
  { value: "Premier Health Plan", label: "Premier Health Plan" },
  {
    value: "Presbyterian Health Plan/Presbyterian Insurance Company",
    label: "Presbyterian Health Plan/Presbyterian Insurance Company",
  },
  { value: "Prestige Health Choice", label: "Prestige Health Choice" },
  {
    value: "Primary Care Case Management (North Dakota Medicaid)",
    label: "Primary Care Case Management (North Dakota Medicaid)",
  },
  { value: "Prime Health Services, Inc", label: "Prime Health Services, Inc" },
  { value: "Prime Healthcare", label: "Prime Healthcare" },
  { value: "PrimeWest Health", label: "PrimeWest Health" },
  { value: "Principal Financial Group", label: "Principal Financial Group" },
  { value: "Priority Health", label: "Priority Health" },
  { value: "Priority Partners", label: "Priority Partners" },
  { value: "ProCare Advantage", label: "ProCare Advantage" },
  { value: "Progressive", label: "Progressive" },
  { value: "Prominence Health Plan", label: "Prominence Health Plan" },
  { value: "ProviDRs Care (WPPA)", label: "ProviDRs Care (WPPA)" },
  { value: "Providence Health Plans", label: "Providence Health Plans" },
  {
    value: "Provider Partners Health Plan",
    label: "Provider Partners Health Plan",
  },
  {
    value: "Public Aid (Illinois Medicaid)",
    label: "Public Aid (Illinois Medicaid)",
  },
  {
    value: "Public Employees Health Program (PEHP)",
    label: "Public Employees Health Program (PEHP)",
  },
  {
    value: "Puget Sound Electrical Workers Trusts",
    label: "Puget Sound Electrical Workers Trusts",
  },
  { value: "Q", label: "Q" },
  { value: "QualCare", label: "QualCare" },
  { value: "QualChoice Arkansas", label: "QualChoice Arkansas" },
  {
    value: "Quality Health Plans of New York",
    label: "Quality Health Plans of New York",
  },
  { value: "Quartz", label: "Quartz" },
  { value: "Quest Behavioral Health", label: "Quest Behavioral Health" },
  { value: "Quiktrip", label: "Quiktrip" },
  { value: "R", label: "R" },
  { value: "RLI Indemnity Company", label: "RLI Indemnity Company" },
  {
    value: "Regence Blue Cross Blue Shield",
    label: "Regence Blue Cross Blue Shield",
  },
  {
    value: "Regence Blue Shield of Washington",
    label: "Regence Blue Shield of Washington",
  },
  { value: "Regent Insurance", label: "Regent Insurance" },
  {
    value: "Reliance Medicare Advantage",
    label: "Reliance Medicare Advantage",
  },
  { value: "Renaissance", label: "Renaissance" },
  {
    value: "Republic-Franklin Insurance",
    label: "Republic-Franklin Insurance",
  },
  { value: "RiverLink Health", label: "RiverLink Health" },
  { value: "RiverSpring Health Plans", label: "RiverSpring Health Plans" },
  { value: "RiverSpring at Home", label: "RiverSpring at Home" },
  { value: "Riverside Health", label: "Riverside Health" },
  { value: "Rockefeller Health Plan", label: "Rockefeller Health Plan" },
  {
    value: "Rocky Mountain Health Plans",
    label: "Rocky Mountain Health Plans",
  },
  { value: "S", label: "S" },
  { value: "SAG AFTRA Health Plan", label: "SAG AFTRA Health Plan" },
  { value: "SAMBA", label: "SAMBA" },
  { value: "SCAN Health Plan", label: "SCAN Health Plan" },
  { value: "SIHO Insurance Services", label: "SIHO Insurance Services" },
  { value: "SSM Health Care", label: "SSM Health Care" },
  { value: "Sagamore Health Network", label: "Sagamore Health Network" },
  {
    value: "Samaritan Health Plan Operations",
    label: "Samaritan Health Plan Operations",
  },
  { value: "San Francisco Health Plan", label: "San Francisco Health Plan" },
  { value: "Sana", label: "Sana" },
  { value: "Sanford Health Plan", label: "Sanford Health Plan" },
  {
    value: "Santa Clara Family Health Plan",
    label: "Santa Clara Family Health Plan",
  },
  { value: "Scott & White Health Plan", label: "Scott & White Health Plan" },
  {
    value: "Security Health Plan of Wisconsin, Inc.",
    label: "Security Health Plan of Wisconsin, Inc.",
  },
  { value: "Select Care", label: "Select Care" },
  { value: "Select Health Network", label: "Select Health Network" },
  {
    value: "Select Health of South Carolina",
    label: "Select Health of South Carolina",
  },
  { value: "SelectHealth", label: "SelectHealth" },
  { value: "Sendero Health Plans", label: "Sendero Health Plans" },
  { value: "Seneca Insurance", label: "Seneca Insurance" },
  { value: "Senior Dimensions", label: "Senior Dimensions" },
  { value: "Senior Whole Health", label: "Senior Whole Health" },
  { value: "Sentry Insurance", label: "Sentry Insurance" },
  { value: "Seton Health Plan", label: "Seton Health Plan" },
  { value: "Sharp Health Plan", label: "Sharp Health Plan" },
  { value: "Sierra Health and Life", label: "Sierra Health and Life" },
  { value: "SightCare", label: "SightCare" },
  { value: "SilverSummit Healthplan", label: "SilverSummit Healthplan" },
  { value: "Simply Healthcare", label: "Simply Healthcare" },
  { value: "Simpra Advantage", label: "Simpra Advantage" },
  { value: "Solis Health Plans", label: "Solis Health Plans" },
  { value: "Solstice", label: "Solstice" },
  {
    value: "SoonerCare (Oklahoma Medicaid)",
    label: "SoonerCare (Oklahoma Medicaid)",
  },
  { value: "Soundpath Health", label: "Soundpath Health" },
  {
    value: "South Country Health Alliance",
    label: "South Country Health Alliance",
  },
  {
    value: "South Florida Community Care Network",
    label: "South Florida Community Care Network",
  },
  {
    value: "Southwestern Health Resources (SWHR)",
    label: "Southwestern Health Resources (SWHR)",
  },
  { value: "Spectera", label: "Spectera" },
  {
    value: "Standard Life and Accident Insurance Company",
    label: "Standard Life and Accident Insurance Company",
  },
  {
    value: "Stanford Health Care Advantage",
    label: "Stanford Health Care Advantage",
  },
  { value: "StarNet Insurance", label: "StarNet Insurance" },
  { value: "State Farm®", label: "State Farm®" },
  { value: "State National Insurance", label: "State National Insurance" },
  { value: "Staywell Insurance", label: "Staywell Insurance" },
  {
    value: "Steward Health Care Network - Health Choice Arizona",
    label: "Steward Health Care Network - Health Choice Arizona",
  },
  { value: "Steward Health Choice", label: "Steward Health Choice" },
  { value: "Stratose", label: "Stratose" },
  { value: "SummaCare", label: "SummaCare" },
  { value: "Summit Community Care", label: "Summit Community Care" },
  { value: "Sunflower Health Plan", label: "Sunflower Health Plan" },
  { value: "Sunrise Advantage Plan", label: "Sunrise Advantage Plan" },
  { value: "Sunshine Health", label: "Sunshine Health" },
  { value: "Superior HealthPlan", label: "Superior HealthPlan" },
  { value: "Superior Vision", label: "Superior Vision" },
  { value: "Sussex Insurance", label: "Sussex Insurance" },
  { value: "Sutter Health Plus", label: "Sutter Health Plus" },
  { value: "SutterSelect", label: "SutterSelect" },
  { value: "T", label: "T" },
  { value: "TNUS Insurance", label: "TNUS Insurance" },
  { value: "TakeCare", label: "TakeCare" },
  { value: "Teachers Health Trust", label: "Teachers Health Trust" },
  { value: "TexanPlus", label: "TexanPlus" },
  {
    value: "Texas Children's Health Plan",
    label: "Texas Children's Health Plan",
  },
  { value: "Texas Health Aetna", label: "Texas Health Aetna" },
  {
    value: "Texas Independence Health Plan (TIHP)",
    label: "Texas Independence Health Plan (TIHP)",
  },
  { value: "Texas Kids First", label: "Texas Kids First" },
  { value: "The HSC Health Care System", label: "The HSC Health Care System" },
  { value: "The Hartford", label: "The Hartford" },
  {
    value: "The Health Plan of the Upper Ohio Valley, Inc.",
    label: "The Health Plan of the Upper Ohio Valley, Inc.",
  },
  {
    value: "Three Rivers Providers Network (TRPN)",
    label: "Three Rivers Providers Network (TRPN)",
  },
  { value: "Total Health Care", label: "Total Health Care" },
  { value: "Touchstone", label: "Touchstone" },
  { value: "Transamerica", label: "Transamerica" },
  { value: "Travelers", label: "Travelers" },
  { value: "Tricare", label: "Tricare" },
  {
    value: "Trillium Community Health Plan",
    label: "Trillium Community Health Plan",
  },
  { value: "Trilogy Health Insurance", label: "Trilogy Health Insurance" },
  {
    value: "Triple-S Salud: Blue Cross Blue Shield of Puerto Rico",
    label: "Triple-S Salud: Blue Cross Blue Shield of Puerto Rico",
  },
  {
    value: "Triwest Healthcare Alliance",
    label: "Triwest Healthcare Alliance",
  },
  { value: "TrueHealth New Mexico", label: "TrueHealth New Mexico" },
  { value: "Trusted Health Plan", label: "Trusted Health Plan" },
  { value: "Tuality Health Alliance", label: "Tuality Health Alliance" },
  { value: "Tufts Health Freedom Plan", label: "Tufts Health Freedom Plan" },
  { value: "Tufts Health Plan", label: "Tufts Health Plan" },
  { value: "U", label: "U" },
  {
    value: "UCHP (University of Chicago Health Plan)",
    label: "UCHP (University of Chicago Health Plan)",
  },
  { value: "UCare", label: "UCare" },
  { value: "UHA Health Insurance", label: "UHA Health Insurance" },
  { value: "ULLICO Casualty Company", label: "ULLICO Casualty Company" },
  { value: "UPMC Health Plan", label: "UPMC Health Plan" },
  { value: "US Family Health Plan", label: "US Family Health Plan" },
  { value: "US Health Group", label: "US Health Group" },
  {
    value: "USA Managed Care Organization",
    label: "USA Managed Care Organization",
  },
  {
    value: "USAble Mutual Insurance Company",
    label: "USAble Mutual Insurance Company",
  },
  { value: "Ultimate Health Plans", label: "Ultimate Health Plans" },
  { value: "Umpqua Health Alliance", label: "Umpqua Health Alliance" },
  { value: "UniCare", label: "UniCare" },
  { value: "Uniform Medical Plan", label: "Uniform Medical Plan" },
  { value: "Union Eye Care", label: "Union Eye Care" },
  { value: "Union Health Services, Inc", label: "Union Health Services, Inc" },
  { value: "Union Plans", label: "Union Plans" },
  { value: "United American", label: "United American" },
  { value: "United Behavioral Health", label: "United Behavioral Health" },
  { value: "UnitedHealthOne", label: "UnitedHealthOne" },
  { value: "UnitedHealthcare", label: "UnitedHealthcare" },
  {
    value: "UnitedHealthcare Community Plan",
    label: "UnitedHealthcare Community Plan",
  },
  { value: "UnitedHealthcare Oxford", label: "UnitedHealthcare Oxford" },
  { value: "Unity Health Insurance", label: "Unity Health Insurance" },
  { value: "Univera Healthcare", label: "Univera Healthcare" },
  { value: "Universal American", label: "Universal American" },
  {
    value: "Universal Underwriters Insurance",
    label: "Universal Underwriters Insurance",
  },
  {
    value: "University Hospitals (Health Design Plus)",
    label: "University Hospitals (Health Design Plus)",
  },
  {
    value: "University Physician Network (UPN)",
    label: "University Physician Network (UPN)",
  },
  {
    value: "University of Arizona Health Plans",
    label: "University of Arizona Health Plans",
  },
  {
    value: "University of Maryland Health Advantage",
    label: "University of Maryland Health Advantage",
  },
  {
    value: "University of Maryland Health Partners",
    label: "University of Maryland Health Partners",
  },
  {
    value: "University of St. Mary of the Lake - Mundelein Seminary",
    label: "University of St. Mary of the Lake - Mundelein Seminary",
  },
  {
    value: "University of Utah Health Plans",
    label: "University of Utah Health Plans",
  },
  {
    value: "Upper Peninsula Health Plan",
    label: "Upper Peninsula Health Plan",
  },
  { value: "V", label: "V" },
  { value: "VNS Choice Health Plans", label: "VNS Choice Health Plans" },
  { value: "VSP", label: "VSP" },
  { value: "Valley Forge Insurance", label: "Valley Forge Insurance" },
  { value: "Valley Health Plan", label: "Valley Health Plan" },
  { value: "Valor Health Plan", label: "Valor Health Plan" },
  { value: "Vantage Health Plan, Inc.", label: "Vantage Health Plan, Inc." },
  {
    value: "Ventura County Health Care Plan",
    label: "Ventura County Health Care Plan",
  },
  { value: "Vibra Health Plan", label: "Vibra Health Plan" },
  { value: "VillageCareMax", label: "VillageCareMax" },
  {
    value: "Virginia Coordinated Care (VCC)",
    label: "Virginia Coordinated Care (VCC)",
  },
  { value: "Virginia Health Network", label: "Virginia Health Network" },
  {
    value: "Virginia Premier Health Plan",
    label: "Virginia Premier Health Plan",
  },
  { value: "Vision Benefits of America", label: "Vision Benefits of America" },
  { value: "Vision Care Direct", label: "Vision Care Direct" },
  { value: "Vision Plan of America", label: "Vision Plan of America" },
  { value: "Viva Health Plan", label: "Viva Health Plan" },
  { value: "Vivida Health", label: "Vivida Health" },
  { value: "Volusia Health Network", label: "Volusia Health Network" },
  { value: "Vytra", label: "Vytra" },
  { value: "W", label: "W" },
  { value: "WEA Trust", label: "WEA Trust" },
  { value: "WPS Health Plan", label: "WPS Health Plan" },
  {
    value: "WRM America Indemnity Company",
    label: "WRM America Indemnity Company",
  },
  { value: "Well Sense Health Plan", label: "Well Sense Health Plan" },
  { value: "WellFirst Health", label: "WellFirst Health" },
  { value: "Wellcare", label: "Wellcare" },
  {
    value: "Wellmark Blue Cross Blue Shield",
    label: "Wellmark Blue Cross Blue Shield",
  },
  { value: "West American Insurance", label: "West American Insurance" },
  {
    value: "West Virginia Senior Advantage",
    label: "West Virginia Senior Advantage",
  },
  { value: "Western Health Advantage", label: "Western Health Advantage" },
  { value: "Western Sky Community Care", label: "Western Sky Community Care" },
  {
    value: "Willamette Valley Community Health",
    label: "Willamette Valley Community Health",
  },
  { value: "Workers' Compensation", label: "Workers' Compensation" },
  { value: "Y", label: "Y" },
  {
    value: "Yamhill Community Care Organization",
    label: "Yamhill Community Care Organization",
  },
  { value: "Z", label: "Z" },
  { value: "Zenith", label: "Zenith" },
  { value: "Zing Health", label: "Zing Health" },
];

export const UNDERGRADUATE_DEGREES = [
  { value: "BAS", label: "BAS" },
  { value: "BA", label: "BA" },
  { value: "BArch", label: "BArch" },
  { value: "BBmE", label: "BBmE" },
  { value: "BB/BBA", label: "BB/BBA" },
  { value: "BCL", label: "BCL" },
  { value: "BCE", label: "BCE" },
  { value: "BCompE", label: "BCompE" },
  { value: "BCS", label: "BCS" },
  { value: "BCJ", label: "BCJ" },
  { value: "BDSc", label: "BDSc" },
  { value: "BD", label: "BD" },
  { value: "BEE", label: "BEE" },
  { value: "BE/BEng", label: "BE/BEng" },
  { value: "BET", label: "BET" },
  { value: "BES", label: "BES" },
  { value: "BFE", label: "BFE" },
  { value: "BFA", label: "BFA" },
  { value: "BF", label: "BF" },
  { value: "BHA", label: "BHA" },
  { value: "BHL", label: "BHL" },
  { value: "BIS", label: "BIS" },
  { value: "BJ", label: "BJ" },
  { value: "LLB", label: "LLB" },
  { value: "BLS", label: "BLS" },
  { value: "BLit", label: "BLit" },
  { value: "BMS", label: "BMS" },
  { value: "BME", label: "BME" },
  { value: "Bmin", label: "Bmin" },
  { value: "BM", label: "BM" },
  { value: "BN/BSN", label: "BN/BSN" },
  { value: "BOTh", label: "BOTh" },
  { value: "BPharm", label: "BPharm" },
  { value: "BPhil", label: "BPhil" },
  { value: "BRE", label: "BRE" },
  { value: "BS", label: "BS" },
  { value: "BSAE", label: "BSAE" },
  { value: "BSAE", label: "BSAE" },
  { value: "BSBE", label: "BSBE" },
  { value: "BSBS", label: "BSBS" },
  { value: "BSBAE", label: "BSBAE" },
  { value: "BSB", label: "BSB" },
  { value: "BSChBE", label: "BSChBE" },
  { value: "BSCME", label: "BSCME" },
  { value: "BSChE", label: "BSChE" },
  { value: "BSCh", label: "BSCh" },
  { value: "BS-CIE", label: "BS-CIE" },
  { value: "BSCE", label: "BSCE" },
  { value: "BSCET/BSCivET", label: "BSCET/BSCivET" },
  {
    value: "BSCE/BSCoE/BSCpE/BSCmpE/BSCP",
    label: "BSCE/BSCoE/BSCpE/BSCmpE/BSCP",
  },
  { value: "BSCET", label: "BSCET" },
  { value: "BSCS", label: "BSCS" },
  { value: "BSCSE", label: "BSCSE" },
  { value: "BSConET", label: "BSConET" },
  { value: "BSCJ", label: "BSCJ" },
  { value: "BSDDT", label: "BSDDT" },
  { value: "BEd/BSEd", label: "BEd/BSEd" },
  { value: "BSECE", label: "BSECE" },
  { value: "BSEE", label: "BSEE" },
  { value: "BSEET", label: "BSEET" },
  { value: "BSET", label: "BSET" },
  { value: "BSEMET", label: "BSEMET" },
  { value: "BSE/BSEN", label: "BSE/BSEN" },
  { value: "BSEMgt", label: "BSEMgt" },
  { value: "BSET", label: "BSET" },
  { value: "BSEnE/BSEnvE", label: "BSEnE/BSEnvE" },
  { value: "BSForRes", label: "BSForRes" },
  { value: "BSIE", label: "BSIE" },
  { value: "BSMfgE", label: "BSMfgE" },
  { value: "BSMSE", label: "BSMSE" },
  { value: "BSMAE", label: "BSMAE" },
  { value: "BSMSE", label: "BSMSE" },
  { value: "BSME", label: "BSME" },
  { value: "BSMET", label: "BSMET" },
  { value: "BSMtE", label: "BSMtE" },
  { value: "BSMIE", label: "BSMIE" },
  { value: "BSSE", label: "BSSE" },
  { value: "BS-SYST", label: "BS-SYST" },
  { value: "BSSE", label: "BSSE" },
  { value: "BS, SB", label: "BS, SB" },
  { value: "BSW", label: "BSW" },
  { value: "BSWE", label: "BSWE" },
  { value: "BSE", label: "BSE" },
  { value: "BT/BTech", label: "BT/BTech" },
  { value: "BTh", label: "BTh" },
  { value: "BWE", label: "BWE" },
];

const currYear = new Date().getFullYear();
export const YEAR_OPTIONS = Array.from(
  new Array(currYear - 1959),
  (val, index) => ({
    value: 10 + currYear - index,
    label: 10 + currYear - index,
  }),
);

export const LICENSE_EXPIRATION_YEAR_OPTIONS = Array.from(
  new Array(20),
  (val, index) => ({ value: currYear + index, label: currYear + index }),
);

export const LICENSE_EXPIRATION_MONTH_OPTIONS = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const MEMBER_BOARD_OPTIONS_FREQUENTLY_USED = [
  {
    value: "American Board of Psychiatry and Neurology",
    label: "American Board of Psychiatry and Neurology",
  },
  {
    value: "American Osteopathic Board of Neurology and Psychiatry",
    label: "American Osteopathic Board of Neurology and Psychiatry",
  },
  {
    value: "American Board of Physical Medicine and Rehabilitation",
    label: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "American Board of Pain Medicine",
    label: "American Board of Pain Medicine",
  },
  {
    value: "American Board of Anesthesiology",
    label: "American Board of Anesthesiology",
  },
  {
    value: "American Board of Preventive Medicine",
    label: "American Board of Preventive Medicine",
  },
];

export const MEMBER_BOARD_OPTIONS_PHYSICIAN_GENERAL = [
  {
    value: "American Board of Allergy and Immunology",
    label: "American Board of Allergy and Immunology",
  },
  {
    value: "American Board of Colon and Rectal Surgery",
    label: "American Board of Colon and Rectal Surgery",
  },
  {
    value: "American Board of Dermatology",
    label: "American Board of Dermatology",
  },
  {
    value: "American Board of Emergency Medicine",
    label: "American Board of Emergency Medicine",
  },
  {
    value: "American Board of Family Medicine",
    label: "American Board of Family Medicine",
  },
  {
    value: "American Board of Internal Medicine",
    label: "American Board of Internal Medicine",
  },
  {
    value: "American Board of Medical Genetics and Genomics",
    label: "American Board of Medical Genetics and Genomics",
  },
  {
    value: "American Board of Neurological Surgery",
    label: "American Board of Neurological Surgery",
  },
  {
    value: "American Board of Nuclear Medicine",
    label: "American Board of Nuclear Medicine",
  },
  {
    value: "American Board of Obstetrics and Gynecology",
    label: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "American Board of Ophthalmology",
    label: "American Board of Ophthalmology",
  },
  {
    value: "American Board of Orthopaedic Surgery",
    label: "American Board of Orthopaedic Surgery",
  },
  {
    value: "American Board of Otolaryngology - Head and Neck Surgery",
    label: "American Board of Otolaryngology - Head and Neck Surgery",
  },
  {
    value: "American Board of Pathology",
    label: "American Board of Pathology",
  },
  {
    value: "American Board of Pediatrics",
    label: "American Board of Pediatrics",
  },
  {
    value: "American Board of Plastic Surgery",
    label: "American Board of Plastic Surgery",
  },
  {
    value: "American Board of Radiology",
    label: "American Board of Radiology",
  },
  { value: "American Board of Surgery", label: "American Board of Surgery" },
  {
    value: "American Board of Thoracic Surgery",
    label: "American Board of Thoracic Surgery",
  },
  { value: "American Board of Urology", label: "American Board of Urology" },
];

export const MEMBER_BOARD_OPTIONS_PHYSICIAN = [
  { groupId: 0, options: MEMBER_BOARD_OPTIONS_FREQUENTLY_USED },
  { groupId: 1, options: MEMBER_BOARD_OPTIONS_PHYSICIAN_GENERAL },
];

export const MEMBER_BOARD_OPTIONS_PHYSICIAN_FLATTEN =
  MEMBER_BOARD_OPTIONS_PHYSICIAN.slice()
    .map(({ options }) => options)
    .flat();

export const MEMBER_BOARD_OPTIONS_PSYCHOLOGIST = [
  {
    value: "American Board of Professional Psychology",
    label: "American Board of Professional Psychology",
  },
];

export const MEMBER_BOARD_OPTIONS_SOCIAL_WORKER = [
  {
    value: "American Board of Professional Clinical Social Work (ABCSW)",
    label: "American Board of Professional Clinical Social Work (ABCSW)",
  },
];

export const MEMBER_BOARD_OPTIONS_THERAPIST = [
  {
    value: "National Certified Counselor (NCC)",
    label: "National Certified Counselor (NCC)",
  },
];

export const SPECIALTY_BY_MEMBER_BOARD = [
  {
    value: "Pain Medicine",
    label: "Pain Medicine",
    board: "American Board of Pain Medicine",
  },
  {
    value: "Allergy and Immunology",
    label: "Allergy and Immunology",
    board: "American Board of Allergy and Immunology",
  },
  {
    value: "Anesthesiology",
    label: "Anesthesiology",
    board: "American Board of Anesthesiology",
  },
  {
    value: "Colon and Rectal Surgery",
    label: "Colon and Rectal Surgery",
    board: "American Board of Colon and Rectal Surgery",
  },
  {
    value: "Dermatology",
    label: "Dermatology",
    board: "American Board of Dermatology",
  },
  {
    value: "Emergency Medicine",
    label: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Family Medicine",
    label: "Family Medicine",
    board: "American Board of Family Medicine",
  },
  {
    value: "Internal Medicine",
    label: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Clinical Biochemical Genetics",
    label: "Clinical Biochemical Genetics",
    board: "American Board of Medical Genetics and Genomics",
  },
  {
    value: "Clinical Genetics and Genomics (MD)",
    label: "Clinical Genetics and Genomics (MD)",
    board: "American Board of Medical Genetics and Genomics",
  },
  {
    value: "Laboratory Genetics and Genomics",
    label: "Laboratory Genetics and Genomics",
    board: "American Board of Medical Genetics and Genomics",
  },
  {
    value: "Neurological Surgery",
    label: "Neurological Surgery",
    board: "American Board of Neurological Surgery",
  },
  {
    value: "Nuclear Medicine",
    label: "Nuclear Medicine",
    board: "American Board of Nuclear Medicine",
  },
  {
    value: "Obstetrics and Gynecology",
    label: "Obstetrics and Gynecology",
    board: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "Ophthalmology",
    label: "Ophthalmology",
    board: "American Board of Ophthalmology",
  },
  {
    value: "Orthopaedic Surgery",
    label: "Orthopaedic Surgery",
    board: "American Board of Orthopaedic Surgery",
  },
  {
    value: "Otolaryngology - Head and Neck Surgery",
    label: "Otolaryngology - Head and Neck Surgery",
    board: "American Board of Otolaryngology - Head and Neck Surgery",
  },
  {
    value: "Pathology - Anatomic/Pathology - Clinical",
    label: "Pathology - Anatomic/Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Pathology - Anatomic",
    label: "Pathology - Anatomic",
    board: "American Board of Pathology",
  },
  {
    value: "Pathology - Clinical",
    label: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Pediatrics",
    label: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Physical Medicine and Rehabilitation",
    label: "Physical Medicine and Rehabilitation",
    board: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "Plastic Surgery",
    label: "Plastic Surgery",
    board: "American Board of Plastic Surgery",
  },
  {
    value: "Aerospace Medicine",
    label: "Aerospace Medicine",
    board: "American Board of Preventive Medicine",
  },
  {
    value: "Occupational Medicine",
    label: "Occupational Medicine",
    board: "American Board of Preventive Medicine",
  },
  {
    value: "Public Health and General Preventive Medicine",
    label: "Public Health and General Preventive Medicine",
    board: "American Board of Preventive Medicine",
  },
  {
    value: "Psychiatry",
    label: "Psychiatry",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Neurology",
    label: "Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Neurology with Special Qualification in Child Neurology",
    label: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Diagnostic Radiology",
    label: "Diagnostic Radiology",
    board: "American Board of Radiology",
  },
  {
    value: "Interventional Radiology and Diagnostic Radiology",
    label: "Interventional Radiology and Diagnostic Radiology",
    board: "American Board of Radiology",
  },
  {
    value: "Medical Physics (Diagnostic, Nuclear, Therapeutic)",
    label: "Medical Physics (Diagnostic, Nuclear, Therapeutic)",
    board: "American Board of Radiology",
  },
  {
    value: "Radiation Oncology",
    label: "Radiation Oncology",
    board: "American Board of Radiology",
  },
  {
    value: "General Surgery",
    label: "General Surgery",
    board: "American Board of Surgery",
  },
  {
    value: "Vascular Surgery",
    label: "Vascular Surgery",
    board: "American Board of Surgery",
  },
  {
    value: "Thoracic and Cardiac Surgery",
    label: "Thoracic and Cardiac Surgery",
    board: "American Board of Thoracic Surgery",
  },
  { value: "Urology", label: "Urology", board: "American Board of Urology" },
];

export const SUBSPECIALITY_BY_MEMBER_BOARD = [
  // {
  //   value: "Adult Cardiac Anesthesiology*",
  //   label: "Adult Cardiac Anesthesiology*",
  //   specialty: "Anesthesiology",
  //   board: "American Board of Anesthesiology",
  // },
  {
    value: "Critical Care Medicine",
    label: "Critical Care Medicine",
    specialty: "Anesthesiology",
    board: "American Board of Anesthesiology",
  },
  {
    value: "Hospice and Palliative Medicine",
    label: "Hospice and Palliative Medicine",
    specialty: "Anesthesiology",
    board: "American Board of Anesthesiology",
  },
  // {
  //   value: "Neurocritical Care",
  //   label: "Neurocritical Care",
  //   specialty: "Anesthesiology",
  //   board: "American Board of Anesthesiology",
  // },
  {
    value: "Pain Medicine",
    label: "Pain Medicine",
    specialty: "Anesthesiology",
    board: "American Board of Anesthesiology",
  },
  {
    value: "Pediatric Anesthesiology",
    label: "Pediatric Anesthesiology",
    specialty: "Anesthesiology",
    board: "American Board of Anesthesiology",
  },
  {
    value: "Sleep Medicine",
    label: "Sleep Medicine",
    specialty: "Anesthesiology",
    board: "American Board of Anesthesiology",
  },
  {
    value: "Dermatopathology",
    label: "Dermatopathology",
    specialty: "Dermatology",
    board: "American Board of Dermatology",
  },
  {
    value: "Micrographic Dermatologic Surgery",
    label: "Micrographic Dermatologic Surgery",
    specialty: "Dermatology",
    board: "American Board of Dermatology",
  },
  {
    value: "Pediatric Dermatology",
    label: "Pediatric Dermatology",
    specialty: "Dermatology",
    board: "American Board of Dermatology",
  },
  {
    value: "Anesthesiology Critical Care Medicine",
    label: "Anesthesiology Critical Care Medicine",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Emergency Medical Services",
    label: "Emergency Medical Services",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Hospice and Palliative Medicine",
    label: "Hospice and Palliative Medicine",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Internal Medicine-Critical Care Medicine",
    label: "Internal Medicine-Critical Care Medicine",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Medical Toxicology",
    label: "Medical Toxicology",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Neurocritical Care",
    label: "Neurocritical Care",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Pain Medicine",
    label: "Pain Medicine",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Pediatric Emergency Medicine",
    label: "Pediatric Emergency Medicine",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Sports Medicine",
    label: "Sports Medicine",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Undersea and Hyperbaric Medicine",
    label: "Undersea and Hyperbaric Medicine",
    specialty: "Emergency Medicine",
    board: "American Board of Emergency Medicine",
  },
  {
    value: "Adolescent Medicine",
    label: "Adolescent Medicine",
    specialty: "Family Medicine",
    board: "American Board of Family Medicine",
  },
  {
    value: "Geriatric Medicine",
    label: "Geriatric Medicine",
    specialty: "Family Medicine",
    board: "American Board of Family Medicine",
  },
  {
    value: "Hospice and Palliative Medicine",
    label: "Hospice and Palliative Medicine",
    specialty: "Family Medicine",
    board: "American Board of Family Medicine",
  },
  {
    value: "Pain Medicine",
    label: "Pain Medicine",
    specialty: "Family Medicine",
    board: "American Board of Family Medicine",
  },
  {
    value: "Sleep Medicine",
    label: "Sleep Medicine",
    specialty: "Family Medicine",
    board: "American Board of Family Medicine",
  },
  {
    value: "Sports Medicine",
    label: "Sports Medicine",
    specialty: "Family Medicine",
    board: "American Board of Family Medicine",
  },
  {
    value: "Adolescent Medicine",
    label: "Adolescent Medicine",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Adult Congenital Heart Disease",
    label: "Adult Congenital Heart Disease",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Advanced Heart Failure and Transplant Cardiology",
    label: "Advanced Heart Failure and Transplant Cardiology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Cardiovascular Disease",
    label: "Cardiovascular Disease",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Clinical Cardiac Electrophysiology",
    label: "Clinical Cardiac Electrophysiology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Critical Care Medicine",
    label: "Critical Care Medicine",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Endocrinology, Diabetes and Metabolism",
    label: "Endocrinology, Diabetes and Metabolism",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Gastroenterology",
    label: "Gastroenterology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Geriatric Medicine",
    label: "Geriatric Medicine",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Hematology",
    label: "Hematology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Hospice and Palliative Medicine",
    label: "Hospice and Palliative Medicine",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Infectious Disease",
    label: "Infectious Disease",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Interventional Cardiology",
    label: "Interventional Cardiology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Medical Oncology",
    label: "Medical Oncology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Nephrology",
    label: "Nephrology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Neurocritical Care",
    label: "Neurocritical Care",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Pulmonary Disease",
    label: "Pulmonary Disease",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Rheumatology",
    label: "Rheumatology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Sleep Medicine",
    label: "Sleep Medicine",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Sports Medicine",
    label: "Sports Medicine",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Transplant Hepatology",
    label: "Transplant Hepatology",
    specialty: "Internal Medicine",
    board: "American Board of Internal Medicine",
  },
  {
    value: "Medical Biochemical Genetics",
    label: "Medical Biochemical Genetics",
    specialty: "Clinical Biochemical Genetics",
    board: "American Board of Medical Genetics and Genomics",
  },
  {
    value: "Molecular Genetic Pathology",
    label: "Molecular Genetic Pathology",
    specialty: "Clinical Genetics and Genomics (MD)",
    board: "American Board of Medical Genetics and Genomics",
  },
  {
    value: "Neurocritical Care*",
    label: "Neurocritical Care*",
    specialty: "Neurological Surgery",
    board: "American Board of Neurological Surgery",
  },
  {
    value: "Complex Family Planning*",
    label: "Complex Family Planning*",
    specialty: "Obstetrics and Gynecology",
    board: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "Critical Care Medicine",
    label: "Critical Care Medicine",
    specialty: "Obstetrics and Gynecology",
    board: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "Female Pelvic Medicine and Reconstructive Surgery",
    label: "Female Pelvic Medicine and Reconstructive Surgery",
    specialty: "Obstetrics and Gynecology",
    board: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "Gynecologic Oncology",
    label: "Gynecologic Oncology",
    specialty: "Obstetrics and Gynecology",
    board: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "Maternal-Fetal Medicine",
    label: "Maternal-Fetal Medicine",
    specialty: "Obstetrics and Gynecology",
    board: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "Reproductive Endocrinology and Infertility",
    label: "Reproductive Endocrinology and Infertility",
    specialty: "Obstetrics and Gynecology",
    board: "American Board of Obstetrics and Gynecology",
  },
  {
    value: "Orthopaedic Sports Medicine",
    label: "Orthopaedic Sports Medicine",
    specialty: "Orthopaedic Surgery",
    board: "American Board of Orthopaedic Surgery",
  },
  {
    value: "Surgery of the Hand",
    label: "Surgery of the Hand",
    specialty: "Orthopaedic Surgery",
    board: "American Board of Orthopaedic Surgery",
  },
  {
    value: "Complex Pediatric Otolaryngology",
    label: "Complex Pediatric Otolaryngology",
    specialty: "Otolaryngology - Head and Neck Surgery",
    board: "American Board of Otolaryngology - Head and Neck Surgery",
  },
  {
    value: "Neurotology",
    label: "Neurotology",
    specialty: "Otolaryngology - Head and Neck Surgery",
    board: "American Board of Otolaryngology - Head and Neck Surgery",
  },
  {
    value: "Plastic Surgery Within the Head and Neck*",
    label: "Plastic Surgery Within the Head and Neck*",
    specialty: "Otolaryngology - Head and Neck Surgery",
    board: "American Board of Otolaryngology - Head and Neck Surgery",
  },
  {
    value: "Sleep Medicine",
    label: "Sleep Medicine",
    specialty: "Otolaryngology - Head and Neck Surgery",
    board: "American Board of Otolaryngology - Head and Neck Surgery",
  },
  {
    value: "Blood Banking/Transfusion Medicine",
    label: "Blood Banking/Transfusion Medicine",
    specialty: "Pathology - Anatomic/Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Clinical Informatics",
    label: "Clinical Informatics",
    specialty: "Pathology - Anatomic",
    board: "American Board of Pathology",
  },
  {
    value: "Cytopathology",
    label: "Cytopathology",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Dermatopathology",
    label: "Dermatopathology",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Hematopathology",
    label: "Hematopathology",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Neuropathology",
    label: "Neuropathology",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Pathology - Chemical",
    label: "Pathology - Chemical",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Pathology - Forensic",
    label: "Pathology - Forensic",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Pathology - Medical Microbiology",
    label: "Pathology - Medical Microbiology",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Pathology - Molecular Genetic",
    label: "Pathology - Molecular Genetic",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Pathology - Pediatric",
    label: "Pathology - Pediatric",
    specialty: "Pathology - Clinical",
    board: "American Board of Pathology",
  },
  {
    value: "Adolescent Medicine",
    label: "Adolescent Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Child Abuse Pediatrics",
    label: "Child Abuse Pediatrics",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Developmental-Behavioral Pediatrics",
    label: "Developmental-Behavioral Pediatrics",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Hospice and Palliative Medicine",
    label: "Hospice and Palliative Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Medical Toxicology",
    label: "Medical Toxicology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Neonatal-Perinatal Medicine",
    label: "Neonatal-Perinatal Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Cardiology",
    label: "Pediatric Cardiology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Critical Care Medicine",
    label: "Pediatric Critical Care Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Emergency Medicine",
    label: "Pediatric Emergency Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Endocrinology",
    label: "Pediatric Endocrinology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Gastroenterology",
    label: "Pediatric Gastroenterology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Hematology-Oncology",
    label: "Pediatric Hematology-Oncology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Hospital Medicine",
    label: "Pediatric Hospital Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Infectious Diseases",
    label: "Pediatric Infectious Diseases",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Nephrology",
    label: "Pediatric Nephrology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Pulmonology",
    label: "Pediatric Pulmonology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Rheumatology",
    label: "Pediatric Rheumatology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Pediatric Transplant Hepatology",
    label: "Pediatric Transplant Hepatology",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Sleep Medicine",
    label: "Sleep Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Sports Medicine",
    label: "Sports Medicine",
    specialty: "Pediatrics",
    board: "American Board of Pediatrics",
  },
  {
    value: "Brain Injury Medicine",
    label: "Brain Injury Medicine",
    specialty: "Physical Medicine and Rehabilitation",
    board: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "Neuromuscular Medicine",
    label: "Neuromuscular Medicine",
    specialty: "Physical Medicine and Rehabilitation",
    board: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "Pain Medicine",
    label: "Pain Medicine",
    specialty: "Physical Medicine and Rehabilitation",
    board: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "Pediatric Rehabilitation Medicine",
    label: "Pediatric Rehabilitation Medicine",
    specialty: "Physical Medicine and Rehabilitation",
    board: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "Spinal Cord Injury Medicine",
    label: "Spinal Cord Injury Medicine",
    specialty: "Physical Medicine and Rehabilitation",
    board: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "Sports Medicine",
    label: "Sports Medicine",
    specialty: "Physical Medicine and Rehabilitation",
    board: "American Board of Physical Medicine and Rehabilitation",
  },
  {
    value: "Plastic Surgery Within the Head and Neck*",
    label: "Plastic Surgery Within the Head and Neck*",
    specialty: "Plastic Surgery",
    board: "American Board of Plastic Surgery",
  },
  {
    value: "Surgery of the Hand",
    label: "Surgery of the Hand",
    specialty: "Plastic Surgery",
    board: "American Board of Plastic Surgery",
  },
  {
    value: "Addiction Medicine",
    label: "Addiction Medicine",
    specialty: "Aerospace Medicine",
    board: "American Board of Preventive Medicine",
  },
  {
    value: "Clinical Informatics",
    label: "Clinical Informatics",
    specialty: "Occupational Medicine",
    board: "American Board of Preventive Medicine",
  },
  {
    value: "Medical Toxicology",
    label: "Medical Toxicology",
    specialty: "Public Health and General Preventive Medicine",
    board: "American Board of Preventive Medicine",
  },
  {
    value: "Undersea and Hyperbaric Medicine",
    label: "Undersea and Hyperbaric Medicine",
    specialty: "Public Health and General Preventive Medicine",
    board: "American Board of Preventive Medicine",
  },
  {
    value: "Addiction Psychiatry",
    label: "Addiction Psychiatry",
    specialty: "Psychiatry",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Brain Injury Medicine",
    label: "Brain Injury Medicine",
    specialty: "Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Child and Adolescent Psychiatry",
    label: "Child and Adolescent Psychiatry",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Clinical Neurophysiology",
    label: "Clinical Neurophysiology",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Consultation-Liaison Psychiatry",
    label: "Consultation-Liaison Psychiatry",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Epilepsy",
    label: "Epilepsy",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Forensic Psychiatry",
    label: "Forensic Psychiatry",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Geriatric Psychiatry",
    label: "Geriatric Psychiatry",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Neurocritical Care",
    label: "Neurocritical Care",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Neurodevelopmental Disabilities",
    label: "Neurodevelopmental Disabilities",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Neuromuscular Medicine",
    label: "Neuromuscular Medicine",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Pain Medicine",
    label: "Pain Medicine",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Sleep Medicine",
    label: "Sleep Medicine",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Vascular Neurology",
    label: "Vascular Neurology",
    specialty: "Neurology with Special Qualification in Child Neurology",
    board: "American Board of Psychiatry and Neurology",
  },
  {
    value: "Neuroradiology",
    label: "Neuroradiology",
    specialty: "Diagnostic Radiology",
    board: "American Board of Radiology",
  },
  {
    value: "Nuclear Radiology",
    label: "Nuclear Radiology",
    specialty: "Interventional Radiology and Diagnostic Radiology",
    board: "American Board of Radiology",
  },
  {
    value: "Pain Medicine",
    label: "Pain Medicine",
    specialty: "Medical Physics (Diagnostic, Nuclear, Therapeutic)",
    board: "American Board of Radiology",
  },
  {
    value: "Pediatric Radiology",
    label: "Pediatric Radiology",
    specialty: "Radiation Oncology",
    board: "American Board of Radiology",
  },
  {
    value: "Complex General Surgical Oncology",
    label: "Complex General Surgical Oncology",
    specialty: "General Surgery",
    board: "American Board of Surgery",
  },
  {
    value: "Pediatric Surgery",
    label: "Pediatric Surgery",
    specialty: "Vascular Surgery",
    board: "American Board of Surgery",
  },
  {
    value: "Surgery of the Hand",
    label: "Surgery of the Hand",
    specialty: "Vascular Surgery",
    board: "American Board of Surgery",
  },
  {
    value: "Surgical Critical Care",
    label: "Surgical Critical Care",
    specialty: "Vascular Surgery",
    board: "American Board of Surgery",
  },
  {
    value: "Congenital Cardiac Surgery",
    label: "Congenital Cardiac Surgery",
    specialty: "Thoracic and Cardiac Surgery",
    board: "American Board of Thoracic Surgery",
  },
  {
    value: "Female Pelvic Medicine and Reconstructive Surgery",
    label: "Female Pelvic Medicine and Reconstructive Surgery",
    specialty: "Urology",
    board: "American Board of Urology",
  },
  {
    value: "Pediatric Urology",
    label: "Pediatric Urology",
    specialty: "Urology",
    board: "American Board of Urology",
  },
];
