import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Query blog entry
 */
export default async function queryBlogEntry(): Promise<any[]> {
  const res = await fetchOrchidAPI("/api/blog/v1/blog_entry/?skip=0&limit=80");
  if (!res.ok) {
    throw new Error(
      `failed to get blog entry: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
