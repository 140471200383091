const getReadTime = (contentDocument: Document) => {
  const content = contentDocument.body?.innerText ?? "";
  const wordsPerMinute = 200;
  const numberOfWords = content
    .split(/\s/g)
    .filter((el) => el.length > 1).length;
  const minutes = numberOfWords / wordsPerMinute;
  const readTime = Math.ceil(minutes);
  return readTime;
};

export default getReadTime;
