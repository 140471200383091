import { set_equal } from "utils/fn";

type NotificationsReducerResetAction = {
  type: "reset";
};

type NotificationsReducerAddNotificationsAction = {
  type: "add";
  channels: Set<string>;
};

type NotificationsReducerMarkReadAction = {
  type: "mark_read";
  channels: Set<string>;
};

export type NotificationsReducerAction =
  | NotificationsReducerResetAction
  | NotificationsReducerAddNotificationsAction
  | NotificationsReducerMarkReadAction;

const notificationsReducer = (
  state: Set<string>,
  action: NotificationsReducerAction,
) => {
  switch (action.type) {
    case "reset": {
      const newState = new Set<string>();
      if (set_equal(state, newState)) return state;
      return newState;
    }
    case "add": {
      const newState = new Set<string>(state);
      for (const channel of action.channels) newState.add(channel);
      if (set_equal(state, newState)) return state;
      return newState;
    }
    case "mark_read": {
      const newState = new Set<string>(state);
      for (const channel of action.channels) newState.delete(channel);
      if (set_equal(state, newState)) return state;
      return newState;
    }
  }
};

export default notificationsReducer;
