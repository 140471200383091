import { getOrchidProAllGraduateDegrees } from "utils/formatter/orchidPro";

export default function getOrchidProMedicalDegree(orchidPro) {
  const degreeArr = getOrchidProAllGraduateDegrees(orchidPro);
  if (degreeArr.includes("MD")) {
    return "MD";
  } else if (degreeArr.includes("DO")) {
    return "DO";
  } else return null;
}
