import { PrivateChatSession } from "../pubnub.types";
import hasUnreadMessages from "./has-unread-messages";

const totalNumberOfNotifications = (
  privateChats: Map<string, PrivateChatSession>,
  userCognitoSub: string,
): number => {
  let count = 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_partner, chatSession] of privateChats.entries())
    count += hasUnreadMessages(userCognitoSub, chatSession) ? 1 : 0;
  return count;
};

export default totalNumberOfNotifications;
