import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Update member
 */
export default async function postMember(nextMember) {
  const res = await fetchOrchidAPI("/api/users/v1/patient/", {
    headers: { "content-type": "application/json" },
    method: "PUT",
    body: JSON.stringify(nextMember),
  });
  if (!res.ok) {
    throw new Error(
      `failed to save member info: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
