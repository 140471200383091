function appointmentStatusFlagsFromApiData(data: any): AppointmentStatusFlags {
  if (data.status_flags) {
    return {
      cancelledOrAbandoned: data.status_flags.cancelled_or_abandoned,
      unconfirmedInvite: data.status_flags.unconfirmed_invite,
      cancelledByPatient: data.status_flags.cancelled_by_patient,
      cancelledOrAbandonedByPatient:
        data.status_flags.cancelled_or_abandoned_by_patient,
      orchidProNoShow: data.status_flags.orchid_pro_no_show,
      cancelledByOrchidPro: data.status_flags.cancelled_by_orchid_pro,
      cancelledOrAbandonedByOrchidPro:
        data.status_flags.cancelled_or_abandoned_by_orchid_pro,
      paymentIntentNeedsAction: data.status_flags.payment_intent_needs_action,
      orchidProMayHaveMissedAppointment:
        data.status_flags.orchid_pro_may_have_missed_appointment,
      isInvoiceCreated: data.status_flags.is_invoice_created,
      usingInvoicing: data.status_flags.using_invoicing,
      canCreateInvoice: data.status_flags.can_create_invoice,
      wasNotPaid: data.status_flags.was_not_paid,
      pendingClientPortalApproval:
        data.status_flags.pending_patient_portal_approval,
      pendingClientPortalSignupComplete:
        data.status_flags.pending_patient_portal_signup_complete,
      needsPayment: data.status_flags.needs_payment,
    };
  } else {
    return {
      cancelledOrAbandoned: [
        "cancelled_patient",
        "cancelled_patient_awaiting_transfer",
        "cancelled_patient_manual_transfer",
        "cancelled_orchid_pro",
        "cancelled_pro_no_join",
      ].includes(data.status),
      unconfirmedInvite: data.status === "invited",
      cancelledByPatient: [
        "cancelled_patient",
        "cancelled_patient_awaiting_transfer",
        "cancelled_patient_manual_transfer",
      ].includes(data.status),
      cancelledOrAbandonedByPatient: [
        "cancelled_patient",
        "cancelled_patient_awaiting_transfer",
        "cancelled_patient_manual_transfer",
      ].includes(data.status),
      orchidProNoShow: ["cancelled_pro_no_join"].includes(data.status),
      cancelledByOrchidPro: ["cancelled_orchid_pro"].includes(data.status),
      cancelledOrAbandonedByOrchidPro: [
        "cancelled_orchid_pro",
        "cancelled_pro_no_join",
      ].includes(data.status),
      paymentIntentNeedsAction: ["payment_intent_needs_action"].includes(
        data.status,
      ),
      orchidProMayHaveMissedAppointment: [
        "orchid_pro_may_have_missed_appointment",
      ].includes(data.status),
      isInvoiceCreated: ["is_invoice_created"].includes(data.status),
      usingInvoicing: ["using_invoicing"].includes(data.status),
      canCreateInvoice: ["can_create_invoice"].includes(data.status),
      wasNotPaid: ["was_not_paid"].includes(data.status),
      pendingClientPortalApproval: ["pending_patient_portal_approval"].includes(
        data.status,
      ),
      pendingClientPortalSignupComplete: [
        "pending_patient_portal_signup_complete",
      ].includes(data.status),
      needsPayment: ["needs_payment"].includes(data.status),
    };
  }
}

export function appointmentFromApiData(data: any): AppointmentMetaData {
  return {
    ...data,
    statusFlags: appointmentStatusFlagsFromApiData(data),
    cancel_refund_offer: data.cancel_refund_offer
      ? {
          ...data.cancel_refund_offer,
          cancellation_price_valid_until: new Date(
            data.cancel_refund_offer.cancellation_price_valid_until,
          ),
        }
      : undefined,
  };
}

export function appointmentSeriesFromApiData(
  data: any,
): AppointmentSeriesMetaData {
  return {
    appointment_series_id: data.appointment_series_id,
    create_datetime: data.create_datetime,
    modify_datetime: data.modify_datetime,
    orchid_pro: data.orchid_pro,
    patient: data.patient,
    repeat_rule: data.repeat_rule,
  };
}

export function appointmentInvoiceFromApiData(data: any): InvoiceData {
  return {
    orchid_pro_user_id: data.orchid_pro_user_id,
    invoice_no: data.invoice_no,
    invoice_revision: data.invoice_revision,
    appointment_id: data.appointment_id,
    appointment_status: data.appointment_status,
    bill_to_user_id: data.bill_to_user_id,
    invoice_issued_at: data.invoice_issued_at,
    entry_create_datetime: data.entry_create_datetime,
    deleted_datetime: data.deleted_datetime,
    written_off_datetime: data.written_off_datetime,
    refunded_datetime: data.refunded_datetime,
    terms: data.terms,
    invoice_due_by: data.invoice_due_by,
    amount_total_invoiced: data.amount_total_invoiced,
    amount_total_paid: data.amount_total_paid,
    amount_total_refunded: data.amount_total_refunded,
    amount_written_off: data.amount_written_off,
    line_items: data.line_items,
    bill_to_contact: data.bill_to_contact,
    provider_business_contact: data.provider_business_contact,
    provider_contact: data.provider_contact,
    patient_contact: data.patient_contact,
    note_entry: data.note_entry,
    is_closed: data.is_closed,
    activity_log: data.activity_log,
  };
}

export function appointmentsFromApiData(data: any[]): AppointmentMetaData[] {
  return data.map(appointmentFromApiData);
}

export function appointmentsLastUpdateTimestamp(
  appointments: AppointmentMetaData[],
): number {
  if (appointments.length === 0) return 0;
  return Math.max(
    ...appointments.map((v) => new Date(v.modify_datetime).getTime()),
  );
}

export function selectNewer(
  appointmentLhs: AppointmentMetaData,
  appointmentRhs: AppointmentMetaData,
): AppointmentMetaData {
  return appointmentLhs.modify_datetime < appointmentRhs.modify_datetime
    ? appointmentRhs
    : appointmentLhs;
}

export function appointmentsUpdated(
  appointments: AppointmentMetaData[],
  updates: { [key: string]: AppointmentMetaData },
): AppointmentMetaData[] {
  return appointments.map((a) => {
    const update = updates[a.appointment_id];
    if (!update) return a;
    return selectNewer(a, update);
  });
}
