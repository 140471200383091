import { ReactComponent as JoinOrchidImage } from "assets/svg/join-orchid.svg";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { Button } from "react-bootstrap";
import getClasses from "utils/getClasses";
import styles from "./FAQCTA.module.scss";
import { AmplitudePageName, track } from "utils/amplitude";

const AMPLITUDE_COMPONENT_NAME = "join-orchid-box";

interface Props {
  amplitudePageName: AmplitudePageName;
}

const FAQCTA = ({ amplitudePageName }: Props) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  return (
    <div
      className={getClasses(
        isPhonePortrait && styles["blog-cta--phone"],
        styles["blog-cta"],
      )}
    >
      <JoinOrchidImage className={styles["blog-cta__illustration"]} />
      <div className={styles["blog-cta__title"]}>Empower Your Clinic</div>
      <Button
        size={"lg"}
        className={styles["blog-cta__button"]}
        variant={"primary"}
        onClick={() => {
          track(
            amplitudePageName,
            [AMPLITUDE_COMPONENT_NAME, "start-free-trial-button"],
            "click",
          );
          window.open("/professional-register");
        }}
      >
        Start Free Trial
      </Button>
      <Button
        size={"lg"}
        className={styles["blog-cta__button"]}
        variant={"outline-primary"}
        onClick={() => {
          track(
            amplitudePageName,
            [AMPLITUDE_COMPONENT_NAME, "sign-up-for-demo-button"],
            "click",
          );
          window.open("https://calendly.com/hey-orchid/orchid-walkthrough");
        }}
      >
        Sign up for Demo
      </Button>
    </div>
  );
};

export default FAQCTA;
