import { ReactComponent as Brand } from "assets/svg/orchid-logo.svg";
import { NavbarBrand, NavbarBrandProps } from "react-bootstrap";
import { useUser } from "contexts/UserContext";
import { useLocation, Link } from "react-router-dom";

import styles from "./NavBrand.module.scss";
import getClasses from "utils/getClasses";

const NavBrand = ({ className, ...props }: NavbarBrandProps) => {
  const { user } = useUser();
  const { pathname } = useLocation();
  return (
    <NavbarBrand
      className={getClasses(styles["nav-brand"], className)}
      title={"orchid logo"}
      {...props}
    >
      <Link
        to={
          user?.user_type === "orchid_pro"
            ? "/pro-dashboard/homepage"
            : user?.user_type === "member"
            ? "/member-dashboard/homepage"
            : pathname.startsWith("/professional-homepage")
            ? "/professional-homepage"
            : "/"
        }
        aria-label={"homepage"}
      >
        <Brand height={20} aria-hidden={"true"} />
      </Link>
    </NavbarBrand>
  );
};

export default NavBrand;
