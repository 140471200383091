import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";
import redirectTo404 from "utils/redirectTo404";

/**
 * Query orchid pro by cognito_sub or custom_url
 */
export default async function queryOrchidProById(
  cognito_pub_or_custom_url: string,
  shouldRedirectOn404 = false,
): Promise<OrchidPro> {
  const response = await fetchOrchidAPI(
    `/api/users/v1/orchid_pro/${cognito_pub_or_custom_url}`,
  );
  if (!response.ok) {
    if (shouldRedirectOn404 && response.status === 404) {
      redirectTo404();
    }
    throw new Error(
      `failed to get orchid pro: ${getErrorDetail(await response.text())}`,
    );
  }

  return response.json();
}
