/**
 * routes, text and icon used for navigation when user type is "member"
 */
export const MEMBER_ROUTES = Object.freeze({
  dashboard: "/member-dashboard/homepage",
  messages: "/member-dashboard/messages",
  profile: "/member-dashboard/profile",
} as const);

export type TMemberRoutesKey = keyof typeof MEMBER_ROUTES;
