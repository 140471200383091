import OrchidProRegisterForm from "../orchid-pro-register-form";
import { AmplitudePageName } from "utils/amplitude";
import { ReactComponent as EmailPanelBanner } from "assets/svg/email-panel-banner.svg";
import { ReactComponent as EmailPanelBannerPhone } from "assets/svg/email-panel-banner-phone.svg";
import * as styles from "./BookADemo.module.scss";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

interface Props {
  amplitudePageName: AmplitudePageName;
}

const BookADemo = ({ amplitudePageName }: Props) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  return (
    <div className={styles["book-a-demo"]}>
      <div>
        <div className={styles["book-a-demo__container"]}>
          <div className={styles["book-a-demo__description"]}>
            <h2 className={styles["book-a-demo__title"]}>
              Business "as usual" can get <i>so much better</i>.
            </h2>
            <p className={styles["book-a-demo__content"]}>
              Book a quick demo call with one of our founders & see what Orchid
              can do.
            </p>
          </div>
          <div className={styles["book-a-demo__right"]}>
            <OrchidProRegisterForm amplitudePageName={amplitudePageName} />
          </div>
        </div>
        {!isPhonePortrait ? (
          <EmailPanelBanner />
        ) : (
          <div className={styles["book-a-demo__banner--phone"]}>
            <EmailPanelBannerPhone />
          </div>
        )}
      </div>
    </div>
  );
};

export default BookADemo;
