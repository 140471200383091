import { ChatMessageData } from "../pubnub.types";

const getLastConfirmedMessage = (
  messages: Array<ChatMessageData>,
): ChatMessageData | null => {
  const confirmed = messages.filter((m) => m.messageTimetoken !== null);
  if (confirmed.length === 0) return null;
  return confirmed[confirmed.length - 1];
};

export default getLastConfirmedMessage;
