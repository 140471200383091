import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useUser } from "contexts/UserContext";
import { AppointmentsStoreReducer } from "./reducers/appointments-store-reducer";
import { useOrchidWebSocket } from "contexts/WebSocketContext";
import { appointmentsFromApiData } from "./utils";

interface AppointmentsContextProps {
  appointmentUpdates: { [key: string]: AppointmentMetaData };
  addOrUpdateAppointment: (appointments: AppointmentMetaData[]) => void;
  appointmentOperations: {};
}

export const AppointmentsContext = createContext<AppointmentsContextProps>(
  {} as AppointmentsContextProps,
);

export function AppointmentsProvider({ children }: { children: ReactNode }) {
  const { user } = useUser();
  const userSub = user?.sub;

  const [appointmentUpdates, appointmentUpdatesDispatch] = useReducer(
    AppointmentsStoreReducer,
    {},
  );

  useEffect(() => {
    if (!userSub) appointmentUpdatesDispatch({ type: "reset" });
  }, [userSub]);

  const addOrUpdateAppointment = useCallback(
    (appointments: AppointmentMetaData[]) => {
      appointmentUpdatesDispatch({
        type: "add_or_update",
        appointments,
      });
    },
    [appointmentUpdatesDispatch],
  );

  const { lastMessage } = useOrchidWebSocket();

  useEffect(() => {
    if (lastMessage?.event_type !== "appointment") return;
    appointmentUpdatesDispatch({
      type: "add_or_update",
      appointments: appointmentsFromApiData(lastMessage.appointments),
    });
  }, [lastMessage]);

  return (
    <AppointmentsContext.Provider
      value={{
        appointmentUpdates,
        addOrUpdateAppointment,
        appointmentOperations: {},
      }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
}

export function useAppointmentsProvider() {
  return useContext(AppointmentsContext);
}
