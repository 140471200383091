import React, { useEffect, useMemo, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiHostAndPath } from "utils/api";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import queryOrchidProById from "services/queryOrchidProById";
import RoundedImageWithFallback from "components/rounded-image-with-fallback";
import FullWidthImage from "components/full-width-image";
import styles from "./BlogCard.module.scss";
import { AmplitudePageName, track } from "utils/amplitude";
import createDocumentFromContent from "components/Blogging/utils/createDocumentFromContent";
import getReadTime from "components/Blogging/utils/getReadTime";
import getClasses from "utils/getClasses";
import useFormatDateForBlog from "components/Blogging/hooks/formatDateForBlog";
import getBlogImageUrl, {
  BlogImageType,
} from "components/Blogging/utils/getBlogImageUrl";
import { ImageAltText } from "constants/imageAltText";

interface Props {
  blog: Blog;
  amplitudePageName: AmplitudePageName;
}

const AMPLITUDE_COMPONENT_NAME = "blog-card";

const BlogCard: React.FC<Props> = ({ blog, amplitudePageName }) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const [orchidPro, setOrchidPro] = useState<OrchidPro>();

  const { formatDateForBlog } = useFormatDateForBlog();

  const readTime = useMemo(() => {
    const contentDocument = createDocumentFromContent(blog);
    const readTime = getReadTime(contentDocument);
    return readTime;
  }, [blog]);

  useEffect(() => {
    if (!blog?.author_display_cognito_sub) {
      return;
    }
    queryOrchidProById(blog.author_display_cognito_sub)
      .then(setOrchidPro)
      .catch(console.error);
  }, [blog?.author_display_cognito_sub]);

  return (
    <Link
      className="d-flex w-100"
      to={`/blogs/${blog.url}`}
      onClick={() => {
        track(amplitudePageName, AMPLITUDE_COMPONENT_NAME, "click", {
          eventProperties: {
            "blog-id": blog.id,
            "blog-title": blog.title,
          },
        });
        window.scrollTo(0, 0);
      }}
    >
      <Card
        className={getClasses(
          styles["blog-card"],
          isPhonePortrait && styles["blog-card--phone"],
        )}
      >
        <FullWidthImage
          alt={ImageAltText[blog?.id] ?? "Loading…"}
          src={getBlogImageUrl(blog, BlogImageType.main)}
          height={200}
          className="lazyload rounded-top"
        />
        <Card.Body className={styles["card-body"]}>
          <Row className={styles["tag-and-read-time"]}>
            <div>
              {blog.tags.map((el, i) => {
                return (
                  <span key={i} className={styles["tag"]}>
                    {el}
                  </span>
                );
              })}
            </div>
            <span className={styles["read-time"]}>{readTime} min read</span>
          </Row>
          <Card.Text
            className={getClasses("module line-clamp-2", styles["card-text"])}
            style={{ height: !isPhonePortrait ? "3.5rem" : "auto" }}
          >
            {blog.title}
          </Card.Text>
          <Card.Text className={`module line-clamp-3 ${styles["summary"]}`}>
            {blog.summary}
          </Card.Text>

          <Row className={`mb-3 ${styles["author-and-date"]}`}>
            <RoundedImageWithFallback
              src={apiHostAndPath(
                orchidPro
                  ? `/api/users/orchid_pro/profile_image/${orchidPro.cognito_sub}`
                  : `/blog/profile_image/${blog.id}`,
              )}
              width={24}
              height={24}
              style={{ border: "1px solid white" }}
            />
            <span className={styles["author"]}>{blog.author_display_name}</span>
            <span className={styles["author-date-separator"]}>•</span>
            <span className={styles["date-published"]}>
              {formatDateForBlog(blog.create_datetime)}
            </span>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default BlogCard;
