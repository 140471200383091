import { useMemo } from "react";
import {
  ChannelData,
  ChatAccessDataMapByChannel,
  ChatMessageData,
  PrivateChatSession,
} from "../pubnub.types";
import filterWithSender from "../utils/filter-with-sender";
import getLastReceiptTimestamp from "../utils/get-last-receipt-timestamp";
import sortMessages from "../utils/sort-messages";
import updateMessages from "../utils/update-messages";

const usePrivateChats = (
  userSub: string | undefined,
  newChatsByChannel: {
    [channel: string]: ChannelData;
  },
  onlineStatus: Set<string>,
  notifications: Set<string>,
  chatAccessDataByChannel: ChatAccessDataMapByChannel,
  chatHistory: Map<string, ChatMessageData[]>,
) => {
  const privateChats = useMemo<Map<string, PrivateChatSession>>(() => {
    if (!userSub) return new Map();

    const newPrivateChats: Map<string, PrivateChatSession> = new Map();
    for (const [channel, messages] of chatHistory.entries()) {
      const access = chatAccessDataByChannel.get(channel);
      if (!access) continue;
      const partnerCognitoSub = access.partnerCognitoSub;
      newPrivateChats.set(partnerCognitoSub, {
        channel: channel,
        onlineStatus: onlineStatus.has(partnerCognitoSub),
        partnerCognitoSub,
        allowSend: access?.allowSend || false,
        hasNotification: notifications.has(channel),
        userMessageReadTimestamp: getLastReceiptTimestamp(messages, userSub),
        partnerMessageReadTimestamp: getLastReceiptTimestamp(
          messages,
          partnerCognitoSub,
        ),
        messages: sortMessages(messages),
      });
    }
    for (const [channel, channelData] of Object.entries(newChatsByChannel)) {
      const access = chatAccessDataByChannel.get(channel);
      if (access?.type !== "private") continue;
      const partnerCognitoSub = access.partnerCognitoSub;
      const existingData = newPrivateChats.get(partnerCognitoSub);
      const messages = filterWithSender(
        updateMessages(channelData.messages, existingData?.messages || []),
      );
      newPrivateChats.set(partnerCognitoSub, {
        channel,
        onlineStatus: onlineStatus.has(partnerCognitoSub),
        partnerCognitoSub,
        allowSend: access.allowSend || false,
        hasNotification: notifications.has(channel),
        userMessageReadTimestamp: getLastReceiptTimestamp(messages, userSub),
        partnerMessageReadTimestamp: getLastReceiptTimestamp(
          messages,
          partnerCognitoSub,
        ),
        messages: sortMessages(messages),
      });
    }
    return newPrivateChats;
  }, [
    userSub,
    newChatsByChannel,
    onlineStatus,
    notifications,
    chatAccessDataByChannel,
    chatHistory,
  ]);

  return { privateChats };
};

export default usePrivateChats;
