import { Col, Row, Image, Container, Button, Navbar } from "react-bootstrap";
import { ReactComponent as OutlineArrowRightCircle } from "assets/svg/outline-arrow-right-circle.svg";
import { ReactComponent as CloseButton } from "assets/svg/outline-cross.svg";
import "animate.css";
import NavBrand from "components/nav-brand/NavBrand";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

const GET_STARTED_DOCTOR = "/img/auth/join-as-a-pro.png";
const GET_STARTED_PATIENT = "/img/auth/get-personalized-care.png";

type Props = { setShowDropdown: (arg: boolean) => void };
const GetStartedDropdown = ({ setShowDropdown }: Props) => (
  <Container
    fluid
    style={{
      top: "0",
      right: "0",
      height: "100vh",
      zIndex: 2000,
      flexDirection: "column",
    }}
    className="background-white position-fixed w-100 p-0 d-flex animate__animated animate__fadeInDown"
  >
    <Navbar className={getClasses("shadow-sm", styles["nav-bar"])}>
      <NavBrand />
    </Navbar>
    <Button
      style={{ top: "90px", right: "15px" }}
      variant="link"
      className="gray01 position-absolute"
      onClick={() => setShowDropdown && setShowDropdown(false)}
    >
      <CloseButton />
    </Button>

    <Row className="my-auto">
      <Col md={6} className="text-center my-auto">
        <Image
          className={styles["get-started-member-img"]}
          src={GET_STARTED_PATIENT}
          fluid
        />
        <Button
          variant="link"
          className="rg-18 d-flex align-items-center justify-content-center"
          href="/member-register"
        >
          Get personalized care <OutlineArrowRightCircle className="ml-2" />
        </Button>
      </Col>
      <Col md={6} className="text-center my-auto">
        <Image
          className={styles["get-started-orchid-pro-img"]}
          src={GET_STARTED_DOCTOR}
          fluid
        />
        <Button
          variant="link"
          className="rg-18 d-flex align-items-center justify-content-center"
          href="/professional-register"
        >
          Join as a professional <OutlineArrowRightCircle className="ml-2" />
        </Button>
      </Col>
    </Row>
  </Container>
);

export default GetStartedDropdown;
