import { GENERAL_ROUTES } from "constants/generalRoutes";
import { MEMBER_ROUTES, TMemberRoutesKey } from "constants/memberRoutes";
import { PRO_ROUTES, TProRoutesKey } from "constants/proRoutes";
import { useUser } from "contexts/UserContext";
import getClasses from "utils/getClasses";
import styles from "./BottomNavigation.module.scss";
import BottomNavItem from "./components/bottom-nav-item";
import getBottomNavItemProps from "./utils/getBottomNavItemProps";

interface BottomNavigationProps {
  includeInLayout?: boolean;
}

const BottomNavigation = ({ includeInLayout }: BottomNavigationProps) => {
  const { user } = useUser();

  return (
    <div
      className={getClasses(
        styles["bottom-navigation"],
        includeInLayout && styles["bottom-navigation--include-in-layout"],
      )}
    >
      {user?.user_type !== "orchid_pro" && (
        <>
          <BottomNavItem
            exact={true}
            to={GENERAL_ROUTES.home}
            {...getBottomNavItemProps("home")}
          />
          <BottomNavItem
            exact={true}
            to={GENERAL_ROUTES.professionalSearch}
            {...getBottomNavItemProps("professionalSearch")}
          />
        </>
      )}
      {user?.signup_form_finished &&
        user.user_type === "member" &&
        Object.entries(MEMBER_ROUTES).map(([key, value]) => (
          <BottomNavItem
            key={key}
            exact={true}
            to={value as typeof MEMBER_ROUTES[TMemberRoutesKey]}
            {...getBottomNavItemProps(key as TMemberRoutesKey)}
          />
        ))}

      {user?.signup_form_finished &&
        user.user_type === "orchid_pro" &&
        Object.entries(PRO_ROUTES).map(([key, value]) => (
          <BottomNavItem
            key={key}
            exact={true}
            to={value as typeof PRO_ROUTES[TProRoutesKey]}
            {...getBottomNavItemProps(key as TProRoutesKey)}
          />
        ))}

      {(!user || !user.email_confirmed || !user.signup_form_finished) && (
        <BottomNavItem
          exact={true}
          to={GENERAL_ROUTES.login}
          {...getBottomNavItemProps("login")}
        />
      )}
    </div>
  );
};

export default BottomNavigation;
