import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Amend orchid pro type by orchid_pro_type_id
 */
export default async function putOrchidProTypeById(
  orchid_pro_type_id: string,
  nextOrchidProType,
) {
  const res = await fetchOrchidAPI(
    `/api/users/v1/orchid_pro_type/${orchid_pro_type_id}`,
    {
      headers: { "content-type": "application/json" },
      method: "PUT",
      body: JSON.stringify(nextOrchidProType),
    },
  );
  if (!res.ok) {
    throw new Error(
      `failed to update orchid pro type: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
