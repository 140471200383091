import { useState, useCallback } from "react";

export default function useToggleModal(initialState: boolean) {
  const [show, setShow] = useState(initialState);

  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);

  return {
    show,
    open,
    close,
  };
}
