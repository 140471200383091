import { apiHostAndPath } from "utils/api";
import capitalize from "utils/capitalize";

const HIDE_TYPE = "Other";

/**
 * get orchid_pro_type display text
 */
// TODO: REMOVE
export const getOrchidProTypeString = ({ orchid_pro_type }: OrchidPro) =>
  (orchid_pro_type || []).filter((el) => el !== HIDE_TYPE).join(", ");

/**
 * get orchid_pro_types display text
 */
export const getOrchidProTypeStringFromNewArr = ({
  orchid_pro_types,
}: OrchidPro) =>
  (orchid_pro_types || [])
    .map(({ orchid_pro_type }) => capitalize(orchid_pro_type.type))
    .join(", ");

/**
 * get orchid pro specialty display text (if any)
 */
export const getOrchidProSpecialtyStringFromNewArr = ({
  orchid_pro_types,
}: OrchidPro) =>
  (orchid_pro_types || [])
    .map(
      ({ orchid_pro_type }) =>
        orchid_pro_type.specialty?.specialty &&
        capitalize(orchid_pro_type.specialty.specialty),
    )
    .join(", ");

/**
 * get certification display text
 */
export const getOrchidProCertificationString = ({
  orchid_pro_types,
  board_certification,
  certifications,
}: OrchidPro) =>
  (
    (orchid_pro_types?.some(
      ({ orchid_pro_type }) => orchid_pro_type.type === "physician",
    )
      ? board_certification
      : certifications) || []
  )
    .filter((el) => el !== HIDE_TYPE)
    .join(", ");

/**
 * get first name display text
 */
export const getOrchidProFirstName = ({
  preferred_first_name = "",
  first_name = "",
}: OrchidPro) => capitalize(preferred_first_name || first_name);

/**
 * get full name display text
 */
export const getOrchidProFullName = ({
  preferred_first_name = "",
  first_name = "",
  last_name = "",
}: OrchidPro) =>
  `${capitalize(preferred_first_name || first_name)} ${capitalize(last_name)}`;

/**
 * get abbrev last name
 */
export const getOrchidProAbbrLastName = (str: string) =>
  !str ? "" : `${capitalize(str[0])}.`;

/**
 * get abbrev name display text
 */
export const getOrchidProAbbrName = ({
  preferred_first_name = "",
  first_name = "",
  last_name = "",
}: OrchidPro) =>
  [
    capitalize(preferred_first_name || first_name),
    getOrchidProAbbrLastName(last_name),
  ].join(" ");

/**
 * get all degrees display text
 */
// export const getOrchidProAllDegrees = ({
//   medical_degree,
//   degrees,
// }: OrchidPro) => [
//   medical_degree,
//   ...(degrees || []).map(({ degree }) => degree),
// ];

/**
 * get all graduate degrees
 */
export const getOrchidProAllGraduateDegrees = ({
  medical_degree,
  graduate,
}: OrchidPro) => {
  if (medical_degree) {
    return [
      medical_degree,
      ...(graduate || [])
        .filter(({ degree }) => degree !== medical_degree)
        .map(({ degree }) => degree),
    ];
  } else {
    return [...(graduate || []).map(({ degree }) => degree)];
  }
};

/**
 * get all graduate degrees display text
 */
export const getOrchidProAllGraduateDegreesString = (orchidPro: OrchidPro) =>
  getOrchidProAllGraduateDegrees(orchidPro).join(", ");

/**
 * get three degrees display text
 */
export const getOrchidProThreeDegreesString = (orchidPro: OrchidPro) =>
  getOrchidProAllGraduateDegrees(orchidPro).slice(0, 3).join(", ");

/**
 * get full name and only medical degree display text, usually used as title
 */
export const getOrchidProNameAndMedicalDegreeString = (orchidPro: OrchidPro) =>
  [getOrchidProFullName(orchidPro), orchidPro.medical_degree]
    .filter((e) => e)
    .join(", ");

/**
 * get full name and all graduate degrees display text, usually used as title
 */
export const getOrchidProNameAndAllGraduateDegreesString = (
  orchidPro: OrchidPro,
) => {
  if (!getOrchidProAllGraduateDegrees(orchidPro)?.length) {
    return getOrchidProFullName(orchidPro);
  } else {
    return [
      getOrchidProFullName(orchidPro),
      getOrchidProAllGraduateDegrees(orchidPro),
    ]
      .filter((e) => e)
      .join(", ");
  }
};

/**
 * get abbrev name and all degrees display text, usually used as title
 */
export const getOrchidProAbbrevNameAndAllDegreesString = (
  orchidPro: OrchidPro,
) =>
  [getOrchidProAbbrName(orchidPro), getOrchidProAllGraduateDegrees(orchidPro)]
    .filter((e) => e)
    .join(", ");

const ORCHID_PRO_PROFILE_IMAGE_API = "/api/users/orchid_pro/profile_image";
/**
 * get profile image url
 */
export const getOrchidProProfileImageUrl = (
  cognitoSub: OrchidPro["cognito_sub"],
) => apiHostAndPath(`${ORCHID_PRO_PROFILE_IMAGE_API}/${cognitoSub}`);
