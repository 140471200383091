import React from "react";
import { GENDER_OPTIONS } from "constants/genderOptions";
import { SEX_OPTIONS } from "constants/sexOptions";
import { Form, Col, Dropdown } from "react-bootstrap";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import DropdownToggle from "components/dropdown-toggle/DropdownToggle";
import FormControlWithMask from "components/utility/FormControlWithMask";
import { PHONE_NUMBER_MASK } from "constants/phoneNumberMask";
import InputWithBackdrop from "components/input-with-backdrop";
import { ReactComponent as OutlineChevronDown } from "assets/svg/outline-chevron-down-16.svg";

const OptionalPanel = ({ member, fieldChangedCallback }) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const onInputChange = (event) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    fieldChangedCallback(name, value);
  };

  const onSexChange = (nextSex) => {
    fieldChangedCallback("sex", nextSex);
  };

  const onGenderChange = (nextGender) => {
    fieldChangedCallback("gender", nextGender);
  };

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} md="4">
          <Form.Label className="rg-12 gray01">Gender</Form.Label>
          {!isPhonePortrait ? (
            <DropdownToggle
              title={member.gender || "Select Gender"}
              Caret={<OutlineChevronDown />}
            >
              <Dropdown.Item onClick={() => fieldChangedCallback("gender", "")}>
                Select...
              </Dropdown.Item>
              {GENDER_OPTIONS.map((el) => (
                <Dropdown.Item
                  key={el.value}
                  onClick={() => fieldChangedCallback("gender", el.value)}
                >
                  {el.label}
                </Dropdown.Item>
              ))}
            </DropdownToggle>
          ) : (
            <InputWithBackdrop
              value={member.gender || ""}
              placeholder={"Please select your gender"}
              options={[
                "Male",
                "Female",
                "Non-binary",
                "Transgender",
                "Prefer not to say",
              ]}
              onClickOption={onGenderChange}
            />
          )}
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label className="rg-12 gray01">Sex</Form.Label>
          {!isPhonePortrait ? (
            <DropdownToggle
              title={member.sex || "Select Sex"}
              Caret={<OutlineChevronDown />}
            >
              <Dropdown.Item onClick={() => fieldChangedCallback("sex", "")}>
                Select...
              </Dropdown.Item>
              {SEX_OPTIONS.map((el) => (
                <Dropdown.Item
                  key={el.value}
                  onClick={() => fieldChangedCallback("sex", el.value)}
                >
                  {el.label}
                </Dropdown.Item>
              ))}
            </DropdownToggle>
          ) : (
            <InputWithBackdrop
              value={member.sex || ""}
              placeholder={"Please select your sex"}
              options={["Male", "Female"]}
              onClickOption={onSexChange}
            />
          )}
        </Form.Group>

        <Form.Group as={Col} md="4" xs="12">
          <Form.Label className="rg-12 gray01">Phone Number</Form.Label>
          <FormControlWithMask
            name="phone_number"
            mask={PHONE_NUMBER_MASK}
            maskChar="-"
            maskRegex="\d"
            value={member.phone_number || ""}
            onChange={onInputChange}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12" xs="12">
          <Form.Label className="rg-12 gray01">Insurance Provider</Form.Label>
          <Form.Control
            name="insurance_provider"
            placeholder="Enter insurance provider"
            value={member.insurance_provider || ""}
            onChange={onInputChange}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6" xs="12">
          <Form.Label className="rg-12 gray01">Group ID</Form.Label>
          <Form.Control
            name="insurance_group_id"
            placeholder="Enter group ID"
            value={member.insurance_group_id || ""}
            onChange={onInputChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" xs="12">
          <Form.Label className="rg-12 gray01">Member ID</Form.Label>
          <Form.Control
            name="insurance_member_id"
            placeholder="Enter member ID"
            value={member.insurance_member_id || ""}
            onChange={onInputChange}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Label className="rg-12 gray01">About Me</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter additional information about yourself"
          rows={4}
          name="biography"
          maxLength={1024}
          value={member.biography || ""}
          onChange={onInputChange}
        />
      </Form.Group>
    </>
  );
};

export default OptionalPanel;
