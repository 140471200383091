export default function canElementBeRequired(
  templateElement: TemplateElement,
): templateElement is RequireableTemplateElement {
  return templateElement.hasOwnProperty("isRequired");
}

export function canEditorViewItemBeRequired(
  item: AtomicEditorViewItem,
): item is AtomicEditorViewItem & {
  templateElement: RequireableTemplateElement;
} {
  return canElementBeRequired(item.templateElement);
}

export function canElementHaveInitials(
  templateElement: TemplateElement,
): templateElement is InitialsEnabledTemplateElement {
  return (
    templateElement.hasOwnProperty("isRequired") &&
    templateElement.type !== "signature" &&
    templateElement.type !== "radios-grid"
  );
}

export function canEditorViewItemHaveInitials(
  item: AtomicEditorViewItem,
): item is AtomicEditorViewItem & {
  templateElement: InitialsEnabledTemplateElement;
} {
  return canElementHaveInitials(item.templateElement);
}

export function canElementHaveOptions(
  templateElement: TemplateElement,
): templateElement is TemplateElementWithOptions<TemplateElementOption> {
  return templateElement.hasOwnProperty("options");
}

export function canOptionHaveAnswerFields(
  templateElement: TemplateElementWithOptions<TemplateElementOption>,
  option: TemplateElementOption,
): option is TemplateElementOptionWithAnswerFields {
  return canElementHaveAnswerFields(templateElement); // This is a bit of a shortcut, use carefully
}

export function canElementHaveAnswerFields(
  templateElement: TemplateElement,
): templateElement is TemplateElementWithOptions<TemplateElementOptionWithAnswerFields> {
  return (
    canElementHaveOptions(templateElement) &&
    ["radios", "checkboxes"].includes(templateElement.type)
  );
}
