import { useEffect, useReducer } from "react";

import { useOrchidWebSocket } from "contexts/WebSocketContext";
import presenceReducer from "../state/presence-reducer";

export function usePresence(): { onlineStatus: Set<string> } {
  const { lastMessage } = useOrchidWebSocket();

  const [onlineStatus, onlineStatusDispatch] = useReducer(
    presenceReducer,
    new Set<string>(),
  );

  useEffect(() => {
    if (!lastMessage) return;
    switch (lastMessage.event_type) {
      case "snapshot":
        onlineStatusDispatch({
          type: "snapshot",
          snapshot: new Set(lastMessage.online_partners),
        });
        break;
      case "join":
        onlineStatusDispatch({
          type: "join",
          partners: new Set([lastMessage.partner]),
        });
        break;
      case "leave":
        onlineStatusDispatch({
          type: "leave",
          partners: new Set([lastMessage.partner]),
        });
        break;
    }
  }, [lastMessage]);

  return { onlineStatus };
}
