import LazyImage from "components/lazy-image";
import { ImageProps } from "react-bootstrap";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

const RoundedImageWithFallback = ({ className, src, ...props }: ImageProps) => (
  <LazyImage
    showGreyFallback
    src={src}
    alt={"loading"}
    {...props}
    roundedCircle
    className={getClasses(styles["rounded-image-with-fallback"], className)}
  />
);

export default RoundedImageWithFallback;
