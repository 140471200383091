import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Client completes their signup session
 *
 */
export default async function completeSignup(
  completeSignupToken: string,
  password: string,
) {
  const res = await fetchOrchidAPI(
    `/api/users/v1/patient_signup_session/complete/${completeSignupToken}`,
    {
      method: "POST",
      body: JSON.stringify({
        password: password,
      }),
    },
  );
  if (!res.ok) {
    throw new Error(
      `failed to complete signup: ${getErrorDetail(await res.text())}`,
    );
  }

  return;
}
