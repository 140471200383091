import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useCallback, useEffect, useState } from "react";
import getClasses from "utils/getClasses";
import styles from "./ReadingProgressBar.module.scss";

interface ReadingProgressBarProps {
  articleBodyRef: React.RefObject<HTMLDivElement>;
  ignoreHeader?: boolean;
}

const getIsHidden = () => window.scrollY < 100;

const truncateToZeroToOne = (num: number) => Math.min(Math.max(num, 0), 1);

const COUNT_VISIBLE_AS_READ = true;

const ReadingProgressBar = ({
  articleBodyRef,
  ignoreHeader = false,
}: ReadingProgressBarProps) => {
  const { isPhonePortrait } = usePhonePortraitContext();

  const getScrollProgress = useCallback(() => {
    const { current: articleBody } = articleBodyRef;
    const { scrollTop, clientHeight } = document.documentElement;
    if (!articleBody) {
      return "0%";
    }

    const scrollOffset = articleBody.offsetTop;
    const scrollPx = COUNT_VISIBLE_AS_READ
      ? scrollTop - scrollOffset + clientHeight
      : scrollTop - scrollOffset;
    const winHeightPx = COUNT_VISIBLE_AS_READ
      ? articleBody.offsetHeight
      : articleBody.offsetHeight - clientHeight;
    const scrollProgress = `${
      truncateToZeroToOne(scrollPx / winHeightPx) * 100
    }%`;
    return scrollProgress;
  }, [articleBodyRef]);

  const [isHidden, setIsHidden] = useState(getIsHidden);
  const [scrollProgress, setScrollProgress] = useState(getScrollProgress);

  const onScroll = useCallback(() => {
    setIsHidden(getIsHidden);
    setScrollProgress(getScrollProgress);
  }, [getScrollProgress]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return (
    <div
      className={getClasses(
        styles["reading-progress-bar"],
        isHidden && styles["reading-progress-bar--hidden"],
        isPhonePortrait && styles["reading-progress-bar--phone"],
        ignoreHeader && styles["reading-progress-bar--ignore-header"],
      )}
    >
      <div
        className={styles["indicator"]}
        style={{
          width: scrollProgress,
        }}
      ></div>
    </div>
  );
};

export default ReadingProgressBar;
