import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ReactComponent as WriteForUsBanner } from "assets/svg/write-for-us/write-for-us-banner.svg";
import { ReactComponent as OutlineArrowRight } from "assets/svg/outline-arrow-right.svg";
import { ReactComponent as Step1Icon } from "assets/svg/write-for-us/step-1.svg";
import { ReactComponent as Step2Icon } from "assets/svg/write-for-us/step-2.svg";
import { ReactComponent as Step3Icon } from "assets/svg/write-for-us/step-3.svg";
import { ReactComponent as Step4Icon } from "assets/svg/write-for-us/step-4.svg";
import { ReactComponent as IncompleteDrafts } from "assets/svg/write-for-us/incomplete-drafts.svg";
import { ReactComponent as PromotionalCopy } from "assets/svg/write-for-us/promotional-copy.svg";
import { ReactComponent as InaccurateContent } from "assets/svg/write-for-us/inaccurate-content.svg";
import { ReactComponent as PublishedWork } from "assets/svg/write-for-us/published-work.svg";
import { ReactComponent as OutlineUserSpeaker32 } from "assets/svg/outline-user-speaker-32.svg";
import { ReactComponent as OutlineFilters32 } from "assets/svg/outline-filters-32.svg";
import { ReactComponent as OutlineGrid32 } from "assets/svg/outline-grid-32.svg";
import { ReactComponent as OutlineCut } from "assets/svg/outline-cut.svg";
import { ReactComponent as OutlineEmotxdSad } from "assets/svg/outline-emotxd-sad.svg";
import { ReactComponent as SubmitBlog } from "assets/svg/write-for-us/submit-blog.svg";
import { ReactComponent as StyleGuidelinesEllipse } from "assets/svg/write-for-us/style-guidelines-ellipse.svg";
import FullWidthImage from "components/full-width-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import { Link } from "react-router-dom";
import getClasses from "utils/getClasses";
import styles from "./WriteForUs.module.scss";
import { AmplitudePageName, track } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import WriteForUsPhoneView from "./WFUPhoneView";
import Footer from "components/footer/Footer";
import { Helmet } from "react-helmet";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "write-for-orchid-page";

const WRITE_FOR_US_LANDING = "/img/write-for-us/hero-page.svg";
const ILLUSTRATION_1 = "/img/write-for-us/illustration1.png";
const ILLUSTRATION_2 = "/img/write-for-us/illustration2.png";

export const questions = [
  {
    id: "0",
    question: "When will I hear back about my submission?",
    answer:
      "Our editorial team is small but mighty — you can expect to hear back from us with an answer within 1-2 weeks.",
  },
  {
    id: "1",
    question: "If my submission has been accepted, what happens next?",
    answer:
      "Once we've given a thumbs up to your submission, our editorial team will share any feedback necessary to enhance your post's readability or accessibility. That editing process will take place in Google docs as Suggestions and Comments. If substantial changes are needed, our team will send the draft back to you to make those changes. You'll be given the opportunity to read and approve the final polished blog post before publication.",
  },
  {
    id: "2",
    question: "When will my accepted blog post be published?",
    answer:
      "Date of publication depends on the current volume of our content calendar. We'll communicate your publication date with you as soon as we can confirm it. If your post involves timely subject matter (for example, related to a current event or themed month), please note that in your submission.",
  },
  {
    id: "3",
    question: "Do I get anything in exchange for publishing my blog post?",
    answer:
      "Publishing your content on Orchid's blog gives your business a key benefit: exposure. Your post will link directly to your Orchid profile, where professionals can message you and patients can book sessions with you. We also promote your post and business on Orchid's social media channels, helping your practice reach a wider audience.",
  },
  {
    id: "4",
    question:
      "What if I want to pitch an idea but haven't written the post yet?",
    answer:
      "You're welcome to email us at info@orchid.health with your ideas! If you're uncertain whether your topic is suitable for Orchid's blog, we'll be happy to answer your questions. We do not, however, give feedback on partial drafts. Please only submit complete post drafts or brief idea pitches.",
  },
  {
    id: "5",
    question: "Anything else I should know about publishing on Orchid's blog?",
    answer:
      "If we publish your post to Orchid's blog, Orchid obtains full rights to the content, including but not limited to: editing, mixing, duplicating, using, or reusing it in whole or in part. Once published on Orchid, please do not publish your Orchid blog post anywhere else. However, you are always welcome and encouraged to share a link to your published Orchid post wherever you'd like.",
  },
];

const WriteForUs = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  const { isPhonePortrait } = usePhonePortraitContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Write for Orchid</title>
        <meta
          name="description"
          content="Orchid is more than an electronic health record—we're a community who cares about growing your private practice. Get featured and inspire thousands."
        />
        <meta property="og:title" content="Write for Orchid" />
        <meta
          property="og:description"
          content="Orchid is more than an electronic health record—we're a community who cares about growing your private practice. Get featured and inspire thousands."
        />

        <meta name="twitter:title" content="Write for Orchid" />
        <meta
          name="twitter:description"
          content="Orchid is more than an electronic health record—we're a community who cares about growing your private practice. Get featured and inspire thousands."
        />
      </Helmet>
      {isPhonePortrait ? (
        <WriteForUsPhoneView />
      ) : (
        <div className={styles["write-for-us"]}>
          <FullWidthImage src={WRITE_FOR_US_LANDING} fluid />
          <div className={styles["write-for-us__intro"]}>
            <Container
              className={getClasses(
                styles["write-for-us__intro-container"],
                styles["write-for-us__container"],
              )}
            >
              <h1
                className={getClasses(
                  styles["write-for-us__bottom-56"],
                  styles["write-for-us__title"],
                )}
              >
                Write for the Orchid Community
              </h1>

              <div className={styles["write-for-us__intro-paragraph"]}>
                <b>
                  Orchid is more than an electronic health record platform —
                  we're a growing community of mental health professionals who
                  care about growth and wellness, and our emerging blog reflects
                  that.
                </b>
              </div>
              <div className={styles["write-for-us__intro-paragraph"]}>
                If you’re a mental health professional with any experience with
                blogging about mental healthcare topics, then we want to hear
                from you! You could fit right into our community of healthcare
                professional contributors on{" "}
                <Link
                  to="/blogs"
                  onClick={() =>
                    track(AMPLITUDE_PAGE_NAME, "orchid-blog-link", "click")
                  }
                >
                  The Orchid blog
                </Link>
                .
              </div>
              <div className={styles["write-for-us__intro-paragraph"]}>
                <b>
                  Before submitting any blog posts for consideration, please
                  thoroughly review the submission guidelines and FAQ below.
                </b>{" "}
                Your submission must meet our requirements in order to get
                published on Orchid's blog. Orchid's editorial team reserves the
                right to reject submissions at our discretion.
              </div>
            </Container>
          </div>
          <div className={styles["write-for-us__guidelines"]}>
            <Container className={styles["write-for-us__container"]}>
              <h1
                className={getClasses(
                  styles["write-for-us__bottom-40"],
                  styles["write-for-us__title"],
                )}
              >
                Guidelines for Blog Submissions
              </h1>
              <div className={styles["write-for-us__submit-requirements"]}>
                <div
                  className={styles["write-for-us__submit-requirements-title"]}
                >
                  Submission requirements
                </div>
                <div className={"rg-18"}>
                  Following these content and quality guidelines when writing
                  your blog post will give you a better chance at publication on
                  Orchid's blog.
                </div>
              </div>
              <Row className={styles["write-for-us__guidelines-row"]}>
                <Col className={styles["write-for-us__guidelines-left"]}>
                  <FullWidthImage src={ILLUSTRATION_1} fluid />
                </Col>
                <Col className={styles["write-for-us__guidelines-right"]}>
                  <h4 className={styles["write-for-us__sub-title"]}>
                    Write for Orchid's primary audience
                  </h4>
                  <div className={styles["write-for-us__bottom-56"]}>
                    Orchid's blog is intended for an audience of mental health
                    professionals. Please only submit blog posts meant for that
                    audience, not for patients or the general public. Our
                    audience of mental health professionals includes (but is not
                    limited to): therapists, psychologists, psychiatrists,
                    social workers, policymakers, and researchers in the United
                    States.
                  </div>
                  <h4 className={styles["write-for-us__sub-title"]}>
                    Align your post’s topic with Orchid’s content categories
                  </h4>
                  <div className={"mb-3"}>
                    We’re interested in considering in-depth, actionable blog
                    posts about any topic that’s important to mental health
                    professionals. We’re particularly interested in blog posts
                    that share practical advice and expertise on topics that fit
                    into our blog’s content categories:
                  </div>
                  <ul>
                    <li>
                      Professional Development: practice management, business
                      growth, continuing education
                    </li>
                    <li>Clinician Wellness: personal growth, self-care</li>
                    <li>Industry Research: case studies, reports</li>
                    <li>
                      In the News: current trends and developments in mental
                      healthcare
                    </li>
                  </ul>
                  <div className={styles["write-for-us__bottom-56"]}>
                    <Link
                      to="/blogs"
                      onClick={() =>
                        track(AMPLITUDE_PAGE_NAME, "orchid-blog-link", "click")
                      }
                    >
                      Read current posts on our blog
                    </Link>{" "}
                    to get a better sense of the types of topics your blog post
                    could focus on.
                  </div>

                  <h4 className={styles["write-for-us__sub-title"]}>
                    Make the point of your post clear
                  </h4>
                  <div>
                    Have you ever read something and walked away uncertain about
                    what you were supposed to gain from reading it? Make sure
                    your blog post doesn't create that type of experience. Your
                    blog post should communicate a clear central premise that
                    includes valuable takeaways that are relevant and useful to
                    Orchid's audience. Whatever you choose to write about, make
                    it clear from the first paragraph what your point is and why
                    it matters.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className={styles["write-for-us__style-guidelines"]}>
            <Container
              className={getClasses(
                styles["write-for-us__container"],
                styles["write-for-us__style-guidelines-container"],
              )}
            >
              <h4 className={styles["write-for-us__sub-title"]}>
                Follow Orchid's style guidelines
              </h4>
              <p className={"m-0"}>
                Style matters as much as substance when creating blog posts.
              </p>
              <p>
                Following these guidelines will help you craft more legible and
                engaging content.
              </p>
              <div className={styles["write-for-us__style-guidelines-content"]}>
                <Row className={styles["write-for-us__style-guidelines-row"]}>
                  <OutlineUserSpeaker32 />
                  <span
                    className={styles["write-for-us__style-guidelines-right"]}
                  >
                    Use language that's natural to your voice. When in doubt,
                    lean into being conversational over academic in tone.
                  </span>
                </Row>
                <Row className={styles["write-for-us__style-guidelines-row"]}>
                  <OutlineCut />
                  <span
                    className={styles["write-for-us__style-guidelines-right"]}
                  >
                    Structure sentences that are simple, clear, and the most
                    direct routes to your point.
                  </span>
                </Row>
                <Row className={styles["write-for-us__style-guidelines-row"]}>
                  <OutlineFilters32 />
                  <span
                    className={styles["write-for-us__style-guidelines-right"]}
                  >
                    Break up your text with bold section headings that emphasize
                    the key points of your post.
                  </span>
                </Row>
                <Row className={styles["write-for-us__style-guidelines-row"]}>
                  <OutlineGrid32 />
                  <span
                    className={styles["write-for-us__style-guidelines-right"]}
                  >
                    Use bullet points or subheadings where appropriate.
                    Skimmable content structures like those give your words more
                    room to breathe and help readers digest information better
                    than long blocks of text.
                  </span>
                </Row>
                <Row className={styles["write-for-us__style-guidelines-row"]}>
                  <OutlineEmotxdSad />
                  <span
                    className={styles["write-for-us__style-guidelines-right"]}
                  >
                    Avoid jargon. If you must use a term that's not widely known
                    by mental health professionals, define that term clearly in
                    your post.
                  </span>
                </Row>
              </div>

              <p>
                Please take the time to self-edit your post along these
                guidelines before submitting it to Orchid. Not sure where to
                begin with editing? You could try this{" "}
                <a
                  href="https://hemingwayapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    track(AMPLITUDE_PAGE_NAME, "hemingwayapp-link", "click")
                  }
                >
                  free editing app
                </a>
                — it helps you identify where your sentence structure and
                vocabulary could benefit from a refresh (and why).
              </p>
            </Container>
            <StyleGuidelinesEllipse
              className={styles["write-for-us__style-guidelines-img"]}
            />
          </div>
          <Container className={styles["write-for-us__container"]}>
            <Row className={styles["write-for-us__guidelines-row"]}>
              <Col className={styles["write-for-us__guidelines-left"]}>
                <FullWidthImage src={ILLUSTRATION_2} fluid />
              </Col>
              <Col className={styles["write-for-us__guidelines-right"]}>
                <h4 className={styles["write-for-us__sub-title"]}>
                  Pay attention to word count
                </h4>
                <div className={styles["write-for-us__bottom-56"]}>
                  Although we won't reject a submission based solely on length,
                  800-1,800 words is a good word count range to target. For
                  example, a post around 1,200 words is long enough to convey a
                  meaningful amount of information, but not too long that
                  readers might be deterred from reading it in full. If your
                  post is shorter than 800 words or longer than 1,800 words,
                  we'll still consider it if it checks all the other
                  requirements.
                </div>
                <h4 className={styles["write-for-us__sub-title"]}>
                  Suggest a title for your post
                </h4>
                <div className={styles["write-for-us__bottom-56"]}>
                  The first thing readers will notice about your blog post is
                  the title. Capture their attention with a title that clearly
                  communicates the topic or premise of the post. Keep your title
                  brief: 10 words or 100 characters maximum. It's okay if the
                  title isn't perfect — just give it your best effort. Orchid
                  reserves the right to edit the title in order to influence
                  marketing metrics.
                </div>
                <h4 className={styles["write-for-us__sub-title"]}>
                  Properly cite your work
                </h4>
                <div className={"mb-3"}>
                  If directing to appropriate online sources: Use hyperlinks
                  with text that makes it clear where that hyperlink will direct
                  readers (instead of simply writing something like “Read
                  more”). Please minimize the number of links in your article
                  (10 maximum).
                </div>
                <div className={"mb-3"}>
                  If citing offline sources: Cite references according to{" "}
                  <a
                    href="https://apastyle.apa.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      track(
                        AMPLITUDE_PAGE_NAME,
                        "APA-style-guidelines-link",
                        "click",
                      )
                    }
                  >
                    American Psychological Associate (APA) style guidelines
                  </a>
                  . Integrate your references directly into the text; don't use
                  footnotes.
                </div>
                <div className={styles["write-for-us__bottom-56"]}>
                  Your blog post should be understood as a standalone piece, but
                  if it's related to a larger body of work or journal article,
                  please briefly give that context in your post along with
                  hyperlinks or citations where appropriate.
                </div>
                <h4 className={styles["write-for-us__sub-title"]}>
                  Include an author bio
                </h4>
                <div>
                  We ask that all authors include a short biography (100 words
                  max). That will appear in the publication at the bottom of
                  your blog post. Biographies should include your name, your
                  institutional affiliation and position, and the focus of your
                  work.
                </div>
              </Col>
            </Row>

            <hr />
            <div className={styles["write-for-us__submission-decline"]}>
              <h1
                className={getClasses(
                  styles["write-for-us__bottom-40"],
                  styles["write-for-us__title"],
                )}
              >
                Submissions we won't accept
              </h1>
            </div>

            <div className={styles["write-for-us__submission-decline-warning"]}>
              Not following the submission requirements above and/or doing any
              of the following no-no's will make it unlikely we'd accept your
              blog post submission.
            </div>

            <Row className={styles["write-for-us__submission-decline-row"]}>
              <IncompleteDrafts />
              <span
                className={styles["write-for-us__submission-decline-right"]}
              >
                <h4 className={styles["write-for-us__sub-title"]}>
                  Incomplete drafts
                </h4>
                <p className={"mb-0"}>
                  Please only submit complete post drafts. Our editors will only
                  put your post through a single round of edits, so your post
                  should be your complete and best work when you send it to us.
                  Partial drafts, outlines, or posts lacking a clear central
                  premise will not be accepted.
                </p>
              </span>
            </Row>

            <Row className={styles["write-for-us__submission-decline-row"]}>
              <PromotionalCopy />
              <span
                className={styles["write-for-us__submission-decline-right"]}
              >
                <h4 className={styles["write-for-us__sub-title"]}>
                  Promotional copy
                </h4>
                <p className={"mb-0"}>
                  Do not include promotional language about yourself or your
                  organization. Your post must not be construed as a
                  link-building scheme, and it shouldn't contain
                  marketing-related links or promotional language about any
                  businesses, products, or events.
                </p>
              </span>
            </Row>

            <Row className={styles["write-for-us__submission-decline-row"]}>
              <InaccurateContent />
              <span
                className={styles["write-for-us__submission-decline-right"]}
              >
                <h4 className={styles["write-for-us__sub-title"]}>
                  Offensive or inaccurate content
                </h4>
                <p className={"mb-0"}>
                  Do not include offensive language or discriminatory content.
                  Your post should also not be overly critical of individuals or
                  companies. Do not violate the confidentiality, privacy, or
                  privilege of any protected relationships, information, or
                  communications.{" "}
                </p>
              </span>
            </Row>

            <Row className={styles["write-for-us__submission-decline-row"]}>
              <PublishedWork />
              <span
                className={styles["write-for-us__submission-decline-right"]}
              >
                <h4 className={styles["write-for-us__sub-title"]}>
                  Previously published work
                </h4>
                <p className={"mb-0"}>
                  Please only submit original content that has not been
                  published anywhere else. We will not accept your submission if
                  it has already appeared in another website, blog, social media
                  platform, or other form of publication.{" "}
                </p>
              </span>
            </Row>
            <hr className={styles["write-for-us__hr"]} />

            <div className={styles["write-for-us__faq"]}>
              <h1
                className={getClasses(
                  styles["write-for-us__bottom-56"],
                  styles["write-for-us__title"],
                )}
              >
                Blog Submissions FAQ
              </h1>
              {questions.map(({ id, question, answer }, idx) => {
                return (
                  <CustomAccordion
                    key={id}
                    id={id}
                    index={idx}
                    question={question}
                    answer={answer}
                    amplitudePageName={AMPLITUDE_PAGE_NAME}
                  />
                );
              })}
            </div>
          </Container>
          <div className={"justify-content-center d-flex"}>
            <WriteForUsBanner />
          </div>

          <Container className={styles["write-for-us__submit-blog"]}>
            <h1
              className={getClasses(
                styles["write-for-us__title"],
                styles["write-for-us__bottom-56"],
              )}
            >
              How to Submit a Blog Post
            </h1>
            <div>
              <Row className={styles["write-for-us__submit-blog-row"]}>
                <Col className={styles["write-for-us__submit-blog-left"]}>
                  <Step1Icon />
                </Col>

                <Col className={styles["write-for-us__submit-blog-right"]}>
                  <div className={styles["write-for-us__submit-blog-title"]}>
                    Make sure your full post draft is readable and editable in
                    one of the following formats:
                  </div>
                  <div className={styles["write-for-us__submit-blog-content"]}>
                    <div>a. Word doc (.doc, .docx) </div>
                    <div>
                      b. Google doc (with the{" "}
                      <a
                        href="https://support.google.com/docs/answer/2494822?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Cshare-a-link-to-the-file%2Cshare-a-file-publicly"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          track(
                            AMPLITUDE_PAGE_NAME,
                            "google-doc-share-settings-link",
                            "click",
                          )
                        }
                      >
                        Share settings
                      </a>{" "}
                      of "Anyone on the internet with this link can edit")
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className={styles["write-for-us__submit-blog-row"]}>
                <Col className={styles["write-for-us__submit-blog-left"]}>
                  <Step2Icon />
                </Col>

                <Col className={styles["write-for-us__submit-blog-right"]}>
                  <div className={styles["write-for-us__submit-blog-title"]}>
                    Attach your Word doc or link to your Google doc in an email.
                    Remember that doc must include your:
                  </div>
                  <div className={styles["write-for-us__submit-blog-content"]}>
                    <div>a. Suggested title for your blog post</div>
                    <div>
                      b. Full draft of your blog post (edited and formatted
                      according to our submission guidelines)
                    </div>
                    <div>c. Short author bio (at the bottom of your post)</div>
                  </div>
                </Col>
              </Row>

              <Row className={styles["write-for-us__submit-blog-row"]}>
                <Col className={styles["write-for-us__submit-blog-left"]}>
                  <Step3Icon />
                </Col>

                <Col className={styles["write-for-us__submit-blog-right"]}>
                  <div className={styles["write-for-us__submit-blog-title"]}>
                    Add the email subject line "Orchid Blog Post Submission"
                  </div>
                </Col>
              </Row>

              <Row className={styles["write-for-us__submit-blog-row"]}>
                <Col className={styles["write-for-us__submit-blog-left"]}>
                  <Step4Icon />
                </Col>

                <Col className={styles["write-for-us__submit-blog-right"]}>
                  <div className={styles["write-for-us__submit-blog-title"]}>
                    Send your submission to{" "}
                    <a
                      href="mailto:info@orchid.health"
                      onClick={() =>
                        track(
                          AMPLITUDE_PAGE_NAME,
                          "info@orchid-email-link",
                          "click",
                        )
                      }
                    >
                      info@orchid.health
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
            <SubmitBlog className={styles["write-for-us__submit-blog-img"]} />
          </Container>
          <div className={styles["write-for-us__footer"]}>
            <h1 className={getClasses("white", styles["write-for-us__title"])}>
              Ready to submit?
            </h1>
            <Button
              className={styles["write-for-us__action"]}
              as="a"
              href="mailto:info@orchid.health"
              onClick={() =>
                track(AMPLITUDE_PAGE_NAME, "send-your-pitch-button", "click")
              }
            >
              <OutlineArrowRight />
              <div className={styles["write-for-us__action-text"]}>
                Send your pitch
              </div>
            </Button>
          </div>
          <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
        </div>
      )}
    </>
  );
};

export default WriteForUs;
