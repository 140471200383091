const OBSERVER_LOOP = "ResizeObserver loop limit exceeded";

/**
 * check if should suppress errors
 * @param msg error message or event
 */
export default function shouldSuppressError(msg: string | Event) {
  const message = typeof msg === "string" ? msg : msg.toString();
  // omit resize observer string
  if (message.includes(OBSERVER_LOOP)) {
    return false;
  }
}
