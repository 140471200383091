import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import queryInfoList from "services/queryInfoList";
import queryNoteMetaDataMinimals from "services/queryNoteMetaDataMinimals";
import fetchAllPages from "utils/fetch-all-pages";

export const notesCustomApi = createApi({
  reducerPath: "notesCustomApi",
  baseQuery: fakeBaseQuery(), // In this case we're only using RTK Query for the caching, hooks etc., not actually making requests
  tagTypes: ["note-for-appointment"],
  endpoints: (builder) => ({
    getAllNotes: builder.query<
      NoteDataInfo[],
      { appointmentId: string | number }
    >({
      queryFn: async (arg) => {
        try {
          const noteMetaDataMinimals = await fetchAllPages(
            queryNoteMetaDataMinimals,
            {
              appointment_id: arg.appointmentId,
            },
          );

          if (noteMetaDataMinimals.length === 0) {
            return { data: [] };
          }

          const allNotesRevisions = await fetchAllPages(queryInfoList, {
            note_data_id: noteMetaDataMinimals.map((note) => note.note_data_id),
          });

          const latestNotesRevisionsMap = allNotesRevisions.reduce(
            (acc, note) => {
              if (!acc[note.note_data_id]) {
                acc[note.note_data_id] = note;
              } else if (
                acc[note.note_data_id].note_revision < note.note_revision
              ) {
                acc[note.note_data_id] = note;
              }

              return acc;
            },
            {} as Record<string, NoteDataInfo>,
          );

          const latestNotesRevisions = Object.values(latestNotesRevisionsMap);

          return { data: latestNotesRevisions };
        } catch (e) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: e?.toString(),
            } as FetchBaseQueryError,
          };
        }
      },
      providesTags: (_result, _error, args) => [
        { type: "note-for-appointment", id: args.appointmentId.toString() },
      ],
    }),
  }),
});

export const { useGetAllNotesQuery } = notesCustomApi;
