import React, { ReactNode } from "react";
import ModalHeader from "react-bootstrap/ModalHeader";
import { ModalProps } from "react-bootstrap/Modal";
import { ReactComponent as CloseButton } from "assets/svg/outline-cross.svg";
import IconButton from "../../icon-button";
import styles from "./ModalHeader.module.scss";
import getClasses from "utils/getClasses";

type Props = {
  Header: ReactNode;
  onHide: ModalProps["onHide"];
  className?: string;
  disabled?: boolean;
  showHeaderDivider?: boolean;
};

const ModalHeaderLayout = ({
  Header,
  onHide,
  className = "",
  disabled = false,
  showHeaderDivider,
}: Props) => (
  <ModalHeader
    className={getClasses(
      styles["modal-header"],
      !showHeaderDivider && "border-0",
      className,
    )}
    closeButton={false}
  >
    <div className={styles["modal-header__title"]}>{Header}</div>
    {onHide && (
      <IconButton
        className={styles["modal-header__close"]}
        onClick={onHide}
        disabled={disabled}
      >
        <CloseButton />
      </IconButton>
    )}
  </ModalHeader>
);

export default ModalHeaderLayout;
