import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import FullWidthImage from "components/full-width-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import Footer from "components/footer/Footer";
import LazyImage from "components/lazy-image";
import { Link } from "react-router-dom";
import { AmplitudePageName, track } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { stats, questions } from "./PartnerWithOrchid";
import { ReactComponent as DownArrow } from "assets/svg/partner-with-orchid/down-arrow-phone.svg";

import styles from "./PartnerWithOrchidMobile.module.scss";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "partner-with-orchid";

const LANDING_URL_PHONE = "/img/partner-with-orchid/landing-phone.png";
const BENEFIT_1_URL_PHONE = "/img/partner-with-orchid/benefit-1-phone.png";
const BENEFIT_2_URL_PHONE = "/img/partner-with-orchid/benefit-2-phone.png";
const BENEFIT_3_URL_PHONE = "/img/partner-with-orchid/benefit-3-phone.png";
const AMHC_COFOUNDER_URL_PHONE = "/img/partner-with-orchid/amhc-cofounder-phone.png";
const CASE_STUDY_PDF_URL = "/pdf/AMHC_X_Orchid_Case_Study.pdf"

const PartnerWithOrchidMobile = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  return (
    <div className={styles["phone-view"]}>
      <div className={styles["landing"]}>
        <div className={styles["landing__title"]}>
          Make a bigger impact with Orchid
        </div>
        <div className={styles["landing__content"]}>
          Save your organization more time, money, and lives with Orchid’s customized partnership solutions.
        </div>
        <div className={"d-flex justify-content-center"}>
          <LazyImage
            src={LANDING_URL_PHONE}
            width={335}
            className={styles["landing__image"]}
          />
        </div>
        <Button
          className={styles["action"]}
          target={"_blank"}
          as={Link}
          to={"/about-us"}
          onClick={() => {
            track(
              AMPLITUDE_PAGE_NAME,
              "mobile-partner-with-orchid-button",
              "click",
            );
          }}
        >
          Partner with Orchid
        </Button>
      </div>

      <div className={styles["container"]}>
        <h2 className={styles["title"]}>
          Get more when you partner <br />
          with Orchid
        </h2>
        <div className={styles["start-trial"]}>
          More than just a top-notch EHR for private practice clinicians, Orchid is your mental health organization’s personalized solution for achieving better results with fewer resources.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={BENEFIT_1_URL_PHONE} fluid />
        </div>
        <div className={styles["step-title"]}>
          Save more annually
        </div>
        <div className={styles["step-content"]}>
          Let us handle the time-consuming administrative work that’s holding you back from making a bigger impact on your community. 
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={BENEFIT_2_URL_PHONE} fluid />
        </div>
        <div className={styles["step-title"]}>
          Convert more humans to your cause
        </div>
        <div className={styles["step-content"]}>
          Need support with conversion marketing, analytics insights, or customer care? You name it, we can offer you bespoke automations and hands-on assistance from a dedicated team.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={BENEFIT_3_URL_PHONE} fluid />
        </div>
        <div className={styles["step-title"]}>
          Access an exclusive mental health network
        </div>
        <div className={styles["step-content"]}>
          Enrich your community by connecting with our growing network of clinicians and clients.
        </div>
      </div>

      <div className={styles["case-study"]}>
        <div className={styles["case-study__discover"]}>
          Discover how partner organizations have benefited from Orchid so far…
        </div>
        <div>
          <DownArrow />
        </div>

        <div className={styles["case-study__case-study"]}>
          CASE STUDY:
        </div>
        <div className={styles["case-study__amhc"]}>
          Asian Mental Health Collective
        </div>
        <div className={styles["case-study__learn"]}>
          Learn how the non-profit AMHC saves more time & shows up for their community through <br /> their partnership with Orchid.
        </div>

        <a href={CASE_STUDY_PDF_URL} download="AMHC_X_Orchid_Case_Study.pdf" className={styles["download"]}>
          <Button
            size={"lg"}
            className={styles["action"]}
            onClick={() => {
              track(
                AMPLITUDE_PAGE_NAME,
                "mobile-download-case-study-button",
                "click",
              );
            }}
          >
            Download the Case Study
          </Button>
        </a>

        <Card className={styles["quote-card"]}>
        <FullWidthImage className={styles["quote-card__img"]} src={AMHC_COFOUNDER_URL_PHONE} fluid />
          <div className={styles["quote-card__box"]}>
            <div className={styles["quote-card__text"]}>
              <span>“ </span> Orchid has shown a commitment to making mental healthcare more accessible by offering clinicians a more robust and affordable platform. When choosing a partner, it was important to find a company that was dedicated to supporting what we believed in: the desire to give back to the mental health community at large.<span> ”</span>
            </div>
            <div className={styles["quote-card__citation"]}>
              — Christopher Vo, AMHC Co-Founder
            </div>
          </div>
        </Card>
      </div>
      
      <div className={styles["challenge"]}>
        <div className={styles["challenge__box"]}>
          <div className={styles["challenge__title"]}>
            The Challenge
          </div>

          <div className={styles["challenge__text"]}>
            When the non-profit organization Asian Mental Health Collective (AMHC) funded a whopping $130,000 worth of free therapy sessions for AAPI clients through their Lotus Therapy Fund (LTF), they needed a streamlined way to connect AMHC providers with grantees. 
          </div>
        </div>

        <div className={styles["challenge__box"]}>
          <div className={styles["challenge__title"]}>
            The Solution
          </div>

          <div className={styles["challenge__text"]}>
            Orchid rose to that challenge: Powered by our all-in-one EHR platform, over 100 LTF grantees received free therapy from AMHC providers through Orchid and set out on their mental health journeys together. <br /><br />

            And we didn’t stop there: Partnering with Orchid also helped AMHC reduce overhead and grow their network, thanks to Orchid’s bespoke analytics reports and conversion marketing campaign.
          </div>
        </div>
        
        <div className={styles["challenge__result-card"]}>
          <div className={styles["challenge__title"]}>
            The Result
          </div>

          <div className={styles["challenge__text"]}>
          Orchid saved AMHC time, money, and headaches, so they could keep up the hard work of connecting the AAPI community with life-saving mental health care. 
          </div>

          <div>
            {stats.map((stat) => 
              <Card 
                key={stat.id}
                className={styles["stat"]}
              >
                <div className={styles["stat__title"]}>{stat.title}</div>
                <div
                  className={styles["stat__text"]}
                  dangerouslySetInnerHTML={{ __html: stat.text }}
                />
              </Card>
            )}
          </div>
        </div>

        <a href={CASE_STUDY_PDF_URL} download="AMHC_X_Orchid_Case_Study.pdf" className={styles["download"]}>
          <Button
            size={"lg"}
            className={styles["action"]}
            onClick={() => {
              track(
                AMPLITUDE_PAGE_NAME,
                "mobile-download-case-study-button",
                "click",
              );
            }}
          >
            Download the Case Study
          </Button>
        </a>
      </div>

      <div className={styles["faq"]}>
        <h1 className={styles["faq__title"]}>
          Questions? <br /> We’ve got answers.
        </h1>
            
        {questions.map(({ id, question, answer }, idx) =>
          <div className={styles["faq__text"]}>
            <CustomAccordion
              key={id}
              id={id}
              index={idx}
              question={question}
              answer={answer}
              amplitudePageName={AMPLITUDE_PAGE_NAME}
            />
          </div>
        )}
      </div>

      <div className={styles["more-question"]}>
        <div className={styles["more-question__title"]}>
          Got more questions about Orchid?
        </div>
        <Button
          className={styles["more-question__action"]}
          as={Link}
          to={"/orchid-ehr-faq"}
          size={"lg"}
        >
          Read the rest of our FAQs
        </Button>
      </div>

      <div className={styles["get-power"]}>
        <div className={styles["get-power__title"]}>
          Get the power of <br /> Orchid today.
        </div>
        <div className={styles["get-power__text"]}>
          Grow your organization with more efficiency, support, and insights for an affordable fee. 
        </div>

        <Button
          size={"lg"}
          className={styles["action"]}
          as={"a"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={"https://calendly.com/hey-orchid/orchid-walkthrough"}
          onClick={() => {
            track(
              AMPLITUDE_PAGE_NAME,
              "mobile-sign-up-for-demo-button",
              "click",
            );
          }}
        >
          Get your free demo
        </Button>
      </div>
      
      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default PartnerWithOrchidMobile;
