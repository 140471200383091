import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import React, { ReactNode, useEffect } from "react";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";
// questrial
import "@fontsource/questrial/400.css";
// noto sans
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/600.css";
import "@fontsource/noto-sans/700.css";
// noto serif
import "@fontsource/noto-serif/400.css";
/**
 * renders a page layout component that is a direct child of #root and should supply font and other basic styling
 */
const Layout = ({ children }: { children: ReactNode }) => {
  const { isPhonePortrait } = usePhonePortraitContext();

  useEffect(() => {
    // need to override _reboot.scss from bootstrap
    if (isPhonePortrait) {
      document.body.classList.add(styles["body--phone"]);
    } else {
      document.body.classList.remove(styles["body--phone"]);
    }
  }, [isPhonePortrait]);

  return (
    <div
      className={getClasses(
        styles["layout"],
        isPhonePortrait && styles["layout--phone"],
      )}
    >
      {children}
    </div>
  );
};

export default Layout;
