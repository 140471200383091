import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useUser } from "contexts/UserContext";
import logoutUser from "components/after-login-navbar/utils/logoutUser";
import { ReactComponent as OrchidLogo } from "assets/svg/orchid-logo.svg";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const OrchidFooter = ({ hideSignIn }: { hideSignIn?: boolean }) => {
  const { user } = useUser();
  return (
    <div className={styles["footer"]}>
      <Container className="p-0">
        <Row>
          <Col></Col>
          <Col>
            <div className={"text-center"}>
              <div
                className={"d-flex align-items-center justify-content-center"}
              >
                <div className={"gray01 mr-2"}>Powered by</div>
                <OrchidLogo height={20} />
              </div>
              <div className={"mt-3"}>
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </Col>
          <Col className={"d-flex align-items-center justify-content-end"}>
            {hideSignIn ? null : user ? (
              <Button onClick={() => logoutUser(user)} variant={"link"}>
                Sign out
              </Button>
            ) : (
              <div>
                Sign in as{" "}
                <Link to="/login" target="_blank">
                  Existing User
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrchidFooter;
