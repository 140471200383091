import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { AmplitudePageName, track } from "utils/amplitude";
import { FooterSectionData } from "components/client-facing-landing-page/constants";
import getClasses from "utils/getClasses";
import styles from "./Footer.module.scss";

const HIPPA = "/img/hippa.png";

interface FooterSectionProps {
  section: FooterSectionData;
  amplitudePageName: AmplitudePageName;
  expanded?: boolean;
  handleViewAllClick?: () => void;
}

const FooterSection = ({
  section,
  amplitudePageName,
  expanded = false,
  handleViewAllClick,
}: FooterSectionProps) => (
  <section key={section.title} className={styles["footer-col"]}>
    <div className={styles["footer-col-title"]}>{section.title}</div>
    <ul className={styles["footer-col-text"]}>
      {section.links.map((link, i) => {
        const showExpandLink = i === section.collapseAfter && !expanded;
        const hideSectionItem =
          !expanded && section.collapseAfter && i >= section.collapseAfter;
        return (
          <React.Fragment key={link.id}>
            {showExpandLink && (
              <li>
                <span
                  className={styles["footer-col-link"]}
                  onClick={handleViewAllClick}
                >
                  {section.expandLabel}
                </span>
              </li>
            )}
            <li className={getClasses(hideSectionItem && styles["hidden"])}>
              <Link
                className={styles["footer-col-link"]}
                to={link.href}
                onClick={() =>
                  track(amplitudePageName, `${link.id}-link`, "click")
                }
              >
                {link.title}
              </Link>
            </li>
          </React.Fragment>
        );
      })}
    </ul>
    {section.secondaryLevel && (
      <div className={styles["footer-col-with-hippa"]}>
        <div className={styles["footer-col-with-hippa__up"]}>
          <div className={styles["footer-col-title"]}>
            {section.secondaryLevel.title}
          </div>
          <ul className={styles["footer-col-text"]}>
            {section.secondaryLevel.links.map((link) => (
              <li key={link.id}>
                <a
                  className={styles["footer-col-link"]}
                  title={link.title}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Image src={HIPPA} alt={"HIPPA Logo"} fluid width={115} height={62} />
      </div>
    )}
  </section>
);

export default FooterSection;
