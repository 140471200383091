import { TNotesType } from "components/Dashboard/DoctorDashboard/notes-and-forms/types";
import { getErrorDetail } from "components/utility/utils";
import { parseRawTemplateResponse } from "notes-and-forms-v2/util/convertTemplateToV2";
import { fetchOrchidAPI } from "utils/api";

export default async function putNoteOrFormData<T extends TNotesType>(
  type: T,
  dataId: number,
  arg: T extends "note"
    ? {
        note_schema_id: string | number;
        note_schema_revision: number;
        patient_cognito_sub?: string;
        data?: string;
        client_service_and_diagnosis?: string;
        change_reason?: string;
        change_type?: NoteChangeType;
        [key: string]: any;
      }
    : {
        orchid_pro_cognito_sub: string;
        data: string;
      },
): Promise<T extends "note" ? NoteData : OrchidFormData> {
  const response = await fetchOrchidAPI(
    `/api/notes_secure_data/v1/${type}s/data/${type}_data?${type}_data_id=${dataId}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(arg),
    },
  );

  if (!response.ok) {
    const responseText = await response.text();
    throw new Error(getErrorDetail(responseText));
  }

  if (type === "note") {
    const raw = await response.json();

    return parseRawTemplateResponse(type, raw);
  }

  return response.json();
}
