import { ChatMessageWithTimestamp } from "../pubnub.types";
import pubNubTimetokenToDate from "./pubnub-timetoken/pubnub-timetoken-to-date";

const compareMessageTimestamp = <T extends ChatMessageWithTimestamp>(
  lhs: T,
  rhs: T,
): number => {
  if (lhs.messageTimetoken === null && rhs.messageTimetoken === null) return 0;
  if (lhs.messageTimetoken === null) return 1;
  if (rhs.messageTimetoken === null) return -1;
  return (
    pubNubTimetokenToDate(lhs.messageTimetoken).getTime() -
    pubNubTimetokenToDate(rhs.messageTimetoken).getTime()
  );
};

const sortMessages = <T extends ChatMessageWithTimestamp>(messages: T[]) => {
  // return the input unmodified if it's already sorted
  let sorted = true;
  let prev: T | null = null;
  for (const m of messages) {
    if (prev && compareMessageTimestamp(prev, m) > 0) {
      sorted = false;
      break;
    }
    prev = m;
  }
  if (sorted) return messages;

  const newMessages = [...messages];
  newMessages.sort(compareMessageTimestamp);
  return newMessages;
};

export default sortMessages;
