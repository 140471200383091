import React, { ReactNode } from "react";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

const StickyFooter = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  return !isPhonePortrait ? null : (
    <div className={getClasses(styles["sticky-footer"], className)}>
      {children}
    </div>
  );
};

export default StickyFooter;
