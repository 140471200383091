import { fetchOrchidAPI } from "utils/api";

const reportRead = async (partnerSub: string) => {
  // ignore error, not a big deal unless it consistently happens
  await fetchOrchidAPI("/api/users/v1/contacts/report_read", {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ cognito_sub: partnerSub }),
  });
};

export default reportRead;
