import type PubNub from "pubnub";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import fetchPubnubHistory from "../api/fetch-pubnub-history";
import { ChannelToLoad, ChatAccessDataMapByChannel } from "../pubnub.types";
import chatHistoryReducer, {
  chatHistoryReducerInitialState,
} from "../state/chat-history-reducer";
import nextChannelToLoad from "../utils/next-channel-to-load";
import timestampToPubNubTimetoken from "../utils/pubnub-timetoken/timestamp-to-pubnub-timetoken";

export function useChatHistory(
  pubNubInstance: PubNub | null,
  chatAccessDataByChannel: ChatAccessDataMapByChannel,
  channelLoadHistoryPriority: string | null,
  userSub: string | undefined,
) {
  const channelsToLoad = useMemo(
    () =>
      Array.from(chatAccessDataByChannel.values())
        .filter((access) => access.cipherKey)
        .map(
          (access) =>
            ({
              channel: access.channel,
              cipherKey: access.cipherKey,
              limit: undefined,
              messageTimestampUntil: undefined,
            } as ChannelToLoad),
        ),
    [chatAccessDataByChannel],
  );

  const [
    {
      chatHistory,
      oldestMessageDatetimeByChannel,
      lastRequestedTimestampByChannel,
    },
    chatHistoryDispatch,
  ] = useReducer(chatHistoryReducer, chatHistoryReducerInitialState);

  useEffect(() => {
    chatHistoryDispatch({ type: "reset" });
  }, [userSub]);

  useEffect(() => {
    if (!pubNubInstance || !userSub) return;
    const pubnubListeners: PubNub.ListenerParameters = {
      messageAction: (messageActionEvent) => {
        const { channel, data, event } = messageActionEvent;
        if (data.type !== "reaction" || event !== "added") {
          return;
        }
        chatHistoryDispatch({
          type: "add_reaction",
          channel: channel,
          messageTimetoken: data.messageTimetoken,
          actionTimetoken: data.actionTimetoken,
          reactionEmoji: data.value,
        });
      },
    };
    pubNubInstance.addListener(pubnubListeners);

    return () =>
      pubNubInstance && pubNubInstance.removeListener(pubnubListeners);
  }, [pubNubInstance, userSub]);

  const loadMessages = useCallback(
    async (
      channel: string,
      cipherKey: string,
      timestamp: number,
      previousTimestamp: number,
      limit?: number,
    ) => {
      if (!pubNubInstance) {
        return;
      }
      chatHistoryDispatch({
        type: "set_last_requested_timestamp_by_channel",
        channel,
        timestamp,
      });
      try {
        const history = await fetchPubnubHistory({
          pubNubInstance,
          channel,
          privateChatCipherKey: cipherKey,
          onlyOlderThanTimetoken: timestampToPubNubTimetoken(timestamp),
          limit,
        });
        chatHistoryDispatch({
          type: "add",
          channel,
          messages: history,
        });
      } catch (err) {
        chatHistoryDispatch({
          type: "set_last_requested_timestamp_by_channel",
          channel,
          timestamp: previousTimestamp,
        });
      }
    },
    [pubNubInstance],
  );

  useEffect(() => {
    const channelToLoad = nextChannelToLoad(
      channelsToLoad,
      chatHistory,
      channelLoadHistoryPriority,
      lastRequestedTimestampByChannel,
    );
    if (!channelToLoad) return;
    const { channel, cipherKey } = channelToLoad;

    const lastRequestedTimestamp = lastRequestedTimestampByChannel[channel];

    const limit = channel !== channelLoadHistoryPriority ? 1 : undefined;
    loadMessages(channel, cipherKey, Date.now(), lastRequestedTimestamp, limit);
  }, [
    channelsToLoad,
    chatHistory,
    channelLoadHistoryPriority,
    oldestMessageDatetimeByChannel,
    lastRequestedTimestampByChannel,
    loadMessages,
  ]);

  const loadOlderMessagesForPartner = useCallback(
    async (partnerCognitoSub: string, currentLatestMessageDatetime: number) => {
      if (isNaN(currentLatestMessageDatetime)) {
        return;
      }
      const chatAccess = Array.from(chatAccessDataByChannel.values()).find(
        (item) => item.partnerCognitoSub === partnerCognitoSub,
      );
      if (!chatAccess?.channel || !chatAccess?.cipherKey) {
        return;
      }
      const { channel, cipherKey } = chatAccess;
      const lastRequestedTimestamp = lastRequestedTimestampByChannel[channel];
      if (lastRequestedTimestamp <= currentLatestMessageDatetime) {
        return;
      }

      loadMessages(
        channel,
        cipherKey,
        currentLatestMessageDatetime,
        lastRequestedTimestamp,
      );
    },
    [chatAccessDataByChannel, lastRequestedTimestampByChannel, loadMessages],
  );

  const oldestMessageDatetimeByPartnerSub = useMemo(() => {
    const result = new Map<string, number>();
    for (const chatAccess of chatAccessDataByChannel.values()) {
      result.set(
        chatAccess.partnerCognitoSub,
        oldestMessageDatetimeByChannel[chatAccess.channel],
      );
    }
    return result;
  }, [chatAccessDataByChannel, oldestMessageDatetimeByChannel]);

  return {
    chatHistory,
    oldestMessageDatetimeByPartnerSub,
    loadOlderMessagesForPartner,
  };
}
