import type { ReactNode } from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";
import type { DropdownToggleProps } from "react-bootstrap/esm/DropdownToggle";
import { ReactComponent as ChevronDown } from "assets/svg/outline-chevron-down-16.svg";

import getClasses from "utils/getClasses";
import styles from "./DropdownToggle.module.scss";

type Props = Omit<DropdownToggleProps, "title" | "onSelect"> & {
  title: ReactNode;
  iconSize?: string;
  Caret?: ReactNode;
  hideCaret?: boolean;
  menuClassName?: string;
  titleClassName?: string;
  caretClassName?: string;
  onClick?: () => void;
} & {
  onSelect?: DropdownProps["onSelect"];
  drop?: DropdownProps["drop"];
};

const DropdownToggle = ({
  className,
  title,
  iconSize,
  Caret,
  hideCaret,
  menuClassName,
  titleClassName,
  caretClassName,
  children,
  onSelect,
  drop,
  onClick,
  ...props
}: Props) => (
  <Dropdown
    className={getClasses(styles["dropdown-toggle"], className)}
    onSelect={onSelect}
    drop={drop}
    onClick={onClick}
  >
    <Dropdown.Toggle
      variant="outline-primary"
      children={
        <>
          <span
            className={getClasses(
              styles["dropdown-toggle__title"],
              titleClassName,
            )}
          >
            {title}
          </span>
          {!hideCaret && (
            <span
              className={getClasses(
                styles["dropdown-toggle__caret"],
                caretClassName,
              )}
            >
              {Caret || <ChevronDown width={"100%"} height={"100%"} />}
            </span>
          )}
        </>
      }
      role={"combobox"}
      tabIndex={0}
      {...props}
    />
    <Dropdown.Menu role={"listbox"} tabIndex={-1} className={menuClassName}>
      {children}
    </Dropdown.Menu>
  </Dropdown>
);

export default DropdownToggle;
