import { useEffect, useRef } from "react";
import { Alert, AlertProps } from "react-bootstrap";

type Props = {
  error: Error | string;
  offset?: number;
} & AlertProps;

const AutoFocusAlert = ({ error, offset, ...props }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (error && containerRef.current) {
      if (offset) {
        const y =
          containerRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          offset;
        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        containerRef.current.scrollIntoView();
      }
    }
  }, [error, offset]);

  return (
    <Alert variant="danger" ref={containerRef} {...props}>
      {error.toString()}
    </Alert>
  );
};

export default AutoFocusAlert;
