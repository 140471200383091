import React, { Component } from "react";
import { Button, Alert, Spinner, Modal } from "react-bootstrap";
import { getErrorDetail } from "../utility/utils.js";
import { fetchOrchidAPI } from "utils/api";
import ResponsiveModal from "components/responsive-modal";
import FullWidthImage from "components/full-width-image";
import { PhonePortraitContext } from "contexts/phone-portrait-context";
import IconButton from "components/icon-button";
import { ReactComponent as CloseButton } from "assets/svg/outline-cross.svg";
import styles from "./styles.module.scss";
import getClasses from "utils/getClasses";
const VERIFY_EMAIL_SENT = "/img/auth/verify-your-email.png";

class EmailSentModal extends Component {
  state = { error: null, notice: null };

  // onClickContinue = async () => {
  //   const userInfo = await this.props.updateUserInfo();
  //   if (!userInfo) {
  //     this.props.history.replace("/login");
  //     return;
  //   }
  //   const intakeFormUrl = userInfo.groups.includes("orchid_pro")
  //     ? "/quick-intake"
  //     : "/member-intake-form";
  //   if (userInfo.email_confirmed) {
  //     this.props.history.replace(intakeFormUrl);
  //   } else {
  //     this.setState({
  //       notice: "Please confirm your email first!",
  //       error: null,
  //     });
  //   }
  // };

  // suppress on hide on some routes
  onHide = () => {
    if (
      window.location.pathname.startsWith("/pro-dashboard") ||
      window.location.pathname.startsWith("/member-dashboard")
    ) {
      return null;
    }
    return this.props.onHide();
  };

  requestEmailResent = async () => {
    this.setState({ showLoader: true });
    const response = await fetchOrchidAPI(
      "/api/users/v1/resend_email_confirmation",
      {
        method: "PUT",
      },
    );
    if (!response.ok) {
      this.setState({
        error: `Failed to resend confimation email: ${getErrorDetail(
          await response.text(),
        )}`,
        notice: null,
      });
    }
    this.setState({ showLoader: false });
  };

  render() {
    return (
      <PhonePortraitContext.Consumer>
        {({ isPhonePortrait }) => (
          <ResponsiveModal
            centered
            fullPage
            show={this.props.show}
            onHide={this.onHide}
          >
            {!isPhonePortrait ? (
              <Modal.Header
                className="px-4 border-0 shadow-sm align-items-center"
                closeButton
              >
                <Modal.Title>
                  {this.props.isEmailUnconfirmed
                    ? "Verify your account"
                    : "Verify your email"}
                </Modal.Title>{" "}
              </Modal.Header>
            ) : (
              <Modal.Header className={styles["register-modal__header--phone"]}>
                <IconButton onClick={this.onHide}>
                  <CloseButton />
                </IconButton>
                <div>Verify your email</div>
              </Modal.Header>
            )}

            <Modal.Body className="p-0">
              {isPhonePortrait && (
                <FullWidthImage src={VERIFY_EMAIL_SENT} fluid />
              )}
              <div className="p-3">
                <div className="text-center">
                  <p className="bd-17">
                    {this.props.email
                      ? `We sent a verfication email to ${this.props.email}`
                      : `We have sent a verfication email to you.`}
                  </p>

                  {this.props.isEmailUnconfirmed ? (
                    <p>
                      In order to setup your profile, check your inbox for a
                      verification email. If you didn't receive an email, please
                      check your <b>junk</b> or <b>promotions</b> folder.
                    </p>
                  ) : (
                    <p>
                      Please follow the instructions in the email to continue.
                      If you didn't receive the email, please check your{" "}
                      <b>junk</b> or <b>promotions</b> folders.
                    </p>
                  )}
                </div>
                {this.state.error && (
                  <Alert variant="danger">{this.state.error.toString()}</Alert>
                )}
                {this.state.notice && (
                  <Alert variant="info">{this.state.notice.toString()}</Alert>
                )}
                {this.state.showLoader ? (
                  <Button
                    variant="primary"
                    disabled
                    className={getClasses(
                      "w-100",
                      isPhonePortrait && styles["auth-button--phone"],
                    )}
                  >
                    Resend Email{" "}
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    className={getClasses(
                      "w-100",
                      isPhonePortrait && styles["auth-button--phone"],
                    )}
                    onClick={this.requestEmailResent}
                  >
                    Resend email
                  </Button>
                )}
                {isPhonePortrait && (
                  <Button
                    variant="outline-primary"
                    className={getClasses(
                      "w-100 mt-3",
                      styles["auth-button--phone"],
                    )}
                    href="mailto:info@orchid.health"
                  >
                    Contact support
                  </Button>
                )}
                {/* <p className="d-inline fs-12">Already confirmed? </p><Button variant="link" size="sm" className="fs-12 p-0" onClick={this.onClickContinue}>Start Enjoying Your Orchid Benefits</Button> */}
                {!isPhonePortrait && (
                  <p className="mt-3">
                    Need help?{" "}
                    <a href="mailto:info@orchid.health">Contact support</a>
                  </p>
                )}
              </div>
            </Modal.Body>
          </ResponsiveModal>
        )}
      </PhonePortraitContext.Consumer>
    );
  }
}

export default EmailSentModal;
