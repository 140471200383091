import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Form,
  Button,
  InputGroup,
  Spinner,
  Row,
  Col,
  Alert,
  Modal,
} from "react-bootstrap";
// components
import Checkbox from "components/checkbox/Checkbox";
import ModalLayout from "components/modal/modal-layout/ModalLayout";
import ResponsiveModal from "components/responsive-modal";
import IconButton from "components/icon-button";
import { ReactComponent as OrchidLogo } from "assets/svg/orchid-logo.svg";
import { ReactComponent as CloseButton } from "assets/svg/outline-cross.svg";
// contexts
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useUser } from "contexts/UserContext";
// utils
import getClasses from "utils/getClasses";
import { setAmplitudeUser } from "utils/amplitude";
// types
import type {
  CustomEmailSentModalProps,
  CustomLoginModalProps,
} from "contexts/auth-modals-context";
import { analytics } from "constants/segment";
import login from "services/auth/login";

// styles
import styles from "./LoginModal.module.scss";

type Props = CustomLoginModalProps & {
  openMemberRegisterModal: () => void;
  openForgotPasswordModal: () => void;
  setCustomEmailSentModalProps: (arg: CustomEmailSentModalProps) => void;
  openAuthModals: () => void;
  show: boolean;
  onHide: () => void;
};
const LoginModal = ({
  isInNavbar,
  isInDashboard,
  openMemberRegisterModal,
  openForgotPasswordModal,
  setCustomEmailSentModalProps,
  openAuthModals,
  show,
  onHide: onHideProp,
}: Props) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const { updateUserInfo } = useUser();
  const history = useHistory();

  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>();
  const [revealPassword, setRevealPassword] = useState(false);
  const [busy, setBusy] = useState(false);
  const [validated, setValidated] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  // suppress on hide on some routes
  const onHide = () => {
    if (
      window.location.pathname.startsWith("/pro-dashboard") ||
      window.location.pathname.startsWith("/member-dashboard")
    ) {
      return null;
    }
    return onHideProp();
  };

  const onRememberMeCheck = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      setError("Please fill out all of the required fields correctly");
      return;
    }

    setBusy(true);
    setError("");

    try {
      await login(usernameOrEmail, password);
      const userInfo = await updateUserInfo();
      analytics.identify(userInfo?.sub);

      // Until we sign a BAA with Amplitude, we will only identify events coming from pros.
      if (userInfo && userInfo.user_type === "orchid_pro") {
        setAmplitudeUser(userInfo.sub, {
          firstName: userInfo.first_name,
          lastName: userInfo.last_name,
          fullName:
            userInfo.first_name && userInfo.last_name
              ? `${userInfo.first_name} ${userInfo.last_name}`
              : undefined,
          email: userInfo.email,
          businessName: userInfo.business_entity_name,
          userType: userInfo.user_type,
          isTestAccount: userInfo.is_test_user,
        });
      }

      setCustomEmailSentModalProps({ email: usernameOrEmail });
      onHideProp();
      openAuthModals();
      if (userInfo?.signup_form_finished && isInNavbar) {
        if (userInfo.user_type === "orchid_pro") {
          history.push("/pro-dashboard/homepage");
        } else {
          if (!!userInfo?.minors?.length) {
            history.push("/member-dashboard/switch-accounts");
            return;
          }
          history.push("/member-dashboard/homepage");
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setBusy(false);
    }
  };

  return !isPhonePortrait ? (
    <ModalLayout
      size={"md"}
      centered
      show={show}
      onHide={onHide}
      Header={<OrchidLogo width={96} />}
      Footer={
        isInDashboard ? null : (
          <>
            <Button
              type="submit"
              form="login-form"
              className={styles["login-button"]}
              disabled={busy}
            >
              {busy ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={"mr-1"}
                  />
                  Loading...
                </>
              ) : (
                "Log in"
              )}
            </Button>
            <div className={styles["register-row"]}>
              New to Orchid?
              <Link to={"/get-started"}>Register</Link>
            </div>
          </>
        )
      }
      contentClassName={styles["content"]}
      headerClass={styles["header"]}
      bodyClass={styles["body"]}
      footerClass={styles["footer"]}
      className={styles["wrapper"]}
      error={error}
    >
      <div className={styles["title"]}>
        Log in to unlock the best of Orchid.
      </div>
      <Form
        id="login-form"
        onSubmit={handleSubmit}
        className={"form"}
        validated={validated}
        noValidate
      >
        <Form.Group controlId="username_or_email">
          <Form.Label className={styles["form-label"]}>Email</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Email"
            autoComplete="email"
            value={usernameOrEmail}
            onChange={(event) => setUsernameOrEmail(event.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label className={styles["form-label"]}>Password</Form.Label>
          <InputGroup
            className={getClasses("align-items-center", styles["password"])}
          >
            <Form.Control
              required
              type={revealPassword ? "text" : "password"}
              placeholder="Password"
              autoComplete="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <InputGroup.Append
              onClick={(event) => {
                event.preventDefault();
                setRevealPassword(!revealPassword);
              }}
            >
              <InputGroup.Text>
                {revealPassword ? "Hide" : "Show"}
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>

        <div className={styles["remember-me-row"]}>
          <Checkbox
            variant={"rectangle"}
            id="remember_me"
            onChange={onRememberMeCheck}
            checked={rememberMe}
            label="Remember me"
          />
          <Button
            variant="link"
            onClick={() => {
              onHide();
              openForgotPasswordModal();
            }}
          >
            Forgot Password?
          </Button>
        </div>
      </Form>
    </ModalLayout>
  ) : (
    <ResponsiveModal centered show={show} onHide={onHide}>
      <Modal.Header className={styles["login-modal__header--phone"]}>
        <IconButton onClick={onHide}>
          <CloseButton />
        </IconButton>
        <div>Sign in or register</div>
      </Modal.Header>

      <Modal.Body className="px-4">
        <p className="bd-17 text-center px-4">
          Sign in or create an account to join the Orchid community!
        </p>

        {error && <Alert variant="danger">{error.toString()}</Alert>}
        <Form
          onSubmit={handleSubmit}
          className={getClasses(
            // ! DO NOT REMOVE THIS CLASS: password manager may need a default classList
            "form",
            styles["login-modal__auth-form--phone"],
          )}
          validated={validated}
          noValidate
        >
          <Form.Group controlId="username_or_email">
            <Form.Control
              required
              type="text"
              tabIndex={1}
              placeholder="Email"
              autoComplete="email"
              value={usernameOrEmail}
              onChange={(event) => setUsernameOrEmail(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label className="w-100">
              <Row className="align-items-center">
                <Col className="p-0">Password</Col>
                <Col className="text-right p-0">
                  <Button
                    variant="link"
                    onClick={() => {
                      onHide();
                      openForgotPasswordModal();
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Col>
              </Row>
            </Form.Label>
            <InputGroup
              className={getClasses(
                "align-items-center",
                styles["login-modal__password"],
                styles["login-modal__password--phone"],
              )}
            >
              <Form.Control
                required
                type={revealPassword ? "text" : "password"}
                tabIndex={2}
                placeholder="Password"
                autoComplete="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <InputGroup.Append
                onClick={(event) => {
                  event.preventDefault();
                  setRevealPassword(!revealPassword);
                }}
              >
                <InputGroup.Text>
                  {revealPassword ? "Hide" : "Show"}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>

          <a href="mailto:info@orchid.health">Get help signing in</a>

          <Button
            variant="primary"
            tabIndex={3}
            type="submit"
            className={getClasses(
              "w-100 my-3",
              styles["login-modal__auth-button--phone"],
            )}
            disabled={busy}
          >
            {busy ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className={"mr-1"}
                />
                Loading...
              </>
            ) : (
              "Log in"
            )}
          </Button>
        </Form>

        {!window.location.pathname.startsWith("/member-dashboard") &&
          !window.location.pathname.startsWith("/pro-dashboard") && (
            <Button
              variant="outline-primary"
              className={getClasses(
                "w-100",
                styles["login-modal__auth-button--phone"],
              )}
              onClick={() => {
                onHide();
                openMemberRegisterModal();
              }}
            >
              Register for an account
            </Button>
          )}
      </Modal.Body>
    </ResponsiveModal>
  );
};

export default LoginModal;
