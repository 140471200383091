export interface BlogCategory {
  label: string;
  value: string;
}

export const ALL_POSTS_BLOG_CATEGORY: BlogCategory = {
  label: "All Categories",
  value: "",
};

export const BLOG_CATEGORIES: BlogCategory[] = [
  ALL_POSTS_BLOG_CATEGORY,
  { label: "Resources", value: "resources" },
  { label: "Professional Development", value: "professional-development" },
  { label: "Clinician Wellness", value: "clinician-wellness" },
  { label: "Industry Research", value: "industry-research" },
  { label: "In The News", value: "in-the-news" },
];
