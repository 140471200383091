import { PrivateChatSession } from "../pubnub.types";
import pubNubTimetokenToDate from "./pubnub-timetoken/pubnub-timetoken-to-date";

const hasUnreadMessages = (
  currentUserCognitoSub: string,
  chatSession: PrivateChatSession,
): boolean => {
  if (chatSession.hasNotification) return true;
  if (chatSession.userMessageReadTimestamp === null) {
    return chatSession.messages.some(
      (msg) => msg.sender !== currentUserCognitoSub,
    );
  }
  // count up until the last read message
  for (const message of chatSession.messages.slice().reverse()) {
    if (currentUserCognitoSub === message.sender) return false;
    if (
      message.messageTimetoken &&
      chatSession.userMessageReadTimestamp <
        pubNubTimetokenToDate(message.messageTimetoken)
    ) {
      if (currentUserCognitoSub !== message.sender) return true;
    } else {
      break;
    }
  }
  return false;
};

export default hasUnreadMessages;
