import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Query orchid pro
 */
export default async function queryOrchidPro(): Promise<OrchidPro> {
  const res = await fetchOrchidAPI("/api/users/v1/orchid_pro/");
  if (!res.ok) {
    throw new Error(
      `failed to get orchid pro info: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
