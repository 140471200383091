export enum BlogImageType {
  main = "main",
  social = "social",
}

const getBlogImageUrl = (blog: Blog, type: BlogImageType) => {
  //TODO: remove this when we have images delivered by the back-end
  const id =
    process.env?.NODE_ENV === "development" ? 50 + (blog.id % 10) : blog.id;
  switch (type) {
    case BlogImageType.main:
      return `/img/blog/image-${id}.svg`;
    case BlogImageType.social:
      return `/img/blog/image-${id}.png`;
  }
};

export default getBlogImageUrl;
