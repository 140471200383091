import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://fd8bbf434cd967675a70d709291ea829@o4507696273686528.ingest.us.sentry.io/4508009009053696",
  environment: process.env?.NODE_ENV ?? "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error", "warn"],
    }),
  ],
  // Tracing
  tracesSampleRate: 0.1,
  tracePropagationTargets: ["localhost"],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});
