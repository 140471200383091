export interface PressCategory {
  label: string;
  value: string;
}

export const ALL_PRESS_CATEGORY: PressCategory = {
  label: "All Categories",
  value: "",
};

export const PRESS_CATEGORIES: PressCategory[] = [
  ALL_PRESS_CATEGORY,
  { label: "Press Release", value: "press-release" },
  { label: "Award", value: "award" },
  { label: "News", value: "news" },
  { label: "Podcast", value: "podcast" },
];
