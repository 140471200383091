import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Create new orchid pro type
 */
export default async function createOrchidProType(orchidProType) {
  const res = await fetchOrchidAPI("/api/users/v1/orchid_pro_type/create", {
    headers: { "content-type": "application/json" },
    method: "POST",
    body: JSON.stringify(orchidProType),
  });
  if (!res.ok) {
    throw new Error(
      `failed to create new orchid pro type: ${getErrorDetail(
        await res.text(),
      )}`,
    );
  }

  return res.json();
}
