import { ReactComponent as OutlineInfoCircle16 } from "assets/svg/outline-info-circle-16.svg";
import Loading from "components/Loading";
import Checkbox from "components/checkbox/Checkbox";
import CustomSelect from "components/custom-select";
import ErrorMessage from "components/error-message";
import OrchidFooter from "components/orchid-footer";
import {
  ORCHID_PRO_TYPE_OPTIONS,
  PHYSICIAN_PRIMARY_SPECIALTY,
} from "components/provider-intake/utility/constants";
import { getDisplayNameOptions } from "components/provider-intake/utility/getDisplayNameOptions";
import FormControlWithMask from "components/utility/FormControlWithMask";
import { HEARD_ABOUT_ORCHID_VIA } from "components/utility/option-constants";
import { PHONE_NUMBER_MASK } from "constants/phoneNumberMask";
import { STATE_OPTIONS } from "constants/stateOptions";
import { useUser } from "contexts/UserContext";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import createOrchidProType from "services/createOrchidProType";
import postOrchidPro from "services/postOrchidPro";
import putOrchidProTypeById from "services/putOrchidProTypeById";
import queryOrchidPro from "services/queryOrchidPro";
import { AmplitudePageName, track } from "utils/amplitude";
import getClasses from "utils/getClasses";
import getOrchidProDisplayName from "utils/getOrchidProDisplayName";
import getOrchidProMedicalDegree from "utils/getOrchidProMedicalDegree";
import styles from "./QuickIntake.module.scss";
import StepBar from "./StepBar";
import createDemoPatient from "./createDemoPatient";
import useHashStepNavigation from "./useHashStepNavigation";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "pro-new-quick-intake-page";

const TELL_US_ABOUT_YOURSELF = "tell-us-about-yourself";
const DESCRIBE_YOUR_PRACTICE = "describe-your-practice";
const WHAT_IS_YOUR_PRACTICE_ADDRESS = "what-is-your-practice-address";
const WHAT_IS_YOUR_PHONE_NUMBER = "what-is-your-phone-number";
const BEFORE_YOU_FINISH = "before-you-finish";

const STEPS = [
  TELL_US_ABOUT_YOURSELF,
  DESCRIBE_YOUR_PRACTICE,
  WHAT_IS_YOUR_PRACTICE_ADDRESS,
  WHAT_IS_YOUR_PHONE_NUMBER,
  BEFORE_YOU_FINISH,
];

const DEFAULT_LICENSE = {
  type: null,
  conferring_entity: null,
  license_number: null,
  expiration_date: "2024-01-01",
  supervision: {
    has_supervision: false,
    name: null,
    npi: null,
    phone: null,
    license_type: null,
    license_number: null,
  },
  orchid_pro_type_id: null,
};

const DEFAULT_ORCHID_PRO_TYPE = {
  orchid_pro_type_id: null,
  orchid_pro_user_id: null,
  create_datetime: null,
  modify_datetime: null,
  marked_deleted_datetime: null,
  orchid_pro_type: {
    type: "therapist",
    specialty: {
      specialty: null,
      sub_specialty: null,
    },
  },
};

const QuickIntake = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => {
    setPage(AMPLITUDE_PAGE_NAME);
  }, [setPage]);

  const history = useHistory();
  const { defaultUserTimezone, updateUserInfo } = useUser();
  const { currentStep } = useHashStepNavigation(STEPS);

  const [error, setError] = useState<any>();
  const [orchidPro, setOrchidPro] = useState<OrchidPro>();
  const [isBusy, setIsBusy] = useState(false);
  const [sameHomeAddress, setSameHomeAddress] = useState(false);

  const isDataInitialized = useRef(false);
  const isOrchidProTypeDirty = useRef(false);

  const currentIndex = STEPS.indexOf(currentStep);
  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === STEPS.length - 1;

  const displayNameOptions = useMemo(
    () =>
      orchidPro
        ? getDisplayNameOptions(getOrchidProMedicalDegree(orchidPro))
        : [],
    [orchidPro],
  );

  const orchidProType = useMemo(() => {
    if (
      !orchidPro?.orchid_pro_types?.length ||
      !orchidPro.orchid_pro_types[0].orchid_pro_type
    ) {
      return "";
    }

    return orchidPro.orchid_pro_types[0].orchid_pro_type.type ?? "";
  }, [orchidPro]);

  const orchidProSpecialty = useMemo(() => {
    if (
      !orchidPro?.orchid_pro_types?.length ||
      !orchidPro.orchid_pro_types[0].orchid_pro_type
    ) {
      return "";
    }

    return (
      orchidPro.orchid_pro_types[0].orchid_pro_type.specialty?.specialty ?? ""
    );
  }, [orchidPro]);

  const isCurrentStepInvalid = useMemo(() => {
    switch (currentStep) {
      case TELL_US_ABOUT_YOURSELF:
        return !orchidPro?.dob;
      case DESCRIBE_YOUR_PRACTICE:
        return (
          !orchidPro?.licenses[0]?.conferring_entity ||
          !orchidPro?.licenses[0]?.license_number
        );
      case WHAT_IS_YOUR_PRACTICE_ADDRESS:
        return (
          !orchidPro?.office_address_line1 ||
          !orchidPro?.office_city ||
          !orchidPro?.office_state ||
          !orchidPro?.office_zipcode ||
          !orchidPro?.mail_address_line1 ||
          !orchidPro?.mail_city ||
          !orchidPro?.mail_state ||
          !orchidPro?.mail_zipcode
        );
      case WHAT_IS_YOUR_PHONE_NUMBER:
        return false;
      case BEFORE_YOU_FINISH:
        return (
          !orchidPro?.heard_about_orchid_via ||
          !orchidPro?.agreed_to_terms_of_use ||
          !orchidPro?.confirmed_have_social_security_number_allowed_to_work_in_usa
        );
      default:
        return false;
    }
  }, [currentStep, orchidPro]);

  const save = useCallback(async () => {
    if (!orchidPro) {
      throw new Error("Orchid Pro data is not available");
    }

    if (isOrchidProTypeDirty.current) {
      if (!orchidPro.orchid_pro_types[0].orchid_pro_type_id) {
        await createOrchidProType(
          orchidPro.orchid_pro_types[0].orchid_pro_type,
        );
      } else {
        await putOrchidProTypeById(
          orchidPro.orchid_pro_types[0].orchid_pro_type_id,
          orchidPro.orchid_pro_types[0].orchid_pro_type,
        );
      }
    }

    const updatedOrchidPro = await postOrchidPro({
      ...orchidPro,
      name_schema: orchidPro.name_schema ?? displayNameOptions[0].value,
      signature_legal_first_name: orchidPro.first_name,
      signature_legal_last_name: orchidPro.last_name,
    });

    // Since we don't expect anything but the orchid pro type to be changed by the server,
    // let's not update the whole orchid pro object each time to avoid overriding user changes
    if (isOrchidProTypeDirty.current) {
      setOrchidPro((prev) => ({
        ...prev!,
        orchid_pro_types: updatedOrchidPro.orchid_pro_types,
      }));
      isOrchidProTypeDirty.current = false;
    }

    return updatedOrchidPro;
  }, [orchidPro, displayNameOptions]);

  const submitStep = useCallback(async () => {
    setIsBusy(true);
    try {
      await save();
    } catch (error) {
      setError(error);
    } finally {
      setIsBusy(false);
    }
  }, [save]);

  const finish = useCallback(async () => {
    setIsBusy(true);

    try {
      await save();
      await updateUserInfo();
    } catch (error) {
      setError(error);
      setIsBusy(false);
      return;
    }

    try {
      await createDemoPatient(defaultUserTimezone);
    } catch (error) {
      // We should still proceed without the demo user
    } finally {
      history.push("/pro-dashboard/homepage");
    }
  }, [history, save, updateUserInfo, defaultUserTimezone]);

  useEffect(() => {
    if (isDataInitialized.current) {
      return;
    }

    isDataInitialized.current = true;
    queryOrchidPro()
      .then((orchidPro) => {
        if (!orchidPro.orchid_pro_types.length) {
          // We will need to save the default orchid pro type if none is present initially
          isOrchidProTypeDirty.current = true;
        }

        setOrchidPro({
          ...orchidPro,
          orchid_pro_types: orchidPro.orchid_pro_types.length
            ? orchidPro.orchid_pro_types
            : [DEFAULT_ORCHID_PRO_TYPE],
          licenses: orchidPro.licenses.length
            ? orchidPro.licenses
            : [DEFAULT_LICENSE],
        });
      })
      .catch(setError);
  }, []);

  if (error) {
    return (
      <div className={styles.container}>
        <ErrorMessage show errorMsg={error.toString()} />
      </div>
    );
  }

  if (!orchidPro) {
    return (
      <div className={styles.container}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.step}>
          <StepBar steps={STEPS} currentStep={currentIndex} />
          <div
            className={getClasses(
              styles["step-content"],
              currentStep !== TELL_US_ABOUT_YOURSELF && styles.hidden,
            )}
          >
            <h1>Tell us about yourself</h1>
            <h2>Name</h2>
            <div className={styles["name-preview-label"]}>
              Your legal name set during registration is:
            </div>
            <div className={styles["name-preview"]}>
              {orchidPro.first_name} {orchidPro.last_name}
            </div>
            <hr className={styles.separator} />
            <div className={styles.question}>
              How should Orchid communicate with you?
            </div>
            <div>
              <Form.Label className={styles["form-label"]}>
                Preferred First Name
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "click"]}
                  overlay={
                    <Tooltip id="prefer-name-tooltip">
                      If different than your legal first name
                    </Tooltip>
                  }
                >
                  <OutlineInfoCircle16 className={"blue ml-1"} />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                value={orchidPro.preferred_first_name ?? ""}
                onFocus={() =>
                  track(AMPLITUDE_PAGE_NAME, ["preferred-first-name"], "focus")
                }
                onChange={(event) => {
                  track(AMPLITUDE_PAGE_NAME, ["preferred-first-name"], "click");
                  setOrchidPro((prev) => ({
                    ...prev!,
                    preferred_first_name: event.target.value,
                  }));
                }}
                placeholder={"Enter preferred first name"}
              />
            </div>
            <div className={styles.question}>
              How do you want to be shown to your patients?
            </div>
            <div>
              <Form.Label className={styles["form-label"]}>
                Display Name <span className="required">*</span>
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "click"]}
                  overlay={
                    <Tooltip id="display-name-tooltip">
                      This name will be shown on Messages, Bookings, Invites,
                      etc
                    </Tooltip>
                  }
                >
                  <OutlineInfoCircle16 className={"blue ml-1"} />
                </OverlayTrigger>
              </Form.Label>
              <CustomSelect
                required
                value={
                  displayNameOptions.find(
                    (option) => option.value === orchidPro.name_schema,
                  ) ?? displayNameOptions[0]
                }
                onFocus={() =>
                  track(AMPLITUDE_PAGE_NAME, ["name-schema"], "focus")
                }
                onChange={({ value }) => {
                  track(AMPLITUDE_PAGE_NAME, ["name-schema"], "click");
                  setOrchidPro((prev) => ({
                    ...prev!,
                    name_schema: value,
                  }));
                }}
                options={getDisplayNameOptions(
                  getOrchidProMedicalDegree(orchidPro),
                )}
              />
            </div>
            <div className={styles["display-name-preview-label"]}>
              Your Display Name:{" "}
              <span className={styles["display-name-preview"]}>
                {getOrchidProDisplayName(orchidPro)}
              </span>
            </div>
            <div className={styles.separator} />
            <h2>Date of Birth</h2>
            <div>
              <Form.Label className="mb-1 rg-12 gray01">
                Date of Birth <span className="required">*</span>
              </Form.Label>
              <Form.Control
                required
                type="date"
                max={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18),
                  )
                    .toISOString()
                    .split("T")[0]
                }
                pattern="^(0[1-9]|1[012])/(0[1-9]|[12][0-9]|3[01])/(19|20)\d\d$"
                value={orchidPro.dob ?? ""}
                onFocus={() => track(AMPLITUDE_PAGE_NAME, ["dob"], "focus")}
                onChange={(event) => {
                  track(AMPLITUDE_PAGE_NAME, ["dob"], "click");
                  setOrchidPro((prev) => ({
                    ...prev!,
                    dob: event.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div
            className={getClasses(
              styles["step-content"],
              currentStep !== DESCRIBE_YOUR_PRACTICE && styles.hidden,
            )}
          >
            <h1>Describe your practice</h1>
            <div>
              <Form.Label className={styles["form-label"]}>
                Professional Type <span className="required">*</span>
              </Form.Label>
              <CustomSelect
                required
                value={
                  ORCHID_PRO_TYPE_OPTIONS.find(
                    (option) => option.value === orchidProType,
                  ) ?? ORCHID_PRO_TYPE_OPTIONS[0]
                }
                onFocus={() =>
                  track(AMPLITUDE_PAGE_NAME, ["orchid_pro_type"], "focus")
                }
                onChange={(target) => {
                  track(AMPLITUDE_PAGE_NAME, ["orchid_pro_type"], "click");
                  isOrchidProTypeDirty.current = true;
                  setOrchidPro((prev) => ({
                    ...prev!,
                    orchid_pro_types: [
                      {
                        ...prev!.orchid_pro_types[0],
                        orchid_pro_type: {
                          type: target.value,
                          specialty: {
                            specialty:
                              target.value === "physician"
                                ? PHYSICIAN_PRIMARY_SPECIALTY[0].value
                                : null,
                            sub_specialty: null,
                          },
                        },
                      },
                      ...prev!.orchid_pro_types.slice(1),
                    ],
                  }));
                }}
                options={ORCHID_PRO_TYPE_OPTIONS}
              />
            </div>
            {orchidProType === "physician" && (
              <div>
                <Form.Label className={styles["form-label"]}>
                  Specialty <span className="required">*</span>
                </Form.Label>
                <CustomSelect
                  required
                  value={
                    PHYSICIAN_PRIMARY_SPECIALTY.find(
                      (option) => option.value === orchidProSpecialty,
                    ) ?? PHYSICIAN_PRIMARY_SPECIALTY[0]
                  }
                  onFocus={() =>
                    track(
                      AMPLITUDE_PAGE_NAME,
                      ["orchid_pro_specialty"],
                      "focus",
                    )
                  }
                  onChange={(target) => {
                    track(
                      AMPLITUDE_PAGE_NAME,
                      ["orchid_pro_specialty"],
                      "click",
                    );
                    isOrchidProTypeDirty.current = true;
                    setOrchidPro((prev) => ({
                      ...prev!,
                      orchid_pro_types: [
                        {
                          ...prev!.orchid_pro_types[0],
                          orchid_pro_type: {
                            ...prev!.orchid_pro_types[0].orchid_pro_type,
                            specialty: {
                              specialty: target.value,
                              sub_specialty: null,
                            },
                          },
                        },
                        ...prev!.orchid_pro_types.slice(1),
                      ],
                    }));
                  }}
                  options={PHYSICIAN_PRIMARY_SPECIALTY}
                />
              </div>
            )}
            <hr className={styles.separator} />
            <h2>License Details</h2>
            <div className={styles["form-group"]}>
              <div>
                <Form.Label className={styles["form-label"]}>
                  State <span className="required">*</span>
                </Form.Label>
                <CustomSelect
                  required
                  value={STATE_OPTIONS.find(
                    (option) =>
                      option.value === orchidPro.licenses[0].conferring_entity,
                  )}
                  onFocus={() =>
                    track(AMPLITUDE_PAGE_NAME, ["conferring_entity"], "focus")
                  }
                  onChange={(e) => {
                    track(AMPLITUDE_PAGE_NAME, ["conferring_entity"], "click");
                    setOrchidPro((prev) => ({
                      ...prev!,
                      licenses: [
                        {
                          ...prev!.licenses[0],
                          conferring_entity: e.value,
                        },
                        ...prev!.licenses.slice(1),
                      ],
                    }));
                  }}
                  options={STATE_OPTIONS}
                />
              </div>
              <div>
                <Form.Label className={styles["form-label"]}>
                  License Number <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={orchidPro.licenses[0]?.license_number ?? ""}
                  onFocus={() =>
                    track(AMPLITUDE_PAGE_NAME, ["license_number"], "focus")
                  }
                  onChange={(event) => {
                    track(AMPLITUDE_PAGE_NAME, ["license_number"], "click");
                    setOrchidPro((prev) => ({
                      ...prev!,
                      licenses: [
                        {
                          ...prev!.licenses[0],
                          license_number: event.target.value,
                        },
                      ],
                    }));
                  }}
                  placeholder={"Enter license number"}
                />
              </div>
            </div>
          </div>
          <div
            className={getClasses(
              styles["step-content"],
              currentStep !== WHAT_IS_YOUR_PRACTICE_ADDRESS && styles.hidden,
            )}
          >
            <h1>What is your practice address?</h1>
            <h2>Business Address</h2>
            <div className={styles["form-group"]}>
              <div>
                <Form.Label className={styles["form-label"]}>
                  Business Name{" "}
                  <OverlayTrigger
                    placement="right"
                    trigger={["hover", "click"]}
                    overlay={
                      <Tooltip id="business-name-tooltip">
                        You can leave this blank if you have not yet created a
                        separate business for your practice.
                      </Tooltip>
                    }
                  >
                    <OutlineInfoCircle16 className={"blue ml-1"} />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="business_entity_name"
                  value={orchidPro.business_entity_name ?? ""}
                  onFocus={() =>
                    track(
                      AMPLITUDE_PAGE_NAME,
                      ["business_entity_name"],
                      "focus",
                    )
                  }
                  onChange={(event) => {
                    track(
                      AMPLITUDE_PAGE_NAME,
                      ["business_entity_name"],
                      "click",
                    );
                    setOrchidPro((prev) => ({
                      ...prev!,
                      business_entity_name: event.target.value,
                    }));
                  }}
                  placeholder={"Enter Business Name"}
                />
              </div>
              <div>
                <Form.Label className={styles["form-label"]}>
                  Street Address <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  required
                  value={orchidPro.office_address_line1 ?? ""}
                  onFocus={() =>
                    track(
                      AMPLITUDE_PAGE_NAME,
                      ["office_address_line1"],
                      "focus",
                    )
                  }
                  onChange={(event) => {
                    track(
                      AMPLITUDE_PAGE_NAME,
                      ["office_address_line1"],
                      "click",
                    );
                    setOrchidPro((prev) => ({
                      ...prev!,
                      office_address_line1: event.target.value,
                      mail_address_line1: sameHomeAddress
                        ? event.target.value
                        : prev!.mail_address_line1,
                    }));
                  }}
                  placeholder={"Enter Address"}
                />
              </div>
              <div>
                <Form.Label className={styles["form-label"]}>
                  City <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  required
                  value={orchidPro.office_city ?? ""}
                  onFocus={() =>
                    track(AMPLITUDE_PAGE_NAME, ["office_city"], "focus")
                  }
                  onChange={(event) => {
                    track(AMPLITUDE_PAGE_NAME, ["office_city"], "click");
                    setOrchidPro((prev) => ({
                      ...prev!,
                      office_city: event.target.value,
                      mail_city: sameHomeAddress
                        ? event.target.value
                        : prev!.mail_city,
                    }));
                  }}
                  placeholder={"Enter City"}
                />
              </div>

              <div className={styles["form-row"]}>
                <div>
                  <Form.Label className={styles["form-label"]}>
                    State <span className="required">*</span>
                  </Form.Label>

                  <CustomSelect
                    required
                    isMulti={false}
                    value={STATE_OPTIONS.find(
                      (option) => option.value === orchidPro.office_state,
                    )}
                    maxMenuHeight={200}
                    onfFocus={() =>
                      track(AMPLITUDE_PAGE_NAME, ["office_state"], "focus")
                    }
                    onChange={(element) => {
                      track(AMPLITUDE_PAGE_NAME, ["office_state"], "click");
                      setOrchidPro((prev) => ({
                        ...prev!,
                        office_state: element.value,
                        mail_state: sameHomeAddress
                          ? element.value
                          : prev!.mail_state,
                      }));
                    }}
                    options={STATE_OPTIONS}
                  />
                </div>

                <div>
                  <Form.Label className={styles["form-label"]}>
                    Zipcode <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    value={orchidPro.office_zipcode ?? ""}
                    onFocus={() =>
                      track(AMPLITUDE_PAGE_NAME, ["office_zipcode"], "focus")
                    }
                    onChange={(event) => {
                      track(AMPLITUDE_PAGE_NAME, ["office_zipcode"], "click");
                      setOrchidPro((prev) => ({
                        ...prev!,
                        office_zipcode: event.target.value,
                        mail_zipcode: sameHomeAddress
                          ? event.target.value
                          : prev!.mail_zipcode,
                      }));
                    }}
                    placeholder={"Enter Zipcode"}
                  />
                </div>
              </div>
            </div>

            <div className={styles.separtor} />
            <h2>Home Address</h2>

            <div className={styles["form-group"]}>
              <Checkbox
                label={
                  <label className="mb-3 rg-14">Same as Business Address</label>
                }
                onFocus={() =>
                  track(AMPLITUDE_PAGE_NAME, ["sameHomeAddress"], "focus")
                }
                onChange={(event) => {
                  track(AMPLITUDE_PAGE_NAME, ["sameHomeAddress"], "click");
                  setSameHomeAddress(event.target.checked);
                  if (event.target.checked) {
                    setOrchidPro((prev) => ({
                      ...prev!,
                      mail_address_line1: prev!.office_address_line1,
                      mail_city: prev!.office_city,
                      mail_state: prev!.office_state,
                      mail_zipcode: prev!.office_zipcode,
                    }));
                  }
                }}
              />

              <div>
                <Form.Label className={styles["form-label"]}>
                  Street Address <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  required
                  value={orchidPro.mail_address_line1 ?? ""}
                  onFocus={() =>
                    track(AMPLITUDE_PAGE_NAME, ["mail_address_line1"], "focus")
                  }
                  onChange={(event) => {
                    track(AMPLITUDE_PAGE_NAME, ["mail_address_line1"], "click");
                    setOrchidPro((prev) => ({
                      ...prev!,
                      mail_address_line1: event.target.value,
                    }));
                  }}
                  placeholder={"Enter Address"}
                  disabled={sameHomeAddress}
                />
              </div>
              <div>
                <Form.Label className={styles["form-label"]}>
                  City <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  required
                  value={orchidPro.mail_city ?? ""}
                  onFocus={() =>
                    track(AMPLITUDE_PAGE_NAME, ["mail_city"], "focus")
                  }
                  onChange={(event) => {
                    track(AMPLITUDE_PAGE_NAME, ["mail_city"], "click");

                    setOrchidPro((prev) => ({
                      ...prev!,
                      mail_city: event.target.value,
                    }));
                  }}
                  placeholder={"Enter City"}
                  disabled={sameHomeAddress}
                />
              </div>

              <div className={styles["form-row"]}>
                <div>
                  <Form.Label className={styles["form-label"]}>
                    State <span className="required">*</span>
                  </Form.Label>

                  <CustomSelect
                    required
                    isMulti={false}
                    value={STATE_OPTIONS.find(
                      (option) => option.value === orchidPro.mail_state,
                    )}
                    maxMenuHeight={200}
                    onFocus={() =>
                      track(AMPLITUDE_PAGE_NAME, ["mail_state"], "focus")
                    }
                    onChange={(target) => {
                      track(AMPLITUDE_PAGE_NAME, ["mail_state"], "click");
                      setOrchidPro((prev) => ({
                        ...prev!,
                        mail_state: target.value,
                      }));
                    }}
                    options={STATE_OPTIONS}
                    disabled={sameHomeAddress}
                  />
                </div>

                <div>
                  <Form.Label className={styles["form-label"]}>
                    Zipcode <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    value={orchidPro.mail_zipcode ?? ""}
                    onFocus={() =>
                      track(AMPLITUDE_PAGE_NAME, ["mail_zipcode"], "focus")
                    }
                    onChange={(event) => {
                      track(AMPLITUDE_PAGE_NAME, ["mail_zipcode"], "click");
                      setOrchidPro((prev) => ({
                        ...prev!,
                        mail_zipcode: event.target.value,
                      }));
                    }}
                    placeholder={"Enter Zipcode"}
                    disabled={sameHomeAddress}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={getClasses(
              styles["step-content"],
              currentStep !== WHAT_IS_YOUR_PHONE_NUMBER && styles.hidden,
            )}
          >
            <h1>What is your phone number?</h1>
            <span>
              Orchid will never share your number with third parties and will
              use it for official, non-marketing communications only.
            </span>
            <div>
              <Form.Label className={styles["form-label"]}>
                Contact Phone
              </Form.Label>
              <FormControlWithMask
                mask={PHONE_NUMBER_MASK}
                maskChar="-"
                maskRegex="\d"
                value={orchidPro.phone_number ?? ""}
                onFocus={() =>
                  track(AMPLITUDE_PAGE_NAME, ["phone_number"], "focus")
                }
                onChange={(event) => {
                  track(AMPLITUDE_PAGE_NAME, ["phone_number"], "click");
                  setOrchidPro((prev) => ({
                    ...prev!,
                    phone_number: event.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div
            className={getClasses(
              styles["step-content"],
              currentStep !== BEFORE_YOU_FINISH && styles.hidden,
            )}
          >
            <h1>Before you finish</h1>
            <h3>How did you hear about Orchid?</h3>
            <div>
              <Form.Label className={styles["form-label"]}>
                Select channel <span className="required">*</span>
              </Form.Label>
              <CustomSelect
                required
                value={HEARD_ABOUT_ORCHID_VIA.find(
                  (option) => option.value === orchidPro.heard_about_orchid_via,
                )}
                onFocus={() =>
                  track(
                    AMPLITUDE_PAGE_NAME,
                    ["heard_about_orchid_via"],
                    "focus",
                  )
                }
                onChange={(target) => {
                  track(
                    AMPLITUDE_PAGE_NAME,
                    ["heard_about_orchid_via"],
                    "click",
                  );
                  setOrchidPro((prev) => ({
                    ...prev!,
                    heard_about_orchid_via: target.value,
                  }));
                }}
                options={HEARD_ABOUT_ORCHID_VIA}
                placeholder={"Select Channel"}
              />
            </div>
            <div>
              Agree to the terms below to become a registered Orchid
              Professional Independent Contractor.
            </div>
            <Checkbox
              variant={"rectangle"}
              required
              onFocus={() =>
                track(AMPLITUDE_PAGE_NAME, ["agreed_to_terms_of_use"], "focus")
              }
              onChange={(event) => {
                track(AMPLITUDE_PAGE_NAME, ["agreed_to_terms_of_use"], "click");
                setOrchidPro((prev) => ({
                  ...prev!,
                  agreed_to_terms_of_use: event.target.checked,
                }));
              }}
              checked={orchidPro.agreed_to_terms_of_use}
              label={
                <label className={"darker"}>
                  I have read and agree to the{" "}
                  <Link to="/terms-of-use" target="_blank">
                    Terms of Use
                  </Link>
                  . <span className="required">*</span>
                </label>
              }
            />

            <Checkbox
              variant={"rectangle"}
              required
              onFocus={() =>
                track(
                  AMPLITUDE_PAGE_NAME,
                  [
                    "confirmed_have_social_security_number_allowed_to_work_in_usa",
                  ],
                  "focus",
                )
              }
              onChange={(event) => {
                track(
                  AMPLITUDE_PAGE_NAME,
                  [
                    "confirmed_have_social_security_number_allowed_to_work_in_usa",
                  ],
                  "click",
                );
                setOrchidPro((prev) => ({
                  ...prev!,
                  confirmed_have_social_security_number_allowed_to_work_in_usa:
                    event.target.checked,
                }));
              }}
              checked={
                orchidPro.confirmed_have_social_security_number_allowed_to_work_in_usa
              }
              label={
                <label className={"darker"}>
                  I have a valid Social Security number, I am authorized to work
                  in the United States, and I am at least 18 years of age or
                  older. <span className="required">*</span>
                </label>
              }
            />
          </div>
          <div className={styles.navigation}>
            {!isFirstStep && (
              <Button
                variant="outline-primary"
                onClick={() => {
                  track(AMPLITUDE_PAGE_NAME, ["back"], "click");
                  history.goBack();
                }}
                disabled={isBusy && isLastStep}
              >
                Back
              </Button>
            )}
            {isLastStep ? (
              <Button
                variant="primary"
                onClick={() => {
                  track(AMPLITUDE_PAGE_NAME, ["finish"], "click");
                  finish();
                }}
                disabled={isCurrentStepInvalid || isBusy}
              >
                {isBusy ? "Please wait..." : "Finish"}
              </Button>
            ) : (
              <Button
                variant="primary"
                href={`#${STEPS[currentIndex + 1]}`}
                onClick={() => {
                  track(AMPLITUDE_PAGE_NAME, ["continue"], "click");
                  submitStep();
                }}
                disabled={isCurrentStepInvalid}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
      <OrchidFooter />
    </>
  );
};

export default QuickIntake;
