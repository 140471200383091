type AppointmentsStoreResetAction = {
  type: "reset";
};

type AppointmentsStoreAddOrUpdateAction = {
  type: "add_or_update";
  appointments: AppointmentMetaData[];
};

type AppointmentsStoreReducerState = { [key: string]: AppointmentMetaData };

const MAX_APPOINTMENTS = 256;

export function AppointmentsStoreReducer(
  state: AppointmentsStoreReducerState,
  action: AppointmentsStoreResetAction | AppointmentsStoreAddOrUpdateAction,
) {
  switch (action.type) {
    case "reset": {
      if (Object.keys(state).length === 0) return state;
      return {};
    }
    case "add_or_update": {
      const stateAppointmentIds = Object.values(state);
      const onlyAddAfterModifyTime: undefined | string =
        stateAppointmentIds.length > MAX_APPOINTMENTS
          ? stateAppointmentIds.map((a) => a.modify_datetime).sort()[0]
          : undefined;

      let changed = false;
      const newState = { ...state };
      for (const update of action.appointments) {
        if (
          onlyAddAfterModifyTime &&
          update.appointment_start < onlyAddAfterModifyTime
        )
          continue;
        const current = newState[update.appointment_id];
        if (!current || current.modify_datetime < update.modify_datetime) {
          newState[update.appointment_id] = update;
          changed = true;
        }
      }
      if (!changed) return state;

      const newStateAppointments = Object.values(newState);
      if (newStateAppointments.length <= MAX_APPOINTMENTS) return newState;

      newStateAppointments.sort((lhs, rhs) => {
        if (lhs.modify_datetime < rhs.modify_datetime) return 1;
        if (lhs.modify_datetime > rhs.modify_datetime) return -1;
        return 0;
      });

      const trimmedNewState = {};
      for (const appointment of newStateAppointments.slice(
        0,
        MAX_APPOINTMENTS,
      )) {
        trimmedNewState[appointment.appointment_id] = appointment;
      }

      return trimmedNewState;
    }
  }
  throw new Error(`unexpected action type: ${action}`);
}
