import type { TMemberRoutesKey } from "constants/memberRoutes";
import type { TGeneralRoutesKey } from "constants/generalRoutes";
import type { TProRoutesKey } from "constants/proRoutes";

import messages from "messages";
import { ReactComponent as Home } from "assets/svg/home.svg";
import { ReactComponent as Book } from "assets/svg/book.svg";
import { ReactComponent as Dashboard } from "assets/svg/dashboard.svg";
import { ReactComponent as Messages } from "assets/svg/messages.svg";
import { ReactComponent as Profile } from "assets/svg/profile.svg";
import { ReactComponent as Patients } from "assets/svg/outline-users.svg";
import { ReactComponent as Notes } from "assets/svg/outline-document-filled.svg";
import { ReactComponent as Calendar } from "assets/svg/outline-calendar.svg";

export default function getBottomNavItemProps(
  routeKey: TMemberRoutesKey | TGeneralRoutesKey | TProRoutesKey,
) {
  switch (routeKey) {
    case "home": {
      return {
        routeKey,
        Icon: <Home title={routeKey} />,
        message: messages.home,
      };
    }

    case "login": {
      return {
        routeKey,
        Icon: <Profile title={routeKey} />,
        message: messages.login,
      };
    }

    case "professionalSearch": {
      return {
        routeKey,
        Icon: <Book title={routeKey} />,
        message: messages.book,
      };
    }

    case "dashboard": {
      return {
        routeKey,
        Icon: <Dashboard title={routeKey} />,
        message: messages.dashboard,
      };
    }

    case "messages": {
      return {
        routeKey,
        Icon: <Messages title={routeKey} />,
        message: messages.messages,
      };
    }

    case "profile": {
      return {
        routeKey,
        Icon: <Profile title={routeKey} />,
        message: messages.profile,
      };
    }

    case "notes": {
      return {
        routeKey,
        Icon: <Notes title={routeKey} />,
        message: messages.notes,
      };
    }

    case "patients": {
      return {
        routeKey,
        Icon: <Patients title={routeKey} />,
        message: messages.patients,
      };
    }

    case "calendar": {
      return {
        routeKey,
        Icon: <Calendar title={routeKey} />,
        message: messages.calendar,
      };
    }

    default:
      return { routeKey: null, Icon: null, message: "" };
  }
}
