/**
 * routes, text and icon used for navigation when user type is "orchid_pro"
 */
export const PRO_ROUTES = Object.freeze({
  home: "/pro-dashboard/homepage",
  calendar: "/pro-dashboard/schedule",
  notes: "/pro-dashboard/notes-and-forms",
  patients: "/pro-dashboard/patient-charts",
  messages: "/pro-dashboard/messages",
  dataTransfer: "/pro-dashboard/data-transfer",
  orchidStorage: "/pro-dashboard/orchid-storage",

  integrations: "/pro-dashboard/settings/integrations",
  zoomIntegration: "/pro-dashboard/settings/integration/zoom",
} as const);

export type TProRoutesKey = keyof typeof PRO_ROUTES;

export const HIDE_SIDEBAR_ROUTES = [
  "/pro-dashboard/schedule",
  "/pro-dashboard/settings",
];
