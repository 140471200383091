/**
 * intercom app id
 */

import { IS_DEV_ENV } from "./isDevEnv";

// intercom app id by env
export const INTERCOM_APP_ID = IS_DEV_ENV ? "mfl56y1q" : "wekubn67";

/**
 * hide intercom for following routes
 */
export const HIDE_INTERCOM_ROUTES = [
  "/pro-dashboard/messages",
  "/pro-dashboard/notes-and-forms",
  "/pro-dashboard/orchid-storage",
  "/pro-dashboard/settings/my-profile",
  "/pro-dashboard/settings/practice-setting",
  "/pro-dashboard/settings/billing",
  "/pro-dashboard/settings/patient-analytics",
  "/pro-dashboard/settings/ai-setting",
  "/pro-dashboard/schedule",
  "/pro-dashboard/audio-recorder",
  "/member-dashboard/notes-and-forms",
  "/member-dashboard/messages",
  "/video-chat",
];
