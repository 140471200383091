import createNoteOrFormData from "services/createNoteOrFormData";
import putNoteOrFormData from "services/putNoteOrFormData";

const attachNoteToAppointment = async ({
  appointmentId,
  patientId,
  noteSchema,
}: {
  appointmentId: number;
  patientId: string;
  noteSchema: NoteSchema;
}) => {
  const noteMetaData = await createNoteOrFormData("note", {
    appointment_id: appointmentId,
    patient_cognito_sub: patientId,
  });

  const noteData = await putNoteOrFormData("note", noteMetaData.note_data_id, {
    note_schema_id: noteSchema.note_schema_id,
    note_schema_revision: noteSchema.note_schema_revision,
    patient_cognito_sub: patientId,
  });

  return {
    noteMetaData,
    noteData,
  };
};

export default attachNoteToAppointment;
