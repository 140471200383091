import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import FullWidthImage from "components/full-width-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import Footer from "components/footer/Footer";
import { Link } from "react-router-dom";
import { AmplitudePageName } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { questions } from "./OrchidAI";
import { testimonials } from "./OrchidAI";

import getClasses from "utils/getClasses";
import styles from "./OAIPhoneView.module.scss";

const LANDING_PHONE = "/img/orchid-ai/landing-phone.png";

const NEVER_START_PHONE = "/img/orchid-ai/never-start-phone.png";
const TRUST_PHONE = "/img/orchid-ai/trust-phone.png";
const STREAMLINE_PHONE = "/img/orchid-ai/streamline-phone.png";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "orchid-ai-page";

const OAIPhoneView = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  return (
    <div className={styles["phone-view"]}>
      <div className={styles["landing"]}>
        <div className={styles["landing__title"]}>
          Take back your time with Orchid AI
        </div>
        <div className={styles["landing__content"]}>
          The first powerful AI progress note generator that’s fully integrated
          into your EHR
        </div>
        <FullWidthImage
          src={LANDING_PHONE}
          fluid
          className={styles["landing__image"]}
        />
        <Button className={styles["action"]} as={Link} to={"/pricing"}>
          Try Orchid AI
        </Button>
      </div>
      <div className={styles["embrace-section"]}>
        <div className={styles["embrace-section__title"]}>
          Embrace the AI-powered evolution of mental healthcare
        </div>
        <div className={styles["embrace-section__sub-title"]}>
          See how other professionals are thriving thanks to Orchid AI
        </div>

        <div>
          {testimonials.map((item) => (
            <div key={item.id} className={styles["item"]}>
              <div className={styles["quote-sign"]}>“</div>
              <div className={styles["item__body"]}>{item.content}</div>
              <div className={styles["item__footer"]}>
                <span>{item.date}</span>
                <span>•</span>
                <span>{item.author}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles["container"]}>
        <h2 className={styles["title"]}>
          Why automate with Orchid AI clinical notes?
        </h2>

        <div className={styles["step-section"]}>
          <FullWidthImage src={NEVER_START_PHONE} fluid />

          <div className={styles["step-title"]}>
            Never start from a blank page again.
          </div>
          <div className={styles["step-content"]}>
            <p>
              Complete comprehensive, insurance-compliant notes more quickly
              with the combined powers of Orchid’s AI progress note generator
              and the fully customizable templates of your choice.
            </p>
            <p>
              Think of Orchid AI as a super savvy SOAP note generator, a
              dang-that’s-fast DAP note generator, or even a
              wow-I-actually-get-my-evenings-back generator.
            </p>
          </div>

          <FullWidthImage src={TRUST_PHONE} fluid />

          <div className={styles["step-title"]}>
            Invest your time in an AI product you can really trust.
          </div>
          <div className={styles["step-content"]}>
            When it comes to vetting new tech for your practice, you can trust
            in the Orchid AI team. Orchid was founded and developed by a team of
            board-certified licensed clinicians and AI & privacy experts at the
            vanguard of their professions.
          </div>

          <FullWidthImage src={STREAMLINE_PHONE} fluid />

          <div className={styles["step-title"]}>
            Streamline your clinical workflow — all in one place.
          </div>
          <div className={styles["step-content"]}>
            <p>
              Save time by generating your AI clinical notes within the same
              all-in-one practice management software that includes online
              scheduling, automated reminders, integrated billing, telehealth,
              and more.
            </p>
            <p>
              Say goodbye to juggling multiple software accounts when you say
              hello to Orchid.
            </p>
          </div>
        </div>

        <Button
          as={Link}
          to={"/pricing"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          className={styles["action"]}
        >
          Start benefiting from Orchid AI
        </Button>
      </div>

      <div className={styles["how-ai-works"]}>
        <div className={styles["how-ai-works__title"]}>
          How does Orchid AI work?
        </div>
        <Card className={styles["step-card"]}>
          <div className={styles["step-tag"]}>STEP 1</div>
          <div className={styles["step-title"]}>
            Start with your appointment.
          </div>
          <div className={styles["step-content"]}>
            Before you begin a session, set Orchid’s integrated HIPAA-compliant
            AI to generate a session transcript. From there, you can focus 100%
            on your client, rest assured that your administrative experience
            later will be a breeze.
          </div>
        </Card>

        <Card className={styles["step-card"]}>
          <div className={styles["step-tag"]}>STEP 2</div>
          <div className={styles["step-title"]}>
            Effortlessly generate AI clinical notes.
          </div>
          <div className={styles["step-content"]}>
            Kick back and watch as Orchid’s clinically-trained AI model drafts
            any type of note you need, based on key data from the session
            transcript. Get suggestions for CPT codes, diagnoses, goals,
            treatment plans, and more — all within moments.
          </div>
        </Card>

        <Card className={styles["step-card"]}>
          <div className={styles["step-tag"]}>STEP 3</div>
          <div className={styles["step-title"]}>
            Gain measurable session insights.
          </div>
          <div className={styles["step-content"]}>
            Along with the session transcript, Orchid’s AI progress note
            generator also draws insights into key session data points to help
            you become an even more effective clinician. For example, Orchid AI
            will visualize data on speech patterns, such as how often you and
            your client each talk vs. how often you’re each silent.
          </div>
        </Card>
        <Card className={styles["step-card"]}>
          <div className={styles["step-tag"]}>STEP 4</div>
          <div className={styles["step-title"]}>Review, sign, and submit.</div>
          <div className={styles["step-content"]}>
            Simply review Orchid AI’s draft with your expert eye before signing
            off on the note. Your trained expertise + Orchid AI’s handling of
            admin drudgery? Now that’s what we call an EHR Dream Team.
          </div>
        </Card>
        <Card className={styles["step-card"]}>
          <div className={styles["step-tag"]}>STEP 5</div>
          <div className={styles["step-title"]}>
            Do literally anything else.
          </div>
          <div className={styles["step-content"]}>
            Reunite with your hobbies, have a date night, go to bed early — what
            will you do with all the time you’ll get back, thanks to the power
            of Orchid’s AI progress note generator? You’ll even be able to make
            room in your schedule for more clients, helping you earn more and
            grow your practice more efficiently.
          </div>
        </Card>
        <div className={styles["how-ai-works__action"]}>
          <Button
            as={Link}
            to={"/pricing"}
            target={"_blank"}
            rel={"noopener noreferrer"}
            className={styles["action"]}
          >
            Start automating your admin with Orchid AI
          </Button>
        </div>
      </div>

      <div className={styles["phone-view__faq"]}>
        <h2
          className={getClasses(
            styles["bottom-24"],
            styles["phone-view__title"],
          )}
        >
          Questions?
          <br />
          We’ve got answers.
        </h2>
        {questions.map(({ id, question, answer }, idx) => {
          return (
            <CustomAccordion
              key={id}
              id={id}
              index={idx}
              question={question}
              answer={answer}
              amplitudePageName={AMPLITUDE_PAGE_NAME}
            />
          );
        })}
        <div className={styles["more-question"]}>
          <div className={styles["more-question__title"]}>
            Got more questions about Orchid?
          </div>

          <Button
            className={styles["more-question__action"]}
            as={Link}
            to={"/orchid-ehr-faq"}
            size={"lg"}
          >
            Read the rest of our FAQs
          </Button>
        </div>
      </div>
      <div className={styles["get-power"]}>
        <div className={styles["get-power__title"]}>
          Get the power of Orchid AI today.
        </div>
        <div className={styles["get-power__text"]}>
          Transform your practice with increased efficiency, deeper insights,
          and improved client outcomes.
        </div>

        <Button
          size={"lg"}
          className={styles["action"]}
          as={"a"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={"https://calendly.com/hey-orchid/orchid-walkthrough"}
        >
          Get your free Orchid AI demo
        </Button>
      </div>
      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default OAIPhoneView;
