import getClasses from "utils/getClasses";
import styles from "./StepBar.module.scss";

interface StepBarProps {
  steps: string[];
  currentStep: number;
}

const StepBar = ({ steps, currentStep }: StepBarProps) => (
  <div className={styles.container}>
    {steps.map((step, index) => (
      <div
        key={step}
        className={getClasses(
          styles.step,
          currentStep >= index && styles.active,
        )}
      />
    ))}
  </div>
);

export default StepBar;
