const getQueryParams = (params: Record<string, any>) => {
  const entries = Object.entries(params);

  const validEntries = entries.filter(
    ([_, value]) => typeof value !== "undefined",
  );

  if (!validEntries.length) {
    return "";
  }

  const queryParamsString = validEntries.map(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((item) => `${key}=${item}`).join("&");
    }

    return `${key}=${value}`;
  });

  return "?" + queryParamsString.join("&");
};

export default getQueryParams;
