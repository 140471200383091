import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import FullWidthImage from "components/full-width-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import Footer from "components/footer/Footer";
import LazyImage from "components/lazy-image";
import { Link } from "react-router-dom";
import { AmplitudePageName } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { questions, reasons, testimonials } from "./MeasurementBasedCare";

import getClasses from "utils/getClasses";
import styles from "./MBCPhoneView.module.scss";

const LANDING_PHONE = "/img/measurement-based-care/landing-phone.png";

const CARD_1_PHONE = "/img/measurement-based-care/card-1-phone.png";
const CARD_2_PHONE = "/img/measurement-based-care/card-2-phone.png";
const CARD_3_PHONE = "/img/measurement-based-care/card-3-phone.png";
const CARD_4_PHONE = "/img/measurement-based-care/card-4-phone.png";

const STEP_1_PHONE = "/img/measurement-based-care/step-1-phone.png";
const STEP_2_PHONE = "/img/measurement-based-care/step-2-phone.png";
const STEP_3_PHONE = "/img/measurement-based-care/step-3-phone.png";
const STEP_4_PHONE = "/img/measurement-based-care/step-4-phone.png";
const STEP_5_PHONE = "/img/measurement-based-care/step-5-phone.png";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "measurement-based-care-page";

const MBCPhoneView = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  return (
    <div className={styles["phone-view"]}>
      <div className={styles["landing"]}>
        <div className={styles["landing__title"]}>
          Empower Your Clients with Measurement-Based Care
        </div>
        <div className={styles["landing__content"]}>
          Save more hours (and more patients) with EHR-integrated
          measurement-based care tools for your private practice.
        </div>
        <div className={"d-flex justify-content-center"}>
          <LazyImage
            src={LANDING_PHONE}
            width={335}
            className={styles["landing__image"]}
          />
        </div>
        <Button
          size={"lg"}
          className={styles["action"]}
          as={"a"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={"https://calendly.com/hey-orchid/orchid-walkthrough"}
        >
          Book Your Free Demo
        </Button>
      </div>
      <div className={styles["container"]}>
        <h2 className={styles["title"]}>
          Time-Saving Automations for You, <br />
          Life-Saving Results for Patients
        </h2>
        <div className={styles["start-trial"]}>
          Streamline your workflow using Orchid’s measurement-based care (MBC)
          automations.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={CARD_1_PHONE} fluid />
        </div>
        <div className={styles["step-title"]}>
          Improve patient health outcomes
        </div>
        <div className={styles["step-content"]}>
          With Orchid’s MBC tools, <b>83% of patients</b> who regularly took
          PHQ-9 assessments showed a marked <b>improvement in symptoms</b> of
          depression.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={CARD_2_PHONE} fluid />
        </div>
        <div className={styles["step-title"]}>
          Cut patient no-shows by up to 25%
        </div>
        <div className={styles["step-content"]}>
          <b> Save more than $1,000 annually</b> just by decreasing patient
          cancellations and no-shows, while increasing patient engagement,
          thanks to Orchid’s <b>automated measurement-based care tools</b>. 
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={CARD_3_PHONE} fluid />
        </div>
        <div className={styles["step-title"]}>
          Reduce clinician turnover by up to 75%
        </div>
        <div className={styles["step-content"]}>
          Get <b>the antidote to burnout and high turnover</b> by equipping your
          practice with Orchid’s automated MBC tools that{" "}
          <b>lessen the administrative overload</b>, saving you tens of
          thousands in lost revenue and hiring costs.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={CARD_4_PHONE} fluid />
        </div>
        <div className={styles["step-title"]}>Earn more revenue</div>
        <div className={styles["step-content"]}>
          Did you know that insurers may give you{" "}
          <b>higher reimbursement rates</b>
          if you can show data-driven outcomes?{" "}
          <b>Earn up to 10% more revenue per appointment</b> by using Orchid’s
          measurement-based tools. 
        </div>

        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Save More with MBC
        </Button>
      </div>

      <div className={styles["why-automate"]}>
        <h2 className={styles["why-automate__title"]}>
          Why automate measurement-based care with Orchid?
        </h2>
        <div>
          {reasons.map((reason) => {
            return (
              <Card className={styles["why-automate__card"]}>
                <div>{reason.icon}</div>
                <div
                  className={styles["why-automate__card-text"]}
                  dangerouslySetInnerHTML={{ __html: reason.title }}
                />
              </Card>
            );
          })}
        </div>
      </div>
      <div className={styles["container"]}>
        <h2 className={styles["title"]}>
          How do Orchid’s measurement-based care tools work?
        </h2>
        <div className={styles["start-trial"]}>
          Automate every step of MBC — all within the same EHR solution that
          helps you manage the rest of your practice.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={STEP_1_PHONE} fluid />
        </div>

        <div className={styles["step-tag"]}>STEP 1</div>
        <div className={styles["step-title"]}>
          Schedule your assessment forms.
        </div>
        <div className={styles["step-content"]}>
          Select PHQ-9, GAD-7, or PCL-5 baseline forms from our pre-generated
          template library, and set them to auto-send before appointments.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={STEP_2_PHONE} fluid />
        </div>

        <div className={styles["step-tag"]}>STEP 2</div>
        <div className={styles["step-title"]}>Automate reminders.</div>
        <div className={styles["step-content"]}>
          Achieve a 97% form completion rate with Orchid’s automatic reminders
          to patients. 
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={STEP_3_PHONE} fluid />
        </div>

        <div className={styles["step-tag"]}>STEP 3</div>
        <div className={styles["step-title"]}>
          See new insights into patient health.
        </div>
        <div className={styles["step-content"]}>
          Orchid analyzes those forms within seconds and visualizes the results
          for you.
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={STEP_4_PHONE} fluid />
        </div>

        <div className={styles["step-tag"]}>STEP 4</div>
        <div className={styles["step-title"]}>
          Get instant alerts about high-risk individuals.
        </div>
        <div className={styles["step-content"]}>
          We’ll sound the alarms immediately to flag any critical patient
          responses, so you can promptly intervene as needed. 
        </div>

        <div className={styles["step-card"]}>
          <FullWidthImage src={STEP_5_PHONE} fluid />
        </div>

        <div className={styles["step-tag"]}>STEP 5</div>
        <div className={styles["step-title"]}>
          Help patients more effectively.
        </div>
        <div className={styles["step-content"]}>
          When you use Orchid’s measurement-based care tools to inform your
          treatment plans, patients are 3x more likely to reach remission in
          their mental health concerns. 
        </div>
        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Get Results with Orchid’s MBC
        </Button>
      </div>

      <div className={styles["i-love-ehr"]}>
        <div
          className={getClasses(
            styles["bottom-32"],
            styles["i-love-ehr__title"],
          )}
        >
          Clinicians like you are growing with Orchid
        </div>
        <div>
          {testimonials.map((item) => (
            <div key={item.id} className={styles["item"]}>
              <div className={styles["quote-sign"]}>“</div>
              <div className={styles["item__body"]}>{item.content}</div>
              <div className={styles["item__footer"]}>
                <span>{item.date}</span>
                <span>•</span>
                <span>{item.author}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles["get-power"]}>
        <div className={styles["get-power__title"]}>
          Get the power of Orchid today.
        </div>
        <div className={styles["get-power__text"]}>
          Transform your administrative workflow and get better patient results
          with Orchid’s automated measurement-based care tools.
        </div>

        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          target={"_blank"}
          rel={"noopener noreferrer"}
          to={"/pricing"}
        >
          Start Your Free Trial
        </Button>
      </div>
      <div className={styles["phone-view__faq"]}>
        <h2
          className={getClasses(
            styles["bottom-24"],
            styles["phone-view__title"],
          )}
        >
          Questions? <br />
          We’ve got answers.
        </h2>
        {questions.map(({ id, question, answer }, idx) => {
          return (
            <CustomAccordion
              key={id}
              id={id}
              index={idx}
              question={question}
              answer={answer}
              amplitudePageName={AMPLITUDE_PAGE_NAME}
            />
          );
        })}
      </div>
      <div className={styles["more-question"]}>
        <div className={styles["more-question__title"]}>
          Got more questions about Orchid?
        </div>
        <Button
          className={styles["more-question__action"]}
          as={Link}
          to={"/orchid-ehr-faq"}
          size={"lg"}
        >
          Read the rest of our FAQs
        </Button>
      </div>
      <div className={styles["slogan"]}>
        <div className={styles["slogan__title"]}>
          Private Practice – Made Perfect
        </div>
        <div className={styles["slogan__content"]}>
          Save time, money, and admin headaches when you switch to Orchid today.
          <br />
          Experience the difference of managing your practice with Orchid.
        </div>

        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Switch to Orchid
        </Button>
      </div>
      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default MBCPhoneView;
