import { IS_DEV_ENV } from "constants/isDevEnv";

const TEST_USERS = [
  "2d34f3c7-6bda-4c44-b11c-f31ec1cea41c", // aya.gungun.banana+999@gmail.com
  "774ae008-c3e0-4a9f-91c4-6c3cad00ad38", // orchid.exchange.test@gmail.com
  "9cba7758-55fb-4ec4-84df-213db5fc687b", // aya.gungun.2021@gmail.com
  "ed34ed17-9763-4907-99f5-fb335c0886a1", // orchid.health.tiktok@gmail.com
  "os_usr_82cd2cf820f7233c3caa482279f0a0", // pyipeng@alumni.upenn.edu
  "cdd5bb15-bade-4492-8da5-9c9e40583ca1", // joe@orchid.health
];

export default function isTestUser(user: User): boolean {
  return IS_DEV_ENV || TEST_USERS.includes(user.sub);
}
