import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Query patient
 */
export default async function queryPatient() {
  const res = await fetchOrchidAPI("/api/users/v1/patient/");
  if (!res.ok) {
    throw new Error(
      `failed to get patient info: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
