import getBottomNavItemProps from "components/app-navigation/components/phone-navigation/components/bottom-navigation/utils/getBottomNavItemProps";
import { useUser } from "contexts/UserContext";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import getClasses from "utils/getClasses";

import styles from "./styles.module.scss";
import lazy from "utils/lazyOrReload";
const MessageNotifications = lazy(
  () => import("components/message-notifications"),
);

type Props = Omit<NavLinkProps, "children"> &
  ReturnType<typeof getBottomNavItemProps>;

const BottomNavItem = ({ Icon, message, routeKey, ...props }: Props) => {
  const { user } = useUser();
  return !Icon ? null : (
    <NavLink
      {...props}
      activeClassName={styles["bottom-nav-item--active"]}
      className={getClasses(styles["bottom-nav-item"])}
    >
      <span className={styles["bottom-nav-item__icon-container"]}>
        <span className={styles["bottom-nav-item__icon"]}>
          {Icon}
          {/* pubnub sizes big, use lazy load here */}
          {routeKey === "messages" && user && (
            <MessageNotifications
              className={styles["bottom-nav-item__badge"]}
            />
          )}
        </span>
      </span>
      <span className={styles["bottom-nav-item__message"]}>{message}</span>
    </NavLink>
  );
};

export default BottomNavItem;
