import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ReactComponent as WriteForUsBannerPhone } from "assets/svg/write-for-us/write-for-us-banner-phone.svg";
import { ReactComponent as OutlineArrowRight } from "assets/svg/outline-arrow-right.svg";
import { ReactComponent as Step1Icon32 } from "assets/svg/write-for-us/step-1-32.svg";
import { ReactComponent as Step2Icon32 } from "assets/svg/write-for-us/step-2-32.svg";
import { ReactComponent as Step3Icon32 } from "assets/svg/write-for-us/step-3-32.svg";
import { ReactComponent as Step4Icon32 } from "assets/svg/write-for-us/step-4-32.svg";
import { ReactComponent as IncompleteDrafts72 } from "assets/svg/write-for-us/incomplete-drafts-72.svg";
import { ReactComponent as PromotionalCopy72 } from "assets/svg/write-for-us/promotional-copy-72.svg";
import { ReactComponent as InaccurateContent72 } from "assets/svg/write-for-us/inaccurate-content-72.svg";
import { ReactComponent as PublishedWork72 } from "assets/svg/write-for-us/published-work-72.svg";
import { ReactComponent as OutlineUserSpeaker32 } from "assets/svg/outline-user-speaker-32.svg";
import { ReactComponent as OutlineFilters32 } from "assets/svg/outline-filters-32.svg";
import { ReactComponent as OutlineGrid32 } from "assets/svg/outline-grid-32.svg";
import { ReactComponent as OutlineCut } from "assets/svg/outline-cut.svg";
import { ReactComponent as OutlineEmotxdSad } from "assets/svg/outline-emotxd-sad.svg";
import { ReactComponent as SubmitBlog } from "assets/svg/write-for-us/submit-blog.svg";
import { ReactComponent as StyleGuidelinesEllipse } from "assets/svg/write-for-us/style-guidelines-ellipse.svg";
import { ReactComponent as Divider } from "assets/svg/write-for-us/divider.svg";
import FullWidthImage from "components/full-width-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import Footer from "components/footer/Footer";
import { Link } from "react-router-dom";
import { AmplitudePageName, track } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { questions } from "./WriteForUs";

import getClasses from "utils/getClasses";
import styles from "./WFUPhoneView.module.scss";

const ILLUSTRATION_1 = "/img/write-for-us/illustration1.png";
const ILLUSTRATION_2 = "/img/write-for-us/illustration2.png";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "write-for-orchid-page";

const WFUPhoneView = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  return (
    <div className={styles["phone-view"]}>
      <div className={styles["phone-view__landing-section"]}>
        <FullWidthImage src={"/img/write-for-us/hero-mobile.png"} />
        <div className={styles["phone-view__welcome-message"]}>
          <h2 className={"rg-24 deep-blue questrial"}>
            Community blog submissions welcome!
          </h2>
          <p>
            Are you an Orchid member who likes to write? We invite you to share
            your expertise with the mental health professionals community by
            writing for Orchid's blog.
          </p>
          <p>Learn how to submit a blog post to Orchid!</p>
        </div>
      </div>
      <div
        className={getClasses(
          styles["phone-view__top-40"],
          styles["phone-view__container"],
        )}
      >
        <h2
          className={getClasses(
            styles["phone-view__bottom-24"],
            styles["phone-view__title"],
          )}
        >
          Write for the Orchid Community
        </h2>

        <div className={styles["phone-view__intro-paragraph"]}>
          <b>
            Orchid is more than an electronic health record platform — we're a
            growing community of mental health professionals who care about
            growth and wellness, and our emerging blog reflects that.
          </b>
        </div>
        <div className={styles["phone-view__intro-paragraph"]}>
          If you're a mental health professional with any experience with
          blogging about mental healthcare topics, then we want to hear from
          you! You could fit right into our community of healthcare professional
          contributors on{" "}
          <Link
            to="/blogs"
            onClick={() =>
              track(AMPLITUDE_PAGE_NAME, "orchid-blog-link", "click")
            }
          >
            The Orchid blog
          </Link>
          .
        </div>
        <div className={styles["phone-view__intro-paragraph"]}>
          <b>
            Before submitting any blog posts for consideration, please
            thoroughly review the submission guidelines and FAQ below.
          </b>{" "}
          Your submission must meet our requirements in order to get published
          on Orchid's blog. Orchid's editorial team reserves the right to reject
          submissions at our discretion.
        </div>
        <div
          className={getClasses(
            styles["phone-view__bottom-40"],
            styles["phone-view__top-40"],
            "text-center",
          )}
        >
          <Divider />
        </div>
        <h2
          className={getClasses(
            styles["phone-view__bottom-24"],
            styles["phone-view__title"],
          )}
        >
          Guidelines for Blog Submissions
        </h2>
        <div className={styles["phone-view__submit-requirements"]}>
          <div className={styles["phone-view__submit-requirements-title"]}>
            Submission requirements
          </div>
          <div className={"rg-14"}>
            Following these content and quality guidelines when writing your
            blog post will give you a better chance at publication on Orchid's
            blog.
          </div>
        </div>
      </div>
      <div className={styles["phone-view__top-40"]}>
        <FullWidthImage src={ILLUSTRATION_1} height={"auto"} width={"100%"} />
        <div
          className={getClasses(
            styles["phone-view__top-40"],
            styles["phone-view__bottom-40"],
            styles["phone-view__container"],
          )}
        >
          <h4 className={styles["phone-view__sub-title"]}>
            Write for Orchid's primary audience
          </h4>
          <div className={styles["phone-view__bottom-24"]}>
            Orchid's blog is intended for an audience of mental health
            professionals. Please only submit blog posts meant for that
            audience, not for patients or the general public. Our audience of
            mental health professionals includes (but is not limited to):
            therapists, psychologists, psychiatrists, social workers,
            policymakers, and researchers in the United States.
          </div>
          <h4 className={styles["phone-view__sub-title"]}>
            Align your post's topic with Orchid's content categories
          </h4>
          <div className={"mb-3"}>
            We're interested in considering in-depth, actionable blog posts
            about any topic that's important to mental health professionals.
            We're particularly interested in blog posts that share practical
            advice and expertise on topics that fit into our blog's content
            categories:
          </div>
          <ul>
            <li>
              <b>Professional Development:</b> practice management, business
              growth, continuing education
            </li>
            <li>
              <b>Clinician Wellness:</b> personal growth, self-care
            </li>
            <li>
              <b>Industry Research:</b> case studies, reports
            </li>
            <li>
              <b>In the News:</b> current trends and developments in mental
              healthcare
            </li>
          </ul>
          <div className={styles["phone-view__bottom-24"]}>
            <Link
              to="/blogs"
              onClick={() =>
                track(AMPLITUDE_PAGE_NAME, "orchid-blog-link", "click")
              }
            >
              Read current posts on our blog
            </Link>{" "}
            to get a better sense of the types of topics your blog post could
            focus on.
          </div>

          <h4 className={styles["phone-view__sub-title"]}>
            Make the point of your post clear
          </h4>
          <div>
            Have you ever read something and walked away uncertain about what
            you were supposed to gain from reading it? Make sure your blog post
            doesn't create that type of experience. Your blog post should
            communicate a clear central premise that includes valuable takeaways
            that are relevant and useful to Orchid's audience. Whatever you
            choose to write about, make it clear from the first paragraph what
            your point is and why it matters.
          </div>
        </div>
      </div>
      <div className={styles["phone-view__style-guidelines"]}>
        <div className={styles["phone-view__style-guidelines-inner"]}>
          <h4 className={getClasses("mb-3", styles["phone-view__title"])}>
            Follow Orchid's style guidelines
          </h4>
          <p className={styles["phone-view__bottom-32"]}>
            Style matters as much as substance when creating blog posts.
            Following these guidelines will help you craft more legible and
            engaging content.
          </p>
          <Row className={styles["phone-view__style-guidelines-row"]}>
            <OutlineUserSpeaker32 />
            <span>
              Use language that's natural to your voice. <br />
              When in doubt, lean into being conversational over academic in
              tone.
            </span>
          </Row>
          <Row className={styles["phone-view__style-guidelines-row"]}>
            <OutlineCut />
            <span>
              Structure sentences that are simple, clear, and the most direct
              routes to your point.
            </span>
          </Row>
          <Row className={styles["phone-view__style-guidelines-row"]}>
            <OutlineFilters32 />
            <span>
              Break up your text with bold section headings that emphasize the
              key points of your post.
            </span>
          </Row>
          <Row className={styles["phone-view__style-guidelines-row"]}>
            <OutlineGrid32 />
            <span>
              Use bullet points or subheadings where appropriate. Skimmable
              content structures like those give your words more room to breathe
              and help readers digest information better than long blocks of
              text.
            </span>
          </Row>
          <Row className={styles["phone-view__style-guidelines-row"]}>
            <OutlineEmotxdSad />
            <span>
              Avoid jargon. If you must use a term that's not widely known by
              mental health professionals, define that term clearly in your
              post.
            </span>
          </Row>

          <p className={"m-0"}>
            Please take the time to self-edit your post along these guidelines
            before submitting it to Orchid. Not sure where to begin with
            editing? You could try this{" "}
            <a
              href="https://hemingwayapp.com/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                track(AMPLITUDE_PAGE_NAME, "hemingwayapp-link", "click")
              }
            >
              free editing app
            </a>
            — it helps you identify where your sentence structure and vocabulary
            could benefit from a refresh (and why).
          </p>
          <StyleGuidelinesEllipse
            className={styles["phone-view__style-guidelines-img"]}
          />
        </div>
      </div>
      <FullWidthImage src={ILLUSTRATION_2} height={"auto"} width={"100%"} />
      <div
        className={getClasses(
          styles["phone-view__top-40"],
          styles["phone-view__bottom-40"],
          styles["phone-view__container"],
        )}
      >
        <h4 className={styles["phone-view__sub-title"]}>
          Pay attention to word count
        </h4>
        <div className={styles["phone-view__bottom-24"]}>
          Although we won't reject a submission based solely on length,
          800-1,800 words is a good word count range to target. For example, a
          post around 1,200 words is long enough to convey a meaningful amount
          of information, but not too long that readers might be deterred from
          reading it in full. If your post is shorter than 800 words or longer
          than 1,800 words, we'll still consider it if it checks all the other
          requirements.
        </div>
        <h4 className={styles["phone-view__sub-title"]}>
          Suggest a title for your post
        </h4>
        <div className={styles["phone-view__bottom-24"]}>
          The first thing readers will notice about your blog post is the title.
          Capture their attention with a title that clearly communicates the
          topic or premise of the post. Keep your title brief: 10 words or 100
          characters maximum. It's okay if the title isn't perfect — just give
          it your best effort. Orchid reserves the right to edit the title in
          order to influence marketing metrics.
        </div>
        <h4 className={styles["phone-view__sub-title"]}>
          Properly cite your work
        </h4>
        <div className={"mb-3"}>
          If directing to appropriate online sources: Use hyperlinks with text
          that makes it clear where that hyperlink will direct readers (instead
          of simply writing something like “Read more”). Please minimize the
          number of links in your article (10 maximum).
        </div>
        <div className={"mb-3"}>
          If citing offline sources: Cite references according to{" "}
          <a
            href="https://apastyle.apa.org/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              track(AMPLITUDE_PAGE_NAME, "APA-style-guidelines-link", "click")
            }
          >
            American Psychological Associate (APA) style guidelines
          </a>
          . Integrate your references directly into the text; don't use
          footnotes.
        </div>
        <div className={styles["phone-view__bottom-24"]}>
          Your blog post should be understood as a standalone piece, but if it's
          related to a larger body of work or journal article, please briefly
          give that context in your post along with hyperlinks or citations
          where appropriate.
        </div>
        <h4 className={styles["phone-view__sub-title"]}>
          Include an author bio
        </h4>
        <div>
          We ask that all authors include a short biography (100 words max).
          That will appear in the publication at the bottom of your blog post.
          Biographies should include your name, your institutional affiliation
          and position, and the focus of your work.
        </div>
      </div>
      <hr />
      <div
        className={getClasses(
          styles["phone-view__top-40"],
          styles["phone-view__container"],
        )}
      >
        <h2
          className={getClasses(
            styles["phone-view__bottom-24"],
            styles["phone-view__title"],
          )}
        >
          Submissions we won't accept
        </h2>

        <div className={styles["phone-view__submission-decline-warning"]}>
          Not following the submission requirements above and/or doing any of
          the following no-no's will make it unlikely we'd accept your blog post
          submission.
        </div>
        <div className={styles["phone-view__submission-decline"]}>
          <IncompleteDrafts72 />
          <div className={getClasses("mt-3", styles["phone-view__bottom-40"])}>
            <h4 className={styles["phone-view__sub-title"]}>
              Incomplete drafts
            </h4>
            <p className={"mb-0"}>
              Please only submit complete post drafts. Our editors will only put
              your post through a single round of edits, so your post should be
              your complete and best work when you send it to us. Partial
              drafts, outlines, or posts lacking a clear central premise will
              not be accepted.
            </p>
          </div>

          <PromotionalCopy72 />
          <div className={getClasses("mt-3", styles["phone-view__bottom-40"])}>
            <h4 className={styles["phone-view__sub-title"]}>
              Promotional copy
            </h4>
            <p className={"mb-0"}>
              Do not include promotional language about yourself or your
              organization. Your post must not be construed as a link-building
              scheme, and it shouldn't contain marketing-related links or
              promotional language about any businesses, products, or events.
            </p>
          </div>

          <InaccurateContent72 />
          <div className={getClasses("mt-3", styles["phone-view__bottom-40"])}>
            <h4 className={styles["phone-view__sub-title"]}>
              Offensive or inaccurate content
            </h4>
            <p className={"mb-0"}>
              Do not include offensive language or discriminatory content. Your
              post should also not be overly critical of individuals or
              companies. Do not violate the confidentiality, privacy, or
              privilege of any protected relationships, information, or
              communications.{" "}
            </p>
          </div>

          <PublishedWork72 />
          <div className={getClasses("mt-3", styles["phone-view__bottom-40"])}>
            <h4 className={styles["phone-view__sub-title"]}>
              Previously published work
            </h4>
            <p className={"mb-0"}>
              Please only submit original content that has not been published
              anywhere else. We will not accept your submission if it has
              already appeared in another website, blog, social media platform,
              or other form of publication.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className={styles["phone-view__faq"]}>
        <h2
          className={getClasses(
            styles["phone-view__bottom-24"],
            styles["phone-view__title"],
          )}
        >
          Blog Submissions FAQ
        </h2>
        {questions.map(({ id, question, answer }, idx) => {
          return (
            <CustomAccordion
              key={id}
              id={id}
              index={idx}
              question={question}
              answer={answer}
              amplitudePageName={AMPLITUDE_PAGE_NAME}
            />
          );
        })}
      </div>

      <div className={"justify-content-center d-flex"}>
        <WriteForUsBannerPhone />
      </div>

      <div
        className={getClasses(
          styles["phone-view__top-40"],
          styles["phone-view__container"],
        )}
      >
        <h2
          className={getClasses(
            styles["phone-view__title"],
            styles["phone-view__bottom-24"],
          )}
        >
          How to Submit a Blog Post
        </h2>

        <Row className={styles["phone-view__submit-blog-row"]}>
          <Col className={styles["phone-view__submit-blog-left"]}>
            <Step1Icon32 />
          </Col>

          <Col>
            <div className={styles["phone-view__submit-blog-title"]}>
              Make sure your full post draft is readable and editable in one of
              the following formats:
            </div>
            <div className={styles["phone-view__submit-blog-content"]}>
              <div>a. Word doc (.doc, .docx) </div>
              <div>
                b. Google doc (with the{" "}
                <a
                  href="https://support.google.com/docs/answer/2494822?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Cshare-a-link-to-the-file%2Cshare-a-file-publicly"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    track(
                      AMPLITUDE_PAGE_NAME,
                      "google-doc-share-settings-link",
                      "click",
                    )
                  }
                >
                  Share settings
                </a>{" "}
                of "Anyone on the internet with this link can edit")
              </div>
            </div>
          </Col>
        </Row>

        <Row className={styles["phone-view__submit-blog-row"]}>
          <Col className={styles["phone-view__submit-blog-left"]}>
            <Step2Icon32 />
          </Col>

          <Col className={styles["phone-view__submit-blog-right"]}>
            <div className={styles["phone-view__submit-blog-title"]}>
              Attach your Word doc or link to your Google doc in an email.
              Remember that doc must include your:
            </div>
            <div className={styles["phone-view__submit-blog-content"]}>
              <div>a. Suggested title for your blog post</div>
              <div>
                b. Full draft of your blog post (edited and formatted according
                to our submission guidelines)
              </div>
              <div>c. Short author bio (at the bottom of your post)</div>
            </div>
          </Col>
        </Row>

        <Row className={styles["phone-view__submit-blog-row"]}>
          <Col className={styles["phone-view__submit-blog-left"]}>
            <Step3Icon32 />
          </Col>

          <Col className={styles["phone-view__submit-blog-right"]}>
            <div className={styles["phone-view__submit-blog-title"]}>
              Add the email subject line "Orchid Blog Post Submission"
            </div>
          </Col>
        </Row>

        <Row className={styles["phone-view__submit-blog-row"]}>
          <Col className={styles["phone-view__submit-blog-left"]}>
            <Step4Icon32 />
          </Col>

          <Col className={styles["phone-view__submit-blog-right"]}>
            <div className={styles["phone-view__submit-blog-title"]}>
              Send your submission to{" "}
              <a
                href="mailto:info@orchid.health"
                onClick={() =>
                  track(AMPLITUDE_PAGE_NAME, "info@orchid-email-link", "click")
                }
              >
                info@orchid.health
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <div className={"d-flex justify-content-end"}>
        <SubmitBlog className={styles["phone-view__submit-blog-img"]} />
      </div>
      <div className={styles["phone-view__footer"]}>
        <h2 className={getClasses("white", styles["phone-view__title"])}>
          Ready to submit?
        </h2>
        <Button
          className={styles["phone-view__action"]}
          as="a"
          href="mailto:info@orchid.health"
          onClick={() =>
            track(AMPLITUDE_PAGE_NAME, "send-your-pitch-button", "click")
          }
        >
          <OutlineArrowRight />
          <div
            className={getClasses("w-100", styles["phone-view__action-text"])}
          >
            Send your pitch
          </div>
        </Button>
      </div>

      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default WFUPhoneView;
