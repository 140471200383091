import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

export default async function createNewPatient(patient: ClientPortalPatient) {
  const response = await fetchOrchidAPI("/api/users/v1/create_patient/", {
    method: "POST",
    body: JSON.stringify(patient),
  });

  if (!response.ok) {
    const responseText = await response.text();
    throw new Error(getErrorDetail(responseText));
  }

  return response.json();
}
