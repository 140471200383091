import React, { ComponentPropsWithoutRef, forwardRef } from "react";
import { Button } from "react-bootstrap";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

const IconButton = forwardRef(
  (
    {
      className = "",
      ...props
    }: Omit<ComponentPropsWithoutRef<typeof Button>, "variant">,
    ref,
  ) => (
    <Button
      variant="link"
      className={getClasses(styles["icon-button"], className)}
      aria-label={"icon-button"}
      {...props}
      ref={ref as any}
    />
  ),
);

export default IconButton;
