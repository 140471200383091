import { useUser } from "contexts/UserContext";
// import { apiHostAndPath } from "utils/api";
import styles from "./styles.module.scss";
// import RoundedImageWithFallback from "components/rounded-image-with-fallback";

const User = () => {
  const { user } = useUser();
  return (
    <div className={styles["user"]}>
      {/* {user && user.user_type === "orchid_pro" && (
        <RoundedImageWithFallback
          src={apiHostAndPath(
            `/api/users/orchid_pro/profile_image/${user.sub}?timestamp=${user.modify_datetime}`,
          )}
          width={24}
          height={24}
          className="mr-3"
        />
      )} */}
      <span className="rg-16 mr-3 blue">
        {user ? user.first_name : ""} {user ? user.last_name : ""}
        {/* {user && !user.email_confirmed ? (
          <p className="fs-11 mb-0">Email unconfirmed</p>
        ) : null}
        {user && user.email_confirmed && !user.signup_form_finished ? (
          <p className="fs-11 mb-0">Missing profile</p>
        ) : null} */}
      </span>
    </div>
  );
};

export default User;
