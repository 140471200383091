/**
 * routes, text and icon used for navigation when user type is "general"
 */
export const GENERAL_ROUTES = Object.freeze({
  home: "/",
  login: "/login",
  blogs: "/blogs",
  press: "/press",
  pricing: "/pricing",
  /**
   * Google Calendar feature
   */
  googleCalendarSuccess: "/google_calendar_success_redirect",
  googleCalendarFailure: "/google_calendar_fail_redirect",
  // should includes two routes professional-search and group-session
  // can direct to a false route and fallback to professional-search
  groupSession: "/group-session",
  professionalSearch: "/professional-search",
  aboutUs: "/about-us",
} as const);

export type TGeneralRoutesKey = keyof typeof GENERAL_ROUTES;
