import { fetchOrchidAPI } from "utils/api";
import { analytics } from "constants/segment";
import * as amplitude from "@amplitude/analytics-browser";

export default async function logoutUser(user) {
  const response = await fetchOrchidAPI("/api/login/v1/logout", {
    method: "POST",
  });
  if (response.ok) {
    analytics.reset();
    amplitude.reset();

    if (user?.user_type === "orchid_pro") {
      window.location.href = "/professional-homepage";
    } else {
      window.location.href = "/";
    }
  }
}
