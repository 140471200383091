import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import FullWidthImage from "components/full-width-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import Footer from "components/footer/Footer";
import LazyImage from "components/lazy-image";
import { Link } from "react-router-dom";
import { AmplitudePageName } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { questions, reasons, testimonials } from "./SwitchToOrchid";
import { ReactComponent as MdiHeart } from "assets/svg/switch-to-orchid/mdi-heart.svg";

import getClasses from "utils/getClasses";
import styles from "./STOPhoneView.module.scss";

const TRANSITION_PHONE = "/img/switch-to-orchid/transition-phone.png";
const COMPUTER_PHONE = "/img/switch-to-orchid/computer-phone.png";

const STEP_1_PHONE = "/img/switch-to-orchid/step-1-phone.png";
const STEP_2_PHONE = "/img/switch-to-orchid/step-2-phone.png";
const STEP_3_PHONE = "/img/switch-to-orchid/step-3-phone.png";

const HELLO_PHONE = "/img/switch-to-orchid/hello-phone.png";
const PRIORITY_PHONE = "/img/switch-to-orchid/priority-phone.png";
const LESS_TIME_PHONE = "/img/switch-to-orchid/less-time-phone.png";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "switch-to-orchid-page";

const STOPhoneView = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  return (
    <div className={styles["phone-view"]}>
      <div className={styles["switch"]}>
        <div className={styles["switch__title"]}>Switch to Orchid today.</div>
        <div className={styles["switch__content"]}>
          Get the powerful tools your practice needs to grow, plus a simpler way
          to switch EHRs in a snap.
        </div>
        <div className={"d-flex justify-content-center"}>
          <LazyImage
            src={COMPUTER_PHONE}
            width={294}
            className={styles["switch__image"]}
          />
        </div>
        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Switch to Orchid for Free
        </Button>
      </div>
      <div className={styles["container"]}>
        <h2 className={styles["title"]}>
          Your Simple Orchid EHR Transition Plan
        </h2>
        <div className={styles["start-trial"]}>
          <Link to="/pricing/core/month/trial">
            Start your 30-day free trial
          </Link>
          , and we’ll make your EHR transition as simple as pie.*
        </div>
        <div className={styles["step-section"]}>
          <div className={styles["step-card"]}>
            <FullWidthImage src={STEP_1_PHONE} fluid />
          </div>
          <div>
            <div className={styles["step-tag"]}>STEP 1</div>
            <div className={styles["step-title"]}>
              Export Your data from your ex-EHR.
            </div>
            <div className={styles["step-content"]}>
              Follow the instructions in our handy EHR Transition Guide.
            </div>
          </div>
          <div className={styles["step-card"]}>
            <FullWidthImage src={STEP_2_PHONE} fluid />
          </div>
          <div>
            <div className={styles["step-tag"]}>STEP 2</div>
            <div className={styles["step-title"]}>
              Let us import your data to Orchid.
            </div>
            <div className={styles["step-content"]}>
              We’ll take care of those data import headaches for you.
            </div>
          </div>
          <div className={styles["step-card"]}>
            <FullWidthImage src={STEP_3_PHONE} fluid />
          </div>
          <div>
            <div className={styles["step-tag"]}>STEP 3</div>
            <div className={styles["step-title"]}>
              Start using Orchid – all within a day.
            </div>
            <div className={styles["step-content"]}>
              Get back to work right away with everything you need to succeed.{" "}
            </div>
          </div>
        </div>
        <FullWidthImage src={TRANSITION_PHONE} fluid />
      </div>

      <div className={styles["why-switch"]}>
        <h2 className={styles["why-switch__title"]}>Why Switch to Orchid?</h2>
        <div>
          {reasons.map((reason) => {
            return (
              <Card className={styles["why-switch__card"]}>
                <div>{reason.icon}</div>
                <div className={styles["why-switch__card-text"]}>
                  {reason.title}
                </div>
              </Card>
            );
          })}
        </div>
      </div>

      <div className={styles["container"]}>
        <div className={styles["card"]}>
          <div className={styles["card-left"]}>
            <FullWidthImage src={HELLO_PHONE} fluid />
          </div>
          <div className={styles["card-right"]}>
            <div className={styles["card-title"]}>
              <span
                className={getClasses("pl-0", styles["card-title__highlight"])}
              >
                One
              </span>{" "}
              affordable account is all you need.
            </div>
            <p className={"mb-3"}>
              Use Orchid’s comprehensive EHR for everything you need to
              streamline and grow your practice — all from a single account.
            </p>
            <Button
              className={"p-0"}
              variant={"link"}
              as={Link}
              to={"/pricing"}
            >
              Explore all of Orchid’s benefits.
            </Button>
          </div>
        </div>
        <div className={styles["card"]}>
          <div className={styles["card-left"]}>
            <FullWidthImage src={LESS_TIME_PHONE} fluid />
          </div>
          <div className={styles["card-right"]}>
            <div className={styles["card-title"]}>
              Accomplish more in{" "}
              <span
                className={getClasses("pr-0", styles["card-title__highlight"])}
              >
                less time
              </span>
              .
            </div>
            <p className={"mb-3"}>
              Get innovative time-saving features like our fully customizable
              templates, simpler online scheduling, Orchid’s AI clinical notes
              assistant, and much more.
            </p>
            <Button className={"p-0"} variant={"link"} as={Link} to={"/blogs"}>
              Learn more at the Orchid Blog.
            </Button>
          </div>
        </div>
        <div className={styles["card"]}>
          <div className={styles["card-left"]}>
            <FullWidthImage src={PRIORITY_PHONE} fluid />
          </div>
          <div className={styles["card-right"]}>
            <div className={styles["card-title"]}>
              Your
              <span className={styles["card-title__highlight"]}>
                #1 priority
              </span>{" "}
              is our #1 priority.
            </div>
            <p className={"mb-3"}>
              Our team is here to give you personalized support for your
              practice’s unique needs at every stage of the EHR transition — and
              beyond.
            </p>
            <Button
              className={"p-0"}
              variant={"link"}
              as={"a"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={
                "https://calendly.com/hey-orchid/orchid-walkthrough"
              }
            >
              Set up your first free demo.
            </Button>
          </div>
        </div>
      </div>
      <div className={styles["i-love-ehr"]}>
        <div className={styles["i-love-ehr__title"]}>
          “I <MdiHeart /> my EHR,”
        </div>
        <div
          className={getClasses(
            styles["bottom-32"],
            styles["i-love-ehr__title"],
          )}
        >
          said no therapist ever — until they tried Orchid.
        </div>
        <div>
          {testimonials.map((item) => (
            <div key={item.id} className={styles["item"]}>
              <div className={styles["quote-sign"]}>“</div>
              <div className={styles["item__body"]}>{item.content}</div>
              <div className={styles["item__footer"]}>
                <span>{item.date}</span>
                <span>•</span>
                <span>{item.author}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles["slogan"]}>
        <div className={styles["slogan__title"]}>
          Private Practice – Made Perfect
        </div>
        <div className={styles["slogan__content"]}>
          Save time, money, and admin headaches when you switch to Orchid today.
          <br />
          Experience the difference of managing your practice with Orchid.
        </div>

        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Switch to Orchid
        </Button>
      </div>

      <div className={styles["phone-view__faq"]}>
        <h2
          className={getClasses(
            styles["bottom-24"],
            styles["phone-view__title"],
          )}
        >
          FAQ: <br />
          Switching to Orchid
        </h2>
        {questions.map(({ id, question, answer }, idx) => {
          return (
            <CustomAccordion
              key={id}
              id={id}
              index={idx}
              question={question}
              answer={answer}
              amplitudePageName={AMPLITUDE_PAGE_NAME}
            />
          );
        })}
      </div>

      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default STOPhoneView;
