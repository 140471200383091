import { set_equal } from "utils/fn";

type PartnersReducerResetAction = {
  type: "reset";
};

type PartnersReducerAddAction = {
  type: "add";
  partners: Set<string>;
};

export type PartnersRecurerAction =
  | PartnersReducerResetAction
  | PartnersReducerAddAction;

const partnersReducer = (state: Set<string>, action: PartnersRecurerAction) => {
  switch (action.type) {
    case "reset": {
      const newState = new Set<string>();
      if (set_equal(state, newState)) return state;
      return newState;
    }
    case "add": {
      const newState = new Set<string>(state);
      for (const partner of action.partners) newState.add(partner);
      if (set_equal(state, newState)) return state;
      return newState;
    }
  }
  throw new Error(`unexpected action type: ${action}`);
};

export default partnersReducer;
