import { ChatMessageWithTimestamp } from "../pubnub.types";

const updateMessages = <T extends ChatMessageWithTimestamp>(
  oldMessages: T[],
  newMessages: T[],
): T[] => {
  const newMessagesFoundInOld = new Set();
  const messages: T[] = [];
  for (const msg of oldMessages) {
    const existingNewMessage = newMessages.find(
      (m) =>
        (m.messageTimetoken &&
          msg.messageTimetoken &&
          m.messageTimetoken === msg.messageTimetoken) ||
        (m.msgUniqueId && msg.msgUniqueId && m.msgUniqueId === msg.msgUniqueId),
    );
    if (!existingNewMessage) {
      messages.push(msg);
    } else {
      newMessagesFoundInOld.add(existingNewMessage);
      let changed = false;
      const newExisting = { ...existingNewMessage };
      for (const [key, value] of Object.entries(msg)) {
        if (key === "receiptsByCognitoSub") {
          const receipts = { ...newExisting.receiptsByCognitoSub };
          for (const [cognitoSub, timestamp] of Object.entries(
            value as { [cognitoSub: string]: Date },
          )) {
            const ts = receipts[cognitoSub];
            if (ts === undefined || ts < timestamp) {
              receipts[cognitoSub] = timestamp;
              changed = true;
            }
          }
          newExisting[key] = receipts;
        } else {
          if (!newExisting[key] && value) {
            newExisting[key] = value;
            changed = true;
          }
        }
      }
      if (changed) {
        messages.push(newExisting);
      } else {
        messages.push(existingNewMessage);
      }
    }
  }
  for (const msg of newMessages) {
    if (!newMessagesFoundInOld.has(msg)) messages.push(msg);
  }
  return messages;
};

export default updateMessages;
