import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getErrorDetail } from "components/utility/utils";
import { appointmentsFromApiData } from "contexts/appointments/utils";
import { apiHostAndPath, fetchOrchidAPI } from "utils/api";

interface CustomAppointmentQueryArgs {
  useInfiniteScroll?: boolean;
  page: number;
  hideFutureAppointments?: boolean;
  hidePastAppointments?: boolean;
  showOnlyCurrentlyHappeningAppointments?: boolean;
}

interface ForwardedAppointmentQueryArgs {
  newestDatetime?: string;
  oldestDatetime?: string;
  patientUserId?: string;
  orchidProMayHaveMissedAppointment?: boolean;
  appointmentIsCancelledOrAbandoned?: boolean;
}

type AppointmentsQueryArgs = CustomAppointmentQueryArgs &
  ForwardedAppointmentQueryArgs;

interface AppointmentsQueryResponse {
  appointments: AppointmentMetaData[];
  last_modify_datetime: string;
  limit: number;
  skip: number;
  total_count: number;
}

const appointmentQueryArgsToApiParams = ({
  page,
  hideFutureAppointments,
  hidePastAppointments,
  showOnlyCurrentlyHappeningAppointments,
  newestDatetime,
  oldestDatetime,
  patientUserId,
  orchidProMayHaveMissedAppointment,
  appointmentIsCancelledOrAbandoned,
}: AppointmentsQueryArgs) => {
  const params = {
    limit: APPOINTMENTS_PAGE_SIZE.toString(),
    skip: (page * APPOINTMENTS_PAGE_SIZE).toString(),
  };
  if (hideFutureAppointments) {
    params["newest_datetime"] = new Date().toISOString();
  }
  if (hidePastAppointments) {
    params["oldest_datetime"] = new Date().toISOString();
  }
  if (showOnlyCurrentlyHappeningAppointments) {
    const now = new Date();
    const fifteenMinutesFromNow = new Date(Date.now() + 15 * 60 * 1000);
    params["newest_datetime"] = fifteenMinutesFromNow.toISOString();
    params["oldest_datetime"] = now.toISOString();
  }

  if (newestDatetime) {
    if (hideFutureAppointments && process.env.NODE_ENV !== "production") {
      console.warn(
        "hideFutureAppointments is ignored when newestDatetime is provided",
      );
    }
    params["newest_datetime"] = newestDatetime;
  }
  if (oldestDatetime) {
    if (hidePastAppointments && process.env.NODE_ENV !== "production") {
      console.warn(
        "hidePastAppointments is ignored when oldestDatetime is provided",
      );
    }
    params["oldest_datetime"] = oldestDatetime;
  }
  if (patientUserId !== undefined) {
    params["patient_user_id"] = patientUserId;
  }
  if (orchidProMayHaveMissedAppointment !== undefined) {
    params["orchid_pro_may_have_missed_appointment"] =
      orchidProMayHaveMissedAppointment.toString();
  }
  if (appointmentIsCancelledOrAbandoned !== undefined) {
    params["appointment_is_cancelled_or_abandoned"] =
      appointmentIsCancelledOrAbandoned.toString();

    if (appointmentIsCancelledOrAbandoned === true) {
      // TODO: currently show 30 days, need to show all
      const fifteenDaysAgo = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000);
      const tenDaysFromNow = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
      params["newest_datetime"] = tenDaysFromNow.toISOString();
      params["oldest_datetime"] = fifteenDaysAgo.toISOString();
    }
  }

  return new URLSearchParams(params).toString();
};

export const APPOINTMENTS_PAGE_SIZE = 10;

export const schedulerApi = createApi({
  reducerPath: "schedulerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiHostAndPath("/api/scheduler/v1/"),
    credentials: "include",
  }),
  tagTypes: ["appointment"],
  endpoints: (builder) => ({
    getAppointments: builder.query<
      AppointmentsQueryResponse,
      AppointmentsQueryArgs
    >({
      queryFn: async (queryArgs) => {
        const response = await fetchOrchidAPI(
          `/api/scheduler/v1/snapshot/appointments_v2?${appointmentQueryArgsToApiParams(
            queryArgs,
          )}`,
        );

        if (!response.ok) {
          const text = await response.text();
          return {
            error: getErrorDetail(text),
          };
        }

        const json = await response.json();
        return {
          data: {
            ...json,
            appointments: appointmentsFromApiData(json.appointments),
          },
        };
      },
      providesTags: ["appointment"],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return {
          endpointName,
          ...queryArgs,
          page: queryArgs.useInfiniteScroll
            ? "infinite-scroll"
            : queryArgs.page,
        };
      },
      merge: (
        currentCacheData,
        responseData,
        { arg: { useInfiniteScroll, page } },
      ) => {
        if (!useInfiniteScroll) {
          return responseData;
        }

        return {
          ...responseData,
          appointments: [
            ...currentCacheData.appointments.slice(
              0,
              page * APPOINTMENTS_PAGE_SIZE,
            ),
            ...responseData.appointments,
          ],
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.useInfiniteScroll &&
          currentArg?.page !== previousArg?.page
        );
      },
    }),
  }),
});

export const { useGetAppointmentsQuery } = schedulerApi;
