import React, { useState, useEffect } from "react";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useUser } from "contexts/UserContext";
import { Nav, Tab, Alert, Button } from "react-bootstrap";
import { ReactComponent as LongLine } from "assets/svg/long-line.svg";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left.svg";
import SwipeableViews from "react-swipeable-views";
import RequiredPanel from "./required-panel";
import OptionalPanel from "./optional-panel";
import StickyFooter from "components/sticky-footer";
import ContentHeader from "components/content-header/ContentHeader";
import IconButton from "components/icon-button";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

const MemberIntakeForm = ({
  registration,
  error,
  tab,
  fieldChangedCallback,
  tabChangedCallback,
  changed,
  member,
}) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const { user } = useUser();
  const [index, setIndex] = useState(tab === "required" ? 0 : 1);

  useEffect(() => {
    if (tab === "required") {
      setIndex(0);
    } else {
      setIndex(1);
    }
  }, [tab]);

  const handleChangeIndex = (index) => {
    setIndex(index);
    if (index === 0) {
      tabChangedCallback("required");
    } else {
      tabChangedCallback("optional");
    }
  };

  return !user?.email_confirmed ? null : !isPhonePortrait ? (
    <Tab.Container
      defaultActiveKey="required"
      activeKey={tab}
      onSelect={(eventKey) => tabChangedCallback(eventKey)}
    >
      <div className={styles["intake-form__header"]}>
        <Nav
          variant="pills"
          className="d-flex justify-content-center align-items-center mb-1"
        >
          <Nav.Item>
            <Nav.Link
              className={styles["intake-form__step-nav"]}
              eventKey="required"
            >
              1
            </Nav.Link>
          </Nav.Item>
          <LongLine />
          <Nav.Item>
            <Nav.Link
              className={styles["intake-form__step-nav"]}
              eventKey="optional"
            >
              2
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="d-flex justify-content-center">
          <div
            className={getClasses(
              styles["intake-form__step-nav-item"],
              tab === "required" && "font-weight-bold blue",
            )}
          >
            Personal Details
          </div>
          <div
            className={getClasses(
              styles["intake-form__step-nav-item"],
              tab === "optional" && "font-weight-bold blue",
            )}
          >
            Insurance Details (Optional)
          </div>
        </div>
      </div>

      <div className={styles["intake-form__body"]}>
        <Tab.Content>
          {error && <Alert variant="danger">{error.toString()}</Alert>}
          <Tab.Pane
            eventKey="required"
            className={styles["intake-form__content-required"]}
          >
            <h4 className="rg-24 questrial">Personal Details</h4>
            <p className="rg-12">
              To begin, tell us a little about yourself below.
            </p>
            <RequiredPanel
              fieldChangedCallback={fieldChangedCallback}
              member={member}
              registration={registration}
            />
            {registration && (
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="submit"
                  className={styles["intake-form__action--register"]}
                  form="intakeform"
                >
                  Next
                </Button>
              </div>
            )}
          </Tab.Pane>
          <Tab.Pane
            eventKey="optional"
            className={styles["intake-form__content-optional"]}
          >
            <h4 className="rg-24 questrial">Insurance Details</h4>
            <p>
              The information below is optional. You can fill it in later from
              your profile.
            </p>
            <OptionalPanel
              fieldChangedCallback={fieldChangedCallback}
              member={member}
            />
            {registration && (
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-primary"
                  className={styles["intake-form__action--register"]}
                  onClick={() => tabChangedCallback("required")}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className={getClasses(
                    "ml-3",
                    styles["intake-form__action--register"],
                  )}
                >
                  Finish
                </Button>
              </div>
            )}
          </Tab.Pane>

          {!registration && (
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="submit"
                form="edit-profile"
                className={styles["intake-form__save--edit-profile"]}
                disabled={!changed}
              >
                Save
              </Button>
            </div>
          )}
        </Tab.Content>
      </div>
    </Tab.Container>
  ) : (
    <>
      <ContentHeader>
        <IconButton
          onClick={() => {
            tab === "optional"
              ? tabChangedCallback("required")
              : window.location.replace("/");
          }}
        >
          <ChevronLeft />
        </IconButton>
        <div>
          {tab === "required"
            ? "Tell us about yourself"
            : "Optional Information"}
        </div>
        {!registration ? (
          <Button
            variant="link"
            form="edit-profile"
            type="submit"
            disabled={!changed}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="link"
            type="submit"
            form="intakeform"
            disabled={!(member.first_name && member.last_name && member.dob)}
          >
            {tab === "required" ? "Next" : "Save"}
          </Button>
        )}
      </ContentHeader>

      <SwipeableViews
        enableMouseEvents
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        <div className={styles["intake-form--phone"]}>
          {error && <Alert variant="danger">{error.toString()}</Alert>}
          <div className="p-3">
            <RequiredPanel
              registration={registration}
              fieldChangedCallback={fieldChangedCallback}
              member={member}
            />
          </div>
        </div>
        <div className={styles["intake-form--phone"]}>
          {error && <Alert variant="danger">{error.toString()}</Alert>}
          <div>
            <div className={styles["intake-form__alert"]}>
              The information below is optional. You can fill it in later from
              your profile.
            </div>
            <div className="px-3">
              <OptionalPanel
                fieldChangedCallback={fieldChangedCallback}
                member={member}
              />
            </div>
          </div>
        </div>
      </SwipeableViews>

      <StickyFooter className={styles["member-intake-wrapper__footer--phone"]}>
        <Tab.Container
          defaultActiveKey="required"
          activeKey={tab}
          onSelect={(eventKey) => tabChangedCallback(eventKey)}
        >
          <Nav variant="pills" className="justify-content-center mb-3">
            <Nav.Item className={styles["intake-form__step-nav-item--phone"]}>
              <Nav.Link
                className={styles["intake-form__step-nav--phone"]}
                eventKey="required"
              ></Nav.Link>
            </Nav.Item>
            <Nav.Item className={styles["intake-form__step-nav-item--phone"]}>
              <Nav.Link
                className={styles["intake-form__step-nav--phone"]}
                eventKey="optional"
              ></Nav.Link>
            </Nav.Item>
          </Nav>
        </Tab.Container>
      </StickyFooter>
    </>
  );
};

export default MemberIntakeForm;
