import useToggleModal from "hooks/useToggleModal";
import messages from "messages";
import {
  FormControl,
  FormControlProps,
  ListGroup,
  Modal,
} from "react-bootstrap";
import styles from "./styles.module.scss";

type Props<T = string> = {
  options: T[];
  renderOptionValue?: (arg: T) => string;
  onClickOption: (arg: T) => void;
  placeholder?: string;
} & FormControlProps;

const InputWithBackdrop = ({
  options,
  onClickOption,
  renderOptionValue,
  ...props
}: Props) => {
  const { show, open, close } = useToggleModal(false);
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        dialogClassName={styles["input-with-backdrop__modal-dialog"]}
        contentClassName={styles["input-with-backdrop__modal-content"]}
        className={styles["input-with-backdrop__modal"]}
      >
        <ListGroup>
          {options.map((el, i) => (
            <ListGroup.Item
              key={i}
              className={styles["input-with-backdrop__option"]}
              onClick={() => {
                onClickOption(el);
                close();
              }}
            >
              {renderOptionValue ? renderOptionValue(el) : el}
            </ListGroup.Item>
          ))}
        </ListGroup>

        <ListGroup className={styles["input-with-backdrop__cancel-container"]}>
          <ListGroup.Item
            className={styles["input-with-backdrop__cancel"]}
            onClick={close}
          >
            {messages.cancel}
          </ListGroup.Item>
        </ListGroup>
      </Modal>
      <FormControl
        type="text"
        readOnly={true}
        onClick={open}
        className={styles["input-with-backdrop__input"]}
        {...props}
      />
    </>
  );
};

export default InputWithBackdrop;
