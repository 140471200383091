export function valuesToSelectOptions(values, OPTIONS) {
  if (!values) return [];
  const result = [];
  for (const value of values) {
    const option = OPTIONS.find((e) => e.name === value || e.label === value);
    if (option) {
      result.push(option);
    } else {
      // can't really do much about this at this point
      console.error("unknown option value", value, "in:", OPTIONS);
    }
  }
  return result;
}

export const STATE_SEARCH_OPTIONS = [
  { label: "Alabama", value: "AL", urlValue: "alabama" },
  { label: "Alaska", value: "AK", urlValue: "alaska" },
  { label: "Arizona", value: "AZ", urlValue: "arizona" },
  { label: "Arkansas", value: "AR", urlValue: "arkansas" },
  { label: "California", value: "CA", urlValue: "california" },
  { label: "Colorado", value: "CO", urlValue: "colorado" },
  { label: "Connecticut", value: "CT", urlValue: "connecticut" },
  {
    label: "District of Columbia (DC)",
    value: "DC",
    urlValue: "district-of-columbia",
  },
  { label: "Delaware", value: "DE", urlValue: "delaware" },
  { label: "Florida", value: "FL", urlValue: "florida" },
  { label: "Georgia", value: "GA", urlValue: "georgia" },
  // { label: "Hawaii", value: "HI", urlValue: "hawaii" },
  // { label: "Idaho", value: "ID", urlValue: "idaho" },
  { label: "Illinois", value: "IL", urlValue: "illinois" },
  { label: "Indiana", value: "IN", urlValue: "indiana" },
  { label: "Iowa", value: "IA", urlValue: "iowa" },
  // { label: "Kansas", value: "KS", urlValue: "kansas" },
  // { label: "Kentucky", value: "KY", urlValue: "kentucky" },
  { label: "Louisiana", value: "LA", urlValue: "louisiana" },
  { label: "Maine", value: "ME", urlValue: "maine" },
  { label: "Maryland", value: "MD", urlValue: "maryland" },
  { label: "Massachusetts", value: "MA", urlValue: "massachusetts" },
  { label: "Michigan", value: "MI", urlValue: "michigan" },
  { label: "Minnesota", value: "MN", urlValue: "minnesota" },
  { label: "Mississippi", value: "MS", urlValue: "mississippi" },
  { label: "Missouri", value: "MO", urlValue: "missouri" },
  // { label: "Montana", value: "MT", urlValue: "montana" },
  { label: "Nebraska", value: "NE", urlValue: "nebraska" },
  { label: "Nevada", value: "NV", urlValue: "nevada" },
  { label: "New Hampshire", value: "NH", urlValue: "new-hampshire" },
  { label: "New Jersey", value: "NJ", urlValue: "new-jersey" },
  { label: "New Mexico", value: "NM", urlValue: "new-mexico" },
  { label: "New York", value: "NY", urlValue: "new-york" },
  { label: "North Carolina", value: "NC", urlValue: "north-carolina" },
  // { label: "North Dakota", value: "ND", urlValue: "north-dakota" },
  // { label: "Ohio", value: "OH", urlValue: "ohio" },
  { label: "Oklahoma", value: "OK", urlValue: "oklahoma" },
  { label: "Oregon", value: "OR", urlValue: "oregon" },
  { label: "Pennsylvania", value: "PA", urlValue: "pennsylvania" },
  // { label: "Rhode Island", value: "RI", urlValue: "rhode-island" },
  { label: "South Carolina", value: "SC", urlValue: "south-carolina" },
  // { label: "South Dakota", value: "SD", urlValue: "south-dakota" },
  // { label: "Tennessee", value: "TN", urlValue: "tennessee" },
  { label: "Texas", value: "TX", urlValue: "texas" },
  // { label: "Utah", value: "UT", urlValue: "utah" },
  // { label: "Vermont", value: "VT", urlValue: "vermont" },
  { label: "Virginia", value: "VA", urlValue: "virginia" },
  { label: "Washington", value: "WA", urlValue: "washington" },
  // { label: "West Virginia", value: "WV", urlValue: "west-virginia" },
  // { label: "Wisconsin", value: "WI", urlValue: "wisconsin" },
  // { label: "Wyoming", value: "WY", urlValue: "wyoming" },
];

export const HEARD_ABOUT_ORCHID_VIA = [
  { value: "Invite by Family/Friends", label: "Invite by Family/Friends" },
  { value: "Social Media", label: "Social Media" },
  {
    value: "Search Engine (ex. Google Search)",
    label: "Search Engine (ex. Google Search)",
  },
  { value: "AMHC", label: "AMHC" },
  // { value: "Other", label: "Other" },
];

export const RELATIONSHIPS_TO_PATIENT_OPTIONS = [
  {
    id: "parent",
    value: "Parent",
    label: "Parent",
  },
  {
    id: "legal-guardian",
    value: "Legal Guardian",
    label: "Legal Guardian",
  },
  {
    id: "family-member",
    value: "Family Member",
    label: "Family Member",
  },
  {
    id: "other",
    value: "Other",
    label: "Other",
  },
];
