export const ImageAltText = {
  "2": "Person wearing a mask amidst a dense environment of the SARS-CoV-2 virus which causes COVID-19.",
  "8": "Collage of speech bubbles representing various therapy options: Psychodynamic, Interpersonal, CBT, DBT, and Supportive Therapy.",
  "15": "Disappointed person discussing racism in mental health with a frowning face and speech bubble.",
  "25": "A calendar of April with a speech bubble to encourage discussion of mental health during Autism Acceptance Month.",
  "58": "Brain superimposed on a piggy bank, symbolizing financial benefits for mental health professionals.",
  "60": "Paper plane soaring above a notepad with a medical chart representing loss of therapy progress note.",
  "61": "A person using a brush and easel to paint a speech bubble with three dots in it, representing a counseling logo",
  "62": "blue and orange building blocks representing how to build and manage client expectations",
};
