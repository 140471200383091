export const fetchAllPages = async <
  P extends {
    skip?: number;
    limit?: number;
  },
  T,
>(
  wrappedFunction: (originalParams: P) => Promise<T[]>,
  params: Omit<P, "skip" | "limit">,
  limit = 100,
) => {
  let skip = 0;
  let items: T[] = [];
  let lastResultLength = 0;

  do {
    const result = await wrappedFunction({
      ...params,
      skip,
      limit,
    } as P);
    items = items.concat(result);
    lastResultLength = result.length;
    skip += limit;
  } while (lastResultLength === limit);

  return items;
};

export default fetchAllPages;
