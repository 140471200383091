import { ChatMessageData, ChatMessageNotificationData } from "../pubnub.types";

const filterWithSender = (
  messages: ChatMessageNotificationData[],
): ChatMessageData[] => {
  function filterPred(m: ChatMessageNotificationData): m is ChatMessageData {
    return m.sender !== undefined;
  }

  return messages.filter(filterPred);
};
export default filterWithSender;
