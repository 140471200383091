import GeneralNavigation from "../general-navigation/GeneralNavigation";
import BottomNavigation from "./components/bottom-navigation/BottomNavigation";
import { useLocation } from "react-router";

type PhoneNavigationProps = {
  disableNavbarScrolling: boolean;
};

const PhoneNavigation = ({ disableNavbarScrolling }: PhoneNavigationProps) => {
  const { pathname } = useLocation();

  const shouldDisplayBottomNavigation =
    !pathname.startsWith("/member-dashboard/messages/") &&
    !pathname.startsWith("/pro-dashboard/messages/") &&
    !pathname.startsWith("/pro-dashboard/schedule") && // Displayed as part of layout, not on top - see OneOnOneSessionsPageLayout.tsx
    !pathname.startsWith("/video-chat") &&
    !pathname.endsWith("/checkout") &&
    !pathname.endsWith("/member-intake-form") &&
    !pathname.startsWith("/member-dashboard/edit-profile") &&
    !pathname.startsWith("/blogs") &&
    !pathname.startsWith("/press") &&
    !pathname.startsWith("/professional-search") &&
    !pathname.startsWith("/professionals") &&
    !pathname.startsWith("/therapists") &&
    !pathname.startsWith("/psychiatrists") &&
    !pathname.startsWith("/psychologists") &&
    !pathname.startsWith("/pro-dashboard/audio-recorder");

  const shouldDisplayGeneralNavigation =
    pathname === "/professional-search" ||
    pathname.startsWith("/professionals") ||
    pathname.startsWith("/therapists") ||
    pathname.startsWith("/psychiatrists") ||
    pathname.startsWith("/psychologists") ||
    pathname.startsWith("/blogs") ||
    pathname.startsWith("/press");

  return shouldDisplayBottomNavigation ? (
    <BottomNavigation />
  ) : shouldDisplayGeneralNavigation ? (
    <GeneralNavigation disableNavbarScrolling={disableNavbarScrolling} />
  ) : null;
};

export default PhoneNavigation;
