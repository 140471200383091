import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

export default async function requestDemo(
  firstName: string,
  lastName: string,
  email: string,
) {
  const res = await fetchOrchidAPI("/api/quiz/v1/marketing_page/request_demo", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email: email,
    }),
  });
  if (!res.ok) {
    throw new Error(`Failed to book: ${getErrorDetail(await res.text())}`);
  }
  return;
}
