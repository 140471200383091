import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";
import getQueryParams from "utils/getQueryParams";

const URL = "/api/notes_secure_data/v1/notes/data/data_info";
/**
 * Query info list
 */
export default async function queryInfoList(params: {
  patient_cognito_sub?: string;
  note_data_id?: number[];
  skip?: number;
  limit?: number;
}): Promise<NoteDataInfo[]> {
  const url = URL + getQueryParams(params);

  const res = await fetchOrchidAPI(url);
  if (!res.ok) {
    throw new Error(
      `failed to query notes secure data: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
