import { isPlanTerminated } from "components/Subscription/subscription-checkout/utils";
import { IS_DEV_ENV } from "constants/isDevEnv";
import { useUser } from "contexts/UserContext";
import _ from "lodash";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { accessControl } from "./constants";

type Context = {
  subscription: Subscription | undefined;
  setSubscription: (arg: Subscription) => void;
  isSubscribed: boolean;
  canAccessFeature: (feature: Feature) => boolean;
  canAccessPlatform: (user: User | null | undefined) => boolean;
  restrictAccessToPaidFeature: (user: User | null | undefined) => boolean;
  isCoreFreeTrialPeriod: (user: User | null | undefined) => boolean;
  planType: PlanType | null;
  refetch: () => void;
};

const DEBUG_FORCE_CURRENT_USER_PLAN_TYPE: PlanType | null = null;

const SubscriptionContext = createContext<Context>({
  subscription: undefined,
  setSubscription: () => null,
  isSubscribed: false,
  planType: null,
  canAccessFeature: () => false,
  canAccessPlatform: () => false,
  restrictAccessToPaidFeature: () => false,
  isCoreFreeTrialPeriod: () => false,
  refetch: _.noop,
});

type Props = { children: ReactNode };

export const SubscriptionContextProvider = ({ children }: Props) => {
  const { updateUserInfo, user } = useUser();

  const [subscription, setSubscription] = useState<Subscription>();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [planType, setPlanType] = useState<PlanType | null>(null);

  const canAccessFeature = useCallback(
    (feature: Feature): boolean => {
      if (planType === null) {
        return false;
      }
      return accessControl[feature][planType];
    },
    [planType],
  );

  const canAccessPlatform = useCallback(
    (user: User | null | undefined) => {
      return isSubscribed && !!user?.profile_approved_to_use_the_platform;
    },
    [isSubscribed],
  );

  const restrictAccessToPaidFeature = useCallback(
    (user: User | null | undefined) => {
      // amhc providers cannot use new features, except Dr. Park and Dr. Bhang (paid user)
      return (
        !!user?.signup_channel?.amhc &&
        !isSubscribed &&
        user?.sub !== "5a59f35a-94f8-4992-a896-6560d8ece2b2" &&
        user?.sub !== "19b6f0b0-a3cb-4a2c-834f-d45beb7d20ea" &&
        user?.sub !== "1f0134f4-561d-43b6-ae76-74786f4be1c4"
      );
    },
    [isSubscribed],
  );

  const isAIFreeTrialActive = (user: User) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    return (
      !!user?.ai_free_trial_start_datetime &&
      new Date(user.ai_free_trial_start_datetime) > thirtyDaysAgo
    );
  };

  const isCoreFreeTrialPeriod = useCallback((user: User | null | undefined) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    return (
      user?.orchid_pro_subscription?.coupon_id === "FREE_TRIAL_CORE_MONTH" &&
      new Date(user.orchid_pro_subscription.activated_time) > thirtyDaysAgo
    );
  }, []);

  const identifyPlanSubscription = useCallback((user) => {
    // TODO: non registered user can also see the subscription status
    if (!user || user?.user_type === "member") {
      return;
    }

    // Legacy users are by default subscribed and given Complete access
    if (
      (user.create_datetime && user.create_datetime < "2022-09-15") ||
      user?.sub === "ebaec272-7295-4c5e-aa59-4fc3302715d2" ||
      user?.sub === "6da5051b-1281-4dab-a5f4-8947377d5b53" // TODO: ask provider to pay again at next cycle 2024-01-23 13:23:49
    ) {
      setIsSubscribed(true);
      setPlanType("complete");
      return;
    }

    if (
      !user.orchid_pro_subscription ||
      isPlanTerminated(user.orchid_pro_subscription)
    ) {
      if (isAIFreeTrialActive(user)) {
        setIsSubscribed(true);
        setPlanType("ai-only");
        return;
      }
      return setIsSubscribed(false);
    }

    setIsSubscribed(true);

    if (IS_DEV_ENV && DEBUG_FORCE_CURRENT_USER_PLAN_TYPE) {
      setPlanType(DEBUG_FORCE_CURRENT_USER_PLAN_TYPE);
    } else if (user.orchid_pro_subscription.sub_class === "complete") {
      setPlanType("complete");
    } else if (user.orchid_pro_subscription.sub_class === "enterprise") {
      setPlanType("enterprise");
    } else if (user.orchid_pro_subscription.sub_class === "core-with-ai") {
      setPlanType("core-with-ai");
    } else if (user.orchid_pro_subscription.sub_class === "ai-only") {
      setPlanType("ai-only");
    } else {
      setPlanType("core");
    }

    setSubscription(user.orchid_pro_subscription);
  }, []);

  const refetch = useCallback(async () => {
    const user = await updateUserInfo();
    identifyPlanSubscription(user);
  }, [updateUserInfo, identifyPlanSubscription]);

  // memoize value to make sure there's no unccessary re-renders
  const value = useMemo(
    () => ({
      subscription,
      setSubscription,
      isSubscribed,
      canAccessFeature,
      canAccessPlatform,
      restrictAccessToPaidFeature,
      isCoreFreeTrialPeriod,
      planType,
      refetch,
    }),
    [
      subscription,
      isSubscribed,
      canAccessFeature,
      canAccessPlatform,
      restrictAccessToPaidFeature,
      isCoreFreeTrialPeriod,
      planType,
      refetch,
    ],
  );

  // get subscription on init
  useEffect(() => {
    identifyPlanSubscription(user);
  }, [identifyPlanSubscription, user]);

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext);
