import React, { Component } from "react";
import { Modal, Form, Button, Spinner, Alert } from "react-bootstrap";
import { getErrorDetail } from "../utility/utils.js";
import { ReactComponent as OutlineCheck } from "assets/svg/outline-check.svg";
import { fetchOrchidAPI } from "utils/api";
import ResponsiveModal from "components/responsive-modal";

class Login extends Component {
  state = {
    username_or_email: "",
    showLoader: false,
    showPasswordLinkSentModal: false,
    error: null,
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ showLoader: true });
    try {
      const response = await fetchOrchidAPI("/api/login/v1/forgot_password", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username_or_email: this.state.username_or_email.trim(),
        }),
      });
      if (!response.ok) {
        this.setState({
          error: `${getErrorDetail(await response.text())}`,
          showLoader: false,
        });
        return;
      }
      this.setState({ showLoader: false, showPasswordLinkSentModal: true });
    } catch (err) {
      console.error(err);
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    const { showLoader, showPasswordLinkSentModal } = this.state;
    return (
      <ResponsiveModal
        centered
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Header
          className="px-4 border-0 shadow-sm align-items-center"
          closeButton
        >
          <Modal.Title>
            {!showPasswordLinkSentModal ? (
              "Forgot Password"
            ) : (
              <>
                <OutlineCheck /> Password link sent
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        {this.state.error && (
          <Alert variant="danger" className="rounded-0 m-0">
            {this.state.error.toString()}
          </Alert>
        )}
        <Modal.Body className="px-4">
          {!showPasswordLinkSentModal ? (
            <p>Please enter the email you used to register.</p>
          ) : (
            <p>
              A password link was sent to the email address provided. If you
              didn't receive the email, please check your <b>junk</b> or{" "}
              <b>promotions</b> folders.
            </p>
          )}
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="username_or_email">
              <Form.Label className="fs-12">Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                value={this.state.username_or_email}
                onChange={this.onInputChange}
              />
            </Form.Group>
            {showLoader ? (
              <Button
                variant="primary"
                disabled
                type="submit"
                className="w-100"
              >
                {!showPasswordLinkSentModal
                  ? "Send password reset"
                  : "Send password reset again"}
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button variant="primary" type="submit" className="w-100">
                {!showPasswordLinkSentModal
                  ? "Send password reset"
                  : "Send password reset again"}
              </Button>
            )}
          </Form>
          <p className="mt-3">
            Need help? <a href="mailto:info@orchid.health">Contact support</a>
          </p>
        </Modal.Body>
      </ResponsiveModal>
    );
  }
}

export default Login;
