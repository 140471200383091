import debounce from "lodash/debounce";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import isUserAgentPhone from "./utils/isUserAgentPhone";
import isViewPortrait from "./utils/isViewPortrait";

export const PhonePortraitContext = createContext<{
  isPhonePortrait: boolean;
}>({
  isPhonePortrait: false,
});

const IS_PHONE = isUserAgentPhone();

type Props = {
  children: ReactNode;
};

export const PhonePortraitContextProvider = ({ children }: Props) => {
  const [isPortrait, setIsPortrait] = useState(() => isViewPortrait());
  // detect and add event listener on init
  useEffect(() => {
    /**
     * since orientation change should trigger resize and has less compatibility issue, listen to resize event
     * @see https://davidwalsh.name/orientation-change
     */
    const debouncedHandleResize = debounce(() => {
      const nextIsPortrait = isViewPortrait();
      setIsPortrait(nextIsPortrait);
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <PhonePortraitContext.Provider
      value={{
        isPhonePortrait: IS_PHONE && isPortrait,
      }}
      children={children}
    />
  );
};

export const usePhonePortraitContext = () => useContext(PhonePortraitContext);
