import { set_equal } from "utils/fn";

type PresenceReducerSnapshotAction = {
  type: "snapshot";
  snapshot: Set<string>;
};

type PresenceReducerJoinAction = {
  type: "join";
  partners: Set<string>;
};

type PresenceReducerLeaveAction = {
  type: "leave";
  partners: Set<string>;
};

const presenceReducer = (
  state: Set<string>,
  action:
    | PresenceReducerSnapshotAction
    | PresenceReducerJoinAction
    | PresenceReducerLeaveAction,
) => {
  switch (action.type) {
    case "snapshot": {
      if (set_equal(state, action.snapshot)) return state;
      return new Set(action.snapshot);
    }
    case "join": {
      const newState = new Set(state);
      for (const partner of action.partners) newState.add(partner);
      if (set_equal(state, newState)) return state;
      return newState;
    }
    case "leave": {
      const newState = new Set(state);
      for (const partner of action.partners) newState.delete(partner);
      if (set_equal(state, newState)) return state;
      return newState;
    }
  }
};

export default presenceReducer;
