import { useUser } from "contexts/UserContext";
import moment, { MomentInput } from "moment-timezone";

const useFormatDateForBlog = () => {
  const { defaultUserTimezone } = useUser();

  const formatDateForBlog = (date: MomentInput) =>
    moment(date).tz(defaultUserTimezone).format("LL");

  return { formatDateForBlog };
};

export default useFormatDateForBlog;
