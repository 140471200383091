/**
 * Check if the view is on portrait mode, judged by media query
 */
export default function isViewPortrait() {
  if (window.matchMedia("(orientation: portrait)").matches) {
    console.log("using portrait");
    return true;
  }

  return false;
}
