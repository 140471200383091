/**
 * attach function when document is ready
 * @param fn function to be attached
 */
export default function onDocumentReady(fn: () => void) {
  if (document.readyState === "complete") {
    fn();
  } else if ((window as any).attachEvent) {
    (window as any).attachEvent("onload", fn);
  } else {
    window.addEventListener("load", fn, false);
  }
}
