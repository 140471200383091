const convertOptionStringsToV2 = (options: string[] = []) => {
  let lastOptionId = 0;
  return options.map((option) => ({
    id: `option-${lastOptionId++}`,
    title: option,
  }));
};

const getV2TypeOfV1Element = ({
  jsonSchema,
  uiSchema,
}: {
  jsonSchema: JSONSchema;
  uiSchema: UISchema;
}): TemplateElement["type"] => {
  const uiWidget = uiSchema?.["ui:widget"] ?? "";

  switch (jsonSchema.type) {
    case "object": {
      if (uiWidget === "rating") {
        return "radios-grid";
      }

      return "section";
    }

    case "string": {
      if (uiWidget === "radio") {
        return "radios";
      }

      if (!jsonSchema.hasOwnProperty("title")) {
        return "text-only";
      }

      if (uiWidget === "textarea") {
        return "paragraph";
      }

      return "short-answer";
    }

    case "array": {
      return "checkboxes";
    }

    default: {
      return "unknown";
    }
  }
};

const convertV1ElementToV2 = ({
  id,
  jsonSchema,
  uiSchema,
  isRequired,
}: {
  id: string;
  jsonSchema: JSONSchema;
  uiSchema: UISchema;
  isRequired: boolean;
}): TemplateElement => {
  const type = getV2TypeOfV1Element({ jsonSchema, uiSchema });

  switch (type) {
    case "section": {
      return {
        type: "section",
        id,
        title: jsonSchema.title ?? "",
        description: jsonSchema.description ?? "",
        children: convertSectionChildrenToV2({
          jsonSchema,
          uiSchema,
        }),
      };
    }

    case "text-only": {
      return {
        type,
        id,
        description: jsonSchema.description ?? "",
        isRequired,
      };
    }
    case "short-answer":
    case "paragraph": {
      return {
        type,
        id,
        title: jsonSchema.title ?? "",
        isRequired,
      };
    }

    case "radios": {
      return {
        type,
        id,
        title: jsonSchema.title ?? "",
        isRequired,
        options: convertOptionStringsToV2(jsonSchema.enum),
      };
    }

    case "checkboxes": {
      return {
        type,
        id,
        title: jsonSchema.title ?? "",
        isRequired,
        options: convertOptionStringsToV2(jsonSchema.items?.enum),
      };
    }

    case "radios-grid": {
      return {
        type,
        id,
        title: jsonSchema.title ?? "",
        rows: convertOptionStringsToV2(jsonSchema.properties?.rows?.enum),
        columns: convertOptionStringsToV2(jsonSchema.properties?.cols?.enum),
      };
    }

    default: {
      return {
        type: "unknown",
        id,
      };
    }
  }
};

export const convertSectionChildrenToV2 = ({
  jsonSchema,
  uiSchema,
}: {
  jsonSchema: JSONSchema;
  uiSchema: UISchema;
}) => {
  const requiredChildren = jsonSchema.required || [];
  const properties = jsonSchema.properties || {};

  const order = uiSchema["ui:order"] || [];

  return order.map((id) => {
    const convertedElement = convertV1ElementToV2({
      id,
      jsonSchema: properties[id],
      uiSchema: uiSchema[id],
      isRequired: requiredChildren.includes(id),
    });
    if (convertedElement.type === "section") {
      throw new Error("Section children cannot be sections");
    }
    return convertedElement;
  });
};

export const convertTemplateToV2 = ({
  jsonSchema,
  uiSchema,
}: {
  jsonSchema: JSONSchema;
  uiSchema: UISchema;
}) => {
  const requiredChildren = jsonSchema.required || [];
  const properties = jsonSchema.properties || {};

  const order = uiSchema["ui:order"] || [];

  return order.map((id) =>
    convertV1ElementToV2({
      id,
      jsonSchema: properties[id],
      uiSchema: uiSchema[id],
      isRequired: requiredChildren.includes(id),
    }),
  );
};

export const parseJsonForV2Schema = (schema: string) => {
  try {
    const result = JSON.parse(schema);
    return result as PersistedNoteOrFormTemplate;
  } catch (e) {
    return null;
  }
};

export const parseRawTemplateResponse = (type: TemplateType, raw: any) => {
  const uiField = `${type}_ui_schema`;
  const jsonField = `${type}_json_schema`;
  const titleField = `${type}_schema_title`;

  const uiSchema = !raw[uiField] ? raw[uiField] : JSON.parse(raw[uiField]);
  const jsonSchema = !raw[jsonField]
    ? raw[jsonField]
    : JSON.parse(raw[jsonField]);
  const title = raw[titleField] ?? "";

  const v2Schema: PersistedNoteOrFormTemplate = parseJsonForV2Schema(
    raw.schema_v2,
  ) ?? {
    title,
    type,
    elements: convertTemplateToV2({ jsonSchema, uiSchema }),
  };

  return {
    ...raw,
    [uiField]: uiSchema,
    [jsonField]: jsonSchema,
    schema_v2: v2Schema,
  };
};
