import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

const URL = "/api/users/v1/intercom/hmac";
/**
 * Query Hmac for intercom
 */
export default async function queryIntercomHmac(): Promise<{
  unique_id: string;
  hmac: string;
}> {
  const res = await fetchOrchidAPI(URL);
  if (!res.ok) {
    throw new Error(
      `failed to query contact: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
