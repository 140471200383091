import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

export default async function queryUserInfo(
  fetchOptions?: RequestInit,
): Promise<User | null> {
  const res = await fetchOrchidAPI("/api/users/v1/user_info", fetchOptions);
  if (!res.ok) {
    if (res.status === 403) return null;
    throw new Error(
      `failed to get userInfo: ${getErrorDetail(await res.text())}`,
    );
  }

  return res.json();
}
