import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { ReactComponent as OutlineChevronDown } from "assets/svg/outline-chevron-down-16.svg";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

import styles from "./styles.module.scss";

const CustomGroupHeading = (props) => {
  const { groupId } = props.data;
  return groupId !== 0 ? <hr className={styles["custom-select__hr"]} /> : null;
};

const DropdownIndicator = (props) => {
  return (
    <div className={styles["indicator-container"]}>
      <OutlineChevronDown />
    </div>
  );
};

const CustomSelect = ({ ...props }) => {
  const { defaultValue, value, required, isMulti, key } = props;
  const { isPhonePortrait } = usePhonePortraitContext();

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderLeft: !isPhonePortrait ? undefined : "none",
      borderRight: !isPhonePortrait ? undefined : "none",
      borderTop: !isPhonePortrait ? undefined : "none",
      borderRadius: !isPhonePortrait ? 4 : 0,
      backgroundColor: "white",
      borderColor: "#ADCCFF",
      fontSize: 14,
      color: "#828282",
      boxShadow: "none",
      minHeight: 40,

      "&:hover": {
        borderColor: "#ADCCFF",
      },
    }),

    placeholder: (styles) => ({
      ...styles,
      color: "#BDBDBD",
      fontWeight: 400,
    }),

    menu: (styles) => ({
      ...styles,
      fontSize: 12,
      zIndex: 1000,
      // TODO: check if there's better method
      outline: "1px solid white",
      filter: "drop-shadow(0px 2px 6px rgba(189, 189, 189, 0.3))",
    }),

    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#1778E1"
        : isFocused
        ? "#F3FAFF"
        : undefined,
      color: isSelected ? "#FFFFFF" : "#333333",
      "& span": {
        color: isSelected ? "#FFFFFF" : "#828282",
      },
    }),
  };

  const disabledStyleForV2 = props.disabled && props.useNotesAndFormsV2Styles;

  return (
    <div className={"position-relative w-100"}>
      <Form.Control
        tabIndex={-1}
        autoComplete="nope"
        style={{
          opacity: 1,
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 500,
          paddingLeft: 10,
          backgroundImage: "none",
          backgroundColor: "transparent",
          borderColor: disabledStyleForV2 ? "#E7E7E7" : "inherit",
          color: "rgba(255, 255, 255, 0)",
          pointerEvents: "none",
        }}
        value={
          defaultValue ||
          (isMulti ? [value?.map((el) => el.value)] : value?.value) ||
          ""
        }
        onChange={() => null}
        required={required}
      />
      <Select
        key={key}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
          GroupHeading: CustomGroupHeading,
        }}
        styles={selectStyles}
        className={"smoothed"}
        {...props}
        isDisabled={props.disabled}
      />
    </div>
  );
};

export default CustomSelect;
