import React, { useRef, useEffect } from "react";
import { AmplitudePageName /*, track */ } from "utils/amplitude";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import FAQCTA from "./faq-cta/FAQCTA";
import LazyImage from "components/lazy-image";
import Footer from "components/footer/Footer";
import { Helmet } from "react-helmet";
import {
  FAQ_GET_STARTED,
  FAQ_FEATURES,
  FAQ_SECURITY,
  FAQ_SUBSCRIPTIONS,
} from "./constants";
import getClasses from "utils/getClasses";
import styles from "./FAQPage.module.scss";
import FullWidthImage from "components/full-width-image";
import TableOfContents from "components/Blogging/components/table-of-contents/TableOfContents";
import { TableOfContentsEntry } from "components/Blogging/utils/prepareTableOfContents";
import ReadingProgressBar from "components/Blogging/components/reading-progress-bar/ReadingProgressBar";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "faq-page";

const LANDING = "/img/faq-page/landing.png";
const LANDING_PHONE = "/img/faq-page/landing-phone.png";

const TABLE_OF_CONTENTS_DATA: TableOfContentsEntry[] = [
  {
    title: "Getting Started with Orchid",
    sectionHeadingId: "getting-started",
  },
  {
    title: "Orchid Features",
    sectionHeadingId: "features",
  },
  {
    title: "Orchid Subscriptions",
    sectionHeadingId: "subscriptions",
  },
  {
    title: "Orchid Security & Compliance",
    sectionHeadingId: "security-and-compliance",
  },
];

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const articleBodyRef = useRef(null);
  const { isPhonePortrait } = usePhonePortraitContext();

  const mainColumnContent = (
    <>
      <h2 id="getting-started" className={styles["faq__title"]}>
        FAQ: {isPhonePortrait && <br />} Getting Started with Orchid
      </h2>
      {FAQ_GET_STARTED.map(({ id, question, answer }, idx) => {
        return (
          <CustomAccordion
            key={id}
            id={id}
            index={idx}
            question={question}
            answer={answer}
            amplitudePageName={AMPLITUDE_PAGE_NAME}
          />
        );
      })}
      <div className={styles["faq__action"]}>
        <Button size={"lg"} as={Link} to={"/professional-register"}>
          Try Orchid for Free
        </Button>
      </div>

      <h2 id="features" className={styles["faq__title"]}>
        FAQ: {isPhonePortrait && <br />} Orchid Features
      </h2>
      {FAQ_FEATURES.map(({ id, question, answer }, idx) => {
        return (
          <CustomAccordion
            key={id}
            id={id}
            index={idx}
            question={question}
            answer={answer}
            amplitudePageName={AMPLITUDE_PAGE_NAME}
          />
        );
      })}
      <div className={styles["faq__action"]}>
        <Button size={"lg"} as={Link} to={"/professional-register"}>
          Try Orchid for Free
        </Button>
      </div>

      <h2 id="subscriptions" className={styles["faq__title"]}>
        FAQ: {isPhonePortrait && <br />} Orchid Subscriptions
      </h2>
      {FAQ_SUBSCRIPTIONS.map(({ id, question, answer }, idx) => {
        return (
          <CustomAccordion
            key={id}
            id={id}
            index={idx}
            question={question}
            answer={answer}
            amplitudePageName={AMPLITUDE_PAGE_NAME}
          />
        );
      })}
      <div className={styles["faq__action"]}>
        <Button size={"lg"} as={Link} to={"/professional-register"}>
          Try Orchid for Free
        </Button>
      </div>

      <h2 id="security-and-compliance" className={styles["faq__title"]}>
        FAQ: {isPhonePortrait && <br />} Orchid Security & Compliance
      </h2>
      {FAQ_SECURITY.map(({ id, question, answer }, idx) => {
        return (
          <CustomAccordion
            key={id}
            id={id}
            index={idx}
            question={question}
            answer={answer}
            amplitudePageName={AMPLITUDE_PAGE_NAME}
          />
        );
      })}
      <div className={getClasses("mb-0", styles["faq__action"])}>
        <Button size={"lg"} as={Link} to={"/professional-register"}>
          Try Orchid for Free
        </Button>
      </div>
    </>
  );

  return (
    <div>
      <Helmet>
        <title>Orchid EHR | Frequently Asked Questions</title>
        <meta
          name="description"
          content="Get answers to frequently asked questions about Orchid’s EHR practice management software."
        />
        <meta
          property="og:title"
          content="Orchid EHR | Frequently Asked Questions"
        />
        <meta
          property="og:description"
          content="Get answers to frequently asked questions about Orchid’s EHR practice management software."
        />

        <meta
          name="twitter:title"
          content="Orchid EHR | Frequently Asked Questions"
        />
        <meta
          name="twitter:description"
          content="Get answers to frequently asked questions about Orchid’s EHR practice management software."
        />
      </Helmet>
      <ReadingProgressBar
        articleBodyRef={articleBodyRef}
        ignoreHeader={isPhonePortrait}
      />
      {!isPhonePortrait ? (
        <div>
          <div className={styles["landing"]}>
            <Container
              className={getClasses(
                styles["landing__container"],
                styles["landing__left"],
              )}
            >
              <h1 className={styles["landing__title"]}>
                Got questions about <br /> Orchid’s EHR?
              </h1>
              <div className={styles["landing__text"]}>
                Get answers about our innovative practice management platform,
                trusted by
                <br />
                thousands of mental health clinicians (and counting).
              </div>
              <div className={"d-flex"}>
                <Button
                  size={"lg"}
                  className={styles["action"]}
                  as={Link}
                  to={"/professional-register"}
                >
                  Try Orchid
                </Button>
              </div>
            </Container>
            <div className={styles["landing__right"]}>
              <LazyImage src={LANDING} width={704} height={"auto"} />
            </div>
          </div>

          <Container className={styles["faq"]}>
            <div className={styles["faq__side-column"]}>
              <div className={styles["faq__sticky-column-content"]}>
                <TableOfContents
                  data={TABLE_OF_CONTENTS_DATA}
                  articleBodyRef={articleBodyRef}
                  amplitudePageName={AMPLITUDE_PAGE_NAME}
                />
              </div>
            </div>
            <div ref={articleBodyRef} className={styles["faq__main-column"]}>
              {mainColumnContent}
            </div>

            <div className={styles["faq__side-column"]}>
              <aside className={styles["faq__sticky-column-content"]}>
                <FAQCTA amplitudePageName={AMPLITUDE_PAGE_NAME} />
              </aside>
            </div>
          </Container>
          <Container className={styles["slogan"]}>
            <div className={styles["slogan__title"]}>
              Private Practice – Made Perfect
            </div>
            <div className={styles["slogan__content"]}>
              Save time, money, and admin headaches when you switch to Orchid
              today.
              <br />
              Experience the difference of managing your practice with Orchid.
            </div>
            <div className={"d-flex justify-content-center"}>
              <Button
                size={"lg"}
                className={styles["action"]}
                as={Link}
                to={"/professional-register"}
              >
                Switch to Orchid
              </Button>
            </div>
          </Container>
        </div>
      ) : (
        <div>
          <TableOfContents
            data={TABLE_OF_CONTENTS_DATA}
            articleBodyRef={articleBodyRef}
            amplitudePageName={AMPLITUDE_PAGE_NAME}
            ignoreHeader
          />
          <div className={styles["landing-phone"]}>
            <h1 className={styles["landing-phone__title"]}>
              Got questions about Orchid’s EHR?
            </h1>
            <div className={styles["landing-phone__text"]}>
              Get answers about our innovative practice management platform,
              trusted by
              <br />
              thousands of mental health clinicians (and counting).
            </div>
            <FullWidthImage src={LANDING_PHONE} fluid />

            <Button
              size={"lg"}
              className={getClasses("w-100", styles["action"])}
              as={Link}
              to={"/professional-register"}
            >
              Try Orchid
            </Button>
          </div>

          <div ref={articleBodyRef} className={styles["phone-view__faq"]}>
            {mainColumnContent}
          </div>
          <div className={styles["phone-view__faq-cta"]}>
            <FAQCTA amplitudePageName={AMPLITUDE_PAGE_NAME} />
          </div>
          <div className={styles["slogan-phone"]}>
            <div className={styles["slogan-phone__title"]}>
              Private Practice – Made Perfect
            </div>
            <div className={styles["slogan-phone__content"]}>
              Save time, money, and admin headaches when you switch to Orchid
              today.
              <br />
              Experience the difference of managing your practice with Orchid.
            </div>

            <Button
              size={"lg"}
              className={styles["action"]}
              as={Link}
              to={"/professional-register"}
            >
              Switch to Orchid
            </Button>
          </div>
        </div>
      )}

      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default FAQPage;
