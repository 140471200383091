import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertTemplateToV1 } from "notes-and-forms-v2/util/convertTemplateToV1";
import {
  convertTemplateToV2,
  parseJsonForV2Schema,
} from "notes-and-forms-v2/util/convertTemplateToV2";
import { apiHostAndPath } from "utils/api";

export type SaveTemplateMutationPayload =
  | {
      requestType: "create";
      template: NewNoteOrFormTemplate;
    }
  | {
      requestType: "update";
      template: NoteOrFormTemplate;
    };

type UnparsedFormSchema = Omit<
  FormSchema,
  "form_json_schema" | "form_ui_schema" | "schema_v2"
> & {
  form_json_schema: string;
  form_ui_schema: string;
  schema_v2: string;
};

type UnparsedNoteSchema = Omit<
  NoteSchema,
  "note_json_schema" | "note_ui_schema" | "schema_v2"
> & {
  note_json_schema: string;
  note_ui_schema: string;
  schema_v2: string;
};

interface CreateOrEditNoteSchemaPayload {
  note_json_schema: string;
  note_ui_schema: string;
  schema_v2: string;
}

interface CreateOrEditFormSchemaPayload {
  form_json_schema: string;
  form_ui_schema: string;
  schema_v2: string;
}

const getCreateOrUpdatePayload = (
  template: NewNoteOrFormTemplate | NoteOrFormTemplate,
): CreateOrEditNoteSchemaPayload | CreateOrEditFormSchemaPayload => {
  const { jsonSchema, uiSchema } = convertTemplateToV1(template);

  switch (template.type) {
    case "note":
      const noteSchema: PersistedNoteOrFormTemplate = {
        title: template.title,
        type: template.type,
        elements: template.elements,
      };
      return {
        note_json_schema: JSON.stringify(jsonSchema),
        note_ui_schema: JSON.stringify(uiSchema),
        // note_json_schema: JSON.stringify({ title: template.title }),
        // note_ui_schema: "{}",
        schema_v2: JSON.stringify(noteSchema),
      };

    default:
    case "form":
      const formSchema: PersistedNoteOrFormTemplate = {
        title: template.title,
        formType: template.formType,
        type: template.type,
        elements: template.elements,
      };
      return {
        form_json_schema: JSON.stringify(jsonSchema),
        form_ui_schema: JSON.stringify(uiSchema),
        // form_json_schema: JSON.stringify({ title: template.title }),
        // form_ui_schema: "{}",
        schema_v2: JSON.stringify(formSchema),
      };
  }
};

const transformResponse = (
  response: UnparsedNoteSchema | UnparsedFormSchema,
): NoteOrFormTemplate => {
  const v2Schema = parseJsonForV2Schema(response.schema_v2);

  if (response.hasOwnProperty("note_json_schema")) {
    const unparsedNoteSchema = response as UnparsedNoteSchema;

    return {
      id: unparsedNoteSchema.note_schema_id,
      type: "note",
      title: unparsedNoteSchema.title ?? "",
      revision: unparsedNoteSchema.note_schema_revision,
      elements:
        v2Schema?.elements ??
        convertTemplateToV2({
          jsonSchema: JSON.parse(unparsedNoteSchema.note_json_schema),
          uiSchema: JSON.parse(unparsedNoteSchema.note_ui_schema),
        }),
    };
  }

  const unparsedFormSchema = response as UnparsedFormSchema;

  return {
    id: unparsedFormSchema.form_schema_id,
    type: "form",
    formType: unparsedFormSchema.form_type,
    title: unparsedFormSchema.title ?? "",
    revision: unparsedFormSchema.form_schema_revision,
    elements:
      v2Schema?.elements ??
      convertTemplateToV2({
        jsonSchema: JSON.parse(unparsedFormSchema.form_json_schema),
        uiSchema: JSON.parse(unparsedFormSchema.form_ui_schema),
      }),
  };
};

export const notesAndFormsTemplatesApi = createApi({
  reducerPath: "notesAndFormsTemplates",
  baseQuery: fetchBaseQuery({
    credentials: "include",
  }),
  tagTypes: ["Template", "Template-List"],
  endpoints: (builder) => ({
    getTemplate: builder.query<
      NoteOrFormTemplate,
      {
        type: TemplateType;
        id: number;
      }
    >({
      query: ({ type, id }) => {
        switch (type) {
          case "note":
            return apiHostAndPath(
              `/api/notes_secure_data/v1/notes/schema/${id}`,
            );
          case "form":
            return apiHostAndPath(`/api/notes/v1/forms/schema/${id}`);
          case "assessment":
            throw new Error(
              `Querying assessment templates is not supported yet`,
            );
        }
      },

      providesTags: ["Template"],
      transformResponse,
    }),

    listAllTemplates: builder.query<
      NoteOrFormTemplate[],
      {
        type: TemplateType;
      }
    >({
      query: ({ type }) => {
        switch (type) {
          case "note":
            return apiHostAndPath(`/api/notes_secure_data/v1/notes/schema/`);
          case "form":
            return apiHostAndPath(`/api/notes/v1/forms/schema/`);
          case "assessment":
            throw new Error(
              `Querying assessment templates is not supported yet`,
            );
        }
      },
      transformResponse: (response: any) => response?.map(transformResponse),
      providesTags: ["Template-List"],
    }),

    saveTemplate: builder.mutation<
      NoteOrFormTemplate,
      SaveTemplateMutationPayload
    >({
      query: (payload) => {
        if (payload.requestType === "create") {
          switch (payload.template.type) {
            case "note":
              return {
                url: apiHostAndPath("/api/notes_secure_data/v1/notes/schema/"),
                method: "POST",
                body: getCreateOrUpdatePayload(payload.template),
              };
            case "form":
              return {
                url: apiHostAndPath("/api/notes/v1/forms/schema/"),
                method: "POST",
                body: getCreateOrUpdatePayload(payload.template),
              };
            case "assessment":
              throw new Error(
                `Creating assessment templates is not supported yet`,
              );
          }
        }

        switch (payload.template.type) {
          case "note":
            return {
              url: apiHostAndPath(
                `/api/notes_secure_data/v1/notes/schema/${payload.template.id}`,
              ),
              method: "PUT",
              body: getCreateOrUpdatePayload(payload.template),
            };
          case "form":
            return {
              url: apiHostAndPath(
                `/api/notes/v1/forms/schema/${payload.template.id}`,
              ),
              method: "PUT",
              body: getCreateOrUpdatePayload(payload.template),
            };
          case "assessment":
            throw new Error(
              `Updating assessment templates is not supported yet`,
            );
        }
      },

      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        if (payload.requestType === "create") {
          return;
        }
        const { type, id, ...patch } = payload.template;
        const patchResult = dispatch(
          notesAndFormsTemplatesApi.util.updateQueryData(
            "getTemplate",
            { type, id },
            (draft) => {
              Object.assign(draft, patch);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },

      transformResponse,
    }),
  }),
});

export const {
  useGetTemplateQuery,
  useListAllTemplatesQuery,
  useSaveTemplateMutation,
} = notesAndFormsTemplatesApi;
