import DOMPurify from "dompurify";
import type Pubnub from "pubnub";
import { useEffect } from "react";
import { apiHostAndPath } from "utils/api";
import { reportProblem } from "utils/frontend_error_reporting";
import {
  ChannelData,
  ChatAccessDataMapByChannel,
  ChatMessageNotificationData,
} from "../pubnub.types";
import chatMessageDecrypt from "../utils/chat-message-decrypt";

const useChatNotifications = (
  userSub: string | undefined,
  channels: {
    [channel: string]: ChannelData;
  },
  chatAccessDataByChannel: ChatAccessDataMapByChannel,
  pubNubInstance: Pubnub | null,
  pubNubAuthKey: string,
  channelsDispatch,
) => {
  useEffect(() => {
    if (!pubNubInstance || !pubNubAuthKey || !chatAccessDataByChannel) return;

    const channelsDecrypted: Map<
      string,
      Array<ChatMessageNotificationData>
    > = new Map();
    for (const channelData of Object.values(channels)) {
      const access = Array.from(chatAccessDataByChannel.values()).find(
        (session) => session.channel === channelData.channel,
      );
      const cipherKey = access?.cipherKey;
      if (!cipherKey) {
        // skip and wait for the key
        continue;
      }

      const messagesDecrypted: Array<ChatMessageNotificationData> = [];
      for (const messageData of channelData.messagesEncrypted) {
        const encryptedContent = messageData.contentEncrypted;
        let contentSanitized: string | undefined = undefined;
        if (encryptedContent) {
          const msg = chatMessageDecrypt(
            pubNubInstance,
            encryptedContent,
            cipherKey,
          );
          if (!msg) {
            reportProblem({
              reporter_module:
                "OrchidPubNubStateContext/handle_private_file_event",
              context: {},
              frontend_error: "failed to decrypt message entry",
            });
            return;
          }
          contentSanitized = DOMPurify.sanitize(msg.content) as string;
        }
        messagesDecrypted.push({
          sender: messageData.sender,
          messageTimetoken: messageData.messageTimetoken,
          msgUniqueId: messageData.msgUniqueId,
          file: messageData.file,
          reaction: messageData.reaction,
          content: contentSanitized,
          receiptsByCognitoSub: {},
        });
      }
      if (messagesDecrypted.length > 0) {
        channelsDecrypted.set(channelData.channel, messagesDecrypted);
      }
    }

    if (channelsDecrypted.size > 0) {
      channelsDispatch({
        type: "messages_decrypt",
        channels: channelsDecrypted,
      });
    }

    for (const messages of channelsDecrypted.values()) {
      // group messages by partner
      const byPartners = new Map<string, ChatMessageNotificationData[]>();
      for (const message of messages) {
        // filter out messages sent by current user
        if (message.sender === userSub || message.sender === undefined)
          continue;
        let partnerMessages = byPartners.get(message.sender);
        if (partnerMessages === undefined) {
          partnerMessages = [];
          byPartners.set(message.sender, partnerMessages);
        }
        partnerMessages.push(message);
      }
      for (const [sender, messages] of byPartners.entries()) {
        const content = messages.map((m) => m.content).join("\n");
        try {
          new Notification("New message from on Orchid", {
            icon: apiHostAndPath(
              `/api/users/orchid_pro/profile_image/${sender}`,
            ),
            body:
              content && content.length > 0
                ? content
                : "New message from on Orchid",
            tag: messages[0]?.msgUniqueId || "",
          });
        } catch (err) {
          // TODO: IMPROVE shall we report this?
          console.error(
            "this browser doesn't seem to support notifications:",
            err,
          );
        }
      }
    }
  }, [
    userSub,
    channels,
    chatAccessDataByChannel,
    channelsDispatch,
    pubNubInstance,
    pubNubAuthKey,
  ]);
};

export default useChatNotifications;
