import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import FullWidthImage from "components/full-width-image";
import LazyImage from "components/lazy-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import Footer from "components/footer/Footer";
import { Link } from "react-router-dom";
import { AmplitudePageName } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { questions } from "./EHRSecurity";
import styles from "./ESPhoneView.module.scss";

const LANDING_PHONE = "/img/ehr-security/landing-phone.png";

const ICON_1_PHONE = "/img/ehr-security/icon-1-phone.png";
const ICON_2_PHONE = "/img/ehr-security/icon-2-phone.png";
const ICON_3_PHONE = "/img/ehr-security/icon-3-phone.png";

const ICON_4_PHONE = "/img/ehr-security/icon-4-phone.png";
const ICON_5_PHONE = "/img/ehr-security/icon-5-phone.png";
const ICON_6_PHONE = "/img/ehr-security/icon-6-phone.png";

const ICON_7_PHONE = "/img/ehr-security/icon-7-phone.png";
const ICON_8_PHONE = "/img/ehr-security/icon-8-phone.png";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "ehr-security-page";

export const items = [
  {
    icon: <LazyImage src={ICON_1_PHONE} width={72} height={72} />,
    title: "Advanced EHR Security Measures",
    content:
      "Orchid’s HIPAA-compliant EHR software prioritizes the confidentiality, privacy, and system availability of your data. Our unwavering commitment to security emphasizes safeguarding your electronic health records through state-of-the-art EHR security measures.",
  },
  {
    icon: <LazyImage src={ICON_2_PHONE} width={72} height={72} />,
    title: "SOC 2 Type 2 Compliance",
    content:
      "Our system's security meets the standards of SOC 2 Type 2 compliance. That ensures the highest level of security for both patient and clinician data on our platform.",
  },
  {
    icon: <LazyImage src={ICON_3_PHONE} width={72} height={72} />,
    title: "Military-Grade Encryption",
    content:
      "We use the most durable end-to-end encryption methods to secure sensitive data. This means that your files are encrypted during transmission and encrypted again when stored in our system, which minimizes the risk of data loss or unauthorized access.",
  },
  {
    icon: <LazyImage src={ICON_4_PHONE} width={72} height={72} />,
    title: "Continuous Security Monitoring",
    content:
      "Our security team conducts regular scans of all services for vulnerabilities, promptly addressing any identified risks. We also consult with leading independent security firms to stay vigilant about external vulnerability threat assessments and penetration testing. These EHR security measures ensure our defenses remain up-to-date and effective against evolving threats.",
  },
  {
    icon: <LazyImage src={ICON_5_PHONE} width={72} height={72} />,
    title: "High System Availability",
    content:
      "We guarantee a high system uptime (99.9% availability), and we have fail-safes for those just-in-case scenarios. Our data backup system is rigorous, with data stored in top-tier data centers that are protected 24/7, 365 days/year, ensuring continuous service and data protection even in case of catastrophic events.",
  },
  {
    icon: <LazyImage src={ICON_6_PHONE} width={72} height={72} />,
    title: "Stringent Data Handling",
    content:
      "We adhere to strict data-handling requirements for electronic Protected Health Information (PHI), Personally Identifiable Information (PII), and Payment Card Information (PCI). This includes secure disposal systems and role-based access permissions, which ensures that only authorized personnel have to access sensitive information.",
  },
  {
    icon: <LazyImage src={ICON_7_PHONE} width={72} height={72} />,
    title: "Your Data, Your Control",
    content:
      "Orchid respects your data ownership and privacy. We do not share your data for any item. If at any point you cancel your Orchid subscription, all patient data is securely removed from our systems.",
  },
  {
    icon: <LazyImage src={ICON_8_PHONE} width={72} height={72} />,
    title: "Industry-Leading Cyber Liability Insurance",
    content:
      "To further protect our customers, we maintain industry-leading cyber liability insurance. That liability insurance covers a range of potential claims and provides an additional layer of security and peace of mind for all Orchid customers.",
  },
];

const ESPhoneView = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  return (
    <div className={styles["phone-view"]}>
      <div className={styles["landing"]}>
        <div className={styles["landing__title"]}>
          Unparalleled EHR Security You Can Trust
        </div>
        <div className={styles["landing__content"]}>
          Put your confidence in Orchid’s expert EHR security measures.
        </div>
        <div className={"d-flex justify-content-center"}>
          <FullWidthImage src={LANDING_PHONE} fluid />
        </div>
        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Get Your Secure Free Trial
        </Button>
      </div>

      <div className={styles["item-list"]}>
        <h2 className={styles["item-list__title"]}>
          Guaranteed Worry-Free EHR Security
        </h2>
        <div className={styles["item-list__content"]}>
          Orchid is committed to the most rigorous EHR security measures, so you
          can focus on your commitment to client care.
        </div>

        {items.map((reason) => {
          return (
            <Card className={styles["item-list__card"]}>
              <div className={styles["item-list__card-icon"]}>
                {reason.icon}
              </div>

              <div className={styles["item-list__card-title"]}>
                {reason.title}
              </div>
              <div className={styles["item-list__card-content"]}>
                {reason.content}
              </div>
            </Card>
          );
        })}
      </div>

      <div className={styles["commitment"]}>
        <div className={styles["commitment__title"]}>
          Our commitment to your security is no joke.
        </div>
        <div className={styles["commitment__content"]}>
          The whole team here at Orchid understands the critical importance of
          EHR security. We continuously evolve our security measures to meet and
          exceed industry standards, ensuring that everything from data
          protection to network protection is covered.
        </div>

        <Button
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Try Orchid for Free
        </Button>
      </div>

      <div className={styles["phone-view__faq"]}>
        <h2 className={styles["phone-view__title"]}>
          FAQ: <br />
          Orchid EHR Security & Compliance
        </h2>
        {questions.map(({ id, question, answer }, idx) => {
          return (
            <CustomAccordion
              key={id}
              id={id}
              index={idx}
              question={question}
              answer={answer}
              amplitudePageName={AMPLITUDE_PAGE_NAME}
            />
          );
        })}
      </div>
      <div className={styles["more-question"]}>
        <div className={styles["more-question__title"]}>
          Got more questions about Orchid?
        </div>
        <Button
          className={styles["more-question__action"]}
          as={Link}
          to={"/orchid-ehr-faq"}
          size={"lg"}
        >
          Read the rest of our FAQs
        </Button>
      </div>
      <div className={styles["slogan"]}>
        <div className={styles["slogan__title"]}>
          Private Practice – Made Perfect
        </div>
        <div className={styles["slogan__content"]}>
          Save time, money, and admin headaches when you switch to Orchid today.
          <br />
          Experience the difference of managing your practice with Orchid.
        </div>

        <Button
          size={"lg"}
          className={styles["action"]}
          as={Link}
          to={"/professional-register"}
        >
          Switch to Orchid
        </Button>
      </div>
      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default ESPhoneView;
