import React, { useEffect, useState, useRef } from "react";
import { Button, InputGroup, Form } from "react-bootstrap";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import Footer from "components/footer/Footer";
import BookADemo from "components/Subscription/pricing-page/book-a-demo/BookADemo";
import FullWidthImage from "components/full-width-image";
import RangeSlider from "./RangeSlider";
import { Link } from "react-router-dom";
import { AmplitudePageName } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { questions } from "./SavingsCalculator";

import getClasses from "utils/getClasses";
import styles from "./SCPhoneView.module.scss";

const LANDING_PHONE = "/img/savings-calculator/landing-phone.png";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "savings-calculator-page";

const STOPhoneView = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  const [showResult, setShowResult] = useState(false);

  // num of clients
  const [value, setValue] = useState({ min: 0, max: 51 });
  const [rate, setRate] = useState<string>("");

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles["phone-view"]}>
      <div className={styles["save"]}>
        <div className={styles["save__title"]}>
          Save More When You Switch to Orchid
        </div>
        <div className={getClasses("mb-3", styles["save__content"])}>
          Orchid saves you so much time, money, and frustration, we made you
          this handy tool to calculate exactly how much time & money you’ll
          save.<span className={styles["save__star"]}>*</span>
        </div>{" "}
        <div className={styles["save__content"]}>
          <span className={styles["save__star"]}>*</span> Frustration saved =
          priceless.
        </div>
        <FullWidthImage
          src={LANDING_PHONE}
          className={styles["save__image"]}
          fluid
        />
      </div>
      <div ref={containerRef} className={styles["calculator"]}>
        <div className={styles["calculator__title"]}>
          Orchid EHR <br /> Cost Savings Calculator
        </div>

        {!showResult ? (
          <div className={styles["calculator__card"]}>
            <div className={styles["calculator__text"]}>
              # of clients you see each week:
            </div>
            <div className={styles["calculator__range-slider-container"]}>
              <span className={styles["calculator__zero"]}>0</span>{" "}
              <div className={styles["calculator__range-slider"]}>
                <RangeSlider
                  min={0}
                  max={50}
                  step={1}
                  value={value}
                  onChange={setValue}
                />{" "}
              </div>
              <span className={styles["calculator__fifty"]}>50 Clients</span>
            </div>

            <div className={styles["calculator__text"]}>Your hourly rate:</div>
            <div className={"d-flex align-items-center"}>
              <InputGroup className={styles["calculator__form-control"]}>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  placeholder={"75"}
                  type="text"
                  value={rate || ""}
                  onChange={(e) => setRate(e.target.value)}
                />
              </InputGroup>{" "}
              <span className={"ml-2"}>/ hr</span>
            </div>
            <hr className={styles["calculator__hr"]} />

            <Button
              className={styles["calculator__action"]}
              onClick={() => {
                setShowResult(true);
              }}
            >
              See Your Saving Instantly
            </Button>

            <div className={styles["calculator__small-text"]}>
              <i>Don’t worry: We never (ever) save or sell this info.</i>
            </div>
          </div>
        ) : (
          <div
            className={getClasses(
              styles["calculator__card"],
              styles["calculator__result"],
            )}
          >
            <div className={styles["calculator__result-title"]}>
              Your Saving are Ready!
            </div>
            <div className={styles["calculator__result-small-text"]}>
              Based on what you entered...
            </div>
            <div className={styles["calculator__result-text"]}>
              Orchid will:
            </div>
            <div className={styles["calculator__result-card"]}>
              <div className={styles["calculator__result-sub-title"]}>
                save you
              </div>
              <div className={styles["calculator__saving-line"]}>
                up to{" "}
                <div className={styles["calculator__result-card-number"]}>
                  $
                  {(
                    ((value.min * 50 * parseInt(rate || "0")) / 1000) *
                    11.6
                  ).toLocaleString()}
                </div>{" "}
                each and every year.
              </div>
            </div>

            <div className={styles["calculator__result-card"]}>
              <div className={styles["calculator__result-sub-title"]}>
                help you reclaim
              </div>
              <div className={styles["calculator__saving-line"]}>
                up to{" "}
                <div className={styles["calculator__result-card-number"]}>
                  {value.min !== 0 ? value.min * 0.5 * 50 : "0"} hours
                </div>{" "}
                of your time annually.
              </div>
            </div>

            <div className={styles["calculator__result-card"]}>
              <div className={styles["calculator__result-sub-title"]}>
                & even save the healthcare industry
              </div>
              <div className={styles["calculator__saving-line"]}>
                up to{" "}
                <div className={styles["calculator__result-card-number"]}>
                  ${(value.min * 25 * parseInt(rate || "0")).toLocaleString()}
                </div>{" "}
                of your time annually.
              </div>
            </div>
            <p className={styles["calculator__only-when"]}>...but only when</p>
            <div className={styles["calculator__result-sub-title"]}>
              you start your free 30-day trial today.
            </div>
            <hr className={styles["calculator__hr"]} />
            <div className={styles["calculator__action-group"]}>
              <Button
                size={"lg"}
                className={getClasses("mb-3", styles["calculator__action"])}
                as={Link}
                to={"/pricing"}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                Pick your Plan to Start Saving
              </Button>
              <Button
                size={"lg"}
                variant={"outline-primary"}
                onClick={() => {
                  setShowResult(false);
                  if (containerRef.current) {
                    containerRef.current.scrollIntoView();
                  }
                }}
              >
                Retry Calculation
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className={styles["faq"]}>
        <h2
          className={getClasses(
            styles["bottom-24"],
            styles["phone-view__title"],
          )}
        >
          FAQ: <br />
          Wondering how we calculated your EHR cost saving results?
        </h2>
        {questions.map(({ id, question, answer }, idx) => {
          return (
            <CustomAccordion
              key={id}
              id={id}
              index={idx}
              question={question}
              answer={answer}
              amplitudePageName={AMPLITUDE_PAGE_NAME}
            />
          );
        })}
      </div>

      <BookADemo amplitudePageName={AMPLITUDE_PAGE_NAME} />
      <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
    </div>
  );
};

export default STOPhoneView;
